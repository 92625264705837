import { Component, Input, OnInit } from '@angular/core';
import { SpeechSynthesisService } from '@app/core/service';
import { NgIf } from '@angular/common';

@Component({
    selector: 'nemo-speaker',
    templateUrl: './speaker.component.html',
    styleUrls: ['./speaker.component.scss'],
    standalone: true,
    imports: [NgIf]
})
export class SpeakerComponent implements OnInit {

  @Input() text: string;
  @Input() languageCode: string;

  available = true;
  public paused = false;

  constructor(private readonly speechSynthesisService: SpeechSynthesisService) {

  }
  ngOnInit(): void {
    if(this.languageCode ==='nc') {
      this.available = false;
    }
  }


  speak() {
    this.speechSynthesisService.speak(this.text, this.languageCode);
    this.paused = false;
  }

  pause() {
    if (this.paused) {
      this.speechSynthesisService.resume();
    } else {
      this.speechSynthesisService.pause();
    }
    this.paused = !this.paused;
  }

}
