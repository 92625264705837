import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';
import { PlanEtudeRomandSearchViewModel } from '@app/model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlanEtudeRomandService {
  
  

  private readonly url = '/plan-etude-romand';

  constructor(private readonly http: HttpBaseService) { }

  public search(disciplineCode: string, year: string,axe: string, hasCriteria: boolean, term: string): Observable<PlanEtudeRomandSearchViewModel[]> {
    return this.http.get<PlanEtudeRomandSearchViewModel[]>(`${this.url}/${disciplineCode}/${year}/${axe}/${hasCriteria}/${term}`);
  }

  public get(id: string): Observable<PlanEtudeRomandSearchViewModel> {
    return this.http.get<PlanEtudeRomandSearchViewModel>(`${this.url}/${id}`);
  }

  public fetchDisciplines(year:string, hasCriteria: boolean): Observable<string[]> {
    return this.http.get<string[]>(`${this.url}/disciplines/${year}/${hasCriteria}`);
  }
  public fetchYears(hasCriteria: boolean): Observable<number[]> {
    return this.http.get<number[]>(`${this.url}/annees/${hasCriteria}`);
  }
  public fetchAxesThematiques(disciplineCode: string, year: string , hasCriteria: boolean): Observable<string[]> {
    return this.http.get<string[]>(`${this.url}/axes/${disciplineCode}/${year}/${hasCriteria}`);
  }
  public generateJsonForChatGpt(): Observable<string> {
    return this.http.get<string>(`${this.url}/generate-per-for-chat-gpt`);
  }
  public generateLearningJsonForChatGpt(): Observable<string> {
    return this.http.get<string>(`${this.url}/generate-learning-per-for-chat-gpt`);
  }
  public generateExcel(){
    const curDate = this.http.getCurrentFormattedDate(true);
    this.http.downloadExcel(`${this.url}/generate-per-excel`,`per-${curDate}.xlsx`);
  }
  public uploadObjectifsCriterias(formData: FormData): Observable<any> {
    return this.http.upload(`${this.url}/update-form-chat-gpt`,formData);
  }s
}
