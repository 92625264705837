import { Component } from '@angular/core';
import { ParcoursRechercheComponent } from '../../parcours-recherche/parcours-recherche.component';
import { BasepageComponent } from '../../../../shared/components/basepage/basepage.component';

@Component({
    selector: 'nemo-parcours-libre',
    templateUrl: './parcours-libre.component.html',
    styleUrls: ['./parcours-libre.component.scss'],
    standalone: true,
    imports: [BasepageComponent, ParcoursRechercheComponent]
})
export class ParcoursLibreComponent {

  constructor() { }

}
