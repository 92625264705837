import { Component, OnInit } from '@angular/core';
import { PreferencesService, ReferenceDataService } from '@app/core/service';
import { NgIf } from '@angular/common';
import { RouterLinkActive, RouterLink, RouterOutlet } from '@angular/router';
import { BasepageComponent } from '../../../shared/components/basepage/basepage.component';
import { SettingsService } from '@app/core/service/settings.service';

@Component({
    selector: 'nemo-booklets',
    templateUrl: './booklets.component.html',
    styleUrls: ['./booklets.component.scss'],
    standalone: true,
    imports: [BasepageComponent, RouterLinkActive, RouterLink, NgIf, RouterOutlet]
})
export class BookletsComponent implements OnInit {

  newrpn = 0;
  publisher = false;

  constructor(private readonly referenceDataService: ReferenceDataService,
    public preferencesService: PreferencesService,
    public settings: SettingsService
  ) {
  }
  ngOnInit() {
    this.newrpn = this.referenceDataService.getNewState().newBookletModels;
    this.publisher = this.preferencesService.isPublisher();
  }

}
