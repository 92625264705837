

export class Courses {
  courses: CourseSummary[];
}

export class CourseSummary {

  id: number;
  title?: string;
  subtitle?: string;
  duration?: number;
  icon?: string;
  color?: string;
  categories?: string[];
  ribbon?: string;
  mine: boolean;
  distributed: boolean;
  libre: boolean;
  rpn: boolean;
  teacher: boolean;
  progression: number;
  author?: string;
  createdOn?: Date;
  updatedOn?: Date;
  distributedBy?: string;
  distributedFrom?: Date;
  distributedTo?: Date;
  DistributedBy?: string;
  perObjectiveCodes?: string[];

  constructor(model?: CourseSummary) {
    if (model) {
      this.id = model.id;
      this.ribbon = model.ribbon;
      this.duration = model.duration;
      this.icon = model.icon;
      this.title = model.title;
      this.categories = model.categories;
      this.mine = model.mine;
      this.distributed = model.distributed;
      this.rpn = model.rpn;
      this.libre = model.libre;
      this.color = model.color;
      this.progression = model.progression;
      this.author = model.author;
      this.createdOn = model.createdOn;
      this.updatedOn = model.updatedOn;
      this.perObjectiveCodes = model.perObjectiveCodes;
    }
  }
}
