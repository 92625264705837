<div class="lead" *ngFor="let cat of tags;let i = index">
  <div *ngIf="edit">
    <h6 *ngIf="title" class="card-subtitle mb-2 text-muted">{{title}}</h6>
    <a [routerLink]="[]" *ngFor="let tag of cat" class="badge rounded-pill me-1" [ngClass]="{
      'category-tag': !tag.selected,
      'bg-primary': tag.selected
    }" (click)="toggle(tag)">{{ tag.value }}</a>
  </div>
  <div *ngIf="!edit" >
    <h6 *ngIf="title" class="card-subtitle my-1 text-muted">{{title}}</h6>
    <span  [routerLink]="[]" *ngFor="let tag of cat" class="badge rounded-pill category-tag me-1">{{ tag.value }}</span>
  </div>
</div>
