<div *ngIf="evaluation && edit" class="d-none d-print-inline"><span [ngClass]="[evaluation.icon]" [ngStyle]="{'color': evaluation.color}"></span><span class="ms-1" *ngIf="displayLabel">{{evaluation.label}}</span></div>
<div *ngIf="edit && scale">
  <div class="btn-group" role="group" aria-label="Basic example">
    <button *ngFor="let level of scale.levels" ngbTooltip="{{ level.label }}"
      [ngClass]="[evaluationCode === level.code?'btn-'+level.colorClass:'', evaluationCode !== level.code?'btn-outline-secondary':'btn-outline-'+level.colorClass]" class="btn btn-sm" type="button" [attr.aria-pressed]="evaluationCode === level.code" (click)="updateEvaluation(level)" name="options" id="option1" autocomplete="off" checked>
      <span [ngClass]="[level.icon]" [ngStyle]="{'color': evaluationCode === level.code ?'#fff':level.color}"></span><span class="ms-1" [ngStyle]="{'color': evaluationCode === level.code ?'#fff':level.color}" *ngIf="displayLabel">{{level.label}}</span>
    </button>
  </div>
</div>
<div *ngIf="!edit && evaluation" [ngClass]="{'inline': inline}">
  <span [ngClass]="[evaluation.icon]" [ngStyle]="{'color': evaluation.color}"></span><span class="ms-1"  [ngStyle]="{'color': evaluation.color}" *ngIf="displayLabel">{{evaluation.label}}</span>
</div>


