<div class="modal-header">
    <h4 class="modal-title">Ajout d'un média</h4>
    <button type="button" class="btn-close" aria-label="btn-close" (click)="activeModal.dismiss()">

    </button>
  </div>
  <div class="modal-body">
    <nemo-media-upload [mediaUrl]="mediaUrl" [onlyImages]="onlyImages" (mediaChanged)="update($event)"></nemo-media-upload>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss()">Annuler</button>
    <button type="button" class="btn btn-outline-primary" (click)="activeModal.close({ url: this.mediaUrl, name: this.mediaName, icon: this.mediaIcon })">Valider</button>
  </div>

