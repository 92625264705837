import { Injectable } from '@angular/core';
import { CourseSummary, Search, SearchNatureEnum } from '@app/model';
import { RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { ParcoursService } from './parcours.service';
import { take, mergeMap } from 'rxjs/operators';
import { PreferencesService } from './preferences.service';

@Injectable({
  providedIn: 'root'
})
export class ParcoursRechercheResolverService  {

  constructor(private readonly parcoursService: ParcoursService,
  private readonly router: Router,
  private readonly preferencesService: PreferencesService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): CourseSummary[] | Observable<CourseSummary[]> | Promise<CourseSummary[]> {
      const search = this.parcoursService.getSearchFilter();
      search.skip = 0;
      if (route.routeConfig.path === '') {
        if (!this.preferencesService.isStudent()) {
          search.nature = SearchNatureEnum.Private;
          this.parcoursService.setSearchFilter(search);
          return this.parcoursService.getCourses(search).pipe(
            take(1),
            mergeMap(courses => {
              if (courses.length > 0) {
                this.router.navigate(['/parcours/personnels']);
                return EMPTY;
              } else {
                this.router.navigate(['/parcours/rpn']);
                return EMPTY;
              }
            }));
        } else  {
          search.nature = SearchNatureEnum.Teacher;
          this.parcoursService.setSearchFilter(search);
          return this.parcoursService.getCourses(search).pipe(
            take(1),
            mergeMap(courses => {
              if (courses.length > 0) {
                this.router.navigate(['/parcours/enseignants']);
                return EMPTY;
              } else {
                this.router.navigate(['/parcours/rpn']);
                return EMPTY;
              }
            }));
        }
      } else if (route.routeConfig.path === 'personnels') {
        search.nature = SearchNatureEnum.Private;
      } else if (route.routeConfig.path === 'rpn') {
        search.nature = SearchNatureEnum.Rpn;
      } else if (route.routeConfig.path === 'enseignants') {
        search.nature = SearchNatureEnum.Teacher;
      } else if (route.routeConfig.path === 'pour-enseignant') {
        search.nature = SearchNatureEnum.ForTeacher;
      } else {
        search.nature = SearchNatureEnum.Public;
      }
    this.parcoursService.setSearchFilter(search);
    if (route.routeConfig.path === 'enseignants') {
      return this.parcoursService.getCoursesDistributedByTeachers();
    }
    return this.parcoursService.getCourses(search).pipe(
      take(1),
      mergeMap(courses => {
        return of(courses);
      }));
  }
}
