import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { AutoEvaluationService, PreferencesService } from '@app/core/service';
import { Preferences, UserName, UserProfile } from '@app/model';
import { Subscription } from 'rxjs';
import { NgEventBus } from 'ng-event-bus';
import { UserNotificationService } from '@app/core/service/user-notification.service';
import { NgIf } from '@angular/common';
import { AuthenticationService } from '@app/core/service/authentication.service';
import { SettingsService } from '@app/core/service/settings.service';


@Component({
    selector: 'nemo-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    standalone: true,
    imports: [RouterLink, NgIf, RouterLinkActive]
})
export class MenuComponent implements OnInit {

  public preferences: Preferences;
  public userName: UserName;
  public routeMatchUserProfile = false;
  private subscription: Subscription;
  notificationCount = 0;
  notificationEnabled = false;
  selfEvaluationMenuEnabled = false;
  userProfile: UserProfile;

  get hasNotifications(){
    return this.notificationCount > 0;
  }

  constructor(
    private readonly authService: AuthenticationService,
    public router: Router,
    private readonly messageBusService: NgEventBus,
    public readonly settings: SettingsService,
    private readonly userNotificationService: UserNotificationService,
    private readonly preferencesService: PreferencesService,
    private readonly autoEvaluationService: AutoEvaluationService,
    private readonly renderer2: Renderer2) {
  }

  ngOnInit() {
    this.notificationEnabled = this.settings.feature_flags.notifications;
    this.subscription = this.messageBusService.on('notif').subscribe(() => {
      this.notificationCount++;
    });
    this.messageBusService.on('unotif-all').subscribe(() => {
      this.notificationCount = 0;
    });
    this.messageBusService.on('unotif').subscribe(() => {
      this.notificationCount--;
    });
    this.userProfile = this.authService.getCurrentUserProfile();
    this.userName = this.authService.getUsername();
    
    if(this.notificationEnabled){
      this.userNotificationService.count().subscribe(c => this.notificationCount = c);
    }
    
    this.preferencesService.fetchPreferences().subscribe({
      next: (p) => {
        this.preferences = p;
        this.userName = this.authService.getUsername();
        let mode = p.darkMode? 'dark':'light';
        this.renderer2.setAttribute(document.querySelector('html'), 'data-bs-theme', mode);
      },
      error: (e) => console.error(e),
      complete: () => {}
    });
    // .subscribe(p=>{
    //   
    // },
    // error => {this.preferences = new {

    // }});
    this.routeMatchUserProfile = this.router.url.indexOf('preferences') >= 0;
    this.autoEvaluationService.hasEvaluation().subscribe(r => this.selfEvaluationMenuEnabled = r);

  }

  get isLoggedIn(): boolean {
    return this.authService.getCurrentUserProfile().isAuthenticated;
  }

  isStudent(): boolean {
    return this.preferencesService.isStudent();
  }

  isTeacher(): boolean {
    return this.preferencesService.isTeacher();
  }

  isAdmin(): boolean {
    return this.preferencesService.isAdmin();
  }
}
