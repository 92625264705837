<div class="row" *ngIf="currentItem">
  <div class="col-md-6 mb-2" [ngClass]="{'offset-md-3' : !anyHintProvided}">
    <div class="card text-center">
      <ul class="list-group list-group-flush">
        <li class="list-group-item">
          <nemo-item-text [ngClass]="{invisible: !displayLearn}"
            [displayText]="displayLearn"
            [mask]="config.hide"
            [text]="currentItem.learnTxt"
            [languageCode]="list.learnLanguage">
          </nemo-item-text>
        </li>
        <li class="list-group-item">
          <nemo-item-text [ngClass]="{invisible: !displayHint}" [text]="hint" [enableSpeech]="false" [languageCode]="list.hintLanguage"></nemo-item-text>
        </li>
        <li class="list-group-item">
          <div class="input-group">
            <input type="text" class="form-control text-center {{ size }}" nemoFocus
              [ngClass]="{'is-valid':state === AvState.Valid,'is-invalid':state === AvState.NotValid }"
              (keydown)="onKeydown($event)" [(ngModel)]="answer" placeholder="" aria-label="réponse"
              autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" maxlength="999">
            <button class="btn btn-outline-secondary" [disabled]="answer.length < 2" (click)="speakAnswer()"
              type="button"><span class="bi bi-volume-up-fill"></span></button>
            <button class="btn btn-outline-secondary" [disabled]="state===AvState.Valid" (click)="validate()"
              type="button"><span class="bi bi-check-lg"></span></button>
          </div>
        </li>
      </ul>
      <div class="card-footer d-grid gap-2">
        <button type="button" [disabled]="displayLearn" class="btn btn-outline-secondary" (click)="display()">
          <span class="bi bi-eye-fill"></span> Afficher<kbd>&darr;</kbd>
        </button>
        <button [disabled]="state !== AvState.Valid" type="button" class="btn btn-outline-success" (click)="endItem(true)">
          <span class="bi bi-check-lg"></span> Suivant<kbd>&rarr;</kbd>
        </button>
        <button type="button" class="btn btn-outline-warning" (click)="endItem(false)">
          <span class="bi bi-arrow-clockwise"></span> À répéter<kbd>&larr;</kbd>
        </button>
      </div>
    </div>
  </div>
  <div class="col-md-6" *ngIf="anyHintProvided">
    <div class="card text-center">
      <img *ngIf="currentItem.hintImgUrl" class="card-img-top" [src]="currentItem.hintImgUrl" [alt]="currentItem.hintTxt" [ngClass]="{'invisible': !(currentItem.hintImgUrl && currentItem.hintImgUrl.length > 0)}">
      <div class="card-body" *ngIf="currentItem.hintTxt && currentItem.hintTxt.length > 0">
         <nemo-item-text  [text]="currentItem.hintTxt" [languageCode]="list.hintLanguage"></nemo-item-text>
      </div>
    </div>
  </div>
</div>
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Régler</h4>
    <button type="button" class="btn-close" aria-label="btn-close" (click)="modal.dismiss()"></button>
</div>
  <form>
    <ul class="list-group list-group-flush">
      <li class="list-group-item">
        <div class="d-flex flex-wrap py-1">
          <span class="lead flex-grow-1 pe-2">Durée d'affichage du terme à apprendre</span>
          <div class="align-self-center">
            <div class="btn-group" role="group">

              <input type="radio" class="btn-check" name="duration" [(ngModel)]="config.duration" id="duration0" [value]="0" [checked]="config.duration === 0"> 
              <label class="btn btn-outline-secondary" for="duration0">Ne pas afficher</label>
              
              <input type="radio" class="btn-check" name="duration" [(ngModel)]="config.duration" id="duration1" [value]="1" [checked]="config.duration === 1">
              <label class="btn btn-outline-secondary" for="duration1">Jusqu'à la saisie</label>
              
              <input type="radio" class="btn-check" name="duration" [(ngModel)]="config.duration" id="duration2" [value]="2" [checked]="config.duration === 2">
              <label class="btn btn-outline-secondary" for="duration2">Toujours</label>
            </div>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="d-flex flex-wrap py-1">
          <div class="flex-grow-1 align-self-center pe-2">
            <label class="lead form-check-label" for="sonorize">Entendre le mot à l'affichage ?</label>
          </div>
          <div class="align-self-center">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" role="switch" id="sonorize"  [(ngModel)]="config.sonorize" [ngModelOptions]="{standalone: true}">
            </div>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="d-flex flex-wrap py-1">
          <div class="flex-grow-1 align-self-center pe-2">
            <label class="lead form-check-label" for="sonorizeHint">Entendre l'indice ?</label>
          </div>
          <div class="align-self-center">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" role="switch" id="sonorizeHint"  [(ngModel)]="config.sonorizeHint" [ngModelOptions]="{standalone: true}">
            </div>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="d-flex flex-wrap py-1">
          <span class="lead flex-grow-1 pe-2">Masque</span>
          <div class="align-self-center">
            <div class="btn-group" role="group">

              <input type="radio" class="btn-check" name="hide" [(ngModel)]="config.hide" id="hide0" [value]="0" [checked]="config.hide === 0"> 
              <label class="btn btn-outline-secondary" for="hide0">Désactivé</label>
              
              <input type="radio" class="btn-check" name="hide" [(ngModel)]="config.hide" id="hide1" [value]="1" [checked]="config.hide === 1">
              <label class="btn btn-outline-secondary" for="hide1">Haut</label>
              
              <input type="radio" class="btn-check" name="hide" [(ngModel)]="config.hide" id="hide2" [value]="2" [checked]="config.hide === 2">
              <label class="btn btn-outline-secondary" for="hide2">Milieu</label>

              <input type="radio" class="btn-check" name="hide" [(ngModel)]="config.hide" id="hide3" [value]="3" [checked]="config.hide === 3">
              <label class="btn btn-outline-secondary" for="hide3">Bas</label>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </form>
  <div class="modal-footer">
    <small><a [routerLink]="[]" (click)="displayPreferences()">D'autres réglages sont disponibles dans les
      préférences.</a></small>
    <button type="button" class="btn btn-outline-primary" ngbAutofocus (click)="modal.close()">Valider</button>
  </div>
</ng-template>
