<div class="card mb-2"
  [ngClass]="{ editable: edit, noteditable: !edit, 'border-secondary': item.selected, 'bg-body-tertiary': item.selected }">
  <div class="d-flex">
    <div class="p-3 align-self-center" *ngIf="edit">
      <span class="bi bi-three-dots-vertical d-none d-md-block"></span>
      <div class="d-md-none">
        <button type="button" class="btn btn-outline-secondary btn-sm mb-1" tooltip="Monter" tooltipPlacement="top" (click)="moveItemUp()"><span class="bi bi-chevron-up"></span></button><br>
        <button type="button" class="btn btn-outline-secondary btn-sm" tooltip="Descendre" tooltipPlacement="top" (click)="moveItemDown()"><span class="bi bi-chevron-down"></span></button>
      </div>
    </div>
    <div *ngIf="selectable" class="d-flex flex-shrink-1 align-items-center p-2">
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" role="switch"  (change)="toggleSelect($event)" [(ngModel)]="item.selected">
      </div>
    </div>
    <div class="flex-fill flex-grow-1">
      <div class="d-flex flex-column flex-md-row">
        <div class="p-2 flex-fill flex-grow-1 align-self-center">
          <div class="input-group">
            <input #learn type="text" [readonly]="!edit" [ngClass]="{ 'form-control-plaintext': !edit, 'form-control': edit }" (focus)="recieveFocus()"
              [(ngModel)]="item.learnTxt" (input)="inputUpdate($event)" autocomplete="off"
              autocorrect="off" autocapitalize="off" spellcheck="false" maxlength="999" />
          </div>
          <small class="text-muted d-none d-md-block" *ngIf="edit">à apprendre</small>
        </div>
        <div class="p-2 flex-fill flex-grow-1 align-self-center">
          <div class="input-group">
            <input #hint type="text" [readonly]="!edit" [ngClass]="{ 'form-control-plaintext': !edit, 'form-control': edit }" (focus)="recieveFocus()"
              [(ngModel)]="item.hintTxt" (input)="inputUpdate($event)" autocomplete="off" autocorrect="off"
              autocapitalize="off" spellcheck="false" maxlength="999" />
          </div>
          <small class="text-muted d-none d-md-block" *ngIf="edit">indice textuel</small>
        </div>
        <div class="p-2 flex-shrink-1 media-preview-thumbnail">
          <nemo-media-preview [url]="item.hintImgUrl" (click)="changeImg()"></nemo-media-preview>
        </div>
      </div>
    </div>
    <div class="p-2 align-self-center flex-shrink-1" *ngIf="edit">
      <button type="button" class="btn btn-outline-secondary btn-sm" tooltip="Supprimer l'item" tooltipPlacement="top"
        (click)="removeItem()">
        <span class="bi bi-trash-fill"></span>
      </button>
    </div>
  </div>
  <div class="d-flex" *ngIf="advanced">
    <div class="flex-fill p-3 align-self-center" *ngIf="edit">
      <tag-input [(ngModel)]="item.tags" [modelAsStrings]="true" [placeholder]="'Ajouter'"
        [secondaryPlaceholder]="'Catégories'" [maxItems]="8" (onTextChange)="inputUpdate()" (onAdd)="inputUpdate()"
        (onRemove)="inputUpdate()"></tag-input>
    </div>
  </div>
</div>
