<div class="modal-header">
  <h4 class="modal-title">Plan d'étude romand</h4>
  <button type="button" class="btn-close" aria-label="btn-close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <div class="my-2">    
    <nemo-plan-etude-romand-selector [(selectedPers)]="selectedPers" (planEtudeRomandSelected)="activeModal.close(multiSelect?$event:$event[0])" [multi]="multiSelect" [hasCriteria]="hasCriteria"></nemo-plan-etude-romand-selector>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-success" *ngIf="selectedPers.length >0" (click)="activeModal.close(selectedPers)">Valider</button>
  <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss()">Annuler</button>
</div>

