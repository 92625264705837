import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { BasepageComponent } from '../../../shared/components/basepage/basepage.component';

@Component({
    selector: 'nemo-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.scss'],
    standalone: true,
    imports: [BasepageComponent, RouterLink]
})
export class PageNotFoundComponent {

  constructor(private readonly location: Location,
  private readonly router: Router) { }

  back(): void {
    this.location.back();
  }

  home(): void {
    this.router.navigate(['/']);
  }
}
