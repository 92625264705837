<div class="modal-header">
  <h4 class="modal-title">Sélection de la période</h4>
  <button type="button" class="btn-close" aria-label="btn-close" (click)="activeModal.dismiss()">

  </button>
</div>
<div class="modal-body">
    <div class="row row-cols-1 row-cols-md-2 g-4" *ngIf="!selectCustomDate">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Demain</h5>
            <p>Le parcours sera disponible uniquement demain matin et pour la journée.</p>
            <div class="d-grid gap-2">
              <a [routerLink]="[]" class="stretched-link" class="btn btn-outline-secondary stretched-link" (click)="tomorrow()">Demain uniquement <span class="bi bi-arrow-right"></span></a>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Le reste de la semaine</h5>
            <p>Le parcours sera disponible dès maintenant et pour le reste de la semaine jusqu'à vendredi.</p>
            <div class="d-grid gap-2">
              <a [routerLink]="[]" class="stretched-link" class="btn btn-outline-secondary stretched-link" (click)="tillFriday()">Jusqu'à vendredi <span class="bi bi-arrow-right"></span></a>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">La semaine prochaine</h5>
            <p>Le parcours sera disponible dès lundi prochaine et pour le reste de la semaine jusqu'à vendredi.</p>
            <div class="d-grid gap-2">
              <a [routerLink]="[]" class="stretched-link" class="btn btn-outline-secondary stretched-link" (click)="nextWeek()">Toute la semaine prochaine <span class="bi bi-arrow-right"></span></a>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Tout le temps</h5>
            <p>Le parcours sera disponible dès maintenant et pour le reste de l'année.</p>
            <div class="d-grid gap-2">
              <a [routerLink]="[]" class="stretched-link" class="btn btn-outline-secondary stretched-link" (click)="ever()">Tout le temps <span class="bi bi-arrow-right"></span></a>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Durée personnalisée</h5>
            <p>Choisissez une période pendant laquelle le parcours sera disponible.</p>
            <div class="d-grid gap-2">
              <a [routerLink]="[]" class="stretched-link" class="btn btn-outline-secondary stretched-link" (click)="custom()">Durée personnalisée <span class="bi bi-arrow-right"></span></a>
            </div>
          </div>
        </div>
      </div>
    
    </div>
    <div *ngIf="selectCustomDate">
      <div class="row">
        <div class="col-md-8">
          <ngb-datepicker #dp (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t" outsideDays="hidden"></ngb-datepicker>
        </div>
        <div class="col-md-4">
          <div class="mb-2">
            <p class="lead mb-0">Heure de début : </p>
            <ngb-timepicker [(ngModel)]="fromTime"></ngb-timepicker>
          </div>
          <div>
            <p class="lead mb-0">Heure de fin : </p>
            <ngb-timepicker [(ngModel)]="toTime"></ngb-timepicker>
          </div>
        </div>
      </div>
    </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss()" ngbAutofocus>Annuler</button>
  <button type="button" *ngIf="selectCustomDate" class="btn btn-outline-primary" (click)="endDateSelected()">Valider</button>
</div>
<ng-template #t let-date="date" let-focused="focused">
  <span class="custom-day"
        [class.focused]="focused"
        [class.range]="isRange(date)"
        [class.faded]="isHovered(date) || isInside(date)"
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null">
    {{ date.day }}
  </span>
</ng-template>


