import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpBaseService } from './http-base.service';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class MediaServerService {
private readonly url = '/mediaserver';
  constructor(
    private readonly http: HttpBaseService,
    private readonly settings: SettingsService) { }

  isMediaServerRessource(url: string): boolean {
    return url.startsWith(this.settings.mediaServer);
  }
  getMediaServerUrl(baseurl: string): Observable<string> {
    let url = '/';
    if (baseurl.startsWith(this.settings.mediaServer)) {
      url = baseurl.slice(this.settings.mediaServer.length);
    }
    return this.http.get<string>(`${this.url}?url=${encodeURIComponent(url)}`).pipe(map(s => {
      return `${this.settings.mediaServer}${s}`;
    }));
  }
}
