import { SearchNatureEnum } from "./search-nature";

export class Search {
  nature: SearchNatureEnum  = SearchNatureEnum.Public;
  title: string = '';
  categories: string[] = [];
  type: string='';
  skip: number = 0;
  onlyDistributed: boolean;
  planEtudeRomandId: string='';
}
