export class Teacher {

  displayName: string;
  samAccountName: string;
  upn: string;
  email: string;
  sid: string;
  department: string;
  objectGuid: string;
  employeeId: string;
  description: string;

  constructor(model?: Teacher) {
    if (model) {
      this.displayName = model.displayName;
      this.samAccountName = model.samAccountName;
      this.upn = model.upn;
      this.email = model.email;
      this.sid = model.sid;
      this.department = model.department;
      this.objectGuid = model.objectGuid;
      this.employeeId = model.employeeId;
      this.description = model.description;
    }
  }
}
