<div class="row">
  <div class="col-md-8 offset-md-2">
    <div class="py-2 my-2" [@itemState]="state">
      <div *ngIf="list" class="my-2" [dragula]="'dnditems'" [(dragulaModel)]="workinglist">
        <div class="card mb-2 p-2" *ngFor="let i of workinglist">
          <nemo-item-text [text]="i.learnTxt" [languageCode]="list.learnLanguage" [displayText]="config.hide !== 2" [mask]="config.hide"></nemo-item-text>
        </div>
      </div>
      <div class="d-grid gap-2">
        <button type="button" class="btn btn-outline-success" (click)="validate()"><span class="bi bi-check-lg"></span> Valider</button>
      </div>
    </div>
  </div>
</div>
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Régler</h4>
    <button type="button" class="btn-close" aria-label="btn-close" (click)="modal.dismiss()"></button>
  </div>
  <form>
    <ul class="list-group list-group-flush">
      <li class="list-group-item">
        <div class="d-flex flex-wrap py-1">
          <span class="lead flex-grow-1 pe-2">Nombre d'éléments à trier</span>
          <div class="align-self-center">
            <div class="btn-group" role="group">

              <input type="radio" class="btn-check" name="howmanywords" [(ngModel)]="config.howmanywords" id="howmanywords0" [value]="0" [checked]="config.howmanywords === 0"> 
              <label class="btn btn-outline-secondary" for="howmanywords0">Tous</label>
              
              <input type="radio" class="btn-check" name="howmanywords" [(ngModel)]="config.howmanywords" id="howmanywords1" [value]="1" [checked]="config.howmanywords === 1">
              <label class="btn btn-outline-secondary" for="howmanywords1">Maximum 5</label>
              
              <input type="radio" class="btn-check" name="howmanywords" [(ngModel)]="config.howmanywords" id="howmanywords2" [value]="2" [checked]="config.howmanywords === 2">
              <label class="btn btn-outline-secondary" for="howmanywords2">Maximum 10</label>
            </div>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="d-flex flex-wrap py-1">
          <span class="lead flex-grow-1 pe-2">Affichage</span>
          <div class="align-self-center">
            <div class="btn-group" role="group">

              <input type="radio" class="btn-check" name="hide" [(ngModel)]="config.hide" id="hide0" [value]="0" [checked]="config.hide === 0"> 
              <label class="btn btn-outline-secondary" for="hide0">Tout afficher</label>
              
              <input type="radio" class="btn-check" name="hide" [(ngModel)]="config.hide" id="hide4" [value]="4" [checked]="config.hide === 4">
              <label class="btn btn-outline-secondary" for="hide4">Cacher le début</label>
              
              <input type="radio" class="btn-check" name="hide" [(ngModel)]="config.hide" id="hide2" [value]="2" [checked]="config.hide === 2">
              <label class="btn btn-outline-secondary" for="hide2">Cacher tout</label>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </form>
  <div class="modal-footer">
    <small><a [routerLink]="[]" (click)="displayPreferences()">D'autres réglages sont disponibles dans les préférences.</a></small><button type="button" class="btn btn-outline-primary"  ngbAutofocus (click)="modal.close()">Valider</button>
  </div>
</ng-template>
