export class Item {
  id: number;
  selected?: boolean;
  filtered?: boolean;
  listId?: string;
  learnTxt?: string;
  hintTxt?: string;
  hintImgUrl?: string;
  flag?: number;
  tags?: string[];

  constructor(model?: Item) {
    if (model) {
      this.id = model.id;
      this.listId = model.listId;
      this.learnTxt = model.learnTxt;
      this.hintTxt = model.hintTxt;
      this.hintImgUrl = model.hintImgUrl;
      this.flag = model.flag;
      this.selected = model.selected;
      this.filtered = model.filtered;
      this.tags = model.tags;
    }
  }
}

