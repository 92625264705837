import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MediaService, ToastService } from '@app/core/service';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { MediaPreviewComponent } from '../media-preview/media-preview.component';
import { DndDirective } from '../../directives/dnd.directive';
import { NgIf } from '@angular/common';
import { SettingsService } from '@app/core/service/settings.service';

@Component({
    selector: 'nemo-media-upload',
    templateUrl: './media-upload.component.html',
    styleUrls: ['./media-upload.component.scss'],
    standalone: true,
    imports: [NgIf, DndDirective, MediaPreviewComponent, RouterLink, FormsModule]
})
export class MediaUploadComponent {

  @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef;
  @Input() mediaUrl: string;
  @Input() onlyImages = true;
  @Input() mediaName: string;
  @Input() mediaIcon: string;
  @Output() mediaChanged = new EventEmitter<any>();
  uploadInProgress = false;
  uploadProgression = 0;


  constructor(
    private readonly mediaService: MediaService,
    private readonly toastService: ToastService,
    private readonly settings: SettingsService
  ) { }

  /**
  * on file drop handler
  */
  onFileDropped($event) {
    this.uploadAndRetreiveUrl($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.uploadAndRetreiveUrl(files);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  uploadAndRetreiveUrl(file: any) {
    // Control file type and size

    if (this.mediaService.getAllowedMimeTypes().filter(m => m.startsWith('image') || !this.onlyImages).indexOf(file.type) < 0) {
      this.toastService.error(`Le type de fichier ${file.type} n'est pas supporté.`);
      return;
    }
    if ((this.settings.mediaMaxSizeInMo * 1024 * 1024) < file.size) {
      this.toastService.error(`La taille du fichier dépasse la limite de ${this.settings.mediaMaxSizeInMo} Mo.`);
      return;
    }

    // Upload
    const formData = new FormData();
    formData.append('file', file);
    this.uploadInProgress = true;
    this.mediaService.upload(formData).pipe(
      map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              this.uploadProgression = Math.round(event.loaded * 100 / event.total);
              break;
            case HttpEventType.Response:
              this.uploadInProgress = false;
              return event;
          }
        }),
      catchError((error: HttpErrorResponse) => {
        console.log(error.message);
        this.toastService.error('Le téléversement du fichier n\'a pas abouti');
        return of(`${file.data.name} upload failed.`);
      })).subscribe((event: any) => {
        if (typeof (event) === 'object') {
          this.mediaUrl = event.body.url;
          this.mediaName = event.body.name;
          this.mediaIcon = this.mediaService.getIcon(event.body.type);
          this.mediaChanged.emit({
            url:this.mediaUrl,
            name: this.mediaName,
            icon: this.mediaIcon,
            type: event.body.type
          });
        }
      });
  }
  clear() {
    this.mediaUrl = undefined;
    this.mediaChanged.emit({
      url: undefined,
      name: undefined,
      icon: 'edicons-visualidentity-rpn',
      type: undefined
    });
  }
  download() {
    window.open(this.mediaUrl, '_blank');
  }
  update(value: string) {
    this.mediaUrl = value;
    this.mediaChanged.emit({
      url:this.mediaUrl,
      name: this.mediaName,
      icon: this.mediaIcon,
      type: undefined
    });

  }

}
