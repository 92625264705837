<div class="card px-2">
  <div class="d-flex p-1">
    <div class="align-self-center me-1 ">
      <span class="bi bi-three-dots-vertical d-none d-md-block"></span>
      <div class="d-md-none">
        <button type="button" class="btn btn-outline-secondary btn-sm mb-1" tooltip="Monter" tooltipPlacement="top" (click)="up()"><span class="bi bi-chevron-up"></span></button><br>
        <button type="button" class="btn btn-outline-secondary btn-sm" tooltip="Descendre" tooltipPlacement="top" (click)="down()"><span class="bi bi-chevron-down"></span></button>
      </div>
    </div>
    <div class="align-self-center flex-grow-1">
      <nemo-parcours-item-detail [item]="item"></nemo-parcours-item-detail>
    </div>
    <div class="align-self-center">
      <div class="btn-group" role="group" aria-label="actions sur la ressource">
        <button type="button" class="btn btn-outline-secondary btn-sm" tooltip="Éditer" tooltipPlacement="top"
          (click)="editStep()"><span class="bi bi-pen-fill"></span></button>
        <button type="button" class="btn btn-outline-secondary btn-sm" tooltip="Supprimer" tooltipPlacement="top"
          (click)="deleteStep()"><span class="bi bi-trash-fill"></span></button>
      </div>
    </div>
  </div>
</div>
