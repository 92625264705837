
<p class="lead">Il n'a jamais été aussi facile de créer des listes avec ces outils.</p>
<div class="row">
  <div class="col-md-4" *ngIf="conjugaisonEnabled">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Conjugaison</h5>
        <p class="card-text">Créer rapidement du contenu pour la conjugaison dans diverses langues.</p>
        <div class="d-grid gap-2">
          <a [routerLink]="['../conjugaison']" class="btn btn-outline-secondary stretched-link">Démarrer <span class="bi bi-arrow-right"></span></a>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Texte</h5>
        <p class="card-text">Créer une liste à partir d'un texte.</p>
        <div class="d-grid gap-2">
          <a [routerLink]="['../texte']" class="btn btn-outline-secondary stretched-link">Démarrer <span class="bi bi-arrow-right"></span></a>
        </div>
      </div>
    </div>
  </div>
</div>
