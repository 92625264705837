import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RedirectionService {

  private readonly storageKey = 'redirect-to';

  constructor() { }

  public storeRedirectionUrl(url: string) {
    sessionStorage.setItem(this.storageKey, url);
  }
  public getAndFlushRedirectionUrl(): string {
    const red = sessionStorage.getItem(this.storageKey);
    sessionStorage.removeItem(this.storageKey);
    return red;
  }

}
