import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { PreferencesService, RedirectionService } from '../service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../service/authentication.service';

@Injectable({
  providedIn: 'root',
})

export class AdminGuard  {
  constructor(
    private readonly preferencesService: PreferencesService,
    private readonly authService: AuthenticationService,
    private readonly redirectionService: RedirectionService,
    private readonly router: Router
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const url: string = state.url;
    if (this.preferencesService.isAdmin()) {
      return true;
    }
    if(!this.authService.currentUserProfile.isAuthenticated){
      this.redirectionService.storeRedirectionUrl(url);
    }
    this.router.navigate(['/unauthorized']);
    return false;
  }
}