import { Injectable } from '@angular/core';
import { ConjugaisonItem } from '@app/model';
import { Observable } from 'rxjs';
import { HttpBaseService } from './http-base.service';

@Injectable({
  providedIn: 'root'
})
export class ConjugaisonService {

  private readonly url = '/conjugaison';

  constructor(private readonly http: HttpBaseService) { }

  public getAvailableLanguages(): Observable<string[]> {
    return this.http.get<string[]>(`${this.url}/langues`);
  }

  public getAvailableVerbs(languageCode: string, year: number): Observable<string[]> {
    return this.http.get<string[]>(`${this.url}/verbes?langue=${languageCode}&annee=${year}`);
  }

  public getAvailableTenses(languageCode: string, year: number): Observable<string[]> {
    return this.http.get<string[]>(`${this.url}/temps?langue=${languageCode}&annee=${year}`);
  }

  public getItems(verbs: string[], languageCode: string, tenses: string[]): Observable<ConjugaisonItem[]> {
    const v = verbs.join("&verbes=");
    const t = tenses.join("&temps=");
    return this.http.get<ConjugaisonItem[]>(`${this.url}/items?verbes=${v}&langue=${languageCode}&temps=${t}`);
  }
}
