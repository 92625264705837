import { Component, OnInit } from '@angular/core';
import { ConjugaisonSelectorResult } from '@app/model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConjugationSelectorComponent } from '../conjugation-selector/conjugation-selector.component';

@Component({
    selector: 'nemo-conjugation-selector-modal',
    templateUrl: './conjugation-selector-modal.component.html',
    styleUrls: ['./conjugation-selector-modal.component.scss'],
    standalone: true,
    imports: [ConjugationSelectorComponent]
})
export class ConjugationSelectorModalComponent{
  learnLanguageCode = 'fr';
  useHintInFrench = true;
  constructor(public activeModal: NgbActiveModal) { }

  validate(result: ConjugaisonSelectorResult){
    this.activeModal.close(result);
  }
}
