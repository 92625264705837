import { CourseSummary } from './coursesummary';
import { CourseItem } from './courseitem';

export class Course extends CourseSummary {

  description: string;
  planEtudeRomandIds: string[];
  items: CourseItem[];

  constructor(model?: Course) {
    super(model);
    if (model) {
      this.description = model.description;
      this.items = model.items;
      this.planEtudeRomandIds = model.planEtudeRomandIds;
    }
  }
}
