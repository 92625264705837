<div *ngIf="uploadInProgress">
  <div class="card m-3 p-3 text-center">
    <p class="lead">{{onlyImages ? 'L\'image' : 'Le fichier'}} est en cours de chargement.</p>
    <p class="lead">Merci de patienter un petit moment...</p>
    <div  class="text-center">
      <div class="spinner-grow text-secondary" role="status">
        <span class="visually-hidden">Recherche en cours...</span>
      </div>
    </div>
  </div>
</div>
<div class="dropzone text-center border p-3" *ngIf="!mediaUrl && !uploadInProgress" nemoDnd (fileDropped)="onFileDropped($event[0])">
  <input type="file" #fileDropRef id="fileDropRef" (change)="fileBrowseHandler($event.target.files[0])" />
  <h1 class="display-2"><em class="bi bi-cloud-arrow-up-fill"></em></h1>
  <h3>Déposer {{onlyImages ? 'une image' : 'un fichier'}} ici</h3>
  <p>ou</p>
  <h3><label class="form-label" for="filedrop" class="btn btn-primary">Sélectionner {{onlyImages ? 'une image' : 'un fichier'}}</label></h3>
</div>
<div *ngIf="mediaUrl">
  <div class="text-center">
    <nemo-media-preview [url]="mediaUrl" [fluid]="true" [(displayImage)]="mediaUrl"></nemo-media-preview>
  </div>
  <div class="row mt-2">
    <div class="col d-grid gap-2">
      <button class="btn btn-outline-danger btn-sm" type="button" [disabled]="!mediaUrl" (click)="clear()"><span class="bi bi-x-lg"></span> Effacer</button>
    </div>
    <div class="col d-grid gap-2">
      <a [routerLink]="[]" class="btn btn-outline-secondary btn-sm" role="button" (click)="download()"><span class="bi bi-download"></span> Télécharger</a>
    </div>
  </div>
</div>
<div class="lead text-center">ou</div>
<div class="form-group" *ngIf="!uploadInProgress">
  <label for="box">Adresse web</label>
  <input #box type="url" class="form-control" [(ngModel)]="mediaUrl" placeholder="Adresse web" aria-label="Adresse web du média"
    (keyup.enter)="update(box.value)" (blur)="update(box.value)" autocomplete="off" autocorrect="off"  (click)="box.select()"
    autocapitalize="off" spellcheck="false" maxlength="999" />
  <small class="form-text text-muted">Coller ici le lien internet d'une image<span *ngIf="!onlyImages"> ou d'un fichier</span></small>
</div>

