import { Component,  Input } from '@angular/core';
import { CourseItem } from '@app/model';
import { MomentModule } from 'ngx-moment';
import { LogoComponent } from '../logo/logo.component';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'nemo-parcours-item-detail',
    templateUrl: './parcours-item-detail.component.html',
    styleUrls: ['./parcours-item-detail.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf, LogoComponent, MomentModule]
})
export class ParcoursItemDetailComponent {
  @Input() item: CourseItem;
}
