import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';
import { Search, SearchNatureEnum, Teacher } from '@app/model';
import { Observable } from 'rxjs';
import { Booklet, Observation, EvaluationScale, CriteriaObservations, StudentObservations, MonitoredStudent, Criteria } from '@app/model/booklet';


@Injectable({
  providedIn: 'root'
})
export class BookletService {
  
  
  

  private readonly url = '/folio';
  private searchFilter: Search;

  constructor(private readonly http: HttpBaseService) { }

  public getBooklets(search: Search): Observable<Booklet[]> {
    let apiPath: string;
    switch (search.nature) {
      case SearchNatureEnum.Rpn: {
        apiPath = '/rpn';
        break;
      }
      case SearchNatureEnum.Private: {
        apiPath = '/personnels';
        break;
      }
      case SearchNatureEnum.GeneratedFromPlanEtudeRomand: {
        apiPath = '/plan-etude-romand';
        break;
      }
      default: {
        apiPath = '/personnels';
        break;
      }
    }
    return this.http.get<Booklet[]>(this.url + apiPath, {
      params: {
        text: search.title.trim(),
        categories: search.categories,
        skip: search === null ? '0' : `${search.skip}`
        // onlyDistributed: false,
        // progressionPerIds: search.progressionPerIds,
        // attentePerIds: search.attentePerIds
      },
    });
  }

  public getPersonnals(): Observable<Booklet[]> {
    return this.getBooklets({
      title: '',
      type:'',
      nature: SearchNatureEnum.Private,
      categories: [],
      skip: 0,
      onlyDistributed: false,
      planEtudeRomandId: ''
    });
  }

  public get(id: string): Observable<Booklet> {
    return this.http.get<Booklet>(`${this.url}/${id}`);
  }

  public getCsvExport(id: number):Observable<string> {
    return this.http.get<string>(`${this.url}/${id}/csv`);
  }

  public getExcelExport(id: number){
    const curDate = this.http.getCurrentFormattedDate(true);
    this.http.downloadExcel(`${this.url}/${id}/xlsx`,`grille-observation-${curDate}.xlsx`);
  }

  public getNew(): Observable<Booklet> {
    return this.http.post<Booklet>(this.url, {
      title: 'Nouveau folio',
      icon: 'edicons edicons-visualidentity-folio',
      color: 'c-fushia',
    });
  }

  public createNew(booklet: Booklet): Observable<Booklet> {
    return this.http.post<Booklet>(this.url, booklet);
  }

  public clone(booklet: Booklet): Observable<Booklet> {
    return this.http.post<Booklet>(this.url, booklet);
  }

  public save(booklet: Booklet): Observable<Booklet> {
    return this.http.put<Booklet>(this.url, booklet);
  }

  public delete(id: number): Observable<Booklet> {
    return this.http.delete<Booklet>(`${this.url}/${id}`);
  }

  public getSearchFilter(): Search {
    if (this.searchFilter) {
      return this.searchFilter;
    } else {
      return {
        nature: SearchNatureEnum.Private,
        title: '',
        type:'',
        categories: [],
        skip: 0,
        onlyDistributed: false,
        planEtudeRomandId: ''
      };
    }
  }

  public setSearchFilter(filter: Search) {
    this.searchFilter = filter;
  }


  public getObservations(bookletId: number): Observable<Observation[]> {
    return this.http.get<Observation[]>(`${this.url}/${bookletId}/observations`);
  }

  public getObservationsByCriteria(bookletId: number, criteriaId: number): Observable<CriteriaObservations> {
    return this.http.get<CriteriaObservations>(`${this.url}/${bookletId}/observations/critere/${criteriaId}`);
  }

  public getObservationsByStudent(bookletId: number, monitoredStudentId: number): Observable<StudentObservations> {
    return this.http.get<StudentObservations>(`${this.url}/${bookletId}/observations/eleve/${monitoredStudentId}`);
  }

  public saveObservationsForStudent(bookletId: number, monitoredStudentId: number, observations: Observation[]): Observable<Observation[]> {
    return this.http.put<Observation[]>(`${this.url}/${bookletId}/observations/eleve/${monitoredStudentId}`, observations);
  }

  public saveObservationsForCriteria(bookletId: number, criteriaId: number, observations: Observation[]): Observable<Observation[]> {
    return this.http.put<Observation[]>(`${this.url}/${bookletId}/observations/critere/${criteriaId}`, observations);
  }

  public getEvaluationScale(op: boolean): EvaluationScale {
    return {
      levels : [
        {
          code: 'ok',
          label: op ? 'Atteint' : 'Déjà observé',
          color: op ? '#449d44' : '#28a745',
          colorClass: 'success',
          icon: 'bi bi-check-circle'
        },
        {
          code: 'nok',
          label: op ? 'Pas atteint' : 'Abordé, pas encore observé',
          color: op ? '#c9302c' : '#ffc107',
          colorClass: op ? 'danger' :  'warning',
          icon: 'bi bi-x-circle'
        },
        {
          code: 'unrated',
          label: op ? 'Non évalué' : 'Non observable',
          color: op ? '#17a2b8' : '#6c757d',
          colorClass: op ? 'info' : 'secondary',
          icon: op ? 'bi bi-slash-circle' : 'bi bi-circle'
        }
      ]
    };
  }
  public getSelfEvaluationScale(op: boolean): EvaluationScale {
    return {
      levels : [
        {
          code: 'ok',
          label: op ? 'Atteint' : 'Je sais',
          color: op ? '#449d44' : '#28a745',
          colorClass: 'success',
          icon: 'bi bi-check-circle-fill'
        },
        {
          code: 'nok',
          label: op ? 'Pas atteint' : 'Je ne sais pas encore',
          color: op ? '#c9302c' : '#ffc107',
          colorClass: op ? 'danger' :  'warning',
          icon: 'bi bi-x-circle-fill'
        }
      ]
    };
  }
  public generateStudentReports(booklet: Booklet, students: MonitoredStudent[], obs: Observation[]) {
    this.internalGenOPReport(booklet, students, obs);
  }
  public generateStudentReport(booklet: Booklet, student: MonitoredStudent, obs: Observation[]) {
    this.internalGenOPReport(booklet, [student], obs);
  }

  public generateEvaluationReports(booklet: Booklet, students: MonitoredStudent[], obs: Observation[]) {
    this.internalGenOPReport(booklet, students, obs);
  }
  public generateEvaluationReport(booklet: Booklet, student: MonitoredStudent, obs: Observation[]) {
    this.internalGenOPReport(booklet, [student], obs);
  }


  public transmit(teacher: Teacher, bookletid: number): Observable<boolean> {
    return this.http.put<boolean>(`${this.url}/transmit/${bookletid}` , teacher);
  }

  public getCriteriasForPlanEtudesRomand(persIds: string[]): Observable<Criteria[]> {
    const v = persIds.join("&persIds=");
    return this.http.get<Criteria[]>(`${this.url}/criteres-per?persIds=${v}`);
  }
  importFolioTemplate(): Observable<any> {
    return this.http.get(`${this.url}/generate`);
  }
  generatePerFromCiip(): Observable<any> {
    return this.http.get(`${this.url}/importFromCiip`);
  }
  deleteDeprecatedFolios(): Observable<any> {
    return this.http.delete(`${this.url}/deprecated-folio`);
  }

  internalGenOPReport(booklet: Booklet, students: MonitoredStudent[], obs: Observation[]) {
    const curDate = this.http.getCurrentFormattedDate(true);
    const pdfurl = students.length === 1 ? `${this.url}/report/standard/${booklet.id}/${students[0].id}` : `${this.url}/reports/standard/${booklet.id}`;
    // eslint-disable-next-line max-len
    this.http.download(pdfurl, students.length > 1 ? `rapports-${booklet.title}-${curDate}.pdf` : `rapport-${students[0].displayName}-${curDate}.pdf`);
  }

  
}
