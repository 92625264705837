<nemo-basepage [title]="'Groupes'" *ngIf="!loading">
  <div page-body *ngIf="groupes">
    <p>Ces groupes vous permettent de gérer les <a [routerLink]="['/parcours/distributions']">distributions sur les parcours</a> et les <a [routerLink]="['/folios/personnels']">folios</a>.</p>
    <p>Certains sont issus de <a href="https://cloee.rpn.ch/cloee2" rel="noopener noreferrer" target="_blank">CLOEE</a>, mais vous pouvez importer des groupes depuis <a href="https://portail.rpn.ch/administration/logi-a/Pages/ad-prn.aspx" rel="noopener noreferrer" target="_blank">PRONOTE</a> en suivant la procédure accessible ici.</p>
    <div class="row">
      <div class="col-lg-3 col-md-4">
        <div class="mb-2">
          <nemo-card [title]="'Ajouter'" [icon]="'bi bi-plus-circle-fill'" (clicked)="addGroup()"></nemo-card>
        </div>
      </div>
      <div class="col-lg-3 col-md-4" *ngFor="let g of groupes">
        <div class="card mb-2" >
          <div class="card-body d-flex align-items-center">
            <div class="lead">
              <span *ngIf="g.readOnly">
                {{g.name}}
              </span>
              <div class="input-group" *ngIf="!g.readOnly">
                <input type="text"[(ngModel)]="g.name" class="form-control" placeholder="Nom du groupe" aria-label="Nom du groupe" aria-describedby="basic-addon1" required="">
                <button class="btn btn-outline-secondary" type="button" id="button-addon2" (click)="updateGroupName(g.key,g.name)"><i class="bi bi-save-fill"></i></button>
              </div>
            </div>
           
            <div class="flex-grow-1">
              <button type="button" class="btn btn-link"  (click)="toggleGroupView(g)"><i class="bi" [ngClass]="[g.isCollapsed?'bi-chevron-down':'bi-chevron-up']"></i></button>
            </div>
            <div *ngIf="!g.readOnly">
            <button type="button" class="btn btn-outline-secondary" (click)="deleteGroup(g)"><em class="bi bi-trash-fill"></em></button>
          </div>
        </div>
          <ul class="list-group list-group-flush" #collapse="ngbCollapse"  [(ngbCollapse)]="g.isCollapsed">
            <li *ngFor="let student of eleves[g.key]" class="list-group-item">
              <div class="d-flex  align-items-center">
                <small class="flex-grow-1">{{student.displayName}}</small>
                <div *ngIf="!g.readOnly">
                  <button type="button" class="btn btn-outline-secondary btn-sm" (click)="deleteGroupStudent(g,student)"><em class="bi bi-trash-fill"></em></button>
                </div>
              </div>
            </li>
            <li  class="list-group-item" *ngIf="!g.readOnly">
              <nemo-student-search-input (addStudent)="addStudent($event, g)"></nemo-student-search-input>
            </li>
            <li  class="list-group-item" *ngIf="!g.readOnly">
              <button type="button" class="btn btn-outline-secondary" (click)="importInGroup(g)">Importer un groupe depuis PRONOTE</button>
            </li>
          </ul>
      </div>
    </div>
    </div>
    <button type="button" class="btn btn-outline-secondary" (click)="bulkImportInGroup()">Importer des groupes depuis PRONOTE</button>
  </div>
</nemo-basepage>