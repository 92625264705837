import { Component, Input } from '@angular/core';
import { DateRange } from '@app/model';
import { NgbActiveModal, NgbCalendar, NgbTimepickerConfig, NgbTimeStruct, NgbDatepicker, NgbTimepicker } from '@ng-bootstrap/ng-bootstrap';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';

@Component({
    selector: 'nemo-date-range-selector',
    templateUrl: './date-range-selector.component.html',
    styleUrls: ['./date-range-selector.component.scss'],
    standalone: true,
    imports: [NgIf, RouterLink, NgbDatepicker, NgbTimepicker, FormsModule]
})
export class DateRangeSelectorComponent {

  @Input() range: DateRange = {
    start: null,
    end: null
  };

  selectCustomDate = false;

  hoveredDate: NgbDate;

  fromDate: NgbDate;
  toDate: NgbDate;

  fromTime: NgbTimeStruct = {hour: 7, minute: 30, second: 0};
  toTime: NgbTimeStruct = {hour: 17, minute: 30, second: 0};


  constructor(public activeModal: NgbActiveModal, public calendar: NgbCalendar, config: NgbTimepickerConfig) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
    config.minuteStep = 5;
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }


  today() {
    this.fromDate = null;
    this.toDate = this.calendar.getToday();
    this.toTime =  {hour: 23, minute: 55, second: 0};
    this.endDateSelected();
  }
  tomorrow() {
    this.fromDate = this.calendar.getNext(this.calendar.getToday(), 'd', 1);
    this.fromTime =  {hour: 0, minute: 10, second: 0};
    this.toDate = this.calendar.getNext(this.calendar.getToday(), 'd', 1);
    this.toTime =  {hour: 23, minute: 55, second: 0};
    this.endDateSelected();
  }
  tillFriday() {
    this.fromDate = null;
    this.toDate = this.calendar.getNext(
      this.calendar.getToday(),
      'd',
      Math.abs(7 + 5 - this.calendar.getWeekday(this.calendar.getToday())) % 7);
    this.toTime =  {hour: 23, minute: 55, second: 0};
    this.endDateSelected();
  }
  nextWeek() {
    this.fromDate = this.calendar.getNext(
      this.calendar.getToday(),
      'd',
      Math.abs(7 + 1 - this.calendar.getWeekday(this.calendar.getToday())) % 7);
    this.fromTime =  {hour: 0, minute: 10, second: 0};
    this.toDate = this.calendar.getNext(
      this.calendar.getToday(),
      'd',
      (Math.abs(7 + 5 - this.calendar.getWeekday(this.calendar.getToday())) % 7) + 7);
    this.toTime =  {hour: 23, minute: 55, second: 0};
    this.endDateSelected();
  }
  ever() {
    this.fromDate = null;
    this.toDate = null;
    this.endDateSelected();
  }
  custom() {
    this.selectCustomDate = true;
  }

  endDateSelected() {
    // gets the date given
    if (this.fromDate !== null) {
      this.range.start = new Date(this.fromDate.year, this.fromDate.month - 1,
        this.fromDate.day, this.fromTime.hour, this.fromTime.minute, 0, 0) ;
    } else {
      this.range.start = null;
    }
    if (this.toDate !== null) {
      this.range.end = new Date(this.toDate.year, this.toDate.month - 1,
        this.toDate.day, this.toTime.hour, this.toTime.minute, 0, 0) ;
    } else {
      this.range.end = null;
    }

    this.activeModal.close(this.range);
  }

}
