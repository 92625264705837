<nemo-basepage [title]="'Texte'">
    <div page-body>
      <p>Vous pouvez créer une liste de mots à mémoriser à partir du texte que vous insérez ci-dessous.</p>
      <div>
        <label class="form-label" for="exampleFormControlTextarea1">Insérer votre texte ici</label>
        <textarea class="form-control" id="exampleFormControlTextarea1" rows="15" [(ngModel)]="text"></textarea>
      </div>
      <p></p>
      <button type="button" class="btn btn-outline-primary" ngbAutofocus (click)="generateContent()">Créer une liste</button>
    </div>
</nemo-basepage>

