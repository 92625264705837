import { Component, Input, OnInit } from '@angular/core';
import { PlanEtudeRomandService, ReferenceDataService } from '@app/core/service';
import { Category, PlanEtudeRomandSearchViewModel } from '@app/model';
import { ReplacePipe } from '../../pipes/replace.pipe';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'nemo-plan-etude-romand-display',
    templateUrl: './plan-etude-romand-display.component.html',
    styleUrls: ['./plan-etude-romand-display.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, ReplacePipe]
})
export class PlanEtudeRomandDisplayComponent implements OnInit{


  @Input() planEtudeRomand: PlanEtudeRomandSearchViewModel;
  @Input() planEtudeRomandId: string;
  @Input() displayUrl: boolean = true;
  computedYears: string[];
  constructor(private readonly planEtudeRomandService: PlanEtudeRomandService,
    private readonly referenceDataService: ReferenceDataService) {
  }

  ngOnInit(): void {
    if(this.planEtudeRomandId && !this.planEtudeRomand){
      this.planEtudeRomandService.get(this.planEtudeRomandId).subscribe(p=>{
        this.planEtudeRomand = p;
        this.computedYears=this.referenceDataService.computeYears(p.years);
      });
    }else{
      this.computedYears=this.referenceDataService.computeYears(this.planEtudeRomand.years);
    }
  }

  openPer():void{
    window.open(this.planEtudeRomand.url);
  }

}
