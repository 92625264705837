import { Component, OnInit } from '@angular/core';
import { ItemListSummary } from '@app/model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ListeService } from '@app/core/service';
import { RouterLink } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'nemo-itemlist-selector',
    templateUrl: './itemlist-selector.component.html',
    styleUrls: ['./itemlist-selector.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, NgFor, RouterLink, NgClass]
})
export class ItemlistSelectorComponent implements OnInit {

  lists: ItemListSummary[];
  filteredLists: ItemListSummary[];
  selectedList: ItemListSummary;
  filter = '';

  constructor(public activeModal: NgbActiveModal,
    private readonly listService: ListeService) { }

  ngOnInit() {
    this.listService.getPersonnalLists().subscribe(c => {
      this.lists = [...c]
        .sort((l1: ItemListSummary, l2: ItemListSummary) => new Date(l1.updatedOn) < new Date(l2.updatedOn) ? 0 : -1);
      this.filteredLists = this.lists;
    });
  }

  filterlists(event: any) {
    this.filter = event.target.value;
    this.filteredLists =
      this.lists.filter(l => l.title.toLocaleLowerCase().search(new RegExp(this.filter.toLocaleLowerCase(), 'i')) > -1);
  }
  clearFilterlists() {
    this.filter = '';
    this.filteredLists = this.lists;
  }

  selectList(list: ItemListSummary) {
    this.selectedList = list;
  }

  emitChanges() {
    if (this.selectedList) {
      this.listService.getList(this.selectedList.id.toString()).subscribe(l => {
        this.activeModal.close(l);
      });
    }
  }

}
