import { Component, Input } from '@angular/core';
import { ActivityList, CourseItem, Logo } from '@app/model';
import { NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { QuillEditorComponent } from 'ngx-quill';
import { ActivitySelectorComponent } from '../../../shared/components/activity-selector/activity-selector.component';
import { MediaUploadComponent } from '../../../shared/components/media-upload/media-upload.component';
import { FormsModule } from '@angular/forms';
import { LogoComponent } from '../../../shared/components/logo/logo.component';
import { RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';

@Component({
    selector: 'nemo-parcours-item-edition',
    templateUrl: './parcours-item-edition.component.html',
    styleUrls: ['./parcours-item-edition.component.scss'],
    standalone: true,
    imports: [NgIf, RouterLink, LogoComponent, FormsModule, MediaUploadComponent, ActivitySelectorComponent, QuillEditorComponent, NgbInputDatepicker,SharedModule]
})
export class ParcoursItemEditionComponent {

  @Input() item: CourseItem;
  @Input() defaultColor = 'c-grey';
  @Input() defaultIcon = 'edicons-visualidentity-rpn';
  private logoUpdatedByUser = false;

  constructor() { }
  
  updateLogo(logo: Logo) {
    this.logoUpdatedByUser = true;
    this.item.color = logo.color;
    this.item.icon = logo.icon;
  }
  update(value: any) {
    this.item.navigationUrl = value.url;
    if (!this.logoUpdatedByUser) {
      this.item.icon = value.icon;
      this.item.contentType = value.type;
    }

    if(!this.item.title || this.item.title === '' ) {
      this.item.title = value.name;
    }

  }
  updateFromActivity(value: ActivityList){
    this.item.navigationUrl = `${value.activity.route}/${value.list.id}`;
    this.item.title = `${value.list.title} (${value.activity.shortLabel})`;
  }
  selectType(type: string) {
    if (!this.item.itemType) {
      this.item.color = this.defaultColor;
      this.item.icon = this.defaultIcon;
      this.item.title = undefined;
      this.item.description = undefined;
      this.item.navigationUrl = undefined;
      this.item.itemType = type;
      this.item.progressionEnabled = type ==='Link' || type === 'Media' || type === 'Activity';
    }
  }
  openNavigationUrl() {
    window.open(this.item.navigationUrl,'_blank');
  }
  updateClassification(cat: string[]){
    // Changement d'icône sur l'ajout de la catégorie "Moyen d'enseignement officiel" (MER).
    this.item.categories = cat;
  }
}
