import { Item } from '../itemlist';

export class Activity {
  label: string;
  route: string;
  icon: string;
  shortLabel: string;
  caption: string;
  configurable: boolean;
  type: string;
  guards?: ListGuard[];
  config?: ActivityConfig;
}

export interface ListGuard {
  test(items: Item[], traduction: boolean): Item[];
}

export class BothSidedListGuard implements ListGuard {
  test(items: Item[]) {
    return items.filter(i => (i.hintTxt || i.hintImgUrl) && i.learnTxt);
  }
}

export class SingleSidedListGuard implements ListGuard {
  test(items: Item[]) {
    return items.filter(i => i.learnTxt || (i.hintTxt || i.hintImgUrl));
  }
}

export class MinLengthRequiredListGuard implements ListGuard {
  length: number;
  constructor(_length: number) {
    this.length = _length;
  }
  test(items: Item[]) {
    return items.filter(i => i.learnTxt.length >= this.length);
  }
}

export class NonReceptiveListGuard implements ListGuard {
  test(items: Item[]) {
    return items.filter(i => i.tags.filter(t =>
      ['récéptif', 'réceptif', 'receptif', 'receptive'].indexOf(t.toLowerCase()) >= 0
    ).length === 0);
  }
}
export class TwoLanguagesListGuard implements ListGuard {
  test(items: Item[], traduction): Item[] {
    if (traduction) {
      return items;
    }
    return [];
  }
}

export class LearnTxtAndHintTxtFilledListGuard implements ListGuard {
  test(items: Item[]) {
    return items.filter(i => i.hintTxt && i.hintTxt.length > 0 && i.learnTxt && i.learnTxt.length > 0);
  }
}

export class ConjugationListGuard implements ListGuard {
  test(items: Item[]) {
    return items.filter(i => i.tags.filter(t =>
      ['conjugaison'].indexOf(t.toLowerCase()) >= 0
    ).length > 0);
  }
}
export class NonConjugationListGuard implements ListGuard {
  test(items: Item[]) {
    return items.filter(i => i.tags.filter(t =>
      ['conjugaison'].indexOf(t.toLowerCase()) >= 0
    ).length === 0);
  }
}

export abstract class ActivityConfig {

}
