<nemo-basepage [title]="'Modifier le folio'">
  <div page-body *ngIf="userForm" >
    <form [formGroup]="userForm">
      <div class="row">
        <div class="col-lg-4">
          <div>
            <label class="form-label" for="categories" class="visually-hidden">Apparence</label>
            <div class="logo mb-2">
              <nemo-logo [editable]="true" [color]="booklet.color" [icon]="booklet.icon" (editedLogo)="updateLogo($event)">
              </nemo-logo>
            </div>
          </div>
          <div class="form-floating mb-2">
            <input type="text" name="title" id="title" class="form-control form-control-lg" placeholder="Titre"
              formControlName="title" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
              maxlength="149" />
            <label for="title">Titre</label>
          </div>
        </div>
        <div class="col-lg-8" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
          <div class="card mb-2" *ngIf="publisher">
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <span class="fw-bold">Options de publication</span>
              </li>
              <li class="list-group-item">
                <div class="d-flex flex-wrap py-1">
                  <div class="flex-grow-1 align-self-center pe-2">
                    <label class="lead form-check-label" for="rpn">Publier dans les modèles de folios RPN?</label>
                  </div>
                  <div class="align-self-center">
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" role="switch" id="rpn" [(ngModel)]="booklet.template" (change)="itemUpdated()" [ngModelOptions]="{standalone: true}">
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-12">
          <button type="button" form="userForm" class="btn btn-outline-secondary" tooltip="Retour"
            tooltipPlacement="top" [disabled]="userForm.valid && userForm.dirty"
            [routerLink]="['../']">
            <span class="bi bi-arrow-left"></span> Retour
          </button>
          <button type="button" form="userForm" class="btn btn-outline-secondary" tooltip="Supprimer"
            tooltipPlacement="top" [disabled]="userForm.valid && userForm.dirty" (click)="deleteList()"><span
              class="bi bi-trash-fill"></span> Supprimer</button>
          <button type="submit" form="userForm" class="btn btn-primary" tooltip="Sauvegarder" tooltipPlacement="top"
            [disabled]="!userForm.valid || !userForm.dirty || saving" (click)="submitForm()">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="saving"></span><span class="bi bi-save-fill" *ngIf="!saving"></span> Sauvegarder</button>
          <button type="button" class="btn btn-link" (click)="collapse.toggle()" *ngIf="publisher"  [attr.aria-expanded]="!isCollapsed"
            >{{isCollapsed ? 'Plus de détails':'Moins de détails'}}</button>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col">
        <div class="alert alert-warning" role="alert" *ngIf="booklet.criterias.length === 0 || booklet.monitoredStudents.length===0">
          <span class="bi bi-exclamation-circle-fill"></span> Pour commencer il faut ajouter des critères et des élèves.
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <ul ngbNav #nav="ngbNav" class="nav-tabs nav-fill" [(activeId)]="activeId">
          <li [ngbNavItem]="1">
            <a ngbNavLink><span class="bi bi-flag-fill"></span> Critères</a>
            <ng-template ngbNavContent>
              <div class="sticked-top">
                <div class="card my-2">
                  <div class="card-body">
                    <div class="d-flex">
                      <div class="flex-grow-1 me-2 d-grid gap-2">
                        <button type="button" class="btn btn-outline-secondary" tooltip="Ajouter des critères issus du plan d'étude romand" tooltipPlacement="top" (click)="addFromPer()" *ngIf="settings.feature_flags.dacc"><span class="bi bi-plus-circle-fill"></span> Ajouter des critères issus du plan d'étude romand</button>
                        <button type="button" class="btn btn-outline-secondary" tooltip="Ajouter de critère" tooltipPlacement="top" (click)="addCriteria()"  *ngIf="!settings.feature_flags.dacc"><span class="bi bi-plus-circle-fill"></span> Ajouter un critère</button>
                      </div>
                      <nemo-action-menu [placement]="'bottom-left'">
                        <button class="dropdown-item" (click)="addCriteria()" *ngIf="settings.feature_flags.dacc">Ajouter un critère</button>
                        <button class="dropdown-item" (click)="importFromText()">Importer depuis du texte</button>
                        <button class="dropdown-item" (click)="sortCriterias()">Trier automatiquement</button>
                        <button class="dropdown-item" (click)="activateAutoEvaluation()">Activer l'auto évaluation pour tous les critères</button>
                        <button class="dropdown-item" (click)="deactivateAutoEvaluation()">Désactiver l'auto évaluation pour tous les criètres</button>
                      </nemo-action-menu>
                      <div class="ms-2">
                        <button type="submit" form="userForm" class="btn btn-outline-primary" tooltip="Sauvegarder" tooltipPlacement="top" [disabled]="!userForm.valid || !userForm.dirty || saving" (click)="submitForm()"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="saving"></span><span class="bi bi-save-fill" *ngIf="!saving"></span></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div  [dragula]="'dnditems'" [(dragulaModel)]="booklet.criterias" (dragend)="itemUpdated()">
                <div *ngFor="let criteria of booklet.criterias; let i = index">
                  <nemo-criteria [criteria]="criteria" (dropped)="dropCriteria(criteria)" (edited)="editCriteria(criteria)"></nemo-criteria>
                  <div class="text-center d-grid gap-2">
                    <button type="button" class="btn btn-outline-light btn-sm my-1" tooltip="Ajouter un critère" (click)="addCriteria(i + 1)"><span class="bi bi-plus-circle-fill"></span> Ajouter un critère ici</button>
                  </div>
                </div>
                <p id="end"></p>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <a ngbNavLink><span class="bi bi-people-fill"></span> Élèves</a>
            <ng-template ngbNavContent>
              <div class="sticked-top">
                <div class="card my-2">
                  <div class="card-body">
                    <div class="d-flex">
                      <div class="flex-grow-1 me-2 d-grid gap-2">
                        <nemo-student-search (addStudents)="addStudents($event)" (addStudent)="addStudent($event)" (dropStudents)="dropStudents()"></nemo-student-search>
                      </div>
                      <div>
                        <button type="submit" form="userForm" class="btn btn-outline-primary" tooltip="Sauvegarder" tooltipPlacement="top" [disabled]="!userForm.valid || !userForm.dirty" (click)="submitForm()"><span class="bi bi-save-fill"></span></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngFor="let student of booklet.monitoredStudents; let i = index">
                <nemo-user-display (dropUser)="dropStudent(student)">
                  {{student.displayName}}
                </nemo-user-display>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="3">
            <a ngbNavLink><span class="bi bi-people-fill"></span> Équipe pédagogique</a>
            <ng-template ngbNavContent>
              <div class="sticked-top">
                <div class="card my-2">
                  <div class="card-body">
                    <div class="d-flex">
                      <div class="flex-grow-1 me-2 d-grid gap-2">
                        <nemo-teacher-search (addTeacher)="addTeacher($event)"  (dropTeachers)="dropTeachers()"></nemo-teacher-search>
                      </div>
                      <div>
                        <button type="submit" form="userForm" class="btn btn-outline-primary" tooltip="Sauvegarder" tooltipPlacement="top" [disabled]="!userForm.valid || !userForm.dirty" (click)="submitForm()"><span class="bi bi-save-fill"></span></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngFor="let teacher of booklet.grantedTeachers; let i = index">
                <nemo-user-display (dropUser)="dropTeacher(teacher)">
                  {{teacher.displayName}}
                </nemo-user-display>
              </div>
              <div class="card bg-body-tertiary" *ngIf="booklet.mine && featureTransmitEnabled">
                <div class="card-body">
                  <h5 class="card-title">Transmission du folio</h5>
                  <p class="card-text">
                    Vous pouvez transmettre ce folio à un autre enseignant.
                  </p>
                  <button type="button" (click)="openTransitModal()" [disabled]="userForm.valid && userForm.dirty" class="btn btn-outline-secondary"><span class="bi bi-send-fill"></span> Transmettre</button>
                </div>
              </div>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
      </div>
    </div>
  </div>
</nemo-basepage>
