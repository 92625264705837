import { Injectable } from '@angular/core';
import { CheckSpellResult } from '@app/model';
import { sanitizeUrl } from '@braintree/sanitize-url';

@Injectable({
  providedIn: 'root'
})
export class InputValidationService {

  constructor() { }

  getHint(target: string, test: string): string {
    let resultConfirmator = '';
    const length = target.length < test.length ? test.length : target.length;
    for (let i = 0; i < length; i++) {
      if (i < test.length) {
        if (target.charAt(i) === test.charAt(i)) {
          resultConfirmator += target.charAt(i);
        } else {
          if (target.charAt(i) === ' ') {
            resultConfirmator += ' ';
          } else {
            resultConfirmator += '-';
          }
        }
      } else {
        resultConfirmator += '-';
      }
    }
    return resultConfirmator;
  }

  checkSpell(target: string, test: string): CheckSpellResult {
    target = this.cleanSpecialWordCaraters(target);
    test = this.cleanSpecialWordCaraters(test);
    return {
      result: this.getHint(target, test),
      similarity: target === test ? 100 : 0
    };

  }

  cleanText(text: string): string {
    return this.cleanSpecialWordCaraters(this.removeSpecialCaraters(text));
  }

  cleanSpecialWordCaraters(txt: string): string {
    return txt.trim().replace(/\s\s+/g, ' ')
      .replace(/’/g, '\'')
      .replace(/…/g, '...')
      .replace(/¨/g, '"')
      .replace(/‘/g, '\'')
      .replace(/Æ/g, 'Ae')
      .replace(/æ/g, 'ae')
      .replace(/Œ/g, 'Oe')
      .replace(/œ/g, 'oe');
  }

  cleanItem(txt: string): string {
    if (txt != null) {
      return txt.trim().replace(/\s\s+/g, ' ').replace(/’/, '\'').replace(/‘/, '\'');
    }
    return txt;
  }

  removeSpecialCaraters(text: string): string {
    // eslint-disable-next-line max-len
    return text.replace(/(\.|\||,|!|\?|;|:|\(|\)|\[|\]|\{|¡|¡|¨|´|¤|±|¸|·|÷|½|¼|¾|«|¯|»|¿|©|Ð|£|¶|®|§|Þ|¥|¹|²|³|ª|º|¢|ð|°|þ|µ|×|¦|¬|\})/gi, '');
  }

  stripTags(text: string): string {
    return text.replace(/(<([^>]+)>)/ig, '');
  }

  cleanRessourcesUrl(url: string): string {
    if (url) {
      url = url.trim();
    } else{
      url = '';
    }
    if (url !== '' && !url.startsWith('/')) {
      try {
        new URL(url);
      } catch (exception) {
        url = '';
      }
    }
    if (url !== '') {
      url = sanitizeUrl(url);
    }
    
    if (url && url.startsWith(location.origin)) {
      url = url.replace(location.origin, '');
    }
    if (url !== '' && url.startsWith('/')) {
      url = url.toLowerCase();
      
      // On test si ça match un ressources qui doit être routee par angular
      var matchs = url.match(/\/(parcours|listes|folios|activite)\/([a-z\-]*)\/(\d+)(\/.*)?/);
      if(matchs !== null){
        //ça match une route
        switch (matchs[1]) {
          case 'parcours':
          case 'listes':
          case 'folios':
            url = `/${matchs[1]}/detail/${matchs[3]}`;
            break;
          case 'activite':
            url = `/${matchs[1]}/${matchs[2]}/${matchs[3]}`;
            break;
          default:
            url = '';
        }
        return url;
      }
      
      // On test si ça match un média
      const regex = /^\/api\/v1\/medias\/[a-f0-9]{4}(?:[a-f0-9]{4}\-){4}[a-f0-9]{12}\/.*/;
      if (!regex.exec(url)) {
        url = '';
      }
    }
    return url;
  }
}
