<div class="modal-header">
    <h4 class="modal-title">Importer des items depuis du texte</h4>
    <button type="button" class="btn-close" aria-label="btn-close" (click)="activeModal.dismiss()">

    </button>
</div>
<div class="modal-body">
    <textarea ngbAutofocus class="form-control" id="exampleFormControlTextarea1" rows="8" [(ngModel)]="textValue"></textarea>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss()">Annuler</button>
  <button type="button" class="btn btn-outline-primary" (click)="treatText()">Importer</button>
</div>
