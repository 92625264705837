import { Component, Input } from '@angular/core';
import { CourseItem } from '@app/model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TextItemDisplayComponent } from '../text-item-display/text-item-display.component';

@Component({
    selector: 'nemo-text-item-display-modal',
    templateUrl: './text-item-display-modal.component.html',
    styleUrls: ['./text-item-display-modal.component.scss'],
    standalone: true,
    imports: [TextItemDisplayComponent]
})
export class TextItemDisplayModalComponent {

  @Input() item: CourseItem;

  constructor(public activeModal: NgbActiveModal) { }


}
