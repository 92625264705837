import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { StudentService } from '@app/core/service';
import { Group, Student } from '@app/model';
import { FileInputModalComponent } from '@app/shared/components/file-input-modal/file-input-modal.component';
import { MediaUploadModalComponent } from '@app/shared/components/media-upload-modal/media-upload-modal.component';
import { SharedModule } from '@app/shared/shared.module';
import { NgbCollapseModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'nemo-preferences-groupes',
  standalone: true,
  imports: [CommonModule,FormsModule,SharedModule,RouterModule,NgbCollapseModule],
  templateUrl: './preferences-groupes.component.html',
  styleUrl: './preferences-groupes.component.scss'
})
export class PreferencesGroupesComponent implements OnInit{
  groupes: GroupView[]=[];
  eleves: [][]=[[]];
  loading = true;
 

  constructor(private readonly studentService: StudentService,
    private readonly modalService: NgbModal
  ) {
  }
  ngOnInit(): void {
    console.log('tt')
    this.studentService.getGroups().subscribe(groupes=>{
      this.groupes =groupes.map(g=>new GroupView(g));
      var promises = groupes.map(g=>{
        this.studentService.getGroupStudents(g.key).subscribe(eleves=>{
          this.eleves[g.key]=eleves;
        });
      })
      Promise.all(promises).then(()=> {
        this.loading = false;
      })
    });
    
  }
  chunckIntoN(arr, n) {
    if(arr==null){
      return null;
    }
    const size = Math.ceil(arr.length / n);
    return Array.from({ length: n }, (v, i) =>
      arr.slice(i * size, i * size + size)
    );
  }
  toggleGroupView(group: GroupView){
    group.isCollapsed = !group.isCollapsed;
  }
  addGroup(){
    const newGuid=Guid.create().toString().toLocaleLowerCase();
    this.groupes.unshift({
      key: newGuid,
      center:"",
      isCollapsed:false,
      groupType:"",
      name:"Nouveau groupe",
      readOnly:false,
      school:"",
      schoolCode:"",
      students:[]
    });
    this.eleves[newGuid]=[];
  }
  deleteGroup(group: GroupView){
    this.studentService.deleteGroup(group.key).subscribe(()=>{
      this.groupes.splice(this.groupes.indexOf(group), 1);
      //this.eleves[group.key]
    });
  }
  deleteGroupStudent(group: GroupView, eleve: Student){
    this.studentService.deleteGroupStudent(group.key,eleve.cloeeWorldId).subscribe(()=>{
      this.eleves[group.key].splice(this.eleves[group.key].indexOf(eleve), 1);
      
    });;
  }
  addStudent(student: Student, group: GroupView){
    this.studentService.addStudentInGroup(student, group).subscribe(()=>{
      this.eleves[group.key].push(student);
    });
    
    console.log(student.displayName + " in group " +group.name);
  }
  updateGroupName(key:string, name:string) {
    this.studentService.updateGroupName(key,name).subscribe(()=>{});
  }

  importInGroup(groupe: GroupView){
    const modalRef = this.modalService.open(FileInputModalComponent);
    modalRef.result.then((csv: File) => {
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
        console.log();
        this.studentService.addStudentsInGroup(fileReader.result,groupe).subscribe(()=>{
          this.ngOnInit();
        });
      }
      fileReader.readAsText(csv);
    }, () => {
    });
  }
  bulkImportInGroup(){
    const modalRef = this.modalService.open(FileInputModalComponent);
    modalRef.result.then((csv: File) => {
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
        console.log();
        this.studentService.addStudents(fileReader.result).subscribe(()=>{
          this.ngOnInit();
        });
      }
      fileReader.readAsText(csv);
    }, () => {
    });
  }

}

class GroupView extends Group{
  isCollapsed = this.readOnly;
}
