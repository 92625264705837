import { Location, NgIf, NgClass } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivityService, ListeService, ToastService, ParcoursService } from '@app/core/service';
import { Activity, Course, CourseSummary, ItemList, Progression } from '@app/model';
import { CourseSelectorComponent } from '@app/shared/components/course-selector/course-selector.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgEventBus } from 'ng-event-bus';
import { GrilleComponent } from '../grille/grille.component';
import { TriComponent } from '../tri/tri.component';
import { TraductionComponent } from '../traduction/traduction.component';
import { EcritureComponent } from '../ecriture/ecriture.component';
import { AssociationComponent } from '../association/association.component';
import { CarteComponent } from '../carte/carte.component';
import { StackedProgressBarComponent } from '../../../shared/components/stacked-progress-bar/stacked-progress-bar.component';
import { TimerComponent } from '../../../shared/components/timer/timer.component';
import { ClipboardModule } from 'ngx-clipboard';
import { ActionMenuComponent } from '../../../shared/components/action-menu/action-menu.component';
import { BasepageComponent } from '../../../shared/components/basepage/basepage.component';


@Component({
    selector: 'nemo-baseactivite',
    templateUrl: './baseactivite.component.html',
    styleUrls: ['./baseactivite.component.scss'],
    standalone: true,
    imports: [NgIf, BasepageComponent, ActionMenuComponent, ClipboardModule, TimerComponent, StackedProgressBarComponent, CarteComponent, AssociationComponent, EcritureComponent, TraductionComponent, TriComponent, GrilleComponent, NgClass]
})
export class BaseactiviteComponent implements OnInit {

  @HostListener('window:keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    if (event.key === ' ') {
      this.messageBusService.cast('activite', 'act');
  }
  if (event.key === 'ArrowUp' ) {
      this.messageBusService.cast('activite', 'up');
      event.preventDefault();
  }
  if (event.key === 'ArrowRight' ) {
      this.messageBusService.cast('activite', 'right');
      event.preventDefault();
  }
  if (event.key === 'ArrowDown' ) {
      this.messageBusService.cast('activite', 'down');
      event.preventDefault();
  }
  if (event.key === 'ArrowLeft') {
      this.messageBusService.cast('activite', 'left');
      event.preventDefault();
  }
  if (event.key === 'Escape') {
      this.messageBusService.cast('activite', 'abandon');
      this.abandon();
      event.preventDefault();
  }
  }

  public list: ItemList;
  public baseSize = 0;
  public started: Course;
  public progression: Progression;
  public ended = false;
  public abandonned = false;
  public type: string;
  public isCollapsed = true;
  public url: string;
  public courseUrl: string;
  public activity: Activity;

  constructor(public readonly router: Router,
    private readonly listeservice: ListeService,
    private readonly parcoursservice: ParcoursService,
    private readonly route: ActivatedRoute,
    private readonly activityService: ActivityService,
    private readonly toastService: ToastService,
    private readonly messageBusService: NgEventBus,
    private readonly modalService: NgbModal,
    private readonly location: Location) {

    this.progression = {
      success: 0,
      danger: 0,
      info: 0,
      warning: 0
    };
    this.courseUrl = this.parcoursservice.getCurrentCourseUrl();
    this.parcoursservice.setCurrentCourseUrl(null);

  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.type = params['type'];
      this.activity = this.activityService.getAllActivities().filter(a => a.type === this.type)[0];
      this.listeservice.getList(params['id']).subscribe(l => {
        this.list = l;
        this.activity.guards.forEach(g => this.list.items = g.test(this.list.items, l.learnLanguage !== l.hintLanguage));
        this.list.items = this.activityService.shuffle(this.list.items);
        this.baseSize = this.list.items.length;
        this.url = this.router.url;
        this.startGame();
      });
    });
  }

  configure() {
    if (this.activity.configurable) {
      this.activityService.configure();
    }
  }
  startGame() {
    this.activityService.startTimer();
    this.ended = false;
    this.abandonned = false;
  }
  progress(pro: Progression) {
    if(pro.success && pro.success > 100) {
      pro.success = 100;
    }
    if(pro.success && pro.success < 0) {
      pro.success = 0;
    }
    this.progression = pro;
  }
  endGame() {
    this.activityService.stopTimer();
    this.abandonned = false;
    this.ended = true;
  }
  abandon() {
    this.activityService.stopTimer();
    this.abandonned = true;
    this.ended = true;
  }

  restart() {
    this.ngOnInit();
  }
  createCourse() {
    this.parcoursservice.createNew({
      id: 0,
      title: this.list.title,
      description: this.list.description,
      planEtudeRomandIds:[],
      icon: 'bi bi-map-fill',
      color: 'c-grey',
      mine: true,
      libre: false,
      rpn: false,
      teacher: false,
      distributed : false,
      progression: 0,
      ribbon: '',
      items: [
        {
          id: 0,
          navigationUrl: this.url,
          title: `${this.activity.shortLabel} - ${this.list.title}`,
          progressionEnabled: true
        }
      ]
    }).subscribe((t: Course) => {
      this.router.navigate(['/parcours/personnels', t.id, 'edition']);
    });
  }
  sendbymail() {
    const label = this.activity.shortLabel.toLocaleLowerCase();
    const uri = encodeURIComponent(`Partage de l'activité iClasse ${label} sur ${this.list.title}`);
    const body = encodeURIComponent(`Voici l'activité iClasse ${label} sur la liste ${this.list.title}
     accessible à l'url : ${document.location.href}.`);
    window.location.href = `mailto:?subject=${uri} &body=${body}`;
  }

  selectCourse() {
    const modalRef = this.modalService.open(CourseSelectorComponent);
    modalRef.result.then((result: CourseSummary) => {

    }, () => {
    });
  }

  back() {
    //this.router.navigate([this.courseUrl]);
    this.location.back();
  }
  getDocumentLocation() {
    return document.location.href;
  }
  copied(e: any) {
    if (e.isSuccess) {
      this.toastService.info('Copié !');
    }
  }
}
