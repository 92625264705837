import {
  AfterViewChecked,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  TemplateRef,
  OnDestroy,
} from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import {
  ActivityService,
  SpeechSynthesisService,
  InputValidationService,
  PreferencesService,
  ToastService,
} from '@app/core/service';
import { ActivityItem, ItemList, Progression } from '@app/model';
import { FocusDirective } from '@app/shared/directives/focus.directive';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgEventBus } from 'ng-event-bus';
import { Subscription, Observable } from 'rxjs';
import { FocusDirective as FocusDirective_1 } from '../../../shared/directives/focus.directive';
import { FormsModule } from '@angular/forms';
import { ItemTextComponent } from '../common/item-text/item-text.component';
import { NgIf, NgClass } from '@angular/common';

export enum State {
  Write,
  Valid,
  NotValid,
}

@Component({
    selector: 'nemo-ecriture',
    templateUrl: './ecriture.component.html',
    styleUrls: ['./ecriture.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        ItemTextComponent,
        FormsModule,
        FocusDirective_1,
        RouterLink,
    ],
})
export class EcritureComponent implements OnInit, AfterViewChecked, OnDestroy {
  @Input() list: ItemList;
  @Output() activityProgress = new EventEmitter<Progression>(true);
  @Output() activityEnd = new EventEmitter(true);

  @ViewChild(FocusDirective, { static: false }) input: FocusDirective;

  private progression: Progression;
  public config: EcritureConfig;
  public allItems: ActivityItem[] = [];
  public currentItem: ActivityItem;
  public displayLearn = true;
  public displayHint = false;
  public state: State;
  public answer = '';
  public ended = false;
  public hint = '_';
  public AvState = State;
  public size = 'middle';
  public anyHintProvided = false;
  subscription: Subscription;
  keyobs: Subscription;

  @ViewChild('content', {
    static: true
  })
  private readonly configureBtnTemplate: TemplateRef<any>;

  constructor(
    private readonly router: Router,
    private readonly activityService: ActivityService,
    private readonly preferencesService: PreferencesService,
    private readonly speechSynthesisService: SpeechSynthesisService,
    public inputValidationService: InputValidationService,
    public toastService: ToastService,
    public modalService: NgbModal,
    public readonly messageBusService: NgEventBus
  ) {
    this.subscription = activityService.configureActivity$.subscribe(
      () => {
        this.configure();
    });
    this.keyobs = this.messageBusService.on('activite').subscribe(e =>{
      if(e.data === 'down') {
        this.display();
      }
      if(e.data === 'right') {
        this.endItem(true);
      }
      if(e.data === 'left') {
        this.endItem(false);
      }
    });
  }

  ngOnInit() {
    this.size = this.preferencesService.getPreferences().size;
    this.progression = {
      success: 0,
      danger: 0,
      info: 0,
      warning: 0,
    };
    this.activityProgress.emit(this.progression);
    this.config = this.activityService.getConfig<EcritureConfig>(
      'ecriture',
      {
        duration: 1,
        sonorize: false,
        sonorizeHint: false,
        hide: 0
      }
    );
    this.displayLearn = this.config.duration === 0 ? false : true;
    this.allItems = this.list.items.map(item => {
      return new ActivityItem(item);
    });
    this.startItem();
  }
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
    this.keyobs.unsubscribe();
  }
  configure() {
    this.modalService
      .open(this.configureBtnTemplate, { ariaLabelledBy: 'modal-basic-title', size: 'lg' })
      .result.then(
        () => {
          this.activityService.setConfig<EcritureConfig>(
            'ecriture',
            this.config
          );
          this.ngOnInit();
        },
        reason => {
          this.ngOnInit();
        }
      );
  }

  displayPreferences() {
    this.modalService.dismissAll();
    this.router.navigate(['/preferences/']);
  }

  ngAfterViewChecked() {
    if (this.input) {
      this.input.setFocus();
    }
  }

  startItem() {
    this.answer = '';
    this.anyHintProvided = this.list.items
      .filter(i => (i.hintImgUrl && i.hintImgUrl.length > 0) || (i.hintTxt && i.hintTxt.length > 0)).length > 0;
    this.state = State.Write;
    this.displayHint = false;
    this.allItems = this.activityService.shuffle(this.allItems);
    if (this.allItems.filter(i => !i.known).length > 0) {
      const nextItem = this.allItems.filter(i => !i.known)[0];
      if(nextItem.tags.some(t => t === 'conjugaison')){
        this.config = this.activityService.overwriteConfig(this.config, {
          duration: 0,
          sonorize: true,
          sonorizeHint: true,
          hide: 0
        });
      } else{
        this.config = this.activityService.getConfig<EcritureConfig>(
          'ecriture',
          {
            duration: 1,
            sonorize: false,
            sonorizeHint: false,
            hide: 0
          }
        );
      }
      this.currentItem = nextItem;
      this.displayLearn = this.config.duration === 0 ? false : true;
      this.currentItem.attempt++;
      if (this.config.sonorize && this.config.duration > 0) {
        this.speakLearn();
      }
      if (this.config.sonorizeHint && this.config.duration === 0) {
        this.speakHint();
      }
      if (this.input) {
        this.input.setFocus();
      }
    } else {
      this.activityEnd.emit();
    }
  }
  onKeydown(e) {
    if (e.key === 'Enter') {
      if (this.state !== State.Valid) {
        this.validate();
        return;
      } else {
        this.endItem(true);
        return;
      }
    } else {
      if (
        [46, 9, 27, 13, 110, 190, 16, 17, 18].indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode === 65 && e.ctrlKey === true) ||
        // Allow: Ctrl+C
        (e.keyCode === 67 && e.ctrlKey === true) ||
        // Allow: Ctrl+X
        (e.keyCode === 88 && e.ctrlKey === true) ||
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)
      ) {
        // let it happen, don't do anything
        return;
      }
      this.state = State.Write;
      this.displayLearn = this.config.duration === 2 ? true : false;
      this.displayHint = false;
    }
  }

  display() {
    this.displayLearn = true;
  }

  speakAnswer() {
    this.speechSynthesisService.speak(this.answer, this.list.learnLanguage);
  }

  speakLearn() {
    this.speechSynthesisService.speak(this.currentItem.learnTxt, this.list.learnLanguage);
  }

  speakHint() {
    this.speechSynthesisService.speak(this.currentItem.hintTxt, this.list.hintLanguage);
  }

  validate() {
    const check = this.inputValidationService.checkSpell(
      this.currentItem.learnTxt,
      this.answer
    );
    if (check.similarity > 90) {
      this.currentItem.known = true;
      this.displayLearn =
      this.config.duration === 0;
      this.state = State.Valid;
      this.speakLearn();
    } else {
      this.displayHint = true;
      this.hint = check.result;
      this.state = State.NotValid;
    }
  }
  endItem(acquired: boolean) {
    if(this.state === this.AvState.Valid) {
      this.currentItem.known = acquired;
      this.progression.success = Math.round(
        (this.allItems.filter(i => i.known && i.attempt === 1).length /
          this.allItems.length) *
        100
      );
      this.progression.warning = Math.round(
        (this.allItems.filter(i => (!i.known && i.attempt === 1) || i.attempt > 1)
          .length /
          this.allItems.length) *
        100
      );
      this.activityProgress.emit(this.progression);
      this.startItem();
    }
  }
  restart() {
    this.ngOnInit();
  }
}

export class EcritureConfig {
  duration: number;
  sonorize: boolean;
  sonorizeHint: boolean;
  hide: number;
}
