import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BookletSummary, SearchNatureEnum } from '@app/model';
import { BookletService } from './booklet.service';
import { Observable, of } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BookletRechercheResolverService   {

  constructor(private readonly bookletService: BookletService,
  private readonly router: Router) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): BookletSummary[] | Observable<BookletSummary[]> | Promise<BookletSummary[]> {
    const search = this.bookletService.getSearchFilter();
    search.skip = 0;
    if (route.routeConfig.path === '') {
      search.nature = SearchNatureEnum.Private;
      this.bookletService.setSearchFilter(search); 
      this.router.navigate(['/folios/personnels']);
    }
    if (route.routeConfig.path === 'personnels') {
      search.nature = SearchNatureEnum.Private;
    } else if (route.routeConfig.path === 'rpn') {
      search.nature = SearchNatureEnum.Rpn;
    } else if (route.routeConfig.path === 'plan-etude-romand') {
      search.nature = SearchNatureEnum.GeneratedFromPlanEtudeRomand;
    } else {
      search.nature = SearchNatureEnum.Private;
    }
    this.bookletService.setSearchFilter(search);
    return this.bookletService.getBooklets(search).pipe(
      take(1),
      mergeMap(booklets => {
        return of(booklets);
      }));
  }
}
