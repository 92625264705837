import { Component, Input } from '@angular/core';
import { CourseItem } from '@app/model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ParcoursItemStartComponent } from '../parcours-item-start/parcours-item-start.component';
import { NgFor } from '@angular/common';

@Component({
    selector: 'nemo-course-item-selector-modal',
    templateUrl: './course-item-selector-modal.component.html',
    styleUrls: ['./course-item-selector-modal.component.scss'],
    standalone: true,
    imports: [NgFor, ParcoursItemStartComponent]
})
export class CourseItemSelectorModalComponent {

  @Input() items: CourseItem[] = [];

  constructor(public activeModal: NgbActiveModal) { }

  addItem(item: CourseItem): void {
    this.activeModal.close(item);
  }

}
