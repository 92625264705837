export class GridConfig {
  allowBackward: boolean;
  highlightFirstLetter: boolean;
  allowVertical: boolean;
  allowDiagonal: boolean;
}
export class GridWord {
  ref: number;
  label: string;
  found = false;
  placed = false;
  tries = 0;

  constructor(label: string, ref: number) {
    this.ref = ref;
    this.label = label;
  }
}

export enum GridDirection {
  N, NE, E, SE, S, SW, W, NW
}

export class GridCase {

  letter = '';
  selected = false;
  success = false;
  fail = false;
  wordref: Array<number> = [];
  startWord = false;
  endWord = false;
  valueSetted = false;

}

export class Grid {

  cases: Array<Array<GridCase>>;
  words: GridWord[];
  directions: Array<GridDirection>;
  authCaracters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  width = 0;

  constructor(words: string[], config: GridConfig) {
    this.words = words.map((w, i) => new GridWord(w, i));
    this.directions = [GridDirection.E];
    const biggestLength = [... words].sort(function (a, b) {
      return b.length - a.length;
    })[0].length;
    this.width = biggestLength + 3;
    this.cases = [];
    for (let row = 0; row < this.width; row++) {
      this.cases[row] = [];
      for (let col = 0; col < this.width; col++) {
        this.cases[row][col] = new GridCase();
      }
    }

    if (config.allowBackward) {
      this.directions.push(GridDirection.W);
    }
    if (config.allowVertical) {
      this.directions.push(GridDirection.S);
      if (config.allowBackward) {
        this.directions.push(GridDirection.N);
      }
    }
    if (config.allowDiagonal) {
      this.directions.push(GridDirection.NE);
      this.directions.push(GridDirection.SE);
      if (config.allowBackward) {
        this.directions.push(GridDirection.SW);
        this.directions.push(GridDirection.NW);
      }
    }
    this.generate();
  }

  validate(wordref: number): boolean {
    this.words.filter(w => w.ref === wordref)[0].found = true;
    this.cases.forEach(r => r.forEach(c => {
      c.success = c.wordref.indexOf(wordref) >= 0 || c.success;
      c.selected = false;
    }));
    return this.words.filter(w => !w.found).length === 0;
  }

  generate() {
    this.words.forEach((w) => {

      let set = false;
      while ((!set && w.tries < 100) || w.tries > 100) {
        const newdir = this.randomDirection();
        const rndcol = this.randomCol(w, newdir);
        const rndrow = this.randomRow(w, newdir);
        set = false;
        if (this.testWord(w, rndcol, rndrow, newdir)) {
          this.placeWord(w, rndcol, rndrow, newdir);
          w.placed = true;
          set = true;
        } else {
          w.tries++;
        }
      }
    });
    for (let i = 0; i < this.width; i++) {
      for (let j = 0; j < this.width; j++) {
        if (!this.cases[i][j].valueSetted) {
          this.cases[i][j].letter = this.randomLetter();
        }
      }
    }
    this.words = this.words.filter(w => w.placed);
  }

  randomCol (word: GridWord, direction: GridDirection): number {
    const rnd = Math.random();
    switch (direction) {
      case GridDirection.E:
      case GridDirection.NE:
      case GridDirection.SE:
        return Math.floor(rnd * (this.width - word.label.length));
      case GridDirection.S:
      case GridDirection.N:
        return Math.floor(rnd * (this.width));
      case GridDirection.W:
      case GridDirection.NW:
      case GridDirection.SW:
        return this.width - 1 - Math.floor(rnd * (this.width - word.label.length));
      default:
        return 0;
    }
  }

  randomRow(word: GridWord, direction: GridDirection): number {
    const rnd = Math.random();
    switch (direction) {
      case GridDirection.E:
      case GridDirection.W:
        return Math.floor(rnd * (this.width));
      case GridDirection.S:
      case GridDirection.SE:
      case GridDirection.SW:
        return Math.floor(rnd * (this.width - word.label.length));
      case GridDirection.N:
      case GridDirection.NW:
      case GridDirection.NE:
        return this.width - 1 - Math.floor(rnd * (this.width - word.label.length));
      default:
        return 0;
    }
  }

  randomDirection(): GridDirection {
    return this.directions[Math.floor(Math.random() * this.directions.length)];
  }

  testWord(word: GridWord, col: number, row: number, direction: GridDirection) {
    let colTemp = col;
    let rowTemp = row;
    let canPut = true;
    for (let i = 0; i < word.label.length ; i++) {
      const letter = word.label.substr(i, 1);
      if (this.cases[rowTemp][colTemp].letter !== '' && this.cases[rowTemp][colTemp].letter !== letter) {
        canPut = false;
      }
      switch (direction) {
        case GridDirection.E:
          colTemp += 1;
          break;
        case GridDirection.W:
          colTemp -= 1;
          break;
        case GridDirection.NW:
          rowTemp -= 1;
          colTemp -= 1;
          break;
        case GridDirection.N:
          rowTemp -= 1;
          break;
        case GridDirection.NE:
          rowTemp -= 1;
          colTemp += 1;
          break;
        case GridDirection.S:
          rowTemp += 1;
          break;
        case GridDirection.SW:
          rowTemp += 1;
          colTemp -= 1;
          break;
        case GridDirection.SE:
          rowTemp += 1;
          colTemp += 1;
          break;
      }
    }
    return canPut;
  }

  placeWord(word: GridWord, col: number, row: number, direction: GridDirection) {
    let colTemp = col;
    let rowTemp = row;
    for (let i = 0; i < word.label.length; i++) {
      this.setCase(colTemp, rowTemp, word.label.substr(i, 1), i === 0, i === word.label.length - 1, word.ref);
      switch (direction) {
        case GridDirection.E:
          colTemp += 1;
          break;
        case GridDirection.W:
          colTemp -= 1;
          break;
        case GridDirection.NW:
          rowTemp -= 1;
          colTemp -= 1;
          break;
        case GridDirection.N:
          rowTemp -= 1;
          break;
        case GridDirection.NE:
          rowTemp -= 1;
          colTemp += 1;
          break;
        case GridDirection.S:
          rowTemp += 1;
          break;
        case GridDirection.SW:
          rowTemp += 1;
          colTemp -= 1;
          break;
        case GridDirection.SE:
          rowTemp += 1;
          colTemp += 1;
          break;
      }
    }
  }
  setCase(col: number, row: number, letter: string, isStart: boolean, isEnd: boolean, pwordId: number) {
    this.cases[row][col].letter = letter;
    this.cases[row][col].startWord = this.cases[row][col].startWord || isStart;
    this.cases[row][col].endWord = this.cases[row][col].endWord || isEnd;
    this.cases[row][col].wordref.push(pwordId);
    this.cases[row][col].valueSetted = true;
  }
  randomLetter() {
    return this.authCaracters.substr(Math.floor(Math.random() * this.authCaracters.length), 1);
  }
}



