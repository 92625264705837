<div class="container-fluid">
  <div class="row">
    <div class="col-md-4 offset-md-4 text-center">
      <div class="card m-3 p-3">
        <p>
          Nous chargeons le contenu désiré.
        </p>
        <p>
          Veuillez patienter.
        </p>
        <div  class="text-center">
          <div class="spinner-grow text-secondary" role="status">
            <span class="visually-hidden">Recherche en cours...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
