import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadModule } from 'ng2-file-upload';
import { ClipboardModule } from 'ngx-clipboard';
import { NgPipesModule } from 'ngx-pipes';
import { ActionMenuComponent } from './components/action-menu/action-menu.component';
import { ActivitySelectorComponent } from './components/activity-selector/activity-selector.component';
import { BasepageComponent } from './components/basepage/basepage.component';
import { CardComponent } from './components/card/card.component';
import { CategorySelectorComponent } from './components/category-selector/category-selector.component';
import { CategoryComponent } from './components/category/category.component';
import { CourseSelectorComponent } from './components/course-selector/course-selector.component';
import { DateRangeSelectorComponent } from './components/date-range-selector/date-range-selector.component';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { ExportComponent } from './components/export/export.component';
import { ImportFromTextModalComponent } from './components/import-from-text-modal/import-from-text-modal.component';
import { LogoSelectorComponent } from './components/logo-selector/logo-selector.component';
import { LogoComponent } from './components/logo/logo.component';
import { MediaPreviewComponent } from './components/media-preview/media-preview.component';
import { MenuComponent } from './components/menu/menu.component';
import { RibbonComponent } from './components/ribbon/ribbon.component';
import { SpeakerComponent } from './components/speaker/speaker.component';
import { StackedProgressBarComponent } from './components/stacked-progress-bar/stacked-progress-bar.component';
import { SuppressModalComponent } from './components/suppress-modal/suppress-modal.component';
import { TimerComponent } from './components/timer/timer.component';
import { FocusDirective } from './directives/focus.directive';
import { ItemlistSelectorComponent } from './components/itemlist-selector/itemlist-selector.component';
import { EvaluationComponent } from './components/evaluation/evaluation.component';
import { StudentSearchComponent } from './components/student-search/student-search.component';
import { TeacherSearchComponent } from './components/teacher-search/teacher-search.component';
import { UserDisplayComponent } from './components/user-display/user-display.component';
import { TagsTogglerComponent } from './components/tags-toggler/tags-toggler.component';
import { CommentModalComponent } from './components/comment-modal/comment-modal.component';
import { DragulaModule } from 'ng2-dragula';
import { CommentDisplayComponent } from './components/comment-display/comment-display.component';
import { CommentEditComponent } from './components/comment-edit/comment-edit.component';
import { MomentModule } from 'ngx-moment';
import { DiscussionModalComponent } from './components/discussion-modal/discussion-modal.component';
import { AutoEvalutationStateComponent } from './components/auto-evalutation-state/auto-evalutation-state.component';
import { AutoEvaluationModalComponent } from './components/auto-evaluation-modal/auto-evaluation-modal.component';
import { TransmitModalComponent } from './components/transmit-modal/transmit-modal.component';
import { MediaUploadModalComponent } from './components/media-upload-modal/media-upload-modal.component';
import { MediaDisplayModalComponent } from './components/media-display-modal/media-display-modal.component';
import { DndDirective } from './directives/dnd.directive';
import { MediaDisplayComponent } from './components/media-display/media-display.component';
import { SafePipe } from './pipes/safe.pipe';
import { IconComponent } from './components/icon/icon.component';
import { CourseItemSelectorModalComponent } from './components/course-item-selector-modal/course-item-selector-modal.component';
import { ParcoursItemStartComponent } from './components/parcours-item-start/parcours-item-start.component';
import { ToastComponent } from './components/toast/toast.component';
import { ConjugationSelectorComponent } from './components/conjugation-selector/conjugation-selector.component';
import { ConjugationSelectorModalComponent } from './components/conjugation-selector-modal/conjugation-selector-modal.component';
import { MediaUploadComponent } from './components/media-upload/media-upload.component';
import { ParcoursItemDetailComponent } from './components/parcours-item-detail/parcours-item-detail.component';
import { ParcoursItemTypeIconComponent } from './components/parcours-item-type-icon/parcours-item-type-icon.component';
import { TextItemDisplayComponent } from './components/text-item-display/text-item-display.component';
import { TextItemDisplayModalComponent } from './components/text-item-display-modal/text-item-display-modal.component';
import { NewCategorySelectorComponent } from './components/new-category-selector/new-category-selector.component';
import { PlanEtudeRomandSelectorComponent } from './components/plan-etude-romand-selector/plan-etude-romand-selector.component';
import { PlanEtudeRomandSelectorModalComponent } from './components/plan-etude-romand-selector-modal/plan-etude-romand-selector-modal.component';
import { PlanEtudeRomandDisplayComponent } from './components/plan-etude-romand-display/plan-etude-romand-display.component';
import { ReplacePipe } from './pipes/replace.pipe';
import { StudentSearchInputComponent } from './components/student-search-input/student-search-input.component';
import { TeacherSearchInputComponent } from './components/teacher-search-input/teacher-search-input.component';
import { FileInputComponent } from './components/file-input/file-input.component';
import { FileInputModalComponent } from './components/file-input-modal/file-input-modal.component';
import { ParcoursRessourcesRechercheComponent } from './components/parcours-ressources-recherche/parcours-ressources-recherche.component';
import { ParcoursRessourcesDetailComponent } from './components/parcours-ressources-detail/parcours-ressources-detail.component';
import { ParcoursRessourcesRechercheModalComponent } from './components/parcours-ressources-recherche-modal/parcours-ressources-recherche-modal.component';

@NgModule({
    exports: [
        BasepageComponent,
        CardComponent,
        DragulaModule,
        SuppressModalComponent,
        CategorySelectorComponent,
        SpeakerComponent,
        TimerComponent,
        StackedProgressBarComponent,
        LogoSelectorComponent,
        LogoComponent,
        RibbonComponent,
        DateRangeComponent,
        DateRangeSelectorComponent,
        CategoryComponent,
        ExportComponent,
        MediaPreviewComponent,
        ActionMenuComponent,
        ActivitySelectorComponent,
        CourseSelectorComponent,
        ItemlistSelectorComponent,
        FocusDirective,
        MenuComponent,
        ImportFromTextModalComponent,
        EvaluationComponent,
        StudentSearchComponent,
        TeacherSearchComponent,
        UserDisplayComponent,
        TagsTogglerComponent,
        CommentModalComponent,
        CommentDisplayComponent,
        CommentEditComponent,
        DiscussionModalComponent,
        AutoEvalutationStateComponent,
        AutoEvaluationModalComponent,
        TransmitModalComponent,
        MediaUploadModalComponent,
        MediaDisplayModalComponent,
        MediaDisplayComponent,
        IconComponent,
        CourseItemSelectorModalComponent,
        ParcoursItemStartComponent,
        ToastComponent,
        ConjugationSelectorComponent,
        ConjugationSelectorModalComponent,
        MediaUploadComponent,
        ParcoursItemDetailComponent,
        ParcoursItemTypeIconComponent,
        TextItemDisplayComponent,
        TextItemDisplayModalComponent,
        NewCategorySelectorComponent,
        PlanEtudeRomandSelectorModalComponent,
        PlanEtudeRomandSelectorComponent,
        PlanEtudeRomandDisplayComponent,
        StudentSearchInputComponent,
        TeacherSearchInputComponent,
        FileInputComponent,
        FileInputModalComponent,
        ParcoursRessourcesRechercheComponent,
        ParcoursRessourcesDetailComponent,
        ParcoursRessourcesRechercheModalComponent
    ],
    imports: [
        MomentModule,
        CommonModule,
        DragulaModule.forRoot(),
        FormsModule,
        FileUploadModule,
        NgPipesModule,
        NgbModule,
        ClipboardModule,
        RouterModule,
        BasepageComponent,
        CardComponent,
        SuppressModalComponent,
        CategorySelectorComponent,
        SpeakerComponent,
        TimerComponent,
        StackedProgressBarComponent,
        LogoSelectorComponent,
        LogoComponent,
        RibbonComponent,
        DateRangeComponent,
        DateRangeSelectorComponent,
        CategoryComponent,
        ExportComponent,
        MediaPreviewComponent,
        ActionMenuComponent,
        ActivitySelectorComponent,
        CourseSelectorComponent,
        FocusDirective,
        MenuComponent,
        ImportFromTextModalComponent,
        ItemlistSelectorComponent,
        EvaluationComponent,
        StudentSearchComponent,
        TeacherSearchComponent,
        UserDisplayComponent,
        TagsTogglerComponent,
        CommentModalComponent,
        CommentDisplayComponent,
        CommentEditComponent,
        DiscussionModalComponent,
        AutoEvalutationStateComponent,
        AutoEvaluationModalComponent,
        TransmitModalComponent,
        MediaUploadModalComponent,
        MediaDisplayModalComponent,
        DndDirective,
        MediaDisplayComponent,
        SafePipe,
        IconComponent,
        CourseItemSelectorModalComponent,
        ParcoursItemStartComponent,
        ToastComponent,
        ConjugationSelectorComponent,
        ConjugationSelectorModalComponent,
        MediaUploadComponent,
        ParcoursItemDetailComponent,
        ParcoursItemTypeIconComponent,
        TextItemDisplayComponent,
        TextItemDisplayModalComponent,
        NewCategorySelectorComponent,
        PlanEtudeRomandSelectorComponent,
        PlanEtudeRomandSelectorModalComponent,
        PlanEtudeRomandDisplayComponent,
        ReplacePipe,
        StudentSearchInputComponent,
        TeacherSearchInputComponent,
        FileInputComponent,
        FileInputModalComponent,
        ParcoursRessourcesRechercheComponent,
        ParcoursRessourcesDetailComponent,
        ParcoursRessourcesRechercheModalComponent
    ]
})
export class SharedModule { }
