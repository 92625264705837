import { Injectable } from '@angular/core';

import { CanComponentDeactivate } from './CanComponentDeactivate';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuard  {
  canDeactivate(component: CanComponentDeactivate) {
    return component.canDeactivate ? component.canDeactivate() : true;
  }
}
