import { Injectable } from '@angular/core';
import { UserNotification } from '@app/model';
import { Observable } from 'rxjs';
import { HttpBaseService } from '.';

@Injectable({
  providedIn: 'root'
})
export class UserNotificationService {
  private readonly url = '/notifications';

  constructor(private readonly http: HttpBaseService) { }


  public get(): Observable<UserNotification[]> {
    return this.http.get<UserNotification[]>(`${this.url}`);
  }

  public push(email: string): Observable<UserNotification> {
    return this.http.put<UserNotification>(`${this.url}/dummy/${email}`,{dummy:true});
  }

  public dismiss(id: string): Observable<boolean> {
    return this.http.put<boolean>(`${this.url}/dismiss/${id}`,{});
  }

  public dismissAll(): Observable<boolean> {
    return this.http.put<boolean>(`${this.url}/dismiss`,{});
  }
  public count(): Observable<number> {
    return this.http.get<number>(`${this.url}/count`,{});
  }
}
