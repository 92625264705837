<div class="modal-header">
  <h4 class="modal-title">Rechercher une ressource</h4>
  <button type="button" class="btn-close" aria-label="btn-close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <div class="my-2">    
    <nemo-parcours-ressources-recherche [selectionMode]="true" (add)="add($event)"></nemo-parcours-ressources-recherche>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss()">Annuler</button>
</div>

