import { Component, OnInit } from '@angular/core';
import { ParcoursService } from '@app/core/service';
import { CourseSummary } from '@app/model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RouterLink } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'nemo-course-selector',
    templateUrl: './course-selector.component.html',
    styleUrls: ['./course-selector.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, NgFor, RouterLink, NgClass]
})
export class CourseSelectorComponent implements OnInit {

  courses: CourseSummary[];
  filteredCourses: CourseSummary[];
  selectedCourse: CourseSummary;
  filter = '';

  constructor(public activeModal: NgbActiveModal,
    private readonly parcoursService: ParcoursService) { }

  ngOnInit() {
    this.parcoursService.getPersonnalCourses().subscribe(c => {
      this.courses = [...c]
        .sort((l1: CourseSummary, l2: CourseSummary) => new Date(l1.updatedOn) < new Date(l2.updatedOn) ? 0 : -1);
      this.filteredCourses = this.courses;
    });
  }

  filterCourses(event: any) {
    this.filter = event.target.value;
    this.filteredCourses =
      this.courses.filter(l => l.title.toLocaleLowerCase().search(new RegExp(this.filter.toLocaleLowerCase(), 'i')) > -1);
  }
  clearFilterCourses() {
    this.filter = '';
    this.filteredCourses = this.courses;
  }

  selectCourse(course: CourseSummary) {
    this.selectedCourse = course;
  }

  emitChanges() {
    if (this.selectedCourse) {
      this.activeModal.close(this.selectedCourse);
    }
  }

}
