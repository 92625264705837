import { Component, Input } from '@angular/core';
import { Progression } from '@app/model';
import { NgIf, NgStyle } from '@angular/common';

@Component({
    selector: 'nemo-stacked-progress-bar',
    templateUrl: './stacked-progress-bar.component.html',
    styleUrls: ['./stacked-progress-bar.component.scss'],
    standalone: true,
    imports: [NgIf, NgStyle],
})
export class StackedProgressBarComponent {

  @Input() showLabels = false;
  @Input() height = 5;
  @Input() progression: Progression;

  constructor() {}

}
