import { Component, Input, ViewChild, ElementRef, AfterViewChecked, Output, EventEmitter, OnInit } from '@angular/core';
import { UserComment, Preferences } from '@app/model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PreferencesService } from '@app/core/service';
import { FormsModule } from '@angular/forms';
import { CommentDisplayComponent } from '../comment-display/comment-display.component';
import { NgIf } from '@angular/common';


@Component({
    selector: 'nemo-discussion-modal',
    templateUrl: './discussion-modal.component.html',
    styleUrls: ['./discussion-modal.component.scss'],
    standalone: true,
    imports: [NgIf, CommentDisplayComponent, FormsModule]
})
export class DiscussionModalComponent implements OnInit, AfterViewChecked  {

  @ViewChild('scrollMe', { static: false }) private readonly myScrollContainer: ElementRef;
  @ViewChild('cmnt', { static: false }) private readonly myCommentInput: ElementRef;
  @Input() comments: UserComment[];
  @Output() sendComment = new EventEmitter<string>();

  displayImage = true;
  currentComment: string;
  prefs: Preferences;

  constructor(public activeModal: NgbActiveModal,
    private readonly preferencesService: PreferencesService) { }

  ngOnInit() {
    this.prefs = this.preferencesService.getPreferences();
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }
  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }
  handleBrokenImgUrl(e: any) {
    this.displayImage = false;
  }
  comment() {
    const newComment = {
      author: 'moi',
      avatar: this.prefs.profileImageUrl,
      comment: this.currentComment,
      created: new Date()
    };
    this.comments.push(newComment);
    this.sendComment.emit(newComment.comment);
    this.currentComment = '';
    this.myCommentInput.nativeElement.focus();
  }
}
