import { Component } from '@angular/core';
import { PreferencesService, ToastService } from '@app/core/service';
import { FormsModule } from '@angular/forms';
import { NgbRating } from '@ng-bootstrap/ng-bootstrap';
import { NgIf } from '@angular/common';

@Component({
    selector: 'nemo-user-voice',
    templateUrl: './user-voice.component.html',
    styleUrls: ['./user-voice.component.scss'],
    standalone: true,
    imports: [NgIf, NgbRating, FormsModule]
})
export class UserVoiceComponent {
  public comment: string;
  public currentRate = 0;
  commentSent = false;

  constructor(public preferencesService: PreferencesService,
    public toastService: ToastService) { }

  rate() {
    this.commentSent = true;
    this.preferencesService.rate(this.comment, this.currentRate).subscribe(() => {
      this.toastService.success('Votre avis nous est bien parvenu et fera l\'objet de toute notre attention, merci !');
    });
  }

}
