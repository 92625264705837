import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { StudentService } from '@app/core/service';
import { Group, Student } from '@app/model';
import { FormsModule } from '@angular/forms';
import { NgbHighlight, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownItem } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { StudentSearchInputComponent } from '../student-search-input/student-search-input.component';
import { CommonModule } from '@angular/common';
import { SettingsService } from '@app/core/service/settings.service';

@Component({
    selector: 'nemo-student-search',
    templateUrl: './student-search.component.html',
    styleUrls: ['./student-search.component.scss'],
    standalone: true,
    imports: [NgbHighlight, FormsModule, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu ,NgbDropdownItem ,StudentSearchInputComponent, CommonModule]
})
export class StudentSearchComponent implements OnInit {
  
  public groups: Group[];
  
  
  @Output() addStudents = new EventEmitter<Group>(true);
  @Output() addStudent = new EventEmitter<Student>(true);
  @Output() dropStudents = new EventEmitter();

  constructor(private readonly studentService: StudentService,
        private readonly settings: SettingsService,
        private readonly router: Router
  ) {

  }

  ngOnInit() {
    this.studentService.getGroups().subscribe(g => {
      this.groups = g;
    });
  }
  addGroupStudents(group: Group) {
    this.studentService.getGroupStudents(group.key).subscribe(students => {
      group.students = students;
      this.addStudents.emit(group);
    });
  }
  manageGroups(){
    if(this.settings.feature_flags.groupes){
      this.router.navigate(['preferences','groupes']);
    }
  }

  
  dropAll() {
    this.dropStudents.emit();
  }
}
