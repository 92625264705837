import { Component, Input } from '@angular/core';
import { UserComment } from '@app/model';
import * as moment from 'moment';
import { MomentModule } from 'ngx-moment';
import { NgFor, NgIf } from '@angular/common';
moment.locale('fr-CH');

@Component({
    selector: 'nemo-comment-display',
    templateUrl: './comment-display.component.html',
    styleUrls: ['./comment-display.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf, MomentModule]
})
export class CommentDisplayComponent {

  @Input() comments: UserComment[];
  displayImage = true;

  constructor() { }

}
