import { Component, Input } from '@angular/core';
import { CourseItem, MediaAction } from '@app/model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MediaDisplayComponent } from '../media-display/media-display.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'nemo-media-display-modal',
    templateUrl: './media-display-modal.component.html',
    styleUrls: ['./media-display-modal.component.scss'],
    standalone: true,
    imports: [NgIf, MediaDisplayComponent]
})
export class MediaDisplayModalComponent {

  @Input() mediaAction: MediaAction;
  @Input() item: CourseItem;

  constructor(public activeModal: NgbActiveModal) { }

}
