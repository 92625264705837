import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Item } from '@app/model';
import { ActivityService } from '@app/core/service';
import { TagInputModule } from 'ngx-chips';
import { MediaPreviewComponent } from '../../../shared/components/media-preview/media-preview.component';
import { FormsModule } from '@angular/forms';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'nemo-liste-item',
    templateUrl: './liste-item.component.html',
    styleUrls: ['./liste-item.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf, FormsModule, MediaPreviewComponent, TagInputModule]
})
export class ListeItemComponent {

  @Input() item: Item;
  @Input() edit = true;
  @Input() advanced = false;
  @Input() selectable = false;
  @Output() imageChanged = new EventEmitter();
  @Output() updated = new EventEmitter();
  @Output() deleted = new EventEmitter();
  @Output() focusRecieved = new EventEmitter();
  @Output() movedup = new EventEmitter();
  @Output() moveddown = new EventEmitter();
  @Output() selected = new EventEmitter();

  @ViewChild('learn', {static: false}) learnInput: ElementRef;
  constructor(public router: Router,
    public activityService: ActivityService) { }

  inputUpdate(e) {
    this.updated.emit();
  }
  recieveFocus() {
    this.focusRecieved.emit();
  }
  setFocus() {
    this.learnInput.nativeElement.focus();
  }
  changeImg() {
    this.imageChanged.emit();
  }
  removeItem() {
    this.deleted.emit();
  }
  moveItemUp() {
    this.movedup.emit();
  }

  moveItemDown() {
    this.moveddown.emit();
  }
  toggleSelect() {
    this.selected.emit({ selected: this.item.selected, id: this.item.id });
  }

}
