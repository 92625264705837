<nemo-basepage>
  <div page-body>
    <div class="col-md-4 offset-md-4 text-center">
      <div class="card m-3 p-3">
        <h1><em class="bi bi-heartbreak-fill"></em></h1>
        <p class="lead">
          Cette page ne semble pas exister...
        </p>
        <a [routerLink]="['/']" class="btn btn-outline-secondary stretched-link">Aller à l'accueil <span class="bi bi-arrow-right"></span></a>
      </div>
    </div>
  </div>
</nemo-basepage>
