import { Component, Input } from '@angular/core';
import { NgbDropdown, NgbDropdownToggle, NgbDropdownMenu } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'nemo-action-menu',
    templateUrl: './action-menu.component.html',
    styleUrls: ['./action-menu.component.scss'],
    standalone: true,
    imports: [NgbDropdown, NgbDropdownToggle, NgbDropdownMenu]
})
export class ActionMenuComponent {

  @Input() placement = 'left-top';

  constructor() { }

}
