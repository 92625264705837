<div class="row">
  <div class="col-lg-3 d-none d-lg-block">
    <div class="list-group mb-1">
      <a [routerLink]="[]" class="list-group-item list-group-item-action" [ngClass]="{'list-group-item-secondary':filter.type==='Doc'}" (click)="toggleType('Doc')">
        <small>Document</small>
      </a>
      <a [routerLink]="[]" class="list-group-item list-group-item-action" [ngClass]="{'list-group-item-secondary':filter.type==='Img'}" (click)="toggleType('Img')">
        <small>Images</small>
      </a>
      <a [routerLink]="[]" class="list-group-item list-group-item-action" [ngClass]="{'list-group-item-secondary':filter.type==='Vid'}" (click)="toggleType('Vid')">
        <small>Vidéo</small>
      </a>
      <a [routerLink]="[]" class="list-group-item list-group-item-action" [ngClass]="{'list-group-item-secondary':filter.type==='Act'}" (click)="toggleType('Act')">
        <small>Activité iClasse</small>
      </a>
      <a [routerLink]="[]" class="list-group-item list-group-item-action" [ngClass]="{'list-group-item-secondary':filter.type==='Snd'}" (click)="toggleType('Snd')">
        <small>Sons</small>
      </a>
    </div>
    <nemo-new-category-selector *ngIf="selectableCategories" #cat [selectableCategoriesKeys]="selectableCategories" [selectedCategoriesKeys]="filter.categories" (selectedCategoriesChange)="filterCategory($event)"></nemo-new-category-selector>
    <div class="d-grid gap-2 mb-2">
      <button type="button" class="btn btn-outline-secondary" aria-label="Réinitialiser la recherche"
        [disabled]="filter.categories.length === 0 && filter.title === '' && filter.type === null" (click)="clearFilter()">
        Réinitialiser la recherche
      </button>
    </div>
  </div>
  <div class="col-lg-9">
    <div class="d-flex flex-column flex-md-row align-self-center">
      <div class="flex-grow-1 mt-2 mt-md-0 me-0 me-md-2">
        <div class="d-grid gap-2">
          <div class="input-group">
            <input type="text" class="form-control" (keyup.enter)="search()" [(ngModel)]="filter.title"
              placeholder="Rechercher" aria-label="Rechercher" autocomplete="off" autocorrect="off" autocapitalize="off"
              spellcheck="false" maxlength="255" />
            <button class="btn btn-outline-secondary" (click)="clearTerm()" type="sumbit">
              <span class="bi bi-x-lg"></span>
            </button>
            <button class="btn btn-outline-secondary" (click)="search()" type="sumbit">
              <span class="bi bi-search"></span>
            </button>
          </div>
        </div>
      </div>
      <div class="mt-2 mt-md-0 mb-2">
        <div class="d-grid gap-2">
          <button type="button" class="btn btn-outline-secondary" aria-label="Réinitialiser la recherche"
          [disabled]="filter.categories.length === 0 && filter.title === ''" (click)="clearFilter()">
          Réinitialiser la recherche
        </button>
        </div>
      </div>
    </div>
    <div *ngIf="searching" class="text-center">
      <div class="spinner-grow text-secondary" role="status">
        <span class="visually-hidden">Recherche en cours...</span>
      </div>
    </div>
    <div *ngIf="!searching">
      <div class="row page-fade" *ngIf="!loading && resources" infiniteScroll [infiniteScrollDistance]="1"
        [infiniteScrollThrottle]="50" (scrolled)="loadMoreItems()">
        <ul class="list-group list-group-flush">
          <li class="list-group-item" *ngFor="let resource of resources">
            <nemo-parcours-ressources-detail [resource]="resource" [selectionMode]="selectionMode" (ressourceToAdd)="ressourceToAdd($event)"></nemo-parcours-ressources-detail>
          </li>
        </ul>
      </div>
      <div *ngIf="loading || loadMore"  class="text-center">
        <div class="spinner-grow text-secondary" role="status">
          <span class="visually-hidden">Recherche en cours...</span>
        </div>
      </div>
    </div>
  </div>
</div>