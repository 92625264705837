import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PlanEtudeRomandSelectorComponent } from '../plan-etude-romand-selector/plan-etude-romand-selector.component';
import { PlanEtudeRomandSearchViewModel } from '@app/model';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'nemo-plan-etude-romand-selector-modal',
    templateUrl: './plan-etude-romand-selector-modal.component.html',
    styleUrls: ['./plan-etude-romand-selector-modal.component.scss'],
    standalone: true,
    imports: [CommonModule,PlanEtudeRomandSelectorComponent]
})
export class PlanEtudeRomandSelectorModalComponent {

  @Input() multiSelect = false;
  @Input() hasCriteria = false;
  
  selectedPers: PlanEtudeRomandSearchViewModel[] = [];
  
  constructor(public activeModal: NgbActiveModal) {
    
  }

  emitSelectedPers(){

  }
}
