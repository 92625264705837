import { AppComponent } from './app/app.component';
import { bootstrapApplication } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';


import localeFr from '@angular/common/locales/fr-CH';
import { appConfig } from '@app/app.config';
registerLocaleData(localeFr, 'fr-CH');

const consoleColorBootstrap = 'color: coral';
bootstrapApplication(AppComponent, appConfig).then(() => {
    console.log('%c       (   (                  ',consoleColorBootstrap);
    console.log('%c  (    )\  )\   )         (   ',consoleColorBootstrap);
    console.log('%c  )\ (((_)((_| /( (  (   ))\  ',consoleColorBootstrap);
    console.log('%c ((_))\___ _ )(_)))\ )\ /((_) ',consoleColorBootstrap);
    console.log('%c  (_|(/ __| ((_)_((_|(_|_))   ',consoleColorBootstrap);
    console.log('%c  | || (__| / _` (_-<_-< -_)  ',consoleColorBootstrap);
    console.log('%c  |_| \___|_\__,_/__/__|___|  ',consoleColorBootstrap);
}).catch((err) =>
  console.error(err)
);

