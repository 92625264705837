<div class="explanation card border-secondary mb-3">
  <div class="card-header">Impression de cartes</div>
  <div class="card-body text-secondary">
    <p *ngIf="!doubleSided">Seul le <strong>{{flipped ?'verso':'recto'}}</strong> des cartes est imprimé, afin de commencer un travail de mémorisation, le verso de la carte doit être écrit à la main pour initier le processus de mémorisation.</p>
    <p *ngIf="doubleSided">Les deux côté des cartes sont imprimés, il faut découper sur les bords pleins et plier sur les bords pointillés.</p>
    <button (click)="back()" class="btn btn-outline-secondary">Retour</button>
    <button (click)="flip()" class="btn btn-outline-secondary">Retourner les cartes</button>
    <button (click)="toggleBoth()" class="btn btn-outline-secondary">{{doubleSided ? flipped ?'Verso':'Recto': 'Deux côtés'}}</button>
    <button (click)="print()" class="btn btn-outline-primary">Imprimer</button>
  </div>
</div>
<div *ngIf="list">
  <div *ngIf="!doubleSided">
    <div class="print-card-container" *ngFor="let row of chunkedItems">
      <ng-container *ngFor="let i of row">
      <div class="print-card" >
        <p>{{flipped ? i.hintTxt: i.learnTxt}}</p>
      </div>
      </ng-container>
    </div>
  </div>

  <div *ngIf="doubleSided">
    <div class="print-card-container" *ngFor="let row of chunkedItemsDouble">
      <ng-container *ngFor="let i of row; let idx = index;">
      <div class="print-card bothSided" >
        <p>{{flipped ? (idx % 2 ===0 ? i.hintTxt:i.learnTxt):  (idx % 2 === 0 ? i.learnTxt: i.hintTxt)}}</p>
      </div>
      </ng-container>
    </div>
  </div>
</div>
