export class Criteria {
  id: number;
  label: string;
  selected?: boolean;
  filtered?: boolean;
  tags?: string[];
  categories?: string[];
  studentEvaluationEnabled?: boolean;
  perObjectiveCode?: string;
  perId?: string;
  axesThematiques?: string[];

  constructor(model?: Criteria) {
    if (model) {
      this.id = model.id;
      this.label = model.label;
      this.selected = model.selected;
      this.filtered = model.filtered;
      this.tags = model.tags;
      this.categories = model.categories;
      this.studentEvaluationEnabled = model.studentEvaluationEnabled;
      this.perObjectiveCode = model.perObjectiveCode;
      this.perId = model.perId;
      this.axesThematiques = model.axesThematiques;
    }
  }
}
