<div *ngIf="filter">
  <div class="row">
    <div class="col-lg-3 d-none d-lg-block">
      <div *ngIf="isTeacher()">
        <div *ngIf="!selectedPlanEtudeRomandSearchViewModel" class="page-fade">
          <div class="list-group mb-1">
            <a [routerLink]="[]" class="list-group-item list-group-item-action" (click)="searchPer()">
              <small><span class="edicons edicons-visualidentity-per"></span> Chercher avec le plan d'étude romand</small>
            </a>
          </div>
        </div>
        <div *ngIf="selectedPlanEtudeRomandSearchViewModel" class="page-fade" (click)="clearPer()">
          <div class="list-group mb-1">
            <a [routerLink]="[]" class="list-group-item list-group-item-action"  >
              <div class="d-flex">
                <div class="flex-grow-1 align-self-center">
                  <nemo-plan-etude-romand-display [planEtudeRomand]="selectedPlanEtudeRomandSearchViewModel" [displayUrl]="false"></nemo-plan-etude-romand-display>
                </div>
                <div class="align-self-center"><i class="bi bi-dash-circle"></i></div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <nemo-new-category-selector *ngIf="selectableCategories" [selectableCategoriesKeys]="selectableCategories" #cat [selectedCategoriesKeys]="filter.categories" (selectedCategoriesChange)="filterCategory($event)"></nemo-new-category-selector>
      <div class="d-grid gap-2 mb-2">
        <button type="button" class="btn btn-outline-secondary" aria-label="Réinitialiser la recherche"
          [disabled]="!isFiltered()" (click)="clearFilter()">
          Réinitialiser la recherche
        </button>
      </div>
    </div>
    <div class="col-lg-9">
      <div class="d-flex flex-column flex-md-row align-self-center">
        <div class="flex-grow-1 mt-2 mt-md-0 me-0 me-md-2">
          <div class="d-grid gap-2">
            <div class="input-group">
              <input type="text" class="form-control" (keyup.enter)="search()" [(ngModel)]="filter.title"
                placeholder="Rechercher" aria-label="Rechercher" autocomplete="off" autocorrect="off" autocapitalize="off"
                spellcheck="false" maxlength="255" />
              <button class="btn btn-outline-secondary" (click)="clearTerm()" type="sumbit">
                <span class="bi bi-x-lg"></span>
              </button>
              <button *ngIf="isPublisher() && (rpn || teacher)" class="btn btn-outline-secondary" (click)="exportList()">
                <i class="bi bi-arrow-up-square"></i>
              </button>
              <button class="btn btn-outline-secondary" (click)="search()" type="sumbit">
                <span class="bi bi-search"></span>
              </button>
            </div>
          </div>
        </div>
        <div class="mt-2 mt-md-0 mb-2">
          <div class="d-grid gap-2">
            <button type="button" class="btn btn-outline-secondary" aria-label="Réinitialiser la recherche"
            [disabled]="!isFiltered()" (click)="clearFilter()">
            Réinitialiser la recherche
          </button>
          </div>
        </div>
      </div>
      <div *ngIf="searching" class="text-center">
        <div class="spinner-grow text-secondary" role="status">
          <span class="visually-hidden">Recherche en cours...</span>
        </div>
      </div>
      <div *ngIf="!searching">
        <div class="row py-2" *ngIf="lists && lists.length === 0">
          <div class="col-md-12">
            <div class="alert alert-info" role="alert" *ngIf="isFiltered()">
              <span class="bi bi-exclamation-circle-fill"></span> Aucun parcours ne correspond à ces critères,
              <a [routerLink]="[]" (click)="clearFilter()" class="alert-link">réinitialiser</a>
              la recherche pour trouver ce que vous cherchez.
            </div>
            <div class="alert alert-secondary" role="alert" *ngIf="!isFiltered() && libre">
              <h4 class="alert-heading">Dommage, c'est encore vide par ici !</h4>
              <p>
                Aucun parcours public n'est disponible pour le moment, merci de repasser plus tard.
              </p>
            </div>
            <div class="alert alert-secondary" role="alert" *ngIf="!isFiltered() && private">
              <h4 class="alert-heading">Dommage, c'est encore vide par ici !</h4>
              <p>
                Vous pouvez créer votre premier parcours personnel en cliquant sur le bouton
                <a [routerLink]="[]" (click)="newCourse()">ajouter</a>
                ci-dessous.
              </p>
              <p>
                Peut être qu'un <a [routerLink]="['/parcours/rpn']">parcours RPN</a> pourrait vous intéresser ? Il vous sera possible
                de le copier afin de l'adapter à vos besoins si nécessaire par la suite.
              </p>
            </div>
            <div class="alert alert-secondary" role="alert" *ngIf="!isFiltered() && teacher">
              <h4 class="alert-heading">Dommage, c'est encore vide par ici !</h4>
              <p>
                Aucun parcours pour enseignant·e·s n'est disponible pour le moment, merci de repasser plus tard.
              </p>
              <p>
                Peut être qu'un <a [routerLink]="['/parcours/rpn']">parcours RPN</a> pourrait vous intéresser ? Il vous sera possible
                de le copier afin de l'adapter à vos besoins si nécessaire par la suite.
              </p>
            </div>
          </div>
        </div>
        <div class="row py-2" *ngIf="lists && lists.length !== 0 && libre && !parentCategorySelected">
          <div class="col-md-12">
            <div class="alert alert-info" role="alert">
              <a [routerLink]="[]" (click)="fetchParentsParcours()" class="alert-link">Cliquer pour afficher tous les parcours destinés aux parents.</a>.
            </div>
          </div>
        </div>
        <div class="row page-fade" *ngIf="!loading && lists" infiniteScroll [infiniteScrollDistance]="1"
          [infiniteScrollThrottle]="50" (scrolled)="loadMoreItems()">
          <div class="col-lg-4 col-md-6 mb-2" *ngIf="private">
            <nemo-card [title]="'Ajouter'" [icon]="'bi bi-plus-circle-fill'" (clicked)="newCourse()"></nemo-card>
          </div>
          <div class="col-lg-4 col-md-6 mb-2" *ngFor="let list of lists">
            <nemo-card
              [title]="list.title"
              [subtitle]="list.subtitle"
              [ribbon]="list.ribbon"
              [icon]="list.icon"
              [color]="list.color"
              tabindex="-1"
              [tags]="list.categories"
              [perCodes]="list.perObjectiveCodes"
              [progression]="list.progression"
              (clicked)="courseClicked()"
              [routerLink]="['./',list.id]"
              (categoryclicked)="filterCategory($event)">
              <p class="card-text" *ngIf="private && list.distributed">
                <span class="badge rounded-pill category-tag me-1" *ngIf="list.distributedFrom">Distribution planifiée</span>
                <span class="badge rounded-pill category-tag me-1" *ngIf="!list.distributedFrom">Distribution en cours</span>
              </p>
            </nemo-card>
          </div>
        </div>
        <div *ngIf="loading || loadMore"  class="text-center">
          <div class="spinner-grow text-secondary" role="status">
            <span class="visually-hidden">Recherche en cours...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
