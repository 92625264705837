
<div class="row" *ngIf="languages && changeLanguageEnabled">
  <div class="col-md-4 d-grid gap-2" *ngFor="let l of languages">
    <a [routerLink]="[]" (click)="changeLanguage(l.id)" [ngClass]="{'active': learnLanguage === l.id}" class="btn btn-outline-secondary mb-1">Verbe {{l.label | lowercase}}</a>
  </div>
</div>

<div class="row">
  <div class="col d-grid gap-2" *ngFor="let y of years">
    <a [routerLink]="[]"  (click)="selectYear(y)" [ngClass]="{'active': year===y}" class="btn btn-outline-secondary btn-sm mb-1">{{y}}e</a>
  </div>
</div>

<div class="row" *ngIf="verbs && year">
  <div class="col-md-12">
    <h4>Choisir un verbe</h4>
    <p class="lead">
      <a [routerLink]="[]" *ngFor="let v of verbs" class="badge rounded-pill" [ngClass]="{'category-tag':!isVerbSelected(v), 'bg-primary':isVerbSelected(v)}" (click)="toggleVerb(v)">{{v}}</a>
    </p>
  </div>
</div>
<div class="row" *ngIf="verbs && year">
  <div class="col">
    <h4>Temps de conjugaison</h4>
    <p class="lead">
      <a [routerLink]="[]" *ngFor="let v of tenses" class="badge rounded-pill" [ngClass]="{'category-tag':!isTenseSelected(v), 'bg-primary':isTenseSelected(v)}" (click)="toggleTense(v)">{{v}}</a>
    </p>
  </div>
  <div class="col" *ngIf="false">
    <h4>Traduction</h4>
    <p class="lead">Vous pouvez traduire depuis le français les items, sinon une proposition verbe, temps, forme sera proposée dans la langue choisie.</p>
    <div class="form-check form-switch">
      <label class="form-check-label" for="useFrenchHints">Traduire depuis le français ?</label>
      <input class="form-check-input" type="checkbox" role="switch" id="useFrenchHints" [(ngModel)]="useFrenchHints" [ngModelOptions]="{standalone: true}">
    </div>
  </div>
</div>
<div class="row">
  <div class="col d-grid gap-2">
    <button  (click)="validate()" [disabled]="selectedTenses.length === 0 || selectedVerbs.length === 0" type="button" class="btn btn-outline-primary mt-2">{{ValidateButtonTxt}}</button>
  </div>
</div>
