import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReferenceDataService } from '@app/core/service';
import { Category } from '@app/model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgArrayPipesModule, NgObjectPipesModule } from 'ngx-pipes';
import { RouterLink } from '@angular/router';
import { NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'nemo-category-selector',
    templateUrl: './category-selector.component.html',
    styleUrls: ['./category-selector.component.scss'],
    standalone: true,
    imports: [NgFor, RouterLink, NgClass, NgArrayPipesModule, NgObjectPipesModule]
})
export class CategorySelectorComponent {

  @Input() selectedCategoriesKeys: string[];

  get categories():Category[]{
    return this.referenceDataService.getCategories();
  }
  get selectedCategories():Category[]{
    return this.referenceDataService.getCategories().filter(c=>this.selectedCategoriesKeys.includes(c.key));
  }

  @Input() readonly = false;
  @Output() changed = new EventEmitter<Category[]>();

  public edit = false;

  constructor(private readonly referenceDataService: ReferenceDataService, public activeModal: NgbActiveModal) {
  }

  toggleCategory(category: Category) {
    if(!this.selectedCategoriesKeys.includes(category.key)){
      this.selectedCategoriesKeys.push(category.key)
    }else{
      this.selectedCategoriesKeys.splice(this.selectedCategoriesKeys.indexOf(category.key), 1);
    }
  }

  emitChanges() {
    this.activeModal.close(this.selectedCategoriesKeys);
  }
  categorySelected(c: Category):boolean {
    return this.selectedCategoriesKeys.includes(c.key);
  }
}
