<nemo-basepage [title]="'Folio'">
  <div page-body>
    <div class="row">
      <div class="col-md-12">
        <p class="lead" *ngIf="rpn">Ce modèle de folio est prêt à l'emploi copiez-le pour l'adapter selon vos besoins.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="row d-print-none">
          <div class="col-md-4 mb-1" *ngIf="booklet">
            <div [ngClass]="{'sticked-top-margin': rpn}">
              <nemo-card [big]="true" [author]="booklet.author" [title]="booklet.title" [progression]="booklet.progression" [ribbon]="booklet.ribbon" [icon]="booklet.icon" [color]="booklet.color"></nemo-card>
              <div class="d-grid gap-2 my-2">
                <button type="button" *ngIf="booklet.template || !booklet.mine && !booklet.granted" class="btn btn-outline-primary"
                  tooltip="Créer un folio sur ce modèle" tooltipPlacement="top" (click)="clone()">Créer un folio sur ce modèle chez moi</button>
                <button type="button" *ngIf="booklet.granted || booklet.mine" class="btn btn-outline-primary" tooltip="Modifier"
                  tooltipPlacement="top" [routerLink]="['edition']">
                  <span class="bi bi-pencil-square" aria-hidden="true"></span> Modifier
                </button>
                <button type="button"  *ngIf="booklet.granted || booklet.mine" class="btn btn-outline-secondary" tooltip="Grille d'observation"
                      tooltipPlacement="top" (click)="getXlsx()"><i class="bi bi-file-earmark-excel"></i> Grille d'observation</button>
              </div>
              <div class="d-flex my-2">
                <div class="flex-grow-1 d-grid gap-2">
                  <button type="button" class="btn btn-outline-secondary" tooltip="Retour"
                    tooltipPlacement="top" [routerLink]="['../']"><span class="bi bi-arrow-left"></span> Retour</button>
                </div>
                <div class="ps-2">
                  <nemo-action-menu>
                    <button type="button" class="dropdown-item" tooltip="Générer tous les rapports" *ngIf="!rpn"
                      tooltipPlacement="top" (click)="getReports()">Tous les rapports</button>
                    <button type="button" class="dropdown-item" tooltip="Export Excel"
                      tooltipPlacement="top" (click)="getXlsx()">Grille d'observation</button>
                    <button type="button" class="dropdown-item" tooltip="Copier le lien" tooltipPlacement="top"
                      ngxClipboard [cbContent]="getDocumentLocation()" (cbOnSuccess)="copied($event)">Copier le lien</button>
                    <button type="button" class="dropdown-item" tooltip="Envoyer le lien par courriel"
                      tooltipPlacement="top" (click)="sendbymail()">Envoyer le lien par courriel</button>
                    <button type="button" class="dropdown-item" tooltip="Imprimer" *ngIf="!rpn"
                      tooltipPlacement="top" (click)="print()">Imprimer</button>
                    <button type="button" *ngIf="booklet.granted || booklet.mine" class="dropdown-item" tooltip="Dupliquer"
                      tooltipPlacement="top" (click)="clone()">Dupliquer</button>
                  </nemo-action-menu>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8" *ngIf="rpn || (booklet && !booklet.mine && !booklet.granted)">
            <div *ngFor="let criteria of booklet.criterias">
              <div class="d-flex">
                <div class="flex-fill flex-grow-1 align-self-center mb-2">
                  <nemo-criteria [editable]="false" [criteria]="criteria"></nemo-criteria>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8" *ngIf="!rpn && booklet && (booklet.mine || booklet.granted)">
            <div class="row" *ngIf="booklet && booklet.monitoredStudents.length > 0 && booklet.criterias.length > 0">
              <div class="col-md-6 d-none d-lg-block">
                <div class="card text-center">
                  <div class="card-body">
                    <h6 class="card-subtitle mb-2 text-muted">Progression par évaluation</h6>
                    <canvas #lineChart>{{ chart }}</canvas>
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-lg-6">
                <div class="card text-center">
                  <div class="card-body">
                    <h6 class="card-subtitle mb-2 text-muted">Progression générale</h6>
                    <nemo-stacked-progress-bar *ngIf="observations" [progression]="progression" [height]="15"></nemo-stacked-progress-bar>
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-lg-6">
                <div class="card text-center mt-2"  *ngIf="disciplines.length>0" >
                  <div class="card-body">
                    <nemo-tags-toggler [title]="'Disciplines'" [tags]="[disciplines]" (changed)="applyCriteriaFiltering()"></nemo-tags-toggler>
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-lg-6">
                <div class="card text-center mt-2" *ngIf="annees.length>0">
                  <div class="card-body">
                    <nemo-tags-toggler [title]="'Années'" [tags]="[annees]" (changed)="applyCriteriaFiltering()"></nemo-tags-toggler>
                  </div>
                </div>
              </div>
              <div class="col-md-12" *ngIf="!rpn">
                <div class="card text-center mt-2"  *ngIf="axesThematiques.length>0">
                  <div class="card-body">
                    <nemo-tags-toggler [title]="'Axes thématiques'" [tags]="[axesThematiques]" (changed)="applyCriteriaFiltering()"></nemo-tags-toggler>
                  </div>
                </div>
                <div class="card text-center mt-2" *ngIf="filters.length>0">
                  <div class="card-body">
                    <nemo-tags-toggler [title]="'Catégories'" [tags]="filters" (changed)="applyCriteriaFiltering()"></nemo-tags-toggler>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="booklet && (booklet.monitoredStudents.length === 0 || booklet.criterias.length === 0)">
              <div class="col-md-12">
                <div class="alert alert-secondary" role="alert">
                  <h4 class="alert-heading">Ce folio n'est pas encore tout à fait prêt ...</h4>
                  <p *ngIf="booklet.monitoredStudents.length === 0 ">
                    Aucun élève n'est suivi dans ce folio. <a [routerLink]="['./edition']">Modifiez-le</a> pour ajouter un ou plusieurs élèves.
                  </p>
                  <p *ngIf="booklet.criterias.length === 0 ">
                    Aucun critère n'est défini dans ce folio. <a [routerLink]="['./edition']">Modifiez-le</a> pour ajouter un ou plusieurs critères.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12" *ngIf="!rpn && booklet && (booklet.mine || booklet.granted)">
            <h1 class="d-none d-print-inline">
              {{booklet.title}}
            </h1>
            <div class="mt-2" *ngIf="booklet.monitoredStudents.length > 0 && booklet.criterias.length > 0">
              <table class="table table-sm align-middle table-striped-columns" *ngIf="filteredCriterias.length > 0">
                <caption class="visually-hidden">Observations</caption>
                <thead>
                  <tr class="vertical">
                    <th></th>
                    <th scope="col" *ngFor="let student of booklet.monitoredStudents" class="appreciationHeader">
                      <a [routerLink]="['./eleve', student.id]" data-toggle="tooltip" data-placement="top" [ngbTooltip]="student.displayName" [attr.aria-label]="student.displayName"><span class="d-print-inline"><small>{{student.displayName}}</small></span></a>
                    </th>
                  </tr>
                  <tr class="d-print-none">
                    <th></th>
                    <th scope="col" *ngFor="let student of booklet.monitoredStudents" class="appreciationHeader">
                      <a href="javascript:void(0)"  (click)="getReport(student)" [attr.aria-label]="'Rapport PDF de ' + student.displayName"><span class="bi bi-file-pdf-fill"></span></a>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let criteria of filteredCriterias">
                    <th scope="row" class="criteria"><a [routerLink]="['./critere', criteria.id]"><small>{{criteria.label}}</small></a></th>
                    <td *ngFor="let student of booklet.monitoredStudents" class="appreciation selectable" >
                      <nemo-evaluation *ngIf="observations && scale" [evaluationCode]="getEvaluation(criteria.id, student.id)" [scale]="scale"></nemo-evaluation>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="alert alert-info mt-2" role="alert"  *ngIf="filteredCriterias.length === 0">
                <h4 class="alert-heading">Aucun critère à afficher</h4>
                <p>
                  Sélectionnez dans les catégories ci-dessus une ou l'autre catégorie à afficher.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nemo-basepage>
