<div class="row" *ngIf="currentItem">
  <div class="col-md-6 offset-md-3">
    <div class="py-2" *ngIf="currentItem.hintTxt  || currentItem.hintImgUrl">
      <div class="card text-center">
        <img *ngIf="currentItem.hintImgUrl" class="card-img-top p-1" alt="Card image cap" [src]="currentItem.hintImgUrl"
          [alt]="currentItem.hintTxt">
        <ul class="list-group list-group-flush" *ngIf="currentItem.hintTxt">
          <li class="list-group-item">
            <nemo-item-text [text]="currentItem.hintTxt" [languageCode]="list.hintLanguage"></nemo-item-text>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col-md-6 offset-md-3">
    <div class="card text-center">
      <ul class="list-group list-group-flush">
        <li class="list-group-item" [@itemState]="displayLearn">
          <nemo-item-text [text]="currentItem.learnTxt" [languageCode]="list.learnLanguage"></nemo-item-text>
        </li>
      </ul>
      <div class="card-footer">
        <div class="d-grid gap-2">
          <button type="button" class="btn btn-outline-secondary mb-1" *ngIf="displayLearn === 'guess'"
            (click)="display()"><span class="bi bi-eye-fill"></span> Afficher<kbd>&darr;</kbd></button>
        </div>
        <div [@itemState]="displayLearn"  class="d-grid gap-2">
          <button  type="button" class="btn btn-outline-success mb-1" *ngIf="displayLearn === 'learn'"
            (click)="endItem(true)"><span class="bi bi-check-lg"></span> Je sais<kbd>&rarr;</kbd></button>
          <button [@itemState]="displayLearn" type="button" class="btn btn-outline-warning mb-1" *ngIf="displayLearn === 'learn'"
            (click)="endItem(false)"><span class="bi bi-arrow-clockwise"></span> À répéter<kbd>&larr;</kbd></button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Régler</h4>
    <button type="button" class="btn-close" aria-label="btn-close" (click)="modal.dismiss()"></button>
  </div>
  <form>
    <ul class="list-group list-group-flush">
      <li class="list-group-item">
        <div class="d-flex flex-wrap py-1">
          <div class="flex-grow-1 align-self-center pe-2">
            <label class="lead form-check-label" for="sonorize">Entendre le mot à l'affichage ?</label>
          </div>
          <div class="align-self-center">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" role="switch" id="sonorize"  [(ngModel)]="config.sonorize" [ngModelOptions]="{standalone: true}">
            </div>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="d-flex flex-wrap py-1">
          <div class="flex-grow-1 align-self-center pe-2">
            <label class="lead form-check-label" for="invert">Intervertir le contenu à apprendre et l'indice ?</label>
          </div>
          <div class="align-self-center">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" role="switch" id="invert"  [(ngModel)]="config.invert" [ngModelOptions]="{standalone: true}">
            </div>
          </div>
        </div>
      </li>
    </ul>
  </form>
  <div class="modal-footer">
    <small><a [routerLink]="[]" (click)="displayPreferences()">D'autres réglages sont disponibles dans les préférences.</a></small>
    <button type="button" class="btn btn-outline-primary" ngbAutofocus (click)="modal.close()">Valider</button>
  </div>
</ng-template>
