import { Component, OnInit, HostListener } from '@angular/core';
import { Router, RouterOutlet, RouterLink } from '@angular/router';
import { RedirectionService, PreferencesService, ToastService, ReferenceDataService } from './core/service';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { ToastType, UserProfile } from './model';
import { UserNotificationService } from './core/service/user-notification.service';
import { Subscription } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { NgIf, NgClass } from '@angular/common';
import { ToastComponent } from './shared/components/toast/toast.component';
import { MenuComponent } from './shared/components/menu/menu.component';
import { AuthenticationService } from './core/service/authentication.service';
import { SettingsService } from './core/service/settings.service';

@Component({
    selector: 'nemo-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [MenuComponent, ToastComponent, RouterOutlet, NgIf, FormsModule, RouterLink, NgClass]
})
export class AppComponent implements OnInit {
  title = 'nemo';
  showScroll: boolean;
  showScrollHeight = 300;
  hideScrollHeight = 10;
  closed = false;
  closedActus = false;
  email:string='';
  userProfile: UserProfile;


  private hubConnection: HubConnection;

  constructor(
    private readonly router: Router,
    private readonly authService: AuthenticationService,
    private readonly redirectionService: RedirectionService,
    private readonly referenceDataService: ReferenceDataService,
    public settings: SettingsService,
    public preferencesService: PreferencesService,
    private readonly toastService: ToastService,
    public readonly userNotificationService :UserNotificationService
  ) {
    this.userProfile = this.authService.getCurrentUserProfile();
    if (this.settings.feature_flags.signal_r && this.userProfile.isAuthenticated) {      
      this.hubConnection = new HubConnectionBuilder()
      .withUrl(`/notification`)
      .withAutomaticReconnect()
      .configureLogging(this.settings.environment === 'dev'? LogLevel.Trace :LogLevel.None)
      .build();
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if ((window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) > this.showScrollHeight) {
      this.showScroll = true;
    } else if (this.showScroll &&
      (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) < this.hideScrollHeight) {
      this.showScroll = false;
    }
  }

  ngOnInit() {

    if (this.authService.getCurrentUserProfile().isAuthenticated && localStorage) {
      const redirect = this.redirectionService.getAndFlushRedirectionUrl();
      if (redirect && redirect.length > 0) {
        this.router.navigate([redirect]);
      }
    }
    this.referenceDataService.updateDataFromApi();
    const lastDiplay = localStorage.getItem('actu_l_display');
    const newDate = this.addHours(new Date(lastDiplay),0);

    if ((lastDiplay === null || lastDiplay !== null && newDate < new Date())) {
      this.referenceDataService.getActualites().subscribe((actus) => {
        localStorage.setItem('actu_l_display', new Date().toISOString());
        const actualite = actus && actus.length > 0 ? actus[0] : null;
        if (actualite !== null) {
          this.toastService.notify({
            message:actualite.title,
            type: ToastType.Info,
            url:actualite.uri
          });
        }
      });
    }
  }

  addHours(date: Date, hours: number) {
    date.setTime(date.getTime() + (hours*60*60*1000));
    return date;
  }

  scrollToTop() {
    const el = document.querySelector('header');
    this.scrollTo(el);
  }

  scrollTo(el: Element): void {
    if(el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }

  closeAlert() {
    this.closed = true;
  }

  closeActus() {
    this.closedActus = true;
  }

  testNotification():boolean {
    return false;//this.preferencesService.isAdmin() && (this.settings.environment === 'dev' || this.settings.environment === 'test') ;
  }
  
  addNotification(): void {
    this.userNotificationService.push(this.email).subscribe(r=> Subscription.EMPTY);
  }
}
