import { Component, Input, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Teacher } from '@app/model';
import { UserDisplayComponent } from '../user-display/user-display.component';
import { TeacherSearchComponent } from '../teacher-search/teacher-search.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'nemo-transmit-modal',
    templateUrl: './transmit-modal.component.html',
    styleUrls: ['./transmit-modal.component.scss'],
    standalone: true,
    imports: [NgIf, TeacherSearchComponent, UserDisplayComponent]
})
export class TransmitModalComponent {

  @Output() transmit = new EventEmitter();
  grantedTeacher: Teacher;

  constructor(public activeModal: NgbActiveModal) { }

  selectTeacher(teacher: Teacher) {
    this.grantedTeacher = new Teacher({
        displayName: teacher.displayName,
        samAccountName: teacher.samAccountName,
        department: teacher.department,
        description: teacher.description,
        sid: teacher.sid,
        email: teacher.email,
        employeeId: teacher.employeeId,
        objectGuid: teacher.objectGuid,
        upn: teacher.upn
      });
  }

}
