import { HttpClient, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, retry, throwError } from 'rxjs';
import { saveAs } from 'file-saver';
import { SettingsService } from './settings.service';

@Injectable({ providedIn: 'root' })
export class HttpBaseService {

  constructor(
    private readonly http: HttpClient,
    private readonly settings: SettingsService,
  ) {
  }

  get api_url(): string {
    return `/api/v1`;
  }

  get<T>(url: string, options = {}): Observable<T> {
    const actionUrl = `${this.api_url}${url}`;
    return this.http.get<T>(actionUrl, options).pipe(
      retry(1), // retry a failed request one time
      catchError(this.handleError) // then handle the error
    );
  }

  post<T>(url: string, body: any): Observable<T> {
    const actionUrl = `${this.api_url}${url}`;
    return this.http.post<T>(actionUrl, body);
  }

  put<T>(url: string, body: any): Observable<T> {
    const actionUrl = `${this.api_url}${url}`;
    return this.http.put<T>(actionUrl, body);
  }

  delete<T>(url: string): Observable<T> {
    const actionUrl = `${this.api_url}${url}`;
    return this.http.delete<T>(actionUrl);
  }

  patch<T>(url: string, body: any): Observable<T> {
    const actionUrl = `${this.api_url}${url}`;
    return this.http.patch<T>(actionUrl, body);
  }

  download(url: string, name: string) {
    const actionUrl = `${this.api_url}${url}`;
    this.http.get(actionUrl, { responseType: 'arraybuffer'}).subscribe(f => {
      const b = new Blob([f], { type: 'application/pdf' });
      saveAs(b, name);
    });
  }

  downloadExcel(url: string, name: string) {
    const actionUrl = `${this.api_url}${url}`;
    this.http.get(actionUrl, { responseType: 'arraybuffer'}).subscribe(f => {
      console.log(f);
      const b = new Blob([f], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(b, name);
    });

  }

  upload<T>(url: string, body: any): Observable<HttpEvent<T>> {
    const actionUrl = `${this.api_url}${url}`;
    return this.http.post<T>(actionUrl, body, {
      reportProgress: true,
      observe: 'events'
    });
  }
  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }
  public getCurrentFormattedDate(includeTime=false): string {
    const today = new Date();
    const dd = today.getDate();
    const mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    const time = includeTime?`-${today.getHours()}-${today.getMinutes()}${today.getSeconds()}`:"";
    return `${yyyy}-${this.pad(mm, 2)}-${this.pad(dd, 2)}${time}`;
  }
  private pad(num: number, size: number): string {
    let s = String(num);
    while (s.length < size) {
      s = `0${s}`;
    }
    return s;
  }
}
