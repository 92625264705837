<div class="container" *ngIf="distributions && distributions.length > 0">
  <div class="row">
    <p class="lead">Vous distribuez les parcours suivants :</p>
  </div>
  <div class="row" *ngFor="let d of distributions">
    <div class="card mb-2">
      <div class="card-body">
        <div class="row" >
          <div class="col-md-4">
            <nemo-card
              [author]="d.author"
              [title]="d.title"
              [icon]="d.icon"
              [color]="d.color">
              <p class="card-text text-muted"><small><nemo-date-range [range]="{ start: d.from, end: d.to }"></nemo-date-range></small></p>
            </nemo-card>

            <div class="d-flex my-2">
              <div class="flex-grow-1 d-grid gap-2">
                <button type="button" class="btn btn-outline-secondary" tooltip="Modifier"
                tooltipPlacement="top" [routerLink]="['../personnels/',d.id,'distribution']">Voir</button>
              </div>
              <div class="ps-2">
                <nemo-action-menu>
                  <button type="button" class="dropdown-item" tooltip="Afficher le parcours"
                    tooltipPlacement="top" [routerLink]="['../personnels/',d.id]">Afficher le parcours</button>
                  <button type="button" class="dropdown-item" tooltip="Supprimer la distribution"
                    tooltipPlacement="top" (click)="delete(d.id)">Supprimer la distribution</button>
                </nemo-action-menu>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <ul class="list-unstyled">
              <li *ngFor="let s of d.students.slice(0, d.students.length / 2)"><small>{{s}}</small></li>
            </ul>
          </div>
          <div class="col-md-4">
            <ul class="list-unstyled">
              <li *ngFor="let s of d.students.slice(d.students.length / 2, d.students.length + 1)"><small>{{s}}</small></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row py-2" *ngIf="distributions && distributions.length === 0">
  <div class="col-md-12">
    <div class="alert alert-secondary" role="alert" *ngIf="all">
      <h4 class="alert-heading">Dommage, c'est encore vide par ici !</h4>
      <p>
        Vous ne distribuez aucun parcours actuellement.
      </p>
      <p>
        Consultez vos <a [routerLink]="['../personnels']">parcours personnels</a> pour les distribuer à vos élèves.
      </p>
    </div>
    <div class="alert alert-secondary" role="alert" *ngIf="!all">
      <h4 class="alert-heading">Dommage, c'est encore vide par ici !</h4>
      <p>
        Vous n'avez pas distribué de parcours récemment, essayez de charger les anciennes distributions.
      </p>
      <p>
        Sinon, consultez vos <a [routerLink]="['../personnels']">parcours personnels</a> pour les distribuer à vos élèves.
      </p>
    </div>
  </div>
</div>
<button type="button" *ngIf="!all" class="btn btn-outline-secondary" (click)="toggleAll()">Afficher les distributions plus anciennes</button>
