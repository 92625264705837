import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { ActivityService } from '@app/core/service';
import { ItemList, Progression, Item } from '@app/model';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, RouterLink } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ItemTextComponent } from '../common/item-text/item-text.component';
import { NgFor, NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'nemo-association',
    templateUrl: './association.component.html',
    styleUrls: ['./association.component.scss'],
    animations: [
        trigger('itemState', [
            state('found', style({
                opacity: 0
            })),
            state('selected', style({
                opacity: 1
            })),
            state('notfound', style({
                opacity: 1
            })),
            state('fail', style({
                opacity: 1
            })),
            transition('void => *', [
                animate(300, keyframes([
                    style({ transform: 'translateY(-100%)', offset: 0 }),
                    style({ transform: 'translateY(15px)', offset: 0.3 }),
                    style({ transform: 'translateY(0)', offset: 1.0 })
                ]))
            ]),
            transition('* => found', [
                animate(300, keyframes([
                    style({ transform: 'translateY(0)', offset: 0 }),
                    style({ transform: 'translateY(15px)', offset: 0.7 }),
                    style({ transform: 'translateY(-100%)', offset: 1.0 })
                ]))
            ]),
            transition('* => notfound', [
                animate(300)
            ]),
            transition('* => fail', [
                animate(300, keyframes([
                    style({ transform: 'translateX(0)', offset: 0 }),
                    style({ transform: 'translateX(-15px)', offset: 0.33 }),
                    style({ transform: 'translateX(15px)', offset: 0.66 }),
                    style({ transform: 'translateX(0)', offset: 1.0 })
                ]))
            ])
        ])
    ],
    standalone: true,
    imports: [NgFor, NgClass, NgIf, ItemTextComponent, FormsModule, RouterLink]
})
export class AssociationComponent implements OnInit, OnDestroy {

  @Input() list: ItemList;
  @Output() activityProgress = new EventEmitter<Progression>(true);
  @Output() activityEnd = new EventEmitter(true);

  private progression: Progression;
  private progressionBackup: Progression;
  public config: AssociationConfig;
  public allItems: Item[];
  public workinglist = new Array<AssociationItem>();
  public first: AssociationItem;
  public totalNbItems = 0;
  private countPanels = 1;
  subscription: Subscription;

  @ViewChild('content', {
    static: true
  })
  private readonly configureBtnTemplate: TemplateRef<any>;

  constructor(private readonly activityService: ActivityService,
    private readonly router: Router,
    public readonly modalService: NgbModal) {
    this.subscription = activityService.configureActivity$.subscribe(
      conf => {
        this.configure();
    });
  }

  ngOnInit() {
    this.progression = {
      success: 0,
      danger: 0,
      info: 0,
      warning: 0
    };
    this.progressionBackup = {
      success: 0,
      danger: 0,
      info: 0,
      warning: 0
    };
    this.countPanels = 1;
    this.activityProgress.emit(this.progression);
    this.config = this.activityService.getConfig<AssociationConfig>('association', { howmanywords: 1 });
    this.allItems = Object.assign([], this.list.items);
    this.workinglist = [];
    this.allItems = this.activityService.shuffle(this.allItems);
    this.totalNbItems = this.allItems.length * 2;
    this.alimWorkingItems();
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
  }

  displayPreferences() {
    this.modalService.dismissAll();
    this.router.navigate(['/preferences/']);
  }
  configure() {
    this.modalService
      .open(this.configureBtnTemplate, { ariaLabelledBy: 'modal-basic-title', size: 'lg' })
      .result.then(
        result => {
          this.activityService.setConfig('association', this.config);
          this.ngOnInit();
        },
        reason => {
          this.ngOnInit();
        }
      );
  }
  selectCard(item: AssociationItem) {
    item.attempt++;
    if (item.state !== 'found') {
      item.state = 'selected';
      if (!this.first) {
        this.first = item;
        this.first.state = 'selected';
      } else if (this.first && (item.matching.filter(t => t === this.first.id).length > 0 ||
        this.first.matching.filter(t => t === item.id).length > 0) && item !== this.first) {
        // SUCCESS
        this.first.state = 'found';
        item.state = 'found';
        this.first = null;
      } else {
        // FAIL
        const f = this.first;
        this.first.state = 'fail';
        item.state = 'fail';
        this.first = null;
        setTimeout(() => {
          if (item.state === 'fail') {
            item.state = 'notfound';
          }
          if (f.state === 'fail') {
            f.state = 'notfound';
          }
        }, 2000);
      }
    }
    this.progression.success = this.progressionBackup.success +  Math.round(
      (this.workinglist.filter(i => i.state === 'found' && i.attempt === 1).length / this.totalNbItems) * 100
    );
    this.progression.warning = this.progressionBackup.warning +  Math.round(
      (this.workinglist.filter(i => (i.state !== 'found' && i.state !== 'selected' && i.attempt === 1)
        || i.attempt > 1).length / this.totalNbItems) * 100
    );
    this.activityProgress.emit(this.progression);
    if (this.workinglist.filter(c => c.state === 'notfound' || c.state === 'fail').length === 0) {
      if (this.allItems.length === 0) {
        setTimeout(() => {
          this.activityEnd.emit();
        }, 800);
      } else {
        setTimeout(() => {
          this.alimWorkingItems();
        }, 800);
      }
    }
  }
  alimWorkingItems() {
    let tempItems: Array<Item>;
    this.workinglist = [];
    this.progressionBackup = Object.assign({}, this.progression);
    switch (this.config.howmanywords) {
      case 0:
        tempItems = this.allItems.splice(0, this.list.items.length);
        break;
      case 1:
        tempItems = this.allItems.splice(0, this.activityService.optimizedArrayChunkSize(this.list.items.length, 6, this.countPanels));
        break;
      case 2:
        tempItems = this.allItems.splice(0, this.activityService.optimizedArrayChunkSize(this.list.items.length, 12, this.countPanels));
        break;
      default:
    }
    this.countPanels++;
    tempItems.forEach((item, idx) => {
      this.workinglist.push({
        id: item.id,
        matching: tempItems.filter(i => i.learnTxt === item.learnTxt).map(u => u.id),
        imgUrl: item.hintImgUrl,
        txt: item.hintTxt,
        state: 'notfound',
        attempt: 0
      });
      this.workinglist.push({
        id: item.id,
        matching: tempItems.filter(i => i.hintTxt === item.hintTxt && i.hintImgUrl === item.hintImgUrl).map(u => u.id),
        imgUrl: null,
        txt: item.learnTxt,
        state: 'notfound',
        attempt: 0
      });
    });
    this.workinglist = this.activityService.shuffle(this.workinglist);
  }
}

export class AssociationItem {
  public id: number;
  public matching: number[];
  public txt: string;
  public imgUrl: string;
  public state: string;
  public attempt: number;
}
export class AssociationConfig {
  howmanywords: number; // 0= all, 1= 5, 2 = 10
}
