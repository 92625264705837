<ul class="list-unstyled" >
  <li class="media border-bottom p-2" *ngFor="let comment of comments">
    <img src="{{ comment.avatar }}" alt="avatar" class="me-3 circle" *ngIf="comment.avatar">
    <span class="bi bi-person-circle" *ngIf="!comment.avatar || comment.avatar === null"></span>
    <div class="media-body">
      <h5 class="mt-0">{{comment.author}} <small>{{comment.created | amTimeAgo}}</small></h5>
      {{comment.comment}}
    </div>
  </li>
</ul>
