import { Component, Output, Input, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'nemo-suppress-modal',
    templateUrl: './suppress-modal.component.html',
    styleUrls: ['./suppress-modal.component.scss'],
    standalone: true
})
export class SuppressModalComponent {

  @Input() title = 'Attention';
  @Input() message = 'Vous êtes sur le point de supprimer définitivement cet élément, souhaitez-vous continuer ?';
  @Output() action = new EventEmitter();

  constructor(public activeModal: NgbActiveModal) {
  }

}
