import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'nemo-import-from-text-modal',
    templateUrl: './import-from-text-modal.component.html',
    styleUrls: ['./import-from-text-modal.component.scss'],
    standalone: true,
    imports: [FormsModule]
})
export class ImportFromTextModalComponent {
  public textValue = '';
  constructor(public activeModal: NgbActiveModal) { }

  treatText() {
    this.activeModal.close({txt: this.textValue});
  }

}
