<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <button type="button" class="btn-close" aria-label="btn-close" (click)="activeModal.dismiss()">

  </button>
</div>
<div class="modal-body">
  {{message}}
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss()" ngbAutofocus>Annuler</button>
  <button type="button" class="btn btn-danger" (click)="activeModal.close()">Supprimer</button>
</div>

