import { Injectable } from '@angular/core';
import { Activity,
  BothSidedListGuard,
  ItemList,
  MinLengthRequiredListGuard,
  SingleSidedListGuard,
  NonReceptiveListGuard,
  TwoLanguagesListGuard,
  LearnTxtAndHintTxtFilledListGuard,
  ConjugationListGuard,
  NonConjugationListGuard} from '@app/model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  constructor() { }

  private readonly timerStartSource = new Subject<void>();
  private readonly timerPauseSource = new Subject<void>();
  private readonly timerStopSource = new Subject<void>();
  private readonly configureActivity = new Subject<void>();
  private readonly activities: Activity[] = [
    {
      label: 'Activité cartes',
      route: '/activite/carte',
      icon: 'edicons-game-card',
      shortLabel: 'Mémoriser',
      type: 'carte',
      guards: [new BothSidedListGuard()],
      caption: 'Un indice est proposé, à vous de trouver la réponse.',
      configurable: true
    },
    {
      label: 'Activité d\'association',
      route: '/activite/association',
      icon: 'edicons-game-link',
      shortLabel: 'Associer',
      type: 'association',
      guards: [new BothSidedListGuard()],
      caption: 'Trouvez les paires correspondantes en sélectionnant les cartes.',
      configurable: true
    },
    {
      label: 'Activité d\'écriture',
      route: '/activite/ecriture',
      icon: 'edicons-game-write',
      shortLabel: 'Écrire',
      type: 'ecriture',
      guards: [new SingleSidedListGuard(), new NonReceptiveListGuard()],
      caption: 'Écrivez le mot proposé dans la zone de saisie et vérifiez son orthographe.',
      configurable: true
    },
    {
      label: 'Activité de traduction',
      route: '/activite/traduction',
      icon: 'edicons-game-translate',
      shortLabel: 'Traduire',
      type: 'traduction',
      guards: [new NonReceptiveListGuard(), new TwoLanguagesListGuard(), new LearnTxtAndHintTxtFilledListGuard()],
      caption: 'Traduisez le mot proposé dans la zone de saisie et vérifiez son orthographe.',
      configurable: true
    },
    {
      label: 'Activité de tri',
      route: '/activite/tri',
      icon: 'edicons-game-sort',
      shortLabel: 'Trier',
      type: 'tri',
      guards: [new SingleSidedListGuard(), new NonConjugationListGuard()],
      caption: 'À l\'aide de la souris déplacez les éléments de la liste afin de les trier par ordre alphabétique.',
      configurable: true
    },
    {
      label: 'Activité repérer',
      route: '/activite/grille',
      icon: 'edicons-game-grid',
      shortLabel: 'Repérer',
      type: 'grille',
      guards: [new SingleSidedListGuard(), new MinLengthRequiredListGuard(3), new NonConjugationListGuard()],
      caption: 'Trouvez les mots de la liste en sélectionnant la première et la dernière lettre dans la grille.',
      configurable: true
    }
  ];

  // Observable string streams
  timerStart$ = this.timerStartSource.asObservable();
  timerPause$ = this.timerPauseSource.asObservable();
  timerStop$ = this.timerStopSource.asObservable();
  configureActivity$ = this.configureActivity.asObservable();

  // Service message commands
  startTimer() {
    this.timerStartSource.next();
  }

  stopTimer() {
    this.timerStopSource.next();
  }

  pauseTimer() {
    this.timerPauseSource.next();
  }

  configure() {
    this.configureActivity.next();
  }

  shuffle(a: Array<any>) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }

  optimizedArrayChunkSize(l: number, chunk: number, idx: number): number {
    const nbCase =  Math.floor(l / chunk) + (l % chunk > 0 ? 1 : 0);
    const e = l % nbCase;
    const f = Math.floor(l / nbCase);
    if (idx * f > l) {
      return 0;
    }
    return f + (idx <= e ? 1 : 0);
  }

  public getAllActivities(): Activity[] {
    return this.activities;
  }

  public getAvailableActivities(list: ItemList): Activity[] {
    return this.activities.filter(a => {
      let items = list.items;
      a.guards.forEach((g, i, gds) => {
        items = g.test(items, list.hintLanguage !== list.learnLanguage);
        return items;
      });
      return items.length > 1;
    });
  }

  public setConfig<ActivityConfig>(activityCode: string, config: ActivityConfig) {
    this.activities.filter(a => a.type === activityCode)[0].config = config;
  }

  public getConfig<T>(activityCode: string, defaultConfig: T): T {
    return Object.assign({}, defaultConfig, this.activities.filter(a => a.type === activityCode)[0].config);
  }

  public overwriteConfig<T>(configuration: T, overwrite:any) :T {
    return Object.assign({}, configuration, overwrite);
  }

}
