import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { StudentService } from '@app/core/service';
import { Student } from '@app/model';
import { NgbHighlight, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, debounceTime, distinctUntilChanged, tap, switchMap, catchError, of } from 'rxjs';

@Component({
  selector: 'nemo-student-search-input',
  standalone: true,
  imports: [CommonModule, NgbTypeahead,FormsModule,NgbHighlight],
  templateUrl: './student-search-input.component.html',
  styleUrl: './student-search-input.component.scss'
})
export class StudentSearchInputComponent {
  searching = false;
  searchFailed = false;
  model: any;
  @Output() addStudent = new EventEmitter<Student>(true);
  constructor(private readonly studentService: StudentService) {

  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.studentService.search(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }))
      ),
      tap(() => this.searching = false)
    )
    onSelect($event, input) {
      $event.preventDefault();
      this.addStudent.emit($event.item);
      input.value = '';
    }
}
