import { Criteria } from './criteria';
import { MonitoredStudent } from './monitored-student';

export class Observation {
  id?: number;
  criteriaId?: number;
  monitoredStudentId?: number;
  state?: string;
  comment?: string;
  selfState?: string;
  selfComment?: string;
  markUrl?: string;
  checked: boolean;
  dirty = false;
  autoEvaluationEnabled?: boolean;

  constructor(model?: Observation) {
    if (model) {
      this.id = model.id;
      this.criteriaId = model.criteriaId;
      this.monitoredStudentId = model.monitoredStudentId;
      this.state = model.state;
      this.comment = model.comment;
      this.selfState = model.selfState;
      this.selfComment = model.selfComment;
      this.markUrl = model.markUrl;
      this.checked = model.checked;
      this.autoEvaluationEnabled = model.autoEvaluationEnabled;
    }
  }
}
export class LabelledObservation extends Observation {
  label: string;
  constructor(model?: LabelledObservation) {
    super(model);
    if (model) {
      this.label = model.label;
    }
  }
}
export class FilterableLabelledObservation extends LabelledObservation {
  filtered: boolean;
}
export class Observations {
  observations: Observation[];
}
export class CriteriaObservations extends Observations {
  criteria: Criteria;
}
export class StudentObservations extends Observations {
  student: MonitoredStudent;
}
