import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { ToastService, PreferencesService } from '@app/core/service';
import { Preferences, UserName } from '@app/model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CanComponentDeactivate } from '@app/core/guards/CanComponentDeactivate';
import { DialogService } from '@app/core/service/dialog.service';
import { Observable } from 'rxjs';
import { MediaUploadModalComponent } from '@app/shared/components/media-upload-modal/media-upload-modal.component';
import { UserVoiceComponent } from '../user-voice/user-voice.component';
import { MediaPreviewComponent } from '../../../shared/components/media-preview/media-preview.component';
import { NgIf, NgClass } from '@angular/common';
import { BasepageComponent } from '../../../shared/components/basepage/basepage.component';
import { SettingsService } from '@app/core/service/settings.service';
import { AuthenticationService } from '@app/core/service/authentication.service';

@Component({
    selector: 'nemo-preferences',
    templateUrl: './preferences.component.html',
    styleUrls: ['./preferences.component.scss'],
    standalone: true,
    imports: [
        BasepageComponent,
        NgIf,
        MediaPreviewComponent,
        RouterLink,
        FormsModule,
        ReactiveFormsModule,
        UserVoiceComponent,
        NgClass,
    ],
})
export class PreferencesComponent implements OnInit, CanComponentDeactivate {
  
  @HostListener('window:keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    if ((event.metaKey || event.ctrlKey) && event.key === 's') {
        this.submitForm();
        event.preventDefault();
    }
  }

  public userForm: FormGroup;
  public changeEmailForm: FormGroup;
  public preferences: Preferences;
  public displayName = '';
  
  public isTeacher = false;
  public isPublisher = false;
  public isStudent = false;
  public isSoutien = false;
  public isAdmin = false;
  public canUpdateProfileImage = false;
  public userName: UserName;
  public newEmail:string='';
  changeMobileEnabled: boolean;
  mode = 'light';

  constructor(
    public router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly preferencesService: PreferencesService,
    private readonly toastService: ToastService,
    private readonly modalService: NgbModal,
    private readonly dialogService: DialogService,
    private readonly settings: SettingsService,
    private readonly authService: AuthenticationService,
    private readonly renderer2: Renderer2
  ) {
    this.changeMobileEnabled = this.settings.feature_flags.changeMobile;
  }

  canDeactivate(): Observable<boolean> | boolean {
    // Allow synchronous navigation (`true`) if no crisis or the crisis is unchanged
    if (!this.userForm || !this.userForm.dirty) {
      return true;
    }
    // Otherwise ask the user with the dialog service and return its
    // observable which resolves to true or false when the user decides
    return this.dialogService.confirm('Vous n\'avez pas sauvegardé les modifications, souhaitez-vous continuer sans sauvegarder ?');
  }

  ngOnInit() {
    this.preferencesService.fetchPreferences().subscribe(p=>{
      this.preferences = p;
      this.mode = document.querySelector('html')?.getAttribute('data-bs-theme') ?? 'light';
      this.initForm();
      this.userName = this.authService.getUsername();
      this.isTeacher = this.preferencesService.isTeacher();
      this.isPublisher = this.preferencesService.isPublisher();
      this.isStudent = this.preferencesService.isStudent();
      this.isAdmin = this.preferencesService.isAdmin();
      this.isSoutien = this.preferencesService.isSoutien();
      this.isAdmin = this.preferencesService.isAdmin();
      this.canUpdateProfileImage = this.isSoutien || this.isTeacher || this.isPublisher;
    });
    
  }

  initForm() {
    this.userForm = this.formBuilder.group({
      speed: [this.preferences.synthesisSpeed, [Validators.required]],
      size: [this.preferences.size, [Validators.required]],
      toastEnabled: [this.preferences.toastEnabled],
      warnHealthCheck: [this.preferences.warnHealthCheck],
      darkmode: [this.preferences.darkMode],
      receiveEmailFeedback: [this.preferences.receiveEmailFeedback]
    });
    this.changeEmailForm = this.formBuilder.group({
      email: [this.newEmail, [Validators.required,Validators.email]]
    },
    { updateOn: 'change' });
  }

  changeProfileImage() {
    const modalRef = this.modalService.open(MediaUploadModalComponent, {backdrop: 'static' , size: 'lg'});
    modalRef.componentInstance.mediaUrl =  this.preferences.profileImageUrl;
    modalRef.componentInstance.downloadEnabled = false;
    modalRef.componentInstance.onlyImages = true;
    modalRef.result.then(
      result => {
        this.preferences.profileImageUrl = result.url;
        this.userForm.markAsDirty();
      }
    );
  }
  submitForm(): void {
    this.preferences.synthesisSpeed = this.userForm.get('speed').value;
    this.preferences.size = this.userForm.get('size').value;
    this.preferences.toastEnabled = this.userForm.get('toastEnabled').value;
    this.preferences.warnHealthCheck = this.userForm.get('warnHealthCheck').value;
    this.preferences.receiveEmailFeedback = this.userForm.get('receiveEmailFeedback').value;
    this.preferences.darkMode = this.userForm.get('darkmode').value;
    this.preferencesService.savePreferences(this.preferences).subscribe(preferences => {
      this.preferences = preferences;
      this.toastService.success('Préférences sauvegardées');
      this.userForm.markAsPristine();
    });
  }
  logout() {
    
  }
  changeEmail(content): void {
    this.newEmail = "";
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
			(result) => {
        this.newEmail= this.changeEmailForm.get('email').value
				this.preferencesService.updateEmail(this.newEmail).subscribe(p=>{
          if(p){
            this.toastService.success(`Un courriel vous a été envoyé à l\'adresse ${this.newEmail} pour validation`)
          } else{
            this.toastService.error(`L'adresse n'a pas pu être modifiée.`)
          }
          
        });
			},
      (reason) => {
        this.changeEmailForm.controls["email"].reset();
      }
		);
  }
  checkEmail():boolean {
    return false;
  }
  toggleDarkMode(): void {
    this.mode = this.mode === 'light' ? 'dark':'light';
    this.renderer2.setAttribute(document.querySelector('html'), 'data-bs-theme', this.mode);

  }
  changeMobile(content): void {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
			(result) => {
				
			},
			(reason) => {
				
			},
		);
  }

  matchEmail():boolean {
    return false;
  }
  
}
