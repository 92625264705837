import { Injectable } from '@angular/core';
import { Preferences } from '@app/model';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpBaseService } from './http-base.service';
import { AuthenticationService } from './authentication.service';
import { SettingsService } from './settings.service';

declare const Buffer;

export class DecodedAccessToken {
  public DisplayName: string;
  public group: any;
  public sub: string;
  public upn: string;
  public objectGUID: string;
}

@Injectable({
  providedIn: 'root',
})
export class PreferencesService {
 
  
  private readonly url = '/user';
  private preferences: Preferences = {
    email: null,
    mobile: null,
    id: 0,
    toastEnabled: true,
    profileImageUrl: null,
    receiveEmailFeedback: false,
    size: 'middle',
    synthesisSpeed: 80,
    warnHealthCheck: true,
    darkMode: false
  };

  constructor(
    private readonly http: HttpBaseService,
    private readonly authService: AuthenticationService,
    private readonly settings: SettingsService
  ) {
  }

  initPrefs(): Observable<Preferences> {
    if(this.authService.getCurrentUserProfile().isAuthenticated){
      return this.http.get<Preferences>(`${this.url}/preferences`).pipe(map((pr => {
        this.preferences = pr;
        return pr;
      })));
    }
    return of<Preferences>(this.preferences);
  
  }
  fetchPreferences(): Observable<Preferences> {
    if(this.authService.getCurrentUserProfile().isAuthenticated){
      return this.http.get<Preferences>(`${this.url}/preferences`);
    }
    return of<Preferences>(this.preferences);
  }

  public getPreferences(): Preferences {
    return this.preferences;
  }

  public savePreferences(preferences: Preferences): Observable<Preferences> {
    this.preferences = preferences;
    return this.http.put<Preferences>(`${this.url}/preferences`, preferences);
  }

  public rate(comment: string, currentRate: number): Observable<boolean> {
    return this.http.put<boolean>(`${this.url}/note`, {comment: comment, rating: currentRate});
  }

  public updateEmail(email:string):Observable<boolean>  {
    return this.http.put<boolean>(`${this.url}/update-email`, {value: email}); 
  }

  public isPublisher(): boolean {
    if (this.authService.getCurrentUserProfile().isAuthenticated) {
      return this.testGroup('publisher');
    }
    return false;
  }
  public isTeacher(): boolean {
    if (this.authService.getCurrentUserProfile().isAuthenticated) {
      return this.testGroup('enseignants') || this.testGroup('soutien');
    }
    return false;
  }
  public isSoutien(): boolean {
    if (this.authService.getCurrentUserProfile().isAuthenticated) {
      return this.testGroup('soutien');
    }
    return false;
  }
  public isStudent(): boolean {
    if (this.authService.getCurrentUserProfile().isAuthenticated) {
      return this.testGroup('eleves');
    }
    return false;
  }
  public isAdmin(): boolean {
    if (this.authService.getCurrentUserProfile().isAuthenticated) {
      return this.testGroup('admin');
    }
    return false;
  }

  private testGroup(testGroup: any): boolean {
    return this.authService.getCurrentUserProfile().claims.filter(g => g.type === 'app-role' && g.value.toLowerCase() == testGroup).length > 0;
  }


  validateEmail(token: string):Observable<boolean> {
    return this.http.get<boolean>(`${this.url}/validate-email/${token}`); 
  }
  validateMobile(token: string):Observable<boolean> {
    return this.http.get<boolean>(`${this.url}/validate-mobile/${token}`); 
  }
}

