<nemo-basepage *ngIf="distributionsGroupes" [title]="'Distribution'">
  <div page-body *ngIf="userForm">
    <form [formGroup]="userForm" (ngSubmit)="save()">
      <div class="row mb-2">
        <div class="col-md-6">
          <div class="form-check form-switch">
            <label class="form-check-label" for="preserveFromDeletion">Garder la distribution en fin d'année scolaire ?</label>
            <input class="form-check-input" type="checkbox" role="switch" id="preserveFromDeletion" formControlName="preserveFromDeletion">
          </div>
        </div>
        <div class="col-md-6">
          <p class="lead">Progression générale</p>
          <nemo-stacked-progress-bar *ngIf="this.distributionsGroupes.length > 0" [progression]="progression" [height]="18"
            [showLabels]="true"></nemo-stacked-progress-bar>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-12">
          <button type="button" form="userForm" class="btn btn-outline-secondary" tooltip="Retour" tooltipPlacement="top"
            [disabled]="userForm.valid && userForm.dirty"
            (click)="back()"><span class="bi bi-arrow-left"></span>
            Retour</button>
          <button type="button" form="userForm" class="btn btn-outline-secondary" tooltip="Supprimer"
            tooltipPlacement="top" [disabled]="userForm.valid && userForm.dirty" (click)="delete()"><span
              class="bi bi-trash-fill"></span> Supprimer</button>
          <button type="submit" form="userForm" class="btn btn-primary" tooltip="Sauvegarder" tooltipPlacement="top"
            [disabled]="!userForm.dirty" (click)="save()">
            <span class="bi bi-save-fill"></span> Sauvegarder</button>
        </div>
      </div>
    </form>
    <div class="sticked-top">
      <div class="card mb-2">
        <div class="card-body">
          <div class="d-flex flex-column flex-md-row align-self-center">
            <div class="mt-2 mt-md-0 me-0 me-md-2 flex-grow-1">
              <nemo-student-search (addStudents)="addStudents($event)" (addStudent)="addSingleStudent($event)" (dropStudents)="dropDistributions()"></nemo-student-search>
            </div>
            <div class="mt-2 mt-md-0">
              <button type="button" class="btn btn-outline-secondary" (click)="editRange()"><span class="bi bi-calendar-date-fill"></span> Période pour tous</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngFor="let group of distributionsGroupes">
      <div class="card text-bg-body-tertiary mb-2">
        <div class="card-body">
          <div class="d-flex d-flex-column mb-1">
            <div class="flex-grow-1">
              <h5 class="card-title align-self-center">{{group.label}}</h5>
            </div>
            <div class=" align-self-center" *ngIf="group.distributions.length >1">
              <button type="button" class="btn btn-outline-secondary" (click)="editRangeForGroup(group)"><span class="bi bi-calendar-date-fill"></span> Période du groupe</button>
              <button type="button" class="btn btn-outline-secondary" tooltip="Supprimer" tooltipPlacement="top" (click)="dropGroup(group)"><span class="bi bi-trash-fill"></span></button>
            </div>
          </div>
        
          <nemo-user-display *ngFor="let distribution of group.distributions" [user]="distribution" (dropUser)="dropDistribution(distribution)">
            <div class="d-flex">
              <div class="align-self-center">
                {{distribution.displayName}}
              </div>
              <div class="ps-1 align-self-center flex-grow-1">
                <nemo-stacked-progress-bar [progression]="{success: (distribution.progression === 100 ? distribution.progression: 0),info: (distribution.progression<100 ?distribution.progression:0) }"></nemo-stacked-progress-bar>
              </div>
              <div class="ps-1">
                <nemo-date-range [small]="true" [range]="{ start: distribution.start, end: distribution.end}" [edit]="true" (changed)="updateDates($event,distribution)"></nemo-date-range>
              </div>
            </div>
          </nemo-user-display>
        </div>
      </div>
    </div>
    <!-- <div *ngFor="let distributionGroup of (distributions | orderBy: 'classeGroupeKey' | groupBy: 'classeGroupeKey' | pairs); trackBy: trackItem">  
        <h5>{{distributionGroup[0]=='null'||distributionGroup[0]==null ?'Individuel': distributionGroup[1][0].classeName + ' ' +distributionGroup[1][0].schoolName}}</h5>
      <div *ngFor="let distribution of distributionGroup[1]">
        <nemo-user-display (dropUser)="dropDistribution(distribution)">
          <div class="d-flex">
            <div class="align-self-center w-20">
              {{distribution.displayName}}
            </div>
            <div class="ps-1 align-self-center flex-fill w-40">
              <nemo-stacked-progress-bar [progression]="{success: (distribution.progression === 100 ? distribution.progression: 0),info: (distribution.progression<100 ?distribution.progression:0) }"></nemo-stacked-progress-bar>
            </div>
            <div class="ps-1 w-40">
              <nemo-date-range [range]="{ start: distribution.start, end: distribution.end}" [edit]="true" (changed)="updateDates($event,distribution)"></nemo-date-range>
            </div>
          </div>
        </nemo-user-display>
      </div>
    </div> -->
    
  </div>
</nemo-basepage>
