import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';
import { Observable } from 'rxjs';
import {  Teacher } from '@app/model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TeacherService {
  private readonly url = '/enseignant';

  constructor(private readonly http: HttpBaseService) {}

  public search(term: string): Observable<Teacher[]> {
    return this.http
      .get<Teacher[]>(`${this.url}/${term}`);
  }
}
