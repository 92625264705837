import { Route } from '@angular/router';
import { PageNotFoundComponent } from './accueil/components/page-not-found/page-not-found.component';
import { LoggedGuard } from './core/guards/logged.guard';
import { NotificationsListeComponent } from './notifications/components/notifications-liste/notifications-liste.component';
import { FiltreComponent } from './filtre/components/filtre/filtre.component';
import { AutoEvaluationDetailComponent } from './auto-evaluation/components/auto-evaluation-detail/auto-evaluation-detail.component';
import { BookletCriteriaComponent } from './booklet/components/booklet-criteria/booklet-criteria.component';
import { BookletDetailComponent } from './booklet/components/booklet-detail/booklet-detail.component';
import { BookletEditComponent } from './booklet/components/booklet-edit/booklet-edit.component';
import { BookletSearchComponent } from './booklet/components/booklet-search/booklet-search.component';
import { BookletStudentComponent } from './booklet/components/booklet-student/booklet-student.component';
import { BookletsComponent } from './booklet/components/booklets/booklets.component';
import { CanDeactivateGuard } from './core/guards/can-deactivate.guard';
import { BookletRechercheResolverService, ListeRechercheResolverService, ParcoursRechercheResolverService } from './core/service';
import { BaseactiviteComponent } from './activite/components/baseactivite/baseactivite.component';
import { ListeDetailComponent } from './listes/components/liste-detail/liste-detail.component';
import { ListeImpressionComponent } from './listes/components/liste-impression/liste-impression.component';
import { ListeModificationComponent } from './listes/components/liste-modification/liste-modification.component';
import { ListesRechercheComponent } from './listes/components/listes-recherche/listes-recherche.component';
import { ListesComponent } from './listes/components/listes/listes.component';
import { OutilConjugaisonComponent } from './listes/components/tools/outil-conjugaison/outil-conjugaison.component';
import { OutilTexteComponent } from './listes/components/tools/outil-texte/outil-texte.component';
import { OutilsComponent } from './listes/components/tools/outils/outils.component';
import { StudentGuard } from './core/guards/student.guard';
import { TeacherGuard } from './core/guards/teacher.guard';
import { DistributionComponent } from './parcours/components/distribution/distribution.component';
import { DistributionsComponent } from './parcours/components/distributions/distributions.component';
import { ParcoursLibreComponent } from './parcours/components/libre/parcours-libre/parcours-libre.component';
import { PanierComponent } from './parcours/components/panier/panier.component';
import { ParcoursDetailComponent } from './parcours/components/parcours-detail/parcours-detail.component';
import { ParcoursEnseignantComponent } from './parcours/components/parcours-enseignant/parcours-enseignant.component';
import { ParcoursModificationComponent } from './parcours/components/parcours-modification/parcours-modification.component';
import { ParcoursRechercheComponent } from './parcours/components/parcours-recherche/parcours-recherche.component';
import { ParcoursRessourcesRechercheComponent } from './shared/components/parcours-ressources-recherche/parcours-ressources-recherche.component';
import { ParcoursComponent } from './parcours/components/parcours/parcours.component';
import { PreferencesComponent } from './preferences/components/preferences/preferences.component';
import { AproposComponent } from './apropos/components/apropos/apropos.component';
import { ConditionsComponent } from './apropos/components/conditions/conditions.component';
import { AccueilComponent } from './accueil/components/accueil/accueil.component';
import { ValidationEmailComponent } from './accueil/components/validation-email/validation-email.component';
import { AdministrationComponent } from './administration/components/administration/administration.component';
import { AdminGuard } from './core/guards/admin.guard';
import { PreferencesGroupesComponent } from './preferences/components/preferences-groupes/preferences-groupes.component';
import { UnauthorizedComponent } from './accueil/components/unauthorized/unauthorized.component';

export const appRoutes: Route[] = [
  {
    path: '',
    redirectTo: 'accueil',
    pathMatch: 'full'
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  {
    path: 'accueil',
    children: [
      {
        path: '',
        component: AccueilComponent,
      },
      {
        path: 'validation/email/:token',
        component: ValidationEmailComponent,
        canDeactivate: [CanDeactivateGuard]
      }
    ]
  },
  {
    path: 'apropos',
    children: [
      {
        path: '',
        component: AproposComponent,
      },
      {
        path: 'conditions',
        component: ConditionsComponent
      }
    ]
  },
  {
    path: 'preferences',
    children: [
      {
        path: '',
        component: PreferencesComponent,
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'groupes',
        component: PreferencesGroupesComponent,
        canDeactivate: [CanDeactivateGuard]
      }
    ],
    canActivate: [LoggedGuard]
  },
  {
    path: 'parcours',
    children: [
      {
        path: 'libre',
        component: ParcoursLibreComponent,
        resolve: {
          courses: ParcoursRechercheResolverService
        }
      },
      {
        path: 'libre/:id',
        component: ParcoursDetailComponent
      },
      {
        path: '',
        component: ParcoursComponent,
        canActivate: [LoggedGuard],
        children: [
          {
            path: '',
            component: ParcoursRechercheComponent,
            resolve: {
              courses: ParcoursRechercheResolverService
            }
          },
          {
            path: 'personnels',
            canActivate: [LoggedGuard],
            component: ParcoursRechercheComponent,
            resolve: {
              courses: ParcoursRechercheResolverService
            }
          },
          {
            path: 'rpn',
            canActivate: [LoggedGuard],
            component: ParcoursRechercheComponent,
            resolve: {
              courses: ParcoursRechercheResolverService
            }
          },
          {
            path: 'enseignants',
            canActivate: [StudentGuard],
            component: ParcoursEnseignantComponent,
            resolve: {
              courses: ParcoursRechercheResolverService
            }
          },
          {
            path: 'pour-enseignant',
            component: ParcoursRechercheComponent,
            canActivate: [TeacherGuard],
            resolve: {
              courses: ParcoursRechercheResolverService
            }
          },
          {
            path: 'panier',
            canActivate: [TeacherGuard],
            component: PanierComponent
          },
          {
            path: 'distributions',
            canActivate: [TeacherGuard],
            component: DistributionsComponent
          },
          {
            path: 'ressources',
            canActivate: [TeacherGuard],
            component: ParcoursRessourcesRechercheComponent,
          }
        ]
      },
      {
        path: 'detail/:id',
        component: ParcoursDetailComponent
      },
      {
        path: 'personnels/:id',
        component: ParcoursDetailComponent,
        canActivate: [LoggedGuard]
      },
      {
        path: 'personnels/:id/edition',
        component: ParcoursModificationComponent,
        canActivate: [LoggedGuard]
      },
      {
        path: 'personnels/:id/distribution',
        component: DistributionComponent,
        canActivate: [LoggedGuard]
      },
      {
        path: 'rpn/:id',
        component: ParcoursDetailComponent,
        canActivate: [LoggedGuard]
      },
      {
        path: 'rpn/:id/distribution',
        component: DistributionComponent,
        canActivate: [LoggedGuard]
      },
      {
        path: 'rpn/:id/edition',
        component: ParcoursModificationComponent,
        canActivate: [LoggedGuard]
      },
      {
        path: 'enseignants/:id',
        component: ParcoursDetailComponent,
        canActivate: [LoggedGuard]
      },
      {
        path: 'enseignants/:id/edition',
        component: ParcoursModificationComponent,
        canActivate: [LoggedGuard]
      },
      {
        path: 'enseignants/:id/distribution',
        component: DistributionComponent,
        canActivate: [LoggedGuard]
      },
      {
        path: 'pour-enseignant/:id',
        component: ParcoursDetailComponent,
        canActivate: [LoggedGuard]
      },
      {
        path: 'pour-enseignant/:id/edition',
        component: ParcoursModificationComponent,
        canActivate: [LoggedGuard]
      },
      {
        path: 'pour-enseignant/:id/distribution',
        component: DistributionComponent,
        canActivate: [LoggedGuard]
      },
      {
        path: 'ressources/:id',
        component: ParcoursDetailComponent,
        canActivate: [LoggedGuard]
      },
      {
        path: 'Detail/:id',
        redirectTo: 'detail/:id'
      }
    ]
  },
  {
    path: 'Parcours',
    redirectTo: 'parcours'
  },
  {
    path: 'Externe/Detail/:id',
    redirectTo: 'parcours/libre/:id',
    pathMatch: 'full'
  },
  {
    path: 'listes',
    children: [
      {
        path: '',
        component: ListesComponent,
        canActivate: [LoggedGuard],
        children: [
          {
            path: '',
            component: ListesRechercheComponent,
            resolve: {
              lists: ListeRechercheResolverService
            }
          },
          {
            path: 'personnelles',
            component: ListesRechercheComponent,
            resolve: {
              lists: ListeRechercheResolverService
            }
          },
          {
            path: 'rpn',
            component: ListesRechercheComponent,
            resolve: {
              lists: ListeRechercheResolverService
            }
          },
          {
            path: 'lexique',
            component: ListesRechercheComponent,
            resolve: {
              lists: ListeRechercheResolverService
            }
          },
          {
            path: 'outils',
            component: OutilsComponent
          }
        ]
      },
      {
        path: 'detail/:id',
        component: ListeDetailComponent,
        canActivate: [LoggedGuard]
      },
      {
        path: 'detail/:id/edition',
        component: ListeModificationComponent,
        canActivate: [LoggedGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'personnelles/:id',
        component: ListeDetailComponent,
        canActivate: [LoggedGuard]
      },
      {
        path: 'personnelles/:id/edition',
        component: ListeModificationComponent,
        canActivate: [LoggedGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'rpn/:id',
        component: ListeDetailComponent,
        canActivate: [LoggedGuard]
      },
      {
        path: 'rpn/:id/edition',
        component: ListeModificationComponent,
        canActivate: [LoggedGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'lexique/:id',
        component: ListeDetailComponent,
        canActivate: [LoggedGuard]
      },
      {
        path: 'lexique/:id/edition',
        component: ListeModificationComponent,
        canActivate: [LoggedGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'conjugaison',
        canActivate: [LoggedGuard],
        component: OutilConjugaisonComponent
      },
      {
        path: 'texte',
        canActivate: [LoggedGuard],
        component: OutilTexteComponent
      },
      {
        path: 'impression/:id',
        canActivate: [LoggedGuard],
        component: ListeImpressionComponent
      }
    
    ],
    canActivate: [LoggedGuard]
  },
  {
    path: 'activite',
    children: [
      {
        path: ':type/:id',
        component: BaseactiviteComponent,
      },
    ],
    canActivate: [LoggedGuard]
  },
  {
    path: 'lexique',
    redirectTo: 'listes/lexique'
  },
  {
    path: 'lexique/detail/:id',
    redirectTo: 'listes/lexique/:id'
  },
  {
    path: 'folios',
    children: [
      {
        path: '',
        component: BookletsComponent,
        canActivate: [TeacherGuard],
        children: [
          {
            path: '',
            component: BookletSearchComponent,
            canActivate: [TeacherGuard],
            resolve: {
              booklets: BookletRechercheResolverService
            }
          },
          {
            path: 'personnels',
            component: BookletSearchComponent,
            canActivate: [TeacherGuard],
            resolve: {
              booklets: BookletRechercheResolverService
            }
          },
          {
            path: 'rpn',
            component: BookletSearchComponent,
            canActivate: [TeacherGuard],
            resolve: {
              booklets: BookletRechercheResolverService
            }
          },
          {
            path: 'plan-etude-romand',
            component: BookletSearchComponent,
            canActivate: [TeacherGuard],
            resolve: {
              booklets: BookletRechercheResolverService
            }
          }
        ]
      },
      {
        path: 'detail/:id',
        component: BookletDetailComponent,
        canActivate: [TeacherGuard]
      },
      {
        path: 'personnels/:id',
        component: BookletDetailComponent,
        canActivate: [TeacherGuard]
      },
      {
        path: 'personnels/:id/edition',
        component: BookletEditComponent,
        canActivate: [TeacherGuard]
      },
      {
        path: 'rpn/:id',
        component: BookletDetailComponent,
        canActivate: [TeacherGuard]
      },
      {
        path: 'rpn/:id/edition',
        component: BookletEditComponent,
        canActivate: [TeacherGuard]
      },
      {
        path: 'plan-etude-romand/:id',
        component: BookletDetailComponent,
        canActivate: [TeacherGuard]
      },
      {
        path: 'plan-etude-romand/:id/edition',
        component: BookletEditComponent,
        canActivate: [TeacherGuard]
      },
      {
        path: 'personnels/:bookletid/eleve/:studentid',
        component: BookletStudentComponent,
        canActivate: [TeacherGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'personnels/:bookletid/critere/:criteriaid',
        component: BookletCriteriaComponent,
        canActivate: [TeacherGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'rpn/:bookletid/eleve/:studentid',
        component: BookletStudentComponent,
        canActivate: [TeacherGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'rpn/:bookletid/critere/:criteriaid',
        component: BookletCriteriaComponent,
        canActivate: [TeacherGuard],
        canDeactivate: [CanDeactivateGuard]
      }
    ],
    canActivate: [TeacherGuard]
  },
  {
    path: 'auto-evaluation',
    children: [
      {
        path: '',
        component: AutoEvaluationDetailComponent,
        canActivate: [StudentGuard]
      }
    ]
  },
  {
    path: 'filtre',
    children: [
      {
        path: ':type/:scope/:title/:cats',
        component: FiltreComponent,
      }
    ],
    canActivate:[LoggedGuard]
  },
  {
    path: 'notifications',
    children: [{
      path: '',
      component: NotificationsListeComponent,
      canActivate: [LoggedGuard]
    }]
    
  },
  {
    path: 'administration',
    component: AdministrationComponent,
    canActivate: [AdminGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  { path: '**', component: PageNotFoundComponent }
];
