import { Injectable } from '@angular/core';
import { PreferencesService } from './preferences.service';
import { Howl, Howler } from 'howler';
import { WindowRefService } from './window-ref.service';
import { ToastService } from './toast.service';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class SpeechSynthesisService {

  private howl: Howl;
  private readonly id: number;
  private readonly speechSynthesis: SpeechSynthesis;

  constructor(
    private readonly windowRefService: WindowRefService,
    private readonly preferencesService: PreferencesService,
    private readonly settings: SettingsService,
    private readonly toastService: ToastService
  ) {
    if (!this.settings.feature_flags.google_tts_enabled) {
      this.speechSynthesis = this.windowRefService.nativeWindow().speechSynthesis;
    }
  }

  speak(text: string, languageCode: string): void {
    if(languageCode === 'nc'){
      return;
    }
    text = this.cleanSpeakText(text);
    if (!this.settings.feature_flags.google_tts_enabled) {
      if (this.speechSynthesis) {
        if (this.speechSynthesis.speaking || this.speechSynthesis.pending) {
          this.speechSynthesis.cancel();
        }
        const utterance = new SpeechSynthesisUtterance(text);
        const voice = this.speechSynthesis.getVoices().find(v => v.lang.startsWith(languageCode));

        if (voice) {
          utterance.voice = voice;
          const prefs = this.preferencesService.getPreferences();
          if (languageCode !== 'fr' && prefs.synthesisSpeed >= 80) {
            utterance.rate = 0.8;
          }
          utterance.rate = prefs.synthesisSpeed / 100;
          this.speechSynthesis.speak(utterance);
        } else {
          this.toastService.warn(`Cette langue ${languageCode} n\'est pas activée sur votre système.`);
        }
      }
    } else {
      const prefs = this.preferencesService.getPreferences();
      Howler.unload();
      this.howl = new Howl({
        xhr: {
          method: 'GET',
          withCredentials: true,
        },
        rate: .5 + .5 / 100 * prefs.synthesisSpeed,
        autoplay: true,
        src: `${this.api_url}/${languageCode}/${text}`,
        loop: false,
        format: ['mp3'],
        onplayerror: function () {
          this.howl.once('unlock', function () {
            this.howl.play();
          });
        }
      });
      this.howl.play(0);
    }
  }

  cleanSpeakText(text: string): string {
    return text;//.replace(/[\/]/g, ' - ').replace(/ *\([^)]*\) */g, "");
  }

  pause() {
    if (!this.settings.feature_flags.google_tts_enabled) {
      this.speechSynthesis.pause();
    } else {
      this.howl.pause(this.id);
    }

  }

  resume() {
    if (!this.settings.feature_flags.google_tts_enabled) {
      this.speechSynthesis.resume();
    } else {
      this.howl.play(this.id);
    }
  }

  get api_url(): string { return `api/v1/speech/synthesize`; }

}
