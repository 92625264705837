import { Activity } from './activity';
import { ItemListSummary } from '../itemlist/itemlistsummary';

export class ActivityList {
  activity: Activity;
  list: ItemListSummary;

  constructor(model?: ActivityList) {
    if (model) {
      this.activity = model.activity;
      this.list = model.list;
    }
  }
}
