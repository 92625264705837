<nemo-basepage *ngIf="list" [title]="activity.shortLabel + ' - ' +list.title" [icon]="activity.icon">
  <div page-body class="activity">
    <p class="lead my-2">{{activity.caption}}</p>
    <div class="d-flex align-self-center">
      <div class="me-1" *ngIf="activity.configurable">
        <button class="btn btn-outline-secondary" [disabled]="ended" (click)="configure()"><span class="bi bi-sliders"></span> Régler</button>
      </div>
      <div class="me-1">
        <nemo-action-menu [placement]="'bottom-left'">
          <button type="button" class="dropdown-item" tooltip="Créer un nouveau parcours" tooltipPlacement="top"
            (click)="createCourse()">Créer un nouveau parcours avec cette activité</button>
          <button type="button" class="dropdown-item" tooltip="Copier le lien" tooltipPlacement="top" ngxClipboard
            [cbContent]="getDocumentLocation()" (cbOnSuccess)="copied($event)">Copier le lien</button>
          <button type="button" class="dropdown-item" tooltip="Envoyer le lien par courriel" tooltipPlacement="top"
            (click)="sendbymail()">Envoyer le lien par courriel</button>
        </nemo-action-menu>
      </div>
      <div class="flex-fill text-center me-1">
        <nemo-timer></nemo-timer>
        <nemo-stacked-progress-bar [progression]="progression"></nemo-stacked-progress-bar>
      </div>
      <div class="me-1">
        <button class="btn btn-outline-secondary" [disabled]="ended" (click)="abandon()"><span class="bi bi-stop-circle-fill"></span> Abandonner <kbd>Esc</kbd></button>
      </div>
    </div>
    <div class="mt-2" *ngIf="!ended">
      <nemo-carte *ngIf="type === 'carte'" [list]="list" (activityProgress)="progress($event)" (activityEnd)="endGame($event)"></nemo-carte>
      <nemo-association *ngIf="type === 'association'" [list]="list" (activityProgress)="progress($event)" (activityEnd)="endGame($event)"></nemo-association>
      <nemo-ecriture *ngIf="type === 'ecriture'" [list]="list" (activityProgress)="progress($event)" (activityEnd)="endGame($event)"></nemo-ecriture>
      <nemo-traduction *ngIf="type === 'traduction'" [list]="list" (activityProgress)="progress($event)" (activityEnd)="endGame($event)"></nemo-traduction>
      <nemo-tri *ngIf="type === 'tri'" [list]="list" (activityProgress)="progress($event)" (activityEnd)="endGame($event)"></nemo-tri>
      <nemo-grille *ngIf="type === 'grille'" [list]="list" (activityProgress)="progress($event)" (activityEnd)="endGame($event)"></nemo-grille>
    </div>
    <div class="row">
      <div *ngIf="ended" class="col-md-12">
        <div class="card text-center my-2">
          <div class="card-body">
            <p class="card-text big text-focus-in">
              <span [ngClass]="{'bi bi-stop-circle-fill text-warning': abandonned, 'bi bi-check-lg text-success': !abandonned}"></span> Activité {{abandonned ? 'abandonnée' : 'terminée' }}
            </p>
            <p class="card-text" *ngIf="!abandonned" class="lead">Taux de réussite {{progression.success}} %</p>  
            <div class="d-flex flex-column flex-md-row justify-content-center align-self-center">
              <div class="mt-2 mt-md-0 me-0 me-md-2">
                <div class="d-grid gap-2">
                  <button type="button" class="btn btn-primary" (click)="restart()">Recommencer</button>
                </div>
              </div>
              <div class="mt-2 mt-md-0">
                <div class="d-grid gap-2">
                  <button *ngIf="!courseUrl" type="button" class="btn btn-primary" (click)="back()">Choisir un autre exercice</button>
                  <button *ngIf="courseUrl" type="button" class="btn btn-primary" (click)="back()">Continuer le parcours</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div page-body *ngIf="!list">
    <div  class="text-center">
      <div class="spinner-grow text-secondary" role="status">
        <span class="visually-hidden">Recherche en cours...</span>
      </div>
    </div>
  </div>
</nemo-basepage>
