<div class="alert alert-secondary" role="alert" *ngIf="courses && courses.length === 0">
  <h4 class="alert-heading">Dommage, c'est encore vide par ici !</h4>
  <p>
    Aucun parcours n'est actuellement proposé par un de vos enseignant·e·s. N'hésitez pas à demander à votre
    enseignant·e de distribuer des parcours, ils seront alors disponibles ici.
  </p>
  <p>
    Peut être qu'un <a [routerLink]="['../rpn']">parcours RPN</a> pourrait vous intéresser ? Il vous sera possible
    de le copier afin de l'adapter à vos besoins si nécessaire par la suite.
  </p>
</div>
<div class="container">
  <div class="row" *ngIf="courses && courses.length > 0" >
    <div class="col-md-4 bg-body-tertiary rounded-start">
      <h4 class="display-4">À faire</h4>
      <ul class="list-unstyled">
        <li *ngFor="let course of coursesToStart" class="mb-2">
          <nemo-card [title]="course.title" [author]="course.author" [subtitle]="course.subtitle" [icon]="course.icon"
            [tags]="course.categories" [color]="course.color" [progression]="course.progression"
            [routerLink]="['./', course.id]" (clicked)="dummy()">
            <p class="card-text p-0">
              <small class="text-muted d-block">Ce parcours a été distribué par {{course.distributedBy}}</small><br>
              <small *ngIf="course.distributedTo !== null" class="badge rounded-pill category-tag">reste {{course.distributedTo|  amDifference: today :'days' : false}} j.</small>
            </p>
          </nemo-card>
        </li>
      </ul>
    </div>
    <div class="col-md-4 border-start border-end">
      <h3 class="display-4 border-bottom-2">En cours</h3>
      <ul class="list-unstyled">
        <li *ngFor="let course of runningCourses" class="mb-2">
          <nemo-card [title]="course.title" [author]="course.author" [subtitle]="course.subtitle" [icon]="course.icon"
            [tags]="course.categories" [color]="course.color" [progression]="course.progression"
            [routerLink]="['./',course.id]"
            (clicked)="dummy()">
            <p class="card-text p-0">
              <small class="text-muted d-block">Ce parcours a été distribué par {{course.distributedBy}}</small><br>
              <small *ngIf="course.distributedTo !== null" class="badge rounded-pill b category-tag">reste {{course.distributedTo|  amDifference: today :'days' : false}} j.</small>
            </p>
          </nemo-card>
        </li>
      </ul>
    </div>
    <div class="col-md-4 bg-body-tertiary rounded-end">
      <h3 class="display-4">Terminé</h3>
      <ul class="list-unstyled page-fade">
        <li *ngFor="let course of endedCourses" class="mb-2">
          <nemo-card [title]="course.title"  [author]="course.author" [subtitle]="course.subtitle" [icon]="course.icon"
            [tags]="course.categories" [color]="course.color" [progression]="course.progression"
            (clicked)="restartCourse(course)">
            <p class="card-text"><small class="text-muted d-block">Ce parcours a été distribué par {{course.distributedBy}}</small></p>
          </nemo-card>
        </li>
      </ul>
    </div>
  </div>
</div>
