<div class="modal-header">
  <h4 class="modal-title">Commentaire</h4>
  <button type="button" class="btn-close" aria-label="btn-close" (click)="activeModal.dismiss()">

  </button>
</div>
<div class="modal-body">
  <input id="criteriaLabel" ngbAutofocus type="text" class="form-control" [(ngModel)]="comment" placeholder="Commentaire" aria-label="Commentaire"
    autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" maxlength="150" />
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss()">Annuler</button>
  <button type="button" class="btn btn-primary" (click)="activeModal.close(comment)">Valider</button>
</div>

