import { Component, OnInit } from '@angular/core';
import { ToastService } from '@app/core/service';
import { DistributionService } from '@app/core/service/distribution.service';
import { CourseSummary, Distribution } from '@app/model';
import { SuppressModalComponent } from '@app/shared/components/suppress-modal/suppress-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionMenuComponent } from '../../../shared/components/action-menu/action-menu.component';
import { RouterLink } from '@angular/router';
import { DateRangeComponent } from '../../../shared/components/date-range/date-range.component';
import { CardComponent } from '../../../shared/components/card/card.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'nemo-distributions',
    templateUrl: './distributions.component.html',
    styleUrls: ['./distributions.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, CardComponent, DateRangeComponent, RouterLink, ActionMenuComponent]
})
export class DistributionsComponent implements OnInit {

  distributions:  CourseSummary[];
  all = false;

  constructor(private readonly distributionService: DistributionService,
  private readonly toastService: ToastService,
  private readonly modalService: NgbModal) { }

  ngOnInit(): void {
    this.distributionService.getDistributions(this.all).subscribe(distributions => {
      this.distributions = distributions;
    });
  }
  toggleAll(): void {
    this.all = !this.all;
    this.distributionService.getDistributions(this.all).subscribe(distributions => {
      this.distributions = distributions;
    });
  }
  delete(courseId: number) {
    const modalRef = this.modalService.open(SuppressModalComponent);
    modalRef.result.then((result) => {
      this.distributionService.deleteDistribution(courseId).subscribe((res) => {
        this.toastService.success('Distribution supprimée avec succès');
        this.distributions.splice(this.distributions.indexOf(this.distributions.filter(d => d.id === courseId)[0]), 1);
      });
    }, () => {
    });
  }
}
