import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, RouterLink } from '@angular/router';
import { ParcoursService } from '@app/core/service';
import { CourseSummary } from '@app/model';
import { SuppressModalComponent } from '@app/shared/components/suppress-modal/suppress-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MomentModule } from 'ngx-moment';
import { CardComponent } from '../../../shared/components/card/card.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'nemo-parcours-enseignant',
    templateUrl: './parcours-enseignant.component.html',
    styleUrls: ['./parcours-enseignant.component.scss'],
    standalone: true,
    imports: [NgIf, RouterLink, NgFor, CardComponent, MomentModule]
})
export class ParcoursEnseignantComponent implements OnInit {

  courses: CourseSummary[];
  runningCourses: CourseSummary[];
  endedCourses: CourseSummary[];
  coursesToStart: CourseSummary[];
  filter = { started: true };

  constructor(
    private readonly route: ActivatedRoute,
    private readonly modalService: NgbModal,
    private readonly service: ParcoursService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.route.data.subscribe((data: { courses: CourseSummary[] }) => {
      this.courses = data.courses;
      this.coursesToStart = this.courses.filter(c => c.progression === 0)
      .sort((c1, c2) => c1.distributedFrom < c2.distributedFrom ? 1 : -1);
      this.endedCourses = this.courses.filter(c => c.progression === 100)
      .sort((c1, c2) => c1.distributedFrom < c2.distributedFrom ? 1 : -1);
      this.runningCourses = this.courses.filter(c => c.progression > 0 && c.progression < 100)
      .sort((c1, c2) => c1.distributedFrom < c2.distributedFrom ? 1 : -1);
    });
  }
  dummy() {

  }
  restartCourse(courseSummary: CourseSummary): void {
    const modalRef = this.modalService.open(SuppressModalComponent);
    modalRef.componentInstance.title = 'Vous êtes sur le point de recommencer ce parcours';
    modalRef.componentInstance.message = 'Voulez-vous supprimer votre progression sur ce parcours et le recommencer ?' ;
    modalRef.result.then((result) => {
      this.service.clearProgress(courseSummary.id).subscribe(r => {
        if (r) {
          this.router.navigate(['./', courseSummary.id], {relativeTo: this.route} );
        }
      });

    }, () => {
    });
  }
  public clearFilter() {
    this.filter.started = true;
  }

}
