<div *ngIf="!item.itemType">
  <ul class="list-group list-group-flush">
    <a [routerLink]="[]" (click)="selectType('Link')" class="list-group-item list-group-item-action">
      <div class="d-flex w-100 justify-content-between">
        <h5 class="mb-1">Lien</h5>
        <small><em class="bi bi-link"></em></small>
      </div>
      <p class="mb-1">Un lien vers une ressource en ligne.</p>
      <small>Cette ressource s'ouvrira dans un nouvel onglet.</small>
    </a>
    <a [routerLink]="[]" (click)="selectType('Media')" class="list-group-item list-group-item-action">
      <div class="d-flex w-100 justify-content-between">
        <h5 class="mb-1">Média</h5>
        <small><em class="bi bi-images"></em></small>
      </div>
      <p class="mb-1">Une image, un fichier pdf ou une vidéo.</p>
      <small>Cette ressource s'affichera directement dans iClasse.</small>
    </a>
    <a [routerLink]="[]" (click)="selectType('Text')" class="list-group-item list-group-item-action">
      <div class="d-flex w-100 justify-content-between">
        <h5 class="mb-1">Texte</h5>
        <small><em class="bi bi-card-text"></em></small>
      </div>
      <p class="mb-1">Un texte ou une consigne à lire directement dans le parcours.</p>
    </a>
    <a [routerLink]="[]" (click)="selectType('Title')" class="list-group-item list-group-item-action">
      <div class="d-flex w-100 justify-content-between">
        <h5 class="mb-1">Titre</h5>
        <small><em class="bi bi-type-h1"></em></small>
      </div>
      <p class="mb-1">Un titre pour séparer les éléments du parcours.</p>
    </a>
    <a [routerLink]="[]" (click)="selectType('Activity')" class="list-group-item list-group-item-action">
      <div class="d-flex w-100 justify-content-between">
        <h5 class="mb-1">Activité</h5>
        <small><em class="bi bi-card-list"></em></small>
      </div>
      <p class="mb-1">Une activité iClasse.</p>
    </a>
  </ul>
</div>
<div *ngIf="item.itemType">
  <div class="pb-2" *ngIf="item.itemType !=='Text' && item.itemType !== 'Title'">
    <nemo-logo [color]="item.color" [icon]="item.icon" [editable]="true" [fixed]="false" (editedLogo)="updateLogo($event)"></nemo-logo>
  </div>
  <div class="pb-2">
    <label class="form-label" for="urlLabel">Titre</label>
    <div class="input-group input-group">
      <input ngbAutofocus id="titleLabel" type="text" class="form-control" [(ngModel)]="item.title" placeholder="Titre" aria-label="titre"
        autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" maxlength="250" />
    </div>
    <small class="text-muted d-block">
      Donnez un titre parlant à votre ressource afin qu'elle soit compréhensible d'un coup d'oeil.
    </small>
  </div>
  <div class="pb-2" *ngIf="item.itemType === 'Link'">
    <label class="form-label" for="urlLabel">Lien</label>
    <div class="input-group input-group-lg">
      <input id="urlLabel" type="text" class="form-control" [(ngModel)]="item.navigationUrl" placeholder="Lien" aria-label="lien"
        autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" maxlength="1000" />
      <button class="btn btn-outline-secondary" type="button" id="button-addon2" (click)="openNavigationUrl(item.navigationUrl)"><em class="bi bi-link"></em> Tester</button>
    </div>
    <small class="text-muted d-block">
      Vérifiez bien la qualité de votre lien en le testant.
    </small>
  </div>
  <div class="pb-2" *ngIf="item.itemType === 'Media'">
    <div>
      <nemo-media-upload [mediaUrl]="item.navigationUrl" [onlyImages]="false" (mediaChanged)="update($event)"></nemo-media-upload>
    </div>
  </div>
  <div class="pb-2" *ngIf="item.itemType === 'Activity'">
    <div class="border p-2">
      <nemo-activity-selector (updated)="updateFromActivity($event)"></nemo-activity-selector>
    </div>
  </div>
  <div class="pb-2" *ngIf="item.itemType !== 'Title'">
    <label class="form-label" for="description">Description</label>
    <div>
      <quill-editor [(ngModel)]="item.description" ></quill-editor>  
    </div>
    <small class="text-muted d-block">
      Donnez une description la plus claire possible de la ressource.
    </small>
  </div>
  <div class="d-flex flex-column align-self-center">
    <div>
      <span class=" me-2">Discipline</span>
    </div>
    <div class="flex-grow-1" *ngIf="item">
       <nemo-category name="categories" [(selectedCategoryKeys)]="item.categories" (changed)="updateClassification($event)"></nemo-category>
    </div>
  </div>
  <!-- <div class="pb-2">
    
    <nemo-category name="categories" [(selectedCategoryKeys)]="item.categories" (changed)="updateClassification($event)"></nemo-category>
  </div> -->
  <div class="pb-2">
    <label class="form-label" for="description">Publication</label>   
    <div class="input-group">
      <div class="input-group">
        <input
          class="form-control"
          placeholder="jj.mm.aaaa"
          name="d2"
          [(ngModel)]="item.publishedOn"
          ngbDatepicker
          #d2="ngbDatepicker"
        />
        <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d2.toggle()" type="button"></button>
      </div>
    </div>
    
    <small class="text-muted d-block">
      Spécifiez une date de publication précise pour cette ressource.
    </small>
  </div>
  <div class="pb-2" *ngIf="item.itemType === 'Text'">
    <div class="d-flex flex-wrap py-1">
      <div class="flex-grow-1 align-self-center pe-2">
        <label class="lead form-check-label" for="progressionEnabled">Validation</label>
      </div>
      <div class="align-self-center">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" role="switch" id="progressionEnabled"  [(ngModel)]="item.progressionEnabled" [ngModelOptions]="{standalone: true}">
        </div>
      </div>
    </div>
    <small class="text-muted d-block">
      L'utilisateur doit-il valider la lecture de ce texte ?
    </small>
  </div>
</div>
