import { Component, Input } from '@angular/core';
import { FileInputComponent } from '../file-input/file-input.component';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'nemo-file-input-modal',
  standalone: true,
  imports: [FileInputComponent, FormsModule, CommonModule],
  templateUrl: './file-input-modal.component.html',
  styleUrl: './file-input-modal.component.scss'
})
export class FileInputModalComponent {
  @Input() title ='Importer un fichier';
  selectedFile : File;
  constructor(public activeModal: NgbActiveModal) {
    
  }
  selectFile(file: any){
    this.selectedFile = file;
  }
  import() {
    this.activeModal.close(this.selectedFile);
  }
}
