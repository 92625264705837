<div *ngIf="_categories && _categories.length === 0">
    <div class="alert alert-warning" role="alert">
    Pas de catégorie
  </div>
</div>
<div *ngIf="_categories && _categories.length !== 0">

  <!-- Disciplines-->
  <div *ngIf="disciplineSelect" class="mb-2 page-fade">
    <div *ngFor="let groupOfCategory of (disciplines | orderBy: 'ord' | groupBy: 'sousGroup' | pairs)">
      <div class="list-group mb-1">
        <a *ngFor="let category of groupOfCategory[1]" [routerLink]="[]" class="list-group-item list-group-item-action" [ngClass]="{'list-group-item-secondary':categorySelected(category)}" (click)="toggle(category)">
          <small>{{category.value}}</small>
        </a>
      </div>
    </div>
  </div>
  <div *ngIf="!disciplineSelect" class="mb-2 page-fade">
    <div class="list-group mb-1">
      <a *ngFor="let category of (disciplinesSelected | orderBy: 'ord')" [routerLink]="[]" class="list-group-item list-group-item-action" (click)="toggle(category)"  >
        <div class="d-flex">
          <div class="flex-grow-1 align-self-center">
            <small>{{category.value}}</small>
          </div>
          <div class="align-self-center"><i class="bi bi-dash-circle"></i></div>
        </div>
      </a>
    </div>
    <div class="d-grid gap-2" >
      <button class="btn btn-link btn-sm" type="button" (click)="disciplineSelect=!disciplineSelect">Choisir d'autres disciplines</button>
    </div>  
  </div>
  
  <!-- Années-->
  <div *ngFor="let groupOfCategory of (annees | orderBy: 'ord' | groupBy: 'sousGroup' | pairs)">
    <div class="list-group list-group-horizontal mb-2">
      <a *ngFor="let category of groupOfCategory[1]" [routerLink]="[]" class="list-group-item list-group-item-action text-center" [ngClass]="{'list-group-item-secondary':categorySelected(category)}" (click)="toggle(category)">
        <small>{{category.value}}</small>
      </a>
    </div>
  </div>
  
  <!-- Niveaux-->
  <div *ngFor="let groupOfNiveaux of (niveaux | orderBy: 'ord'| groupBy: 'sousGroup' | pairs)">
    <div class="list-group mb-2">
      <a *ngFor="let category of groupOfNiveaux[1]" [routerLink]="[]" class="list-group-item list-group-item-action" [ngClass]="{'list-group-item-secondary':categorySelected(category)}" (click)="toggle(category)">
        <small>{{category.value}}</small>
      </a>
    </div>
  </div>

  <!-- Catégories-->
  <div *ngIf="catSelect" class="mb-2 page-fade">
    <div *ngFor="let groupOfCategory of (cats | orderBy: 'ord' | groupBy: 'sousGroup' | pairs)">
      <div class="list-group mb-1">
        <a *ngFor="let category of groupOfCategory[1]" [routerLink]="[]" class="list-group-item list-group-item-action" [ngClass]="{'list-group-item-secondary':categorySelected(category)}" (click)="toggle(category)">
          <small>{{category.value}}</small>
        </a>
      </div>
    </div>
  </div>
  <div *ngIf="!catSelect" class="mb-2 page-fade">
    <div class="list-group mb-1">
      <a *ngFor="let category of (catsSelected | orderBy: 'ord')" [routerLink]="[]" class="list-group-item list-group-item-action" (click)="toggle(category)"  >
        <div class="d-flex">
          <div class="flex-grow-1 align-self-center">
            <small>{{category.value}}</small>
          </div>
          <div class="align-self-center"><i class="bi bi-dash-circle"></i></div>
        </div>
      </a>
    </div>
    <div class="d-grid gap-2" >
      <button class="btn btn-link btn-sm" type="button" (click)="catSelect=!catSelect">Choisir d'autres catégories</button>
    </div>  
  </div>
</div>