import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastService, ParcoursService } from '@app/core/service';
import { CourseItem } from '@app/model';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { ParcoursItemDetailComponent } from '../parcours-item-detail/parcours-item-detail.component';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'nemo-parcours-item-start',
    templateUrl: './parcours-item-start.component.html',
    styleUrls: ['./parcours-item-start.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass, ParcoursItemDetailComponent, RouterLink, FormsModule]
})
export class ParcoursItemStartComponent implements OnInit {

  @Input() item: CourseItem;
  @Input() enableAddToCart = false;
  @Input() displayProgression = true;
  @Input() selectionMode = false;
  @Output() started = new EventEmitter<CourseItem>();
  baseUrl: string;

  constructor(
    private readonly parcoursService: ParcoursService,
    private readonly toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.baseUrl = this.item.navigationUrl;
  }
  onChange(){
    this.started.emit(this.item);
  }
  start() {
    this.started.emit(this.item);
  }

  addItemToCart() {
    this.parcoursService.addItemToCart(this.item);
    this.toastService.success('Ressource ajoutée à votre panier de ressources');
  }

}
