<div class="modal-header">
  <h4 class="modal-title">Discipline</h4>
  <button type="button" class="btn-close" aria-label="btn-close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <div class="col-md-12">
    <div *ngFor="let catGroup of categories | orderBy: 'number' | groupBy: 'group' | pairs ">
      {{catGroup[0]}}
      <div>
        <a [routerLink]="[]" *ngFor="let category of catGroup[1] | orderBy: 'ord'" class="badge rounded-pill me-1" [ngClass]="{'category-tag':!categorySelected(category), 'bg-primary':categorySelected(category)}" (click)="toggleCategory(category)">{{category.value}}</a>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss()">Annuler</button>
  <button type="button" class="btn btn-outline-primary"  (click)="emitChanges()"  ngbAutofocus>Valider</button>
</div>



