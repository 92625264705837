<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button type="button" class="btn-close" aria-label="btn-close" (click)="activeModal.dismiss()">

  </button>
</div>
<div class="modal-body">
  <div class="col-md-12">
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text"><span class="bi bi-link"></span></span>
      </div>
      <input type="text" class="form-control" [value]="url" placeholder="url" aria-label="url"
        aria-describedby="button-addon2" #inputTarget maxlength="999">
      <button class="btn btn-outline-secondary" type="button" id="button-addon2" [ngxClipboard]="inputTarget"><span
          class="bi bi-clipboard-fill"></span> Copier</button>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss()" ngbAutofocus>Fermer</button>
</div>
