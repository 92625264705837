import { Injectable } from '@angular/core';
import {
  Category,
  Language,
  Logos,
  NewState,
  Actualite,
  SearchNatureEnum
} from '@app/model';
import { forkJoin, Observable, of } from 'rxjs';
import { HttpBaseService } from './http-base.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ReferenceDataService {
  

  private readonly url = '/reference-data';
  private readonly logos: Logos = {
    colors: [
      { description: 'Autre', value: 'c-grey', selected: false },
      { description: 'Langues', value: 'c-yellow', selected: false },
      { description: 'Mathématiques et Sciences de la nature', value: 'c-red', selected: false },
      { description: 'Sciences humaines et sociales', value: 'c-green', selected: false },
      { description: 'Arts', value: 'c-orange', selected: false },
      { description: 'Corps et mouvement', value: 'c-blue', selected: false },
      { description: 'Éducation numérique', value: 'c-pink', selected: false },
      { description: 'Formation générale', value: 'c-violet', selected: false },
      { description: 'Capacités transversales', value: 'c-brown', selected: false },
      { description: 'Folios', value: 'c-fushia', selected: false }
    ],
    icons: [
      { description: 'RPN', value: 'edicons-visualidentity-rpn', selected: false },
      { description: 'RPN variante', value: 'edicons-visualidentity-rpnapp', selected: false },
      { description: 'Folio', value: 'edicons edicons-visualidentity-folio', selected: false },
      { description: 'Parcours', value: 'bi bi-map-fill', selected: false },
      { description: 'Liste', value: 'bi bi-card-list', selected: false },
      { description: 'Lexique', value: 'bi bi-book-fill', selected: false },
      { description: 'Mémoriser', value: 'edicons-game-card', selected: false },
      { description: 'Associer', value: 'edicons-game-link', selected: false },
      { description: 'Écrire', value: 'edicons-game-write', selected: false },
      { description: 'Image', value: 'bi bi-file-earmark-image-fill', selected: false },
      { description: 'Vidéo', value: 'bi bi-file-earmark-play-fill', selected: false },
      { description: 'Pdf', value: 'bi bi-file-pdf-fill', selected: false },
      { description: 'Fichier', value: 'bi bi-file-earmark-fill', selected: false },
      { description: 'Citoyenneté', value: 'edicons-discipline-citizenship', selected: false },
      { description: 'Géographie', value: 'edicons-discipline-geography', selected: false },
      { description: 'Santé et bien-être', value: 'edicons-discipline-healthwellbeing', selected: false },
      { description: 'Histoire', value: 'edicons-discipline-history', selected: false },
      { description: 'Activités créatrices et manuelles', value: 'edicons-discipline-manualactivities', selected: false },
      { description: 'Activités créatrices et manuelles variante', value: 'bi bi-hammer', selected: false },
      { description: 'Mathématiques', value: 'edicons-discipline-mathematics', selected: false },
      { description: 'Mitic', value: 'edicons-discipline-mitic', selected: false },
      { description: 'Musique', value: 'edicons-discipline-music', selected: false },
      { description: 'Éducation nutritionnelle', value: 'edicons-discipline-nutrition', selected: false },
      { description: 'Sciences de la nature', value: 'edicons-discipline-sciences', selected: false },
      { description: 'Éducation physique', value: 'edicons-discipline-sport', selected: false },
      { description: 'Arts visuels', value: 'edicons-discipline-visualarts', selected: false },
      { description: 'Arts visuels variante', value: 'bi bi-palette-fill', selected: false },
      { description: 'Géométrie', value: 'bi bi-rulers', selected: false },
      { description: 'Addition', value: 'bi bi-plus-lg', selected: false },
      { description: 'Soustraction', value: 'bi bi-dash-lg', selected: false },
      { description: 'Multiplication', value: 'bi bi-x-lg', selected: false },
      { description: 'Chercher', value: 'bi bi-search', selected: false },
      { description: 'Avion', value: 'edicons-shape-airplane', selected: false },
      { description: 'Cercle', value: 'edicons-shape-circle', selected: false },
      { description: 'Nuage', value: 'edicons-shape-cloud', selected: false },
      { description: 'Trèfle', value: 'edicons-shape-clubs', selected: false },
      { description: 'Pique', value: 'edicons-shape-diamonds', selected: false },
      { description: 'Fanion', value: 'edicons-shape-flag', selected: false },
      { description: 'Coeur', value: 'edicons-shape-heart', selected: false },
      { description: 'Tasse', value: 'edicons-shape-mug', selected: false },
      { description: 'Pentagone', value: 'edicons-shape-pentagone', selected: false },
      { description: 'Électricité', value: 'edicons-shape-power', selected: false },
      { description: 'Cordon', value: 'edicons-shape-power-cord', selected: false },
      { description: 'Fusée', value: 'edicons-shape-rocket', selected: false },
      { description: 'Pique', value: 'edicons-shape-spades', selected: false },
      { description: 'Spirale', value: 'edicons-shape-spiral', selected: false },
      { description: 'Carré', value: 'edicons-shape-square', selected: false },
      { description: 'Étoile', value: 'edicons-shape-star', selected: false },
      { description: 'Triangle', value: 'edicons-shape-triangle', selected: false },
      { description: 'Camion', value: 'edicons-shape-truck', selected: false },
      { description: 'Écouter', value: 'bi bi-volume-up-fill', selected: false },
      { description: 'Lire', value: 'bi bi-book-fill', selected: false },
      { description: 'Écrire', value: 'bi bi-pencil-fill', selected: false },
      { description: 'Parler', value: 'bi bi-card-text', selected: false },      
      { description: 'MER', value: 'edicons-visualidentity-rpn', selected: false }
    ]
  };
  languages: Language[] = [
    {"id":"fr","label":"Français"},
    {"id":"de","label":"Allemand"},
    {"id":"en","label":"Anglais"},
    {"id":"es","label":"Espagnol"},
    {"id":"it","label":"Italien"},
    {"id":"nc","label":"Autre"}
  ];
  categories: Category[] = [
    { "key": "1", "group": "Année", "value": "1e", "ord": 330, "short": "1ère", "sousGroup": "Cycle 1", "color": null },
    { "key": "2", "group": "Année", "value": "2e", "ord": 340, "short": "2e", "sousGroup": "Cycle 1", "color": null },
    { "key": "3", "group": "Année", "value": "3e", "ord": 350, "short": "3e", "sousGroup": "Cycle 1", "color": null },
    { "key": "4", "group": "Année", "value": "4e", "ord": 360, "short": "4e", "sousGroup": "Cycle 1", "color": null },
    { "key": "5", "group": "Année", "value": "5e", "ord": 370, "short": "5e", "sousGroup": "Cycle 2", "color": null },
    { "key": "6", "group": "Année", "value": "6e", "ord": 380, "short": "6e", "sousGroup": "Cycle 2", "color": null },
    { "key": "7", "group": "Année", "value": "7e", "ord": 390, "short": "7e", "sousGroup": "Cycle 2", "color": null },
    { "key": "8", "group": "Année", "value": "8e", "ord": 400, "short": "8e", "sousGroup": "Cycle 2", "color": null },
    { "key": "9", "group": "Année", "value": "9e", "ord": 410, "short": "9e", "sousGroup": "Cycle 3", "color": null },
    { "key": "10", "group": "Année", "value": "10e", "ord": 440, "short": "10e", "sousGroup": "Cycle 3", "color": null },
    { "key": "11", "group": "Année", "value": "11e", "ord": 470, "short": "11e", "sousGroup": "Cycle 3", "color": null },
    { "key": "PRI", "group": "Catégorie", "value": "PRIMA", "ord": 1005, "short": "PRIMA", "sousGroup": null, "color": null },
    { "key": "PAR", "group": "Catégorie", "value": "Parents", "ord": 1009, "short": "Parents", "sousGroup": null, "color": null },
    { "key": "ALO", "group": "Catégorie", "value": "Allophone", "ord": 1009, "short": "Allo", "sousGroup": null, "color": null },
    { "key": "DEV", "group": "Catégorie", "value": "Devoirs", "ord": 1010, "short": "DEV", "sousGroup": null, "color": null },
    { "key": "FOR", "group": "Catégorie", "value": "Formation", "ord": 1020, "short": "FOR", "sousGroup": null, "color": null },
    { "key": "API", "group": "Catégorie", "value": "Aides pédagogiques par l'informatique", "ord": 1030, "short": "API", "sousGroup": null, "color": null },
    { "key": "MER", "group": "Catégorie", "value": "Moyen d'enseignement officiel", "ord": 1040, "short": "MER", "sousGroup": "Moyen d'enseignement ", "color": null },
    { "key": "MEN", "group": "Catégorie", "value": "Moyen d'enseignement neuchâtelois", "ord": 1050, "short": "MEN", "sousGroup": "Moyen d'enseignement ", "color": null },
    { "key": "FRA", "group": "Discipline", "value": "Français", "ord": 10, "short": "FRA", "sousGroup": "Langues", "color": "c-yellow" },
    { "key": "ALL", "group": "Discipline", "value": "Allemand", "ord": 20, "short": "ALL", "sousGroup": "Langues", "color": "c-yellow" },
    { "key": "ANG", "group": "Discipline", "value": "Anglais", "ord": 30, "short": "ANG", "sousGroup": "Langues", "color": "c-yellow" },
    { "key": "ITA", "group": "Discipline", "value": "Italien", "ord": 40, "short": "ITA", "sousGroup": "Langues", "color": "c-yellow" },
    { "key": "ESP", "group": "Discipline", "value": "Espagnol", "ord": 50, "short": "ESP", "sousGroup": "Langues", "color": "c-yellow" },
    { "key": "MAT", "group": "Discipline", "value": "Mathématiques", "ord": 60, "short": "MAT", "sousGroup": "Mathématiques et Sciences de la nature", "color": "c-red" },
    { "key": "SCN", "group": "Discipline", "value": "Sciences de la nature", "ord": 70, "short": "SCN", "sousGroup": "Mathématiques et Sciences de la nature", "color": "c-red" },
    { "key": "HIS", "group": "Discipline", "value": "Histoire", "ord": 80, "short": "HIS", "sousGroup": "Sciences humaines et sociales", "color": "c-green" },
    { "key": "GEO", "group": "Discipline", "value": "Géographie", "ord": 90, "short": "GEO", "sousGroup": "Sciences humaines et sociales", "color": "c-green" },
    { "key": "CIT", "group": "Discipline", "value": "Monde contemporain et citoyenneté", "ord": 100, "short": "MCC", "sousGroup": "Sciences humaines et sociales", "color": "c-green" },
    { "key": "LCA", "group": "Discipline", "value": "Langues et cultures de l'Antiquité", "ord": 110, "short": "LCA", "sousGroup": "Sciences humaines et sociales", "color": "c-green" },
    { "key": "AVI", "group": "Discipline", "value": "Arts visuels", "ord": 120, "short": "AVI", "sousGroup": "Arts", "color": "c-orange" },
    { "key": "ACM", "group": "Discipline", "value": "Activités créatrices et manuelles", "ord": 130, "short": "ACM", "sousGroup": "Arts", "color": "c-orange" },
    { "key": "MUS", "group": "Discipline", "value": "Musique", "ord": 140, "short": "MUS", "sousGroup": "Arts", "color": "c-orange" },
    { "key": "PHY", "group": "Discipline", "value": "Éducation physique", "ord": 150, "short": "EPH", "sousGroup": "Corps et mouvement", "color": "c-blue" },
    { "key": "EFA", "group": "Discipline", "value": "Économie familiale", "ord": 160, "short": "EFA", "sousGroup": "Corps et mouvement", "color": "c-blue" },
    { "key": "NUT", "group": "Discipline", "value": "Éducation nutritionnelle", "ord": 170, "short": "NUT", "sousGroup": "Corps et mouvement", "color": "c-blue" },
    { "key": "EN", "group": "Discipline", "value": "Éducation numérique", "ord": 180, "short": "EN", "sousGroup": "Éducation numérique", "color": "c-pink" },
    { "key": "MIT", "group": "Discipline", "value": "Mitic", "ord": 190, "short": "MIT", "sousGroup": "Formation générale", "color": "c-violet" },
    { "key": "SAN", "group": "Discipline", "value": "Santé et bien-être", "ord": 200, "short": "SAN", "sousGroup": "Formation générale", "color": "c-violet" },
    { "key": "FG", "group": "Discipline", "value": "Formation générale", "ord": 210, "short": "FG", "sousGroup": "Formation générale", "color": "c-violet" },
    { "key": "PER", "group": "Discipline", "value": "Choix et projets personnels", "ord": 220, "short": "PER", "sousGroup": "Formation générale", "color": "c-violet" },
    { "key": "VIV", "group": "Discipline", "value": "Vivre ensemble et exercice de la démocratie", "ord": 230, "short": "VIV", "sousGroup": "Formation générale", "color": "c-violet" },
    { "key": "INT", "group": "Discipline", "value": "Interdépendances", "ord": 240, "short": "INT", "sousGroup": "Formation générale", "color": "c-violet" },
    { "key": "CAP", "group": "Discipline", "value": "Capacités transversales", "ord": 250, "short": "CAP", "sousGroup": "Capacités transversales", "color": "c-brown" },
    { "key": "OCM", "group": "Discipline", "value": "OP Activités créatrices manuelles", "ord": 260, "short": "OCM", "sousGroup": "Options professionnelles", "color": "c-grey" },
    { "key": "ODE", "group": "Discipline", "value": "OP Dessin technique et artistique", "ord": 270, "short": "ODE", "sousGroup": "Options professionnelles", "color": "c-grey" },
    { "key": "OEX", "group": "Discipline", "value": "OP Expression orale et corporelle", "ord": 280, "short": "OEX", "sousGroup": "Options professionnelles", "color": "c-grey" },
    { "key": "OIG", "group": "Discipline", "value": "OP Informatique appliquée et gestion", "ord": 290, "short": "OIG", "sousGroup": "Options professionnelles", "color": "c-grey" },
    { "key": "OLA", "group": "Discipline", "value": "OA Langues anciennes", "ord": 300, "short": "OLA", "sousGroup": "Options académiques", "color": "c-grey" },
    { "key": "OLM", "group": "Discipline", "value": "OA Langues modernes", "ord": 310, "short": "OLM", "sousGroup": "Options académiques", "color": "c-grey" },
    { "key": "OSE", "group": "Discipline", "value": "OA Sciences expérimentales", "ord": 320, "short": "OSE", "sousGroup": "Options académiques", "color": "c-grey" },
    { "key": "OSH", "group": "Discipline", "value": "OA Sciences humaines", "ord": 330, "short": "OSH", "sousGroup": "Options académiques", "color": "c-grey" },
    { "key": "FRR", "group": "Discipline", "value": "OP Français (renforcement)", "ord": 340, "short": "FRR", "sousGroup": "Options professionnelles", "color": "c-grey" },
    { "key": "MTR", "group": "Discipline", "value": "OP Mathématiques (renforcement)", "ord": 350, "short": "MTR", "sousGroup": "Options professionnelles", "color": "c-grey" },
    { "key": "REX", "group": "Discipline", "value": "Renforcement/Extension", "ord": 360, "short": "REX", "sousGroup": "Autres", "color": "c-grey" },
    { "key": "ACF", "group": "Discipline", "value": "Activités complémentaires facultatives", "ord": 370, "short": "ACF", "sousGroup": "Autres", "color": "c-grey" },
    { "key": "FR", "group": "Formation", "value": "Formation régulière", "ord": 500, "short": "FR", "sousGroup": null, "color": null },
    { "key": "FS", "group": "Formation", "value": "Formation spécialisée", "ord": 510, "short": "FS", "sousGroup": null, "color": null },
    { "key": "TE", "group": "Formation", "value": "Terminale", "ord": 520, "short": "TE", "sousGroup": null, "color": null },
    { "key": "ACC", "group": "Formation", "value": "Classe d'accueil", "ord": 530, "short": "ACC", "sousGroup": null, "color": null },
    { "key": "NI1", "group": "Niveau", "value": "Niveau 1", "ord": 495, "short": "niv 2", "sousGroup": null, "color": null },
    { "key": "NI2", "group": "Niveau", "value": "Niveau 2", "ord": 497, "short": "niv 2", "sousGroup": null, "color": null },
    // { "key": "FRA1", "group": "Axe", "sousGroup": null, "value":"Écriture et instruments de la communication", "ord": 601 , "short": "écrit", "color": "c-yellow", "parentCategoryKey": "FRA" },
    // { "key": "FRA2", "group": "Axe", "sousGroup": null, "value":"Accès à la littérature", "ord": 602 , "short": "litté", "color": "c-yellow", "parentCategoryKey": "FRA" },
    // { "key": "ALA3", "group": "Axe", "sousGroup": null, "value":"Approches interlinguistiques", "ord": 603 , "short": "app inter", "color": "c-yellow", "parentCategoryKey": "ALL" },
    // { "key": "ANA4", "group": "Axe", "sousGroup": null, "value":"Approches interlinguistiques", "ord": 604 , "short": "app inter", "color": "c-yellow", "parentCategoryKey": "ANG" },
    // { "key": "FRA5", "group": "Axe", "sousGroup": null, "value":"Approches interlinguistiques", "ord": 605 , "short": "app inter", "color": "c-yellow", "parentCategoryKey": "FRA" },
    // { "key": "CAA6", "group": "Axe", "sousGroup": null, "value":"Collaboration", "ord": 606 , "short": "collab", "color": "c-brown", "parentCategoryKey": "CAP" },
    // { "key": "CAA7", "group": "Axe", "sousGroup": null, "value":"Communication", "ord": 607 , "short": "comm", "color": "c-brown", "parentCategoryKey": "CAP" },
    // { "key": "ALA8", "group": "Axe", "sousGroup": null, "value":"Compréhension de l'écrit", "ord": 608 , "short": "comp écrit", "color": "c-yellow", "parentCategoryKey": "ALL" },
    // { "key": "ANA9", "group": "Axe", "sousGroup": null, "value":"Compréhension de l'écrit", "ord": 609 , "short": "comp écrit", "color": "c-yellow", "parentCategoryKey": "ANG" },
    // { "key": "FA10", "group": "Axe", "sousGroup": null, "value":"Compréhension de l'écrit", "ord": 610 , "short": "comp écrit", "color": "c-yellow", "parentCategoryKey": "FRA" },
    // { "key": "AA11", "group": "Axe", "sousGroup": null, "value":"Compréhension de l'oral", "ord": 611 , "short": "comp oral", "color": "c-yellow", "parentCategoryKey": "ALL" },
    // { "key": "AA12", "group": "Axe", "sousGroup": null, "value":"Compréhension de l'oral", "ord": 612 , "short": "comp oral", "color": "c-yellow", "parentCategoryKey": "ANG" },
    // { "key": "FA13", "group": "Axe", "sousGroup": null, "value":"Compréhension de l'oral", "ord": 613 , "short": "comp oral", "color": "c-yellow", "parentCategoryKey": "FRA" },
    // { "key": "CA14", "group": "Axe", "sousGroup": null, "value":"Démarche réflexive", "ord": 614 , "short": "dém réfex", "color": "c-brown", "parentCategoryKey": "CAP" },
    // { "key": "AA15", "group": "Axe", "sousGroup": null, "value":"Fonctionnement de la langue", "ord": 615 , "short": "fct langue", "color": "c-yellow", "parentCategoryKey": "ALL" },
    // { "key": "AA16", "group": "Axe", "sousGroup": null, "value":"Fonctionnement de la langue", "ord": 616 , "short": "fct langue", "color": "c-yellow", "parentCategoryKey": "ANG" },
    // { "key": "FA17", "group": "Axe", "sousGroup": null, "value":"Fonctionnement de la langue", "ord": 617 , "short": "fct langue", "color": "c-yellow", "parentCategoryKey": "FRA" },
    // { "key": "CA18", "group": "Axe", "sousGroup": null, "value":"Pensée créatrice", "ord": 618 , "short": "pensée créa", "color": "c-brown", "parentCategoryKey": "CAP" },
    // { "key": "AA19", "group": "Axe", "sousGroup": null, "value":"Production de l'écrit", "ord": 619 , "short": "prod écrit", "color": "c-yellow", "parentCategoryKey": "ALL" },
    // { "key": "AA20", "group": "Axe", "sousGroup": null, "value":"Production de l'écrit", "ord": 620 , "short": "prod écrit", "color": "c-yellow", "parentCategoryKey": "ANG" },
    // { "key": "FA21", "group": "Axe", "sousGroup": null, "value":"Production de l'écrit", "ord": 621 , "short": "prod écrit", "color": "c-yellow", "parentCategoryKey": "FRA" },
    // { "key": "AA22", "group": "Axe", "sousGroup": null, "value":"Production de l'oral", "ord": 622 , "short": "prod oral", "color": "c-yellow", "parentCategoryKey": "ALL" },
    // { "key": "AA23", "group": "Axe", "sousGroup": null, "value":"Production de l'oral", "ord": 623 , "short": "prod oral", "color": "c-yellow", "parentCategoryKey": "ANG" },
    // { "key": "FA24", "group": "Axe", "sousGroup": null, "value":"Production de l'oral", "ord": 624 , "short": "prod oral", "color": "c-yellow", "parentCategoryKey": "FRA" },
    // { "key": "CA25", "group": "Axe", "sousGroup": null, "value":"Stratégies d'apprentissage", "ord": 625 , "short": "strategie app", "color": "c-brown", "parentCategoryKey": "CAP" }
  ];
  newState: NewState = {
    newBookletModels: 0,
    newDistributedCourses: 0,
    newLexicons: 0,
    newRpnCourses: 0,
    newRpnLists: 0,
    newTeacherCourses: 0
  };

  constructor(private readonly http: HttpBaseService) { }

  public updateDataFromApi() {
    this.http.get<NewState>(`${this.url}/nouveautes`).subscribe(nouveautes =>{
      this.newState = nouveautes;
    });
  }
  

  public getActualites(): Observable<Actualite[]> {
    return this.http.get<Actualite[]>(`${this.url}/actualites`);
  }

  public getLanguages(): Language[] {
    return this.languages;
  }

  public getCategories(): Category[] {
    return this.categories;
  }
  
  public getCategoriesForRessources(ressourceName: string, typeSearch: SearchNatureEnum): Observable<string[]> {
    return of(this.categories.map(c=>c.key));
  }

  public getLogo(): Logos {
    return this.logos;
  }

  public getNewState(): NewState {
    return this.newState;
  }

  computeCategories(cats: Category[]): Category[] {

    //TODO: to refactor
    var result: Category[]=[];

    let checker = (arr: string | any[], target: any[]) => arr.length === target.length && target.every(v => arr.indexOf(v)!==-1);

    if(checker( ['1','2','3','4','5','6','7','8','9','9n1','9n2','10','10n1','10n2','11','11n1','11n2'],cats.filter(c=>c.group==='Année').map(p=>p.key))){
      result.push({ short:'Toutes les années',sousGroup:null, group:'Année',key:'',ord:1100,"color": null,value:'Toutes les années'});
    }
    if(checker(['1','2','3','4'],cats.filter(c=>c.group==='Année').map(p=>p.key))){
      result.push({ short:'Cycle 1',sousGroup:null, group:'Année',key:'',ord:1100,"color": null,value:'Cycle 1'});
    }
    if(checker(['5','6','7','8'],cats.filter(c=>c.group==='Année').map(p=>p.key))){
      result.push({ short:'Cycle 2',sousGroup:null, group:'Année',key:'',ord:1100,"color": null,value:'Cycle 2'});
    }
    if(checker(['9','9n1','9n2','10','10n1','10n2','11','11n1','11n2'],cats.filter(c=>c.group==='Année').map(p=>p.key))){
      result.push({ short:'Cycle 3',sousGroup:null, group:'Année',key:'',ord:1100,"color": null,value:'Cycle 3'});
    }
    if(checker(['9','10','11'],cats.filter(c=>c.group==='Année').map(p=>p.key))){
      result.push({ short:'Cycle 3',sousGroup:null, group:'Année',key:'',ord:1100,"color": null,value:'Cycle 3'});
    }


    if(checker(['1','2'],cats.filter(c=>c.group==='Année').map(p=>p.key))){
      result.push({ short:'1ère-2e',sousGroup:null, group:'Année',key:'',ord:1100,"color": null,value:'1ère - 2e'});
    }
    if(checker(['3','4'],cats.filter(c=>c.group==='Année').map(p=>p.key))){
      result.push({ short:'3e-4e',sousGroup:null, group:'Année',key:'',ord:1100,"color": null,value:'3e - 4e'});
    }
    if(checker(['5','6'],cats.filter(c=>c.group==='Année').map(p=>p.key))){
      result.push({ short:'5e-6e',sousGroup:null, group:'Année',key:'',ord:1100,"color": null,value:'5e - 6e'});
    }
    if(checker(['7','8'],cats.filter(c=>c.group==='Année').map(p=>p.key))){
      result.push({ short:'7e-8e',sousGroup:null, group:'Année',key:'',ord:1100,"color": null,value:'7e - 8e'});
    }
    if(checker(['9','9n1','9n2'],cats.filter(c=>c.group==='Année').map(p=>p.key))){
      result.push({ short:'9e',sousGroup:null, group:'Année',key:'',ord:1100,"color": null,value:'9e'});
    }
    if(checker(['10','10n1','10n2'],cats.filter(c=>c.group==='Année').map(p=>p.key))){
      result.push({ short:'10e',sousGroup:null, group:'Année',key:'',ord:1100,"color": null,value:'10e'});
    }
    if(checker(['11','11n1','11n2'],cats.filter(c=>c.group==='Année').map(p=>p.key))){
      result.push({ short:'11e',sousGroup:null, group:'Année',key:'',ord:1100,"color": null,value:'11e'});
    }
    if(result.length===0 && cats.filter(c=>c.group==='Année').length >= 2){
      var first = cats.filter(c=>c.group==='Année')[0];
      var last = cats.filter(c=>c.group==='Année')[cats.filter(c=>c.group==='Année').length-1];
      result.push({ short:`${first.short}-${last.short}`,sousGroup:null, group:'Année',key:'',ord:1100,"color": null,value:`${first.short} - ${last.short}`});
    }
    if(result.length===0){
      result.push(...cats.filter(c=>c.group==='Année'));
    }
     
    result.push(...cats.filter(c=>c.group!=='Année'));

    // On ne s'occupe que des années
    
    return result;
  }
  computeYears(years: number[]):string[] {
    //TODO: to refactor
    var result: string[]=[];

    let checker = (arr: string | any[], target: any[]) => arr.length === target.length && target.every(v => arr.indexOf(v)!==-1);

    if(checker( [1,2,3,4,5,6,7,8,9,11],years)){
      result.push('Toutes les années');
    }
    if(checker([1,2,3,4],years)){
      result.push('Cycle 1');
    }
    if(checker([5,6,7,8],years)){
      result.push('Cycle 2');
    }
    if(checker([9,10,11],years)){
      result.push('Cycle 3');
    }
    if(checker([1,2],years)){
      result.push('1ère-2e');
    }
    if(checker([3,4],years)){
      result.push('3e-4e');
    }
    if(checker([5,6],years)){
      result.push('5e-6e');
    }
    if(checker([7,8],years)){
      result.push('7e-8e');
    }
    if(checker([9],years)){
      result.push('9e');
    }
    if(checker([10],years)){
      result.push('10e');
    }
    if(checker([11],years)){
      result.push('11e');
    }
    if(result.length===0 && years.length >= 2){
      var first = years[0];
      var last = years[years.length-1];
      result.push(`${first}-${last}`);
    }
    if(result.length===0){
      result.push(...years.map(y=>y+''));
    }

    // On ne s'occupe que des années
    
    return result;
  }
}
