import { Injectable } from '@angular/core';
import { CourseSummary, Distribution } from '@app/model';
import { Observable } from 'rxjs';
import { HttpBaseService } from './http-base.service';

@Injectable({
  providedIn: 'root'
})
export class DistributionService {

  private readonly url = '/distribution';
  constructor(private readonly http: HttpBaseService) { }

  public getDistributions(all: boolean): Observable<CourseSummary[]> {
    return this.http.get<CourseSummary[]>(`${this.url}/${all}`);
  }

  public getDistribution(courseId: number): Observable<Distribution[]> {
    return this.http.get<Distribution[]>(`${this.url}/detail/${courseId}`);
  }

  public saveDistribution(courseId: number, distributions: Distribution[]): Observable<Distribution[]> {
    return this.http.put<Distribution[]>(`${this.url}/${courseId}`, distributions);
  }

  public deleteDistribution(courseId: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.url}/${courseId}`);
  }
}
