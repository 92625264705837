import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Logo } from '@app/model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LogoSelectorComponent } from '../logo-selector/logo-selector.component';
import { NgClass } from '@angular/common';

@Component({
    selector: 'nemo-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.scss'],
    standalone: true,
    imports: [NgClass, LogoSelectorComponent],
})
export class LogoComponent implements OnInit {
  @Input() color = 'c-grey';
  @Input() icon = 'edicons-visualidentity-rpn';
  @Input() editable = false;
  @Input() fixed = false;
  @Input() top = false;
  @Output() editedLogo = new EventEmitter<Logo>();

  constructor(private readonly modalService: NgbModal) {}

  ngOnInit() {
    if (this.color === null || this.color === '') {
      this.color = 'c-grey';
    }
    if (this.icon === null || this.icon === '') {
      this.icon = 'edicons-visualidentity-rpn';
    }
  }

  edit() {}
  editListLogo() {
    if (this.editable) {
      const modalRef = this.modalService.open(LogoSelectorComponent, { size: 'lg' });
      modalRef.componentInstance.color = this.color;
      modalRef.componentInstance.icon = this.icon;

      modalRef.result.then(
        logo => {
          this.icon = logo.icon;
          this.color = logo.color;
          this.editedLogo.emit({ icon: this.icon, color: this.color });
        },
        () => {}
      );
    }
  }
}
