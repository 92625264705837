<div class="modal-header">
  <h4 class="modal-title">Transmission</h4>
  <button type="button" class="btn-close" aria-label="btn-close" (click)="activeModal.dismiss()">

  </button>
</div>
<div class="modal-body">
  <p>Sélectionnez l'enseignant à qui vous voulez transmettre ce folio.</p>
  <nemo-teacher-search *ngIf="!grantedTeacher" (addTeacher)="selectTeacher($event)"></nemo-teacher-search>
  <nemo-user-display *ngIf="grantedTeacher">
    {{grantedTeacher.displayName}}
  </nemo-user-display>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss()" ngbAutofocus>Annuler</button>
  <button type="button" class="btn btn-primary" [disabled]="!grantedTeacher" (click)="activeModal.close(grantedTeacher)"><span class="bi bi-send-fill"></span> Transmettre</button>
</div>

