<div *ngIf="labelledObservation">
  <div class="d-flex">
    <div class="flex-grow-1 py-1" >
      <div  class="lead">{{labelledObservation.label}}</div>
      <input type="text" [readonly]="!edit" placeholder="Commentaire" [(ngModel)]="labelledObservation.comment" (input)="inputUpdated($event)" [ngClass]="{'form-control':true, 'form-control-plaintext': !edit, ' form-control-sm': edit }"
        autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" maxlength="999" />
    </div>
    <div class="p-2 flex-shrink-1 align-self-center media-preview-thumbnail">
      <nemo-media-preview  [ngClass]="{'invisible': labelledObservation.label === 'Commentaire général'}" [url]="labelledObservation.markUrl" (click)="changeMark()"></nemo-media-preview>
    </div>
    <div class="p-2 flex-shrink-1 align-self-center text-center" *ngIf="displayAutoEvaluationIcon">
      <nemo-auto-evalutation-state [ngClass]="{'invisible': labelledObservation.label === 'Commentaire général'}" [autoEvaluationEnabled]="labelledObservation.autoEvaluationEnabled" [state]="labelledObservation.state" [selfState]="labelledObservation.selfState" [selfComment]="labelledObservation.selfComment" [op]="op"></nemo-auto-evalutation-state>
    </div>
    <div class="p-1 align-self-center">
      <nemo-evaluation [ngClass]="{'invisible': labelledObservation.label === 'Commentaire général'}"  [evaluationCode]="labelledObservation.state" [edit]="true" (changed)="levelChanged($event)"  [scale]="scale" *ngIf="scale"></nemo-evaluation>
    </div>
  </div>
</div>
