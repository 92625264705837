
<div class="modal-header" *ngIf="item">
    <h4 class="modal-title">{{item.title}}</h4>
    <button type="button" class="btn-close" aria-label="btn-close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body" *ngIf="mediaAction">
  <div class="text-center">
    <nemo-media-display [mediaAction]="mediaAction"></nemo-media-display>
  </div>
  <div class="bg-body-tertiary p-3 mt-2 description" *ngIf="item.description" [innerHTML]="item.description"></div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" data-dismiss="modal" (click)="activeModal.dismiss()">Fermer</button>
</div>
