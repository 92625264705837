import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DateRange } from '@app/model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateRangeSelectorComponent } from '../date-range-selector/date-range-selector.component';
import { NgIf, NgClass, DatePipe } from '@angular/common';

@Component({
    selector: 'nemo-date-range',
    templateUrl: './date-range.component.html',
    styleUrls: ['./date-range.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass, DatePipe]
})
export class DateRangeComponent {
  @Input() edit = false;
  @Input() range: DateRange = new DateRange();
  @Output() changed = new EventEmitter<DateRange>();
  @Input() small = false;

  constructor(private readonly modalService: NgbModal) { }

  editRange() {
    const modalRef = this.modalService.open(DateRangeSelectorComponent, {size : 'lg'});
    modalRef.result.then((range) => {
      this.range = range;
      this.changed.emit(this.range);
    }, () => {
    });
  }
}
