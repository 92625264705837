import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ActivityService, ListeService, ToastService, ParcoursService } from '@app/core/service';
import { Activity, ItemList, Filter, Course, CourseItem } from '@app/model';
import { NgbModal, NgbCollapse } from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver';
import { ItemlistSelectorComponent } from '@app/shared/components/itemlist-selector/itemlist-selector.component';
import { Location, NgIf, NgFor, NgClass } from '@angular/common';
import { ListeItemComponent } from '../liste-item/liste-item.component';
import { TagsTogglerComponent } from '../../../shared/components/tags-toggler/tags-toggler.component';
import { ClipboardModule } from 'ngx-clipboard';
import { ActionMenuComponent } from '../../../shared/components/action-menu/action-menu.component';
import { CardComponent } from '../../../shared/components/card/card.component';
import { BasepageComponent } from '../../../shared/components/basepage/basepage.component';




@Component({
    selector: 'nemo-liste-detail',
    templateUrl: './liste-detail.component.html',
    styleUrls: ['./liste-detail.component.scss'],
    standalone: true,
    imports: [NgIf, BasepageComponent, CardComponent, RouterLink, ActionMenuComponent, ClipboardModule, NgFor, NgClass, NgbCollapse, TagsTogglerComponent, ListeItemComponent]
})
export class ListeDetailComponent implements OnInit {
  public list: ItemList;
  activities: Activity[];
  public isCollapsed = true;
  public displayFilter = false;
  public filters: Filter[][];
  navigationThroughRouter=true;

  constructor(
    public router: Router,
    private readonly listeservice: ListeService,
    private readonly route: ActivatedRoute,
    private readonly modalService: NgbModal,
    private readonly toastService: ToastService,
    private readonly activityService: ActivityService,
    private readonly parcoursservice: ParcoursService,
    private readonly location: Location
  ) { }

  ngOnInit() {
    this.route.url.subscribe(url=>{
      this.navigationThroughRouter=!url.find(u=>u.path==='detail');
    });
    this.route.params.subscribe(params => {
      this.listeservice.getList(params['id']).subscribe(list => {
        this.list = list;
        this.isCollapsed = !list.lexicon;
        this.displayFilter = list.items.filter(i => i.tags.length > 0).length > 0;
        const tags = [];
        [0, 1, 2, 3, 4, 5, 6, 7].forEach(tidx => {
          const catTags = [].concat([], list.items.map(l => l.tags[tidx])).filter(Boolean).filter(function (elem, index, self) {
            return index === self.indexOf(elem);
          });
          if (catTags.length > 0) {
            tags.push(catTags.map(t => new Filter(t)));
          }
        });
        this.filters = tags;
        this.activities = this.activityService.getAvailableActivities(list);
      });
    });
  }
  clone() {
    const newList = this.list;
    if (this.list.mine) {
      newList.title = `Copie de ${this.list.title}`;
    }
    this.listeservice.cloneList(newList).subscribe(res => {
      this.router.navigate(['/listes/personnelles/',res.id, 'edition']);
      this.toastService.success('Liste copiée');
    });
  }
  createListWithSelectedItems() {
    if (this.list.items.filter(i => i.selected).length > 0) {
      const newList = this.list;
      newList.items = newList.items.filter(i => i.selected);
      this.listeservice.cloneList(newList).subscribe(res => {
        this.router.navigate(['/listes/personnelles/',res.id, 'edition']);
        this.toastService.success('Liste créée');
      });
    }
  }
  addSelectedItemsToList() {
    const modalRef = this.modalService.open(ItemlistSelectorComponent);
    modalRef.result.then(
      (result: ItemList) => {
        this.list.items.filter(i => i.selected).forEach(i => {
          i.id = -1;
          result.items.push(i);
        });
        this.listeservice.saveList(result).subscribe(res => {
          this.unSelectAll();
          this.toastService.success('La sélection a été ajouté à la liste ' + result.title);
        });
      },
      () => { }
    );
  }

  sendbymail() {
    const subject = `Partage de liste iClasse - ${this.list.title}`;
    const body = `Voici la liste iClasse ${this.list.title} accessible à l\'url : ${document.location.href} .`;
    window.location.href = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  }

  back() {
    if(this.navigationThroughRouter){
      this.router.navigate(['../'], { relativeTo:this.route });
    }else{
      this.location.back();
    }
  }

  selectAll() {
    this.list.items.filter(i => !i.filtered).forEach(item => {
      item.selected = true;
    });
  }
  unSelectAll() {
    this.list.items.forEach((item, index) => {
      item.selected = false;
    });
  }
  anySelected(): boolean {
    return this.list.items.filter(i => !i.filtered).filter(i => i.selected).length > 0;
  }
  allSelected(): boolean {
    return this.list.items.filter(i => !i.filtered).filter(i => i.selected).length === this.list.items.filter(i => !i.filtered).length;
  }
  noneSelected(): boolean {
    return this.list.items.filter(i => !i.filtered).filter(i => i.selected).length === 0;
  }
  getDocumentLocation() {
    return document.location.href;
  }
  copied(e: any) {
    if (e.isSuccess) {
      this.toastService.info('Copié !');
    }
  }
  export() {
    const blob = new Blob(this.list.items
      .map(i => `${i.learnTxt}\t${i.hintTxt}\t${i.hintImgUrl}\t${i.tags.join('\t')}\r\n`),
    { type: 'text/plain;charset=utf-8' });
    saveAs(blob, `${this.list.title}.txt`);
  }
  anyFilterActivated(): boolean {
    return this.filters.filter(cat => cat.filter(f => f.selected).length > 0).length > 0;
  }
  handleFilters() {
    if (this.anyFilterActivated()) {
      const activatedFilter = [];
      this.filters.forEach(cat => {
        if (cat.filter(f => f.selected).length > 0) {
          activatedFilter.push(cat.filter(f => f.selected));
        }
      });
      this.list.items.forEach(i => {
        i.filtered = activatedFilter.some(cat => cat.every((f: Filter) => i.tags.indexOf(f.value) < 0));
      });
    } else {
      this.list.items.forEach(i => {
        i.filtered = false;
      });
    }
  }
  createCourse() {
    var generatedCourse = new Course();

    generatedCourse.id = 0;
    generatedCourse.title = this.list.title;
    generatedCourse.description = this.list.description;
    generatedCourse.icon = 'bi bi-map-fill';
    generatedCourse.color = 'c-grey';
    generatedCourse.mine = true;
    generatedCourse.libre = false;
    generatedCourse.rpn = false;
    generatedCourse.teacher = false;
    generatedCourse.distributed = false;
    generatedCourse.progression = 0;
    generatedCourse.ribbon = '';

    generatedCourse.items = this.activities.map(a => {
      var item = new CourseItem();
        item.id = 0;
        item.navigationUrl = `${a.route}/${this.list.id}`;
        item.title = `${a.shortLabel} - ${this.list.title}`;
        item.progressionEnabled = true;
        item.publishedOn = new Date();
        return item;

    });
    this.parcoursservice.createNew(generatedCourse).subscribe((t: Course) => {
      this.router.navigate(['/parcours/personnels', t.id]);
    });
  }
  printCards(): void {
    this.router.navigate(['/listes/impression/', this.list.id]);
  }
}

