import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Criteria, Category } from '@app/model';
import { ReferenceDataService } from '@app/core/service';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'nemo-criteria',
    templateUrl: './criteria.component.html',
    styleUrls: ['./criteria.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor]
})
export class CriteriaComponent implements OnInit {

  @Input() editable = true;
  @Input() criteria: Criteria;
  @Output() dropped = new EventEmitter<Criteria>();
  @Output() edited = new EventEmitter<Criteria>();
  public disciplines: Category[];

  constructor(private readonly referenceDataService: ReferenceDataService) { }

  ngOnInit() {
    if (this.criteria.categories && !(this.criteria.categories.length === 0) ) {
      this.disciplines = this.referenceDataService.getCategories().filter(c=>this.criteria.categories.includes(c.key)).sort((a, b) => a.ord - b.ord);
  }
    
  }

  dropCriteria() {
    this.dropped.emit(this.criteria);
  }

  editCriteria() {
    this.edited.emit(this.criteria);
  }

}
