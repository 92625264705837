import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';
import { Observable } from 'rxjs';
import { AutoEvaluationBooklet } from '@app/model';

@Injectable({
  providedIn: 'root'
})
export class AutoEvaluationService {
  private readonly url = '/auto-evaluation';

  constructor(private readonly http: HttpBaseService) { }

  public hasEvaluation(): Observable<boolean> {
    return this.http.get<boolean>(`${this.url}/has-self-evaluation`);
  }

  public getEvaluation(): Observable<AutoEvaluationBooklet[]> {
    return this.http.get<AutoEvaluationBooklet[]>(`${this.url}/`);
  }

  public commentCriteria(criteriaId: number, comment: string): Observable<boolean> {
    return this.http.put<boolean>(`${this.url}/commentaire/${criteriaId}`, {value: comment});
  }

  public evaluateCriteria(criteriaId: number, state: string): Observable<boolean> {
    return this.http.put<boolean>(`${this.url}/evaluation/${criteriaId}`, {value: state});
  }


}
