import { Injectable } from "@angular/core";
import { NgbDateAdapter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomDateAdapter extends NgbDateAdapter<string> {
	

	fromModel(date: string | null): NgbDateStruct | null {
    var d = new Date(Date.parse(date));
		if (date) {
			return {
				day:    d.getDate(),
				month:  d.getMonth() + 1,
				year:   d.getFullYear(),
			};
		}
		return null;
	}

	toModel(date: NgbDateStruct | null): string | null {
		return date ? new Date(date.year,date.month-1,date.day).toDateString():null;
	}
}