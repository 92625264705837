import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
    selector: 'nemo-ribbon',
    templateUrl: './ribbon.component.html',
    styleUrls: ['./ribbon.component.scss'],
    standalone: true,
    imports: [NgClass]
})
export class RibbonComponent {

  @Input() text = '';
  @Input() left = true;

  constructor() { }

}
