<div *ngIf="!edit">
  <span *ngIf="range.start === null && range.end === null">Pas de période définie</span>
  <span *ngIf="range.start !== null">D<span *ngIf="range.end === null">epuis le</span><span *ngIf="range.end !== null">u</span> {{range.start | date:'dd.MM.yy'}} </span>
  <span *ngIf="range.end !== null"><span *ngIf="range.start === null">Jusqu'</span>au {{range.end | date:'dd.MM.yy'}}</span>
</div>
<div *ngIf="edit">
  <button type="button" class="btn btn-outline-secondary" [ngClass]="{'btn-sm':small}" (click)="editRange()">
    <span class="bi bi-calendar-date-fill"></span> 
    <span *ngIf="range.start === null && range.end === null">Pas de période définie</span>
    <span *ngIf="range.start !== null">D<span *ngIf="range.end === null">epuis le</span><span *ngIf="range.end !== null">u</span> {{range.start | date:'dd.MM.yy'}} </span>
    <span *ngIf="range.end !== null"><span *ngIf="range.start === null">Jusqu'</span>au {{range.end | date:'dd.MM.yy'}}</span>
  </button>
</div>
