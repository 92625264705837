<div *ngIf="!selectedList && lists">
  <h3>Choix de la liste</h3>
  <div class="input-group mb-3" *ngIf="lists.length>10">
    <input type="text" class="form-control" placeholder="Rechercher" aria-label="Rechercher"
      aria-describedby="button-addon2" (keyup)="filterLists($event)" [(ngModel)]="filter" maxlength="255">
    <button class="btn btn-outline-secondary" type="button" id="button-addon2" (click)="clearFilterLists()"><span
        class="bi bi-x-lg"></span></button>
  </div>
  <ul class="list-group list-group-flush" *ngIf="lists">
    <a [routerLink]="[]" *ngFor="let list of filteredLists" class="list-group-item list-group-item-action"
      [ngClass]="{active: selectedList === list}" (click)="selectList(list)">
      <span class="{{list.icon}}"></span> {{list.title}}
    </a>
  </ul>
</div>
<div *ngIf="activities && selectedList">
  <h3>Choix de l'activité</h3>
  <ul class="list-group list-group-flush" >
    <a [routerLink]="[]" *ngFor="let activity of activities" class="list-group-item list-group-item-action"
      [ngClass]="{active: selectedActivity === activity}" (click)="selectActivity(activity)">
      <span class="{{activity.icon}}"></span> {{activity.shortLabel}}
    </a>
  </ul>
</div>
