import { Component, OnInit } from '@angular/core';
import { ReferenceDataService, PreferencesService, ParcoursService } from '@app/core/service';
import { NgEventBus } from 'ng-event-bus';
import { NgIf } from '@angular/common';
import { RouterLinkActive, RouterLink, RouterOutlet } from '@angular/router';
import { BasepageComponent } from '../../../shared/components/basepage/basepage.component';

@Component({
    selector: 'nemo-parcours',
    templateUrl: './parcours.component.html',
    styleUrls: ['./parcours.component.scss'],
    standalone: true,
    imports: [BasepageComponent, RouterLinkActive, RouterLink, NgIf, RouterOutlet]
})
export class ParcoursComponent implements OnInit {

  newrpn = 0;
  newdist = 0;
  newTeacher = 0;
  isCartEmpty = true;
  constructor(private readonly preferencesService: PreferencesService,
    private readonly referenceDataService: ReferenceDataService,
    private readonly parcoursService: ParcoursService,
    private readonly messageBusService: NgEventBus
    ) {
      this.messageBusService.on('display-cart').subscribe(e =>{
        this.isCartEmpty=false;
      });
  }
  ngOnInit() {
    this.newdist = this.referenceDataService.getNewState().newDistributedCourses;
    this.newrpn = this.referenceDataService.getNewState().newRpnCourses;
    this.newTeacher = this.referenceDataService.getNewState().newTeacherCourses;
    this.isCartEmpty = this.parcoursService.getCartItems().length <= 0;
  }
  isStudent(): boolean {
    return this.preferencesService.isStudent();
  }
  isTeacher(): boolean {
    return this.preferencesService.isTeacher();
  }
}
