<nemo-basepage [title]="'Notifications'">
  <div page-body>
    <div *ngIf="notifications && notifications.length > 0">
      <ul class="list-unstyled">
        <li *ngFor="let n of notifications">
          <ngb-alert [type]="'secondary'" (closed)="dismiss(n)">
            <h4 class="alert-heading">{{n.title}}</h4>
            <p>{{n.content}}</p>
            <a [routerLink]="n.url" (click)="dismiss(n)" *ngIf="n.url" class="stretched-link"></a>
            <hr>
            <p class="mb-0 text-end"><small>{{n.date | amTimeAgo}}</small></p>
          </ngb-alert>
        </li>
      </ul>
      <button type="button" class="btn btn-outline-secondary" (click)="dismissAll()"><em class="bi bi-trash-fill"></em> Tout effacer</button>
    </div>
    <div class="alert alert-secondary" role="alert" *ngIf="notifications && notifications.length === 0">
      <h4 class="alert-heading">Dommage, c'est encore vide par ici !</h4>
      <p>
        Il n'y a pour l'instant aucune notification disponible. Repassez plus tard.
      </p>
    </div>
  </div>
</nemo-basepage>
