<div *ngIf="mediaAction && mediaAction.video && otlLoaded" class="ratio ratio-16x9">
  <iframe  title="media" [src]="mediaUrlOtl | safe" allowfullscreen></iframe>
</div>

<img *ngIf="mediaAction && mediaAction.image" class="img-fluid" alt="image" [src]="mediaAction.url" />
<div *ngIf="mediaAction && mediaAction.sound">
  <h1 class="display-1"><em class="bi bi-volume-up-fill"></em></h1>
  <audio controls autoplay [src]="mediaAction.url">
    Votre navigateur ne supporte pas les éléments audio de manière native.
  </audio>
</div>

