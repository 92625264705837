import { Injectable } from '@angular/core';
import { FeatureFlagsSettings } from '@app/model';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private appConfig: any;
  
  
  constructor(private http: HttpClient) { }

  async loadAppConfig() {
    this.appConfig = await lastValueFrom(this.http.get('/settings.json'));
  }


  get mediaMaxSizeInMo(): number{
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }
    return this.appConfig.mediaMaxSizeInMo;
  }

  get feature_flags(): FeatureFlagsSettings{
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }
    return this.appConfig.feature_flags;
  }

  get environment(): string{
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }
    return this.appConfig.environment;
  }

  get mediaPath(): string {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }
    return this.appConfig.mediaPath;
  }

  get mediaServer(): string{
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }
    return this.appConfig.mediaServer;
  }
}
