<nemo-basepage [title]="'Folio d\'élève'">
  <div page-body>
    <div class="row">
      <div class="col-md-12">
        <div class="row  d-print-none">
          <div class="col-md-4 mb-1" *ngIf="student && booklet">
            <nemo-card [big]="true" [author]="booklet.author" [title]="student.displayName" [icon]="booklet.icon" [color]="booklet.color" [subtitle]="booklet.title"></nemo-card>
            <div class="my-2 d-grid gap-2">
              <button type="submit" class="btn btn-primary" tooltip="Sauvegarder" tooltipPlacement="top"
                [disabled]="!dirty || saving" (click)="saveObservations()">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="saving"></span>
                <span class="bi bi-save-fill" *ngIf="!saving"></span> Sauvegarder</button>
              <button type="button" class="btn btn-outline-secondary" tooltip="Rapport"
                tooltipPlacement="top" [disabled]="dirty" (click)="generateReport()"><span class="bi bi-file-pdf-fill"></span> Rapport</button>
            </div>
            <div class="d-flex my-2">
              <div class="flex-grow-1 d-grid gap-2">
                <button type="button" class="btn btn-outline-secondary" tooltip="Retour"
                  tooltipPlacement="top" [disabled]="dirty"
                  [routerLink]="['../../']"><span class="bi bi-arrow-left"></span> Retour</button>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-6 d-none d-lg-block">
                <div class="card text-center">
                  <div class="card-body">
                    <h6 class="card-subtitle mb-2 text-muted">Progression par évaluation</h6>
                    <canvas #lineChart>{{ chart }}</canvas>
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-lg-6">
                <div class="card text-center">
                  <div class="card-body">
                    <h6 class="card-subtitle mb-2 text-muted">Progression générale</h6>
                    <nemo-stacked-progress-bar *ngIf="observations" [progression]="progression" [height]="15"></nemo-stacked-progress-bar>
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-lg-6">
                <div class="card text-center mt-2"  *ngIf="disciplines.length>0" >
                  <div class="card-body">
                    <nemo-tags-toggler [title]="'Disciplines'" [tags]="[disciplines]" (changed)="applyCriteriaFiltering()"></nemo-tags-toggler>
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-lg-6">
                <div class="card text-center mt-2" *ngIf="annees.length>0">
                  <div class="card-body">
                    <nemo-tags-toggler [title]="'Années'" [tags]="[annees]" (changed)="applyCriteriaFiltering()"></nemo-tags-toggler>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="card text-center mt-2"  *ngIf="axesThematiques.length>0">
                  <div class="card-body">
                    <nemo-tags-toggler [title]="'Axes thématiques'" [tags]="[axesThematiques]" (changed)="applyCriteriaFiltering()"></nemo-tags-toggler>
                  </div>
                </div>
                <div class="card text-center mt-2" *ngIf="filters.length>0">
                  <div class="card-body">
                    <nemo-tags-toggler [title]="'Catégories'" [tags]="filters" (changed)="applyCriteriaFiltering()"></nemo-tags-toggler>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <ul ngbNav #nav="ngbNav" class="nav-tabs nav-fill" [(activeId)]="activeId">
              <li [ngbNavItem]="1">
                <a ngbNavLink>Observations</a>
                <ng-template ngbNavContent>
                  <nemo-booklet-observation-actions-toolbar (markAll)="markAll($event)" (update)="updateAllEvaluation($event)" (comment)="commentAllObservations($event)" (eraseAllComments)="eraseAllComments()" (eraseAllEvaluations)="eraseAllEvaluations()" (eraseAllMarks)="eraseAllMarks()"  [scale]="scale" *ngIf="scale"></nemo-booklet-observation-actions-toolbar>
                  <h1 class="d-none d-print-inline" *ngIf="student && booklet">
                    {{booklet.title}}
                    <small class="text-muted d-block">{{student.displayName}}</small>
                  </h1>
                  <ul class="list-group" *ngIf="observations">
                    <li class="list-group-item" *ngFor="let obs of filteredObservations">
                      <nemo-observation [labelledObservation]="obs"  (changed)="updateObservation($event)" [scale]="scale" [op]="booklet.professionalOption"  *ngIf="scale" [displayAutoEvaluationIcon]="displayAutoEvaluationIcon"> </nemo-observation>
                    </li>
                  </ul>
                </ng-template>
              </li>
            </ul>
            <div [ngbNavOutlet]="nav"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nemo-basepage>
