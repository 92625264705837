import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { UserName, UserProfile } from '@app/model';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  
  
  currentUserProfile: UserProfile = {
    isAuthenticated : false,
    claims :[],
    nameClaimType:"name",
    roleClaimType:"role"
  };
  currentUserLoaded = false;
  currentUserLoading = false;
  
  constructor(
    private readonly http: HttpClient
  ) { }

  getCurrentUserProfile():UserProfile {
    return this.currentUserProfile;
  }
  async loadUser() {
    this.currentUserProfile = await lastValueFrom(this.http.get<UserProfile>(`/api/v1/user`));
  }
  public getUsername(): UserName {
    const result = new UserName();
    if (this.currentUserProfile) {
      if (this.currentUserProfile.claims.find(c=>c.type==='DisplayName')) {
        result.displayName =this.currentUserProfile.claims.find(c=>c.type==='DisplayName').value;
      }
      if (this.currentUserProfile.claims.find(c=>c.type==='idp')) {
        result.domain =this.currentUserProfile.claims.find(c=>c.type==='idp').value;
      }
    }
    return result;
  }

  login() {
    if(!this.currentUserProfile.isAuthenticated)
    {
      window.open('/api/v1/account/login','_self');
    }
  }
}
