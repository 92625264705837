<nemo-basepage>
  <div page-body>
    <div class="col-md-4 offset-md-4 text-center">
      <div class="card m-3 p-3">
        <h1>
          <em class="bi bi-shield-lock"></em>
        </h1>
        <p class="lead">
          Vous n'avez pas les droits nécessaires pour accéder à cette ressource...
        </p>
        <a [routerLink]="['/']" *ngIf="isLogged()" class="btn btn-outline-secondary stretched-link">Aller à l'accueil <span class="bi bi-arrow-right"></span></a>
        <a *ngIf="!isLogged()" class="btn btn-primary" role="button"  href="api/v1/account/login"><span class="bi bi-box-arrow-in-right"></span> Se connecter</a>
      </div>
    </div>
  </div>
</nemo-basepage>
