<nemo-basepage [title]="'Listes'">
  <div page-body>
    <ul class="nav nav-tabs mb-3">
      <li class="nav-item">
        <a class="nav-link" [routerLinkActive]="'active'" [routerLink]="['./personnelles']">Personnelles</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLinkActive]="'active'" [routerLink]="['./lexique']">Lexique
          <span class="bi bi-circle-fill" *ngIf="newlexicon > 0"><span class="visually-hidden">De nouveaux lexiques disponibles</span></span>

        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLinkActive]="'active'" [routerLink]="['./outils']">Outils
        </a>
      </li>
    </ul>
    <router-outlet></router-outlet>
  </div>
</nemo-basepage>
