import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TeacherService } from '@app/core/service';
import { Teacher } from '@app/model';
import { NgbHighlight, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, debounceTime, distinctUntilChanged, tap, switchMap, catchError, of } from 'rxjs';

@Component({
  selector: 'nemo-teacher-search-input',
  standalone: true,
  imports: [CommonModule, NgbTypeahead,FormsModule, NgbHighlight],
  templateUrl: './teacher-search-input.component.html',
  styleUrl: './teacher-search-input.component.scss'
})
export class TeacherSearchInputComponent {
  model: any;
  searching = false;
  searchFailed = false;
  @Output() addTeacher = new EventEmitter<Teacher>(true);

  constructor(private readonly teacherService: TeacherService) {

  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => term.length < 3 ? [] :
        this.teacherService.search(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }))
      ),
      tap(() => this.searching = false)
    )
    onSelect($event, input) {
      $event.preventDefault();
      this.addTeacher.emit($event.item);
      input.value = '';
    }

}
