import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NgbActiveModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ParcoursRessourcesRechercheComponent } from '../parcours-ressources-recherche/parcours-ressources-recherche.component';
import { CourseItemSearchViewModel } from '@app/model';

@Component({
  selector: 'nemo-parcours-ressources-recherche-modal',
  standalone: true,
  imports: [CommonModule,NgbModalModule, ParcoursRessourcesRechercheComponent],
  templateUrl: './parcours-ressources-recherche-modal.component.html',
  styleUrl: './parcours-ressources-recherche-modal.component.scss'
})
export class ParcoursRessourcesRechercheModalComponent {
  /**
   *
   */
  constructor(public activeModal: NgbActiveModal) {
    
  }

  add(courseItemSearchViewModel: CourseItemSearchViewModel){
    this.activeModal.close(courseItemSearchViewModel);
  }

}
