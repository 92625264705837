import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { BasepageComponent } from '../../../shared/components/basepage/basepage.component';

@Component({
    selector: 'nemo-apropos',
    templateUrl: './apropos.component.html',
    styleUrls: ['./apropos.component.scss'],
    standalone: true,
    imports: [BasepageComponent, RouterLink]
})
export class AproposComponent {

  constructor() { }

}
