<nemo-basepage *ngIf="list" [title]="'Parcours'">
  <div page-body>
    <div class="row">
      <div class="col-md-12">
        <p class="lead" *ngIf="rpn">Ce parcours est prêt à l'emploi, utilisez-le directement ou bien copiez-le pour le
          modifier selon vos besoins.</p>
        <p class="lead" *ngIf="libre">Ce parcours est libre. Attention toutefois, votre progression ne sera pas
          sauvegardée.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-lg-4">
            <div class="sticked-top-margin">
              <nemo-card [big]="true" [title]="list.title" [progression]="list.progression"
                [author]="list.author"
                [subtitle]="list.subtitle" [description]="list.description" [text]="list.duration?'Durée env. '+list.duration+' min.':null"
                [ribbon]="list.ribbon" [icon]="list.icon" [tags]="list.categories" 
                [perCodes]="list.perObjectiveCodes" 
                [color]="list.color"></nemo-card>
              <div class="my-2 d-grid gap-2">
                <button type="button" *ngIf="!list.mine && !libre && !isOnlyStudent()" class="btn btn-outline-primary"
                  tooltip="Copier le parcours chez moi" tooltipPlacement="top" (click)="clone()">Copier le parcours
                  chez moi</button>
                <button type="button" *ngIf="list.mine" class="btn btn-outline-primary" tooltip="Modifier"
                  tooltipPlacement="top" [routerLink]="['./edition']"><span
                    class="bi bi-pencil-square" aria-hidden="true"></span> Modifier</button>
                <button type="button" *ngIf="isTeacher()" class="btn btn-outline-secondary"
                  tooltip="Distribuer" tooltipPlacement="top"
                  [routerLink]="['./distribution']">Distribuer</button>
              </div>
              <div class="d-flex my-2">
                <div class="flex-grow-1 d-grid gap-2">
                  <button type="button" class="btn btn-outline-secondary" tooltip="Retour"
                    tooltipPlacement="top" (click)="back()"><span class="bi bi-arrow-left"></span> Retour</button>
                </div>
                <div class="ps-2">
                  <nemo-action-menu>
                    <button type="button" [disabled]="list.progression === 0"
                      [ngClass]="{'disabled':list.progression === 0}" class="dropdown-item"
                      tooltip="Effacer ma progression" tooltipPlacement="top" (click)="clearProgression()">Effacer ma
                      progression</button>
                    <button type="button" class="dropdown-item" tooltip="Effacer ma progression"
                    tooltipPlacement="top" (click)="addToCart()">Ajouter des ressources dans le panier</button>
                    <button type="button" class="dropdown-item" tooltip="Copier le lien" tooltipPlacement="top"
                      ngxClipboard [cbContent]="getDocumentLocation()" (cbOnSuccess)="copied($event)">
                      Copier le lien</button>
                    <button type="button" class="dropdown-item" *ngIf="!list.mine && !libre && isOnlyStudent()" 
                  tooltip="Copier le parcours chez moi" tooltipPlacement="top" (click)="clone()">Copier le parcours
                  chez moi</button>
                    <button type="button" class="dropdown-item" tooltip="Envoyer le lien par courriel"
                      tooltipPlacement="top" (click)="sendbymail()">Envoyer le lien par courriel</button>
                    <button type="button" *ngIf="list.mine" class="dropdown-item" tooltip="Dupliquer"
                      tooltipPlacement="top" (click)="clone()">Dupliquer</button>
                  </nemo-action-menu>
                </div>
              </div>
              <div class="card" *ngIf="!isProposed && isPersonnal && isTeacher">
                <div class="card-body">
                  <h5>Mutualiser mon parcours</h5>
                  <p>Vous pouvez proposer la mutualisation de votre parcours.</p>
                  <p>Nous vous en rermercions par avance !</p>
                  <div class="flex-grow-1 d-grid gap-2">
                    <a type="button" *ngIf="list.mine" class="btn btn-outline-secondary" tooltip="Proposer le parcours au SEO"
                      tooltipPlacement="top" (click)="proposerauSeo(content);">Mutualiser mon parcours
                    </a>
                  </div>
                </div>
              </div>
              <div class="card" *ngIf="isProposed && isPersonnal">
                <div class="card-body">
                  <p>Vous avez répondu favorablement à la proposition de mutualisation de votre parcours iClasse et nous vous en remercions.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <nemo-parcours-item-start *ngFor="let item of list.items; let i = index" [item]="item"
              (started)="startItem($event)" [enableAddToCart]="enableAddToCart"></nemo-parcours-item-start>
            <div class="d-flex my-2" *ngIf="isTeacher() && list.planEtudeRomandIds && list.planEtudeRomandIds.length > 0">
              <div class="flex-grow-1 d-grid gap-2 mb-2">
                <button type="button" class="btn btn-link" tooltip="Références au plan d'étude romand"
                      tooltipPlacement="top" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">Progressions et attentes du PER</button>
              </div>
            </div>
            <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
              <div class="mb-2" *ngIf="isTeacher() && list.planEtudeRomandIds && list.planEtudeRomandIds.length > 0">
                <div class="row row-cols-1 row-cols-md-3 row-lg-4 g-4">
                  <div class="col" *ngFor="let p of list.planEtudeRomandIds">
                    <div class="card">
                      <div class="card-body">
                        <nemo-plan-etude-romand-display  [planEtudeRomandId]="p"></nemo-plan-etude-romand-display>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class=" card" *ngIf="ended && !list.mine">
              <div class="card-body">
                <h5 class="card-title">Vous avez terminé ce parcours, donnez votre avis.</h5>
                <ng-template #t let-fill="fill">
                  <span class="star" [class.full]="fill === 100">
                    <span class="half" [style.width.%]="fill">&hearts;</span>&hearts;
                  </span>
                </ng-template>
                <ngb-rating [(rate)]="currentRate" [starTemplate]="t"></ngb-rating>
                <div>
                  <label class="form-label" for="title" class="visually-hidden"></label>
                  <input type="text" name="title" id="title" class="form-control form-control" placeholder="Avis"
                    [(ngModel)]="comment" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
                    maxlength="499" />
                  <button type="button" class="btn btn-outline-secondary mt-2" tooltip="Envoyer" tooltipPlacement="top"
                    (click)="rate()"><span class="bi bi-send-fill"></span> Envoyer</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nemo-basepage>
<ng-template #content let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Mutualiser mon parcours</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
	</div>
	<div class="modal-body">
    <p>Voici comment votre parcours personnel sera traité :</p>
    <ul>
      <li>Le parcours est transmis à un-e chargé-e de mission du SEO en lien avec le domaine disciplinaire ;</li>
      <li>Le parcours est vérifié et mis en conformité avec les standards RPN (couleur, icône, vidéo dans la bibliothèque des médias RPN, etc.) ;</li>
      <li>Une fois ce travail effectué, vous serez contacté-e pour donner votre accord final en vue de la publication de votre parcours personnel adapté dans les parcours RPN. </li>
    </ul>
    <p>Nous vous remercions par avance de votre patience et de votre compréhension.</p>
    <p>Nous restons à votre disposition pour tout complément souhaité et vous adressons, Madame, Monsieur, nos meilleures salutations.</p>
	</div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Annuler</button>
    <button type="button" class="btn btn-primary" (click)="modal.close()">Mutualiser</button>
  </div>
</ng-template>