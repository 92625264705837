<nemo-basepage [title]="'Validation de l\'adresse de courriel'">
    <div  page-body *ngIf="valid">
        <div class="row py-2">
            <div class="col-md-12">
                <div class="alert alert-success" role="alert">
                    <h4 class="alert-heading">Adresse de courriel validée merci !</h4>
                    <p>
                        Vous avez bien validé votre adresse de courriel, merci.
                    </p>
                    <p>
                        Retour à l'<a [routerLink]="['/accueil']">accueil</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div  page-body *ngIf="notValid">
        <div class="row py-2">
            <div class="col-md-12">
                <div class="alert alert-danger" role="alert">
                    <h4 class="alert-heading">L'adresse de courriel n'a pas pu être validée !</h4>
                    <p>
                        Retour à l'<a [routerLink]="['/accueil']">accueil</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</nemo-basepage>