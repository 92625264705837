export class CourseRating {

  comment: string;
  rating: number;
  courseId: number;


  constructor(model?: CourseRating) {
    if (model) {
      this.comment = model.comment;
      this.rating = model.rating;
      this.courseId = model.courseId;
    }
  }
}
