<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <button type="button" class="btn-close" aria-label="btn-close" (click)="activeModal.dismiss()">

  </button>
</div>
<div class="modal-body">
  <nemo-file-input (selectedFile)="selectFile($event)"></nemo-file-input>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss()">Annuler</button>
  <button type="button" class="btn btn-primary" (click)="import()">Importer</button>
</div>