import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BookletService } from '@app/core/service';
import { EvaluationScale } from '@app/model';
import { EvaluationComponent } from '../evaluation/evaluation.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'nemo-auto-evaluation-modal',
    templateUrl: './auto-evaluation-modal.component.html',
    styleUrls: ['./auto-evaluation-modal.component.scss'],
    standalone: true,
    imports: [NgIf, EvaluationComponent]
})
export class AutoEvaluationModalComponent implements OnInit {
  @Input() selfComment: string;
  @Input() selfState: string;
  @Input() op: boolean;
  scale: EvaluationScale;
  constructor(public activeModal: NgbActiveModal, public bookletService: BookletService) {

  }

  ngOnInit() {
    this.scale = this.bookletService.getSelfEvaluationScale(this.op);
  }

}
