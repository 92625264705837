import { Component, Input, OnInit } from '@angular/core';
import { PreferencesService } from '@app/core/service';
import { MaskEnum } from '@app/model';
import { SpeakerComponent } from '../../../../shared/components/speaker/speaker.component';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'nemo-item-text',
    templateUrl: './item-text.component.html',
    styleUrls: ['./item-text.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf, SpeakerComponent]
})
export class ItemTextComponent implements OnInit {

  @Input() text: string;
  @Input() languageCode = 'fr';
  @Input() enableSpeech = true;
  @Input() displayText = true;
  @Input() mask = MaskEnum.None;
  public size = 'middle';

  constructor(public readonly preferencesService: PreferencesService) { }

  ngOnInit() {
    this.size = this.preferencesService.getPreferences().size;
  }

}
