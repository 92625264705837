<div class="modal-header">
    <h4 class="modal-title">Importer des ressources depuis le panier</h4>
    <button type="button" class="btn-close" aria-label="btn-close" (click)="activeModal.dismiss()">

    </button>
</div>
<div class="modal-body">
  <nemo-parcours-item-start *ngFor="let item of items; let i = index" [item]="item" (started)="addItem($event)" [displayProgression]="false"></nemo-parcours-item-start>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss()">Annuler</button>
</div>
