import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReferenceDataService } from '@app/core/service';
import { Logo, Logos, SelectableValueDescription } from '@app/model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RouterLink } from '@angular/router';
import { NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'nemo-logo-selector',
    templateUrl: './logo-selector.component.html',
    styleUrls: ['./logo-selector.component.scss'],
    standalone: true,
    imports: [ NgFor, RouterLink, NgClass]
})
export class LogoSelectorComponent implements OnInit {

  @Input() color: string;
  @Input() icon: string;

  @Output() logo = new EventEmitter<Logo>();

  public logos: Logos;
  public selectedLogo: Logo = {
    color: '',
    icon: ''
  };

  constructor(private readonly refDataService: ReferenceDataService,
    public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    this.logos = this.refDataService.getLogo();

    this.selectedLogo.icon = this.icon;
    this.selectedLogo.color = this.color;

    this.logos.icons.forEach(c => c.selected = this.icon === c.value);
    this.logos.colors.forEach(c => c.selected = this.color === c.value);
  }

  selectIcon(selectedIcon: SelectableValueDescription) {
    this.icon = selectedIcon.value;
    this.selectedLogo.icon = selectedIcon.value;
    this.logos.icons.forEach(c => c.selected = selectedIcon.value === c.value);
  }

  selectColor(selectedColor: SelectableValueDescription) {
    this.color = selectedColor.value;
    this.selectedLogo.color = selectedColor.value;
    this.logos.colors.forEach(c => c.selected = selectedColor.value === c.value);
  }

}
