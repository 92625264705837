import { Component, Input, OnInit } from '@angular/core';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'nemo-media-preview',
    templateUrl: './media-preview.component.html',
    styleUrls: ['./media-preview.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass],
})
export class MediaPreviewComponent implements OnInit {

  @Input() url: string;
  @Input() displayImage = true;
  @Input() fluid = false;
  @Input() thumbnail = false;
  @Input() cardTop = false;
  @Input() profile = false;

  public defaultUrl = '/assets/no-img.png';

  constructor() {}

  ngOnInit() {
    if (this.profile) {
      this.defaultUrl = '/assets/no-profile.png';
    }
  }

  handleBrokenImgUrl(e: any) {

    this.displayImage = false;
  }
}
