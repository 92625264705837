import { HttpResponseBase, provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, CSP_NONCE, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { InMemoryScrollingOptions, PreloadAllModules, provideRouter, withDebugTracing, withEnabledBlockingInitialNavigation, withInMemoryScrolling, withPreloading } from '@angular/router';
import { secureApiInterceptor } from './secure-api.interceptor';
import { appRoutes } from './app.routes';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { NgbModule, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgEventBus } from 'ng-event-bus';
import { QuillModule } from 'ngx-quill';
import { ToastrModule } from 'ngx-toastr';
import { CoreModule } from './core/core.module';
import { CustomDateAdapter } from './core/formatters/custom-date-adapter';
import { CustomDateParserFormatter } from './core/formatters/custom-date-parser-formatter';
import { SharedModule } from './shared/shared.module';
import { SettingsService } from './core/service/settings.service';
import { CommonModule } from '@angular/common';
import { AuthenticationService } from './core/service/authentication.service';
import { HttpBaseService } from './core/service';
import Quill from 'quill';

const nonce = (
  document.querySelector('meta[name="CSP_NONCE"]') as HTMLMetaElement
)?.content;

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'disabled',

};
export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      appRoutes,
      withEnabledBlockingInitialNavigation(),
      //withPreloading(PreloadAllModules),
      //withDebugTracing(),
      withInMemoryScrolling(scrollConfig)
    ),
    provideHttpClient(withInterceptors([secureApiInterceptor])),
    {
      provide: CSP_NONCE,
      useValue: nonce,
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [SettingsService],
      useFactory: (settingsService: SettingsService) => {
        return () => {
          //Make sure to return a promise!
          return settingsService.loadAppConfig();
        };
      }
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AuthenticationService,SettingsService,HttpBaseService],
      useFactory: (authService: AuthenticationService) => {
        return () => {
          //Make sure to return a promise!
          return authService.loadUser();
        };
      }
    },
    importProvidersFrom(
      BrowserModule,
      CommonModule,
      ToastrModule.forRoot({
        progressBar: false,
        maxOpened: 2,
        positionClass: 'toast-top-right'
      }),
      CoreModule,
      SharedModule,
      FormsModule,
      NgbModule,
      QuillModule.forRoot({
        customModules: [{
          implementation: Quill.import('core/module'),
          path: "/quill.js"
        }],
        // strict:true,
        placeholder: 'Rédigez votre texte...',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'color': [] }, { 'background': [] }] // dropdown with defaults from theme
          ]
        }
      })
    ),
    { provide: LOCALE_ID, useValue: 'fr-CH' },
    { provide: NgbDateAdapter, useClass: CustomDateAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    provideAnimations(),
    NgEventBus
  ],
};
