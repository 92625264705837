<nemo-basepage [title]="'Préférences'">
  <div page-body *ngIf="userForm">
    <div class="row">
      <div class="col-md-4 col-lg-3">
        <div class="card text-center">
          <div *ngIf="canUpdateProfileImage">
            <nemo-media-preview [url]="preferences.profileImageUrl" [displayImage]="true" [cardTop]="true"
              [profile]="true" [fontSize]="'10rem'" (click)="changeProfileImage()"></nemo-media-preview>
            <a [routerLink]="[]" class="stretched-link" (click)="changeProfileImage()">changer l'image</a>
          </div>
          <div *ngIf="!canUpdateProfileImage">
            <nemo-media-preview [displayImage]="true" [cardTop]="true" [profile]="true" [fontSize]="'10rem'">
            </nemo-media-preview>
          </div>
          <div class="card-body">
            <h5 class="card-title" *ngIf="userName">{{ userName.displayName }}</h5>
            <p *ngIf="userName && userName.domain"  class="fs-6">Identité <span class="badge rounded-pill bg-info text-dark">{{userName.domain}}</span></p>
            <p>
              <span class="badge rounded-pill category-tag me-1" *ngIf="isStudent">Élève</span>
              <span class="badge rounded-pill category-tag me-1" *ngIf="isTeacher">Enseignant·e</span>
              <span class="badge rounded-pill category-tag me-1" *ngIf="isSoutien">Enseignant·e de soutien</span>
              <span class="badge rounded-pill category-tag me-1" *ngIf="isPublisher">Publicateur</span>
              <span class="badge rounded-pill category-tag me-1" *ngIf="isAdmin">Administrateur</span>
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-8 col-lg-9">
        <form [formGroup]="userForm">
          <div class="card mb-2">
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <span class="fw-bold">Général</span>
              </li>
              <li class="list-group-item">
                <div class="d-flex flex-wrap py-1">
                  <div class="flex-grow-1 align-self-center pe-2">
                    <label class="lead form-check-label" for="toastEnabled">Recevoir des alertes à l'écran ?</label>
                  </div>
                  <div class="align-self-center">
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" role="switch" id="toastEnabled" name="toastEnabled" formControlName="toastEnabled">
                    </div>
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <div class="d-flex flex-wrap py-1">
                  <div class="flex-grow-1 align-self-center pe-2">
                    <label class="lead form-check-label" for="warnHealthCheck">Être averti pour faire une pause ?</label>
                  </div>
                  <div class="align-self-center">
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" role="switch" id="warnHealthCheck" name="warnHealthCheck" formControlName="warnHealthCheck">
                    </div>
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <div class="d-flex flex-wrap py-1">
                  <div class="flex-grow-1 align-self-center pe-2">
                    <label class="lead form-check-label" for="darkmode">Mode sombre ?</label>
                  </div>
                  <div class="align-self-center">
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" role="switch" id="darkmode" name="darkmode" formControlName="darkmode" (change)="toggleDarkMode()">
                    </div>
                  </div>
                </div>
              </li>
              <li class="list-group-item" *ngIf="preferences && preferences.email && preferences.email.length > 0">
                <div class="d-flex flex-wrap py-1">
                  <div class="flex-grow-1 pe-2">
                    <label class="lead form-check-label" for="receiveEmailFeedback">Recevoir les avis sur vos parcours sur adresse de courriel ?</label>
                  </div>
                  <div class="align-self-center">
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" role="switch" id="receiveEmailFeedback" name="receiveEmailFeedback" formControlName="receiveEmailFeedback">
                    </div>
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <div class="d-flex flex-wrap py-1">
                  <div class="flex-grow-1 align-self-center pe-2">
                    <span class="lead">Adresse de courriel</span>
                  </div>
                  <div class="align-self-center lead">
                      {{preferences.email}}
                      <button type="button" class="btn btn-sm btn-outline-secondary" *ngIf="false" (click)="changeEmail(contentEmail)">Changer d'adresse de courriel</button>
                  </div>
                </div>
              </li>
              <li class="list-group-item" *ngIf="changeMobileEnabled">
                <div class="d-flex flex-wrap py-1">
                  <div class="flex-grow-1 align-self-center pe-2">
                    <span class="lead">Numéro de mobile</span>
                  </div>
                  <div class="align-self-center">
                    <div class="form-check form-switch">
                      {{preferences.mobile}}
                      <button type="button" class="btn btn-sm btn-outline-secondary" (click)="changeMobile(contentMobile)"><em class="bi bi-pen-fill"></em></button>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div class="card  mb-2">
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <span class="fw-bold">Dans les activités</span>
              </li>
              <li class="list-group-item">
                <div class="d-flex flex-wrap py-1">
                  <span class="lead flex-grow-1 pe-2" for="customRange1">Vitesse de la synthèse vocale</span>
                  <div class="align-self-center w-50">
                    <input type="range" class="form-range" id="customRange1" min="0" max="100" step="10" id="customRange2" formControlName="speed" />
                  </div>
                </div>
              </li>
              <li class="list-group-item" *ngIf="userForm">
                <div class="d-flex flex-wrap py-1">
                  <span class="lead flex-grow-1 pe-2 align-self-center" for="hmw">Taille du texte à apprendre</span>
                  <div class="align-self-center">
                    <div class="btn-group" role="group" aria-label="Taille du texte à apprendre">
                      <input type="radio" name="size" formControlName="size" [value]="'small'" class="btn-check" id="btnradio1" autocomplete="off">
                      <label class="btn btn-outline-secondary small" for="btnradio1">Petit</label>

                      <input type="radio" name="size" formControlName="size" [value]="'middle'" class="btn-check" id="btnradio2" autocomplete="off">
                      <label class="btn btn-outline-secondary middle" for="btnradio2">Moyen</label>

                      <input type="radio" name="size" formControlName="size" [value]="'big'" class="btn-check" id="btnradio3" autocomplete="off">
                      <label class="btn btn-outline-secondary big" for="btnradio3">Grand</label>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <button type="submit" form="userForm" class="btn btn-primary" tooltip="Sauvegarder" tooltipPlacement="top"
            [disabled]="!userForm.valid || !userForm.dirty" (click)="submitForm()">
            <span class="bi bi-save-fill"></span> Sauvegarder
          </button>
        </form>
        <div class="row mt-5">
          <div class="col">
            <nemo-user-voice></nemo-user-voice>
          </div>
        </div>
      </div>
    </div>
  </div>
</nemo-basepage>
<ng-template #contentEmail let-modal>
  <form [formGroup]="changeEmailForm">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Modification de l'adresse de courriel</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body">
      <div class="input-group has-validation">
        <div class="form-floating" [ngClass]="{'is-invalid': !this.changeEmailForm.valid && !this.changeEmailForm.pristine}">
          <input type="email" class="form-control" id="floatingInput" placeholder="nom.prenom@rpn.com" formControlName="email" ngbAutofocus [ngClass]="{'is-invalid': !this.changeEmailForm.valid && !this.changeEmailForm.pristine}">
          <label for="floatingInput">Nouvelle adresse de courriel</label>
        </div>
        <div class="invalid-feedback" *ngIf="!this.changeEmailForm.valid && !this.changeEmailForm.pristine">
          Veuillez renseigner une adresse de courriel valable.
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Annuler</button>
      <button type="button" class="btn btn-primary" [disabled]="!this.changeEmailForm.valid" (click)="modal.close()">Modifier</button>
    </div>
  </form>
</ng-template>

<ng-template #contentMobile let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Modification du numero de mobile</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
	</div>
	<div class="modal-body">
		<div class="form-floating">
      <input type="tel" class="form-control" id="floatingMobile" placeholder="Mobile">
      <label for="floatingMobile">Nouveau numéro de mobile</label>
    </div>
	</div>
	<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Annuler</button>
    <button type="button" class="btn btn-primary" (click)="modal.close(comment)">Modifier</button>
	
	</div>
</ng-template>

