import { Student } from "./student";

export class Group {

  key: string;
  name: string;
  groupType: string;
  center: string;
  school: string;
  schoolCode: string;
  readOnly:boolean;
  students: Student[];

  constructor(model?: Group) {
    if (model) {
      this.key = model.key;
      this.name = model.name;
      this.groupType = model.groupType;
      this.center = model.center;
      this.school = model.school;
      this.readOnly= model.readOnly;
      this.schoolCode = model.schoolCode;
      this.students = model.students;
    }
  }
}
