<div class="modal-header">
  <h4 class="modal-title">{{!item.itemType ? 'Nouvelle r':'R'}}essource</h4>
  <button type="button" class="btn-close" aria-label="btn-close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <nemo-parcours-item-edition [item]="item" [defaultColor]="defaultColor" [defaultIcon]="defaultIcon"></nemo-parcours-item-edition>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss()">
    Annuler
  </button>
  <button type="button" class="btn btn-outline-primary" [disabled]="!item.itemType" (click)="activeModal.close(item)">
    Valider
  </button>
</div>

