
<div *ngIf="searching" class="text-center">
  <div class="spinner-grow text-secondary" role="status">
    <span class="visually-hidden">Recherche en cours...</span>
  </div>
</div>
<div *ngIf="years && !selectedYear" class="mb-2">
  <div *ngFor="let groupOfCategory of (years | orderBy: 'ord' | groupBy: 'sousGroup' | pairs)">
    <div class="list-group list-group-horizontal mb-2">
      <a *ngFor="let category of groupOfCategory[1]" [routerLink]="[]" class="list-group-item list-group-item-action text-center" (click)="selectYear(category)">
        <small>{{category.value}}</small>
      </a>
    </div>
  </div>
</div>
<div *ngIf="disciplines && !selectedDiscipline" class="mb-2">
  <div class="list-group mb-1">
    <div *ngFor="let groupOfCategory of (disciplines | orderBy: 'ord' | groupBy: 'sousGroup' | pairs)">
      <div class="list-group mb-1">
        <a *ngFor="let d of groupOfCategory[1]" [routerLink]="[]" class="list-group-item list-group-item-action per-discipline" [ngClass]="'u' + d.color" (click)="selectDiscipline(d)">
          <small>{{d.value}}</small>
        </a>
      </div>
    </div>
  </div>
</div>
<div *ngIf="axes && !selectedAxe" class="mb-2">
  <div class="list-group mb-1">
    <a *ngFor="let y of axes" [routerLink]="[]" class="list-group-item list-group-item-action" (click)="selectAxe(y)">
      <small>{{y | replace:'^\\d+ - ':''}}</small>
    </a>
  </div>
</div>
<div *ngIf="selectedYear && selectedDiscipline && selectedAxe" class="mb-2">
  <div class="input-group" *ngIf="enabledSearch">
    <input type="text" class="form-control" (keyup.enter)="search()" [(ngModel)]="term" 
      placeholder="Rechercher" aria-label="Rechercher" autocomplete="off" autocorrect="off" autocapitalize="off"
      spellcheck="false" maxlength="255" />
    <button class="btn btn-outline-secondary" (click)="clearTerm()" type="sumbit">
      <span class="bi bi-x-lg"></span>
    </button>
    <button class="btn btn-outline-secondary" (click)="search()" type="sumbit">
      <span class="bi bi-search"></span>
    </button>
  </div>
</div>

<div *ngIf="multi && matchingLearnings.length > 0">
  <div class="d-grid gap-2">
    <button class="btn btn-outline-secondary btn-sm mb-2" (click)="selectAllToggle()" *ngIf="multi">
      {{selectedPers.length === 0?'Tout sélectionner':'Tout désélectionner'}}
    </button>
  </div>
</div>
<div class="row row-cols-1 row-cols-md-3 row-lg-4 g-4" *ngIf="matchingLearnings.length > 0">
  <div class="col" *ngFor="let l of matchingLearnings">
    <div class="card"  [ngClass]=" {'border-success':(selectedPers.indexOf(l)>=0)}">
      <div class="card-body">
        <nemo-plan-etude-romand-display [planEtudeRomand]="l"></nemo-plan-etude-romand-display>
        <div class="flex-grow-1 d-grid gap-2">
          <a [routerLink]="[]" class="btn btn-outline-secondary btn-sm" (click)="select(l)" *ngIf="!multi">
            Sélectionner
          </a>
          <a [routerLink]="[]" class="btn btn-outline-secondary btn-sm" (click)="multiSelect(l)" *ngIf="multi">
            {{selectedPers.indexOf(l)>=0?'Désélectionnner':'Sélectionner'}}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
