import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { ReferenceDataService } from '@app/core/service';
import { Category } from '@app/model';
import { NgArrayPipesModule, NgObjectPipesModule } from 'ngx-pipes';
import { RouterLink } from '@angular/router';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'nemo-new-category-selector',
    templateUrl: './new-category-selector.component.html',
    styleUrls: ['./new-category-selector.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, RouterLink, NgClass, NgArrayPipesModule, NgObjectPipesModule]
})
export class NewCategorySelectorComponent implements OnInit {
  
  _categories:Category[];

  @Input() selectableCategoriesKeys: string[] = [];
  @Input() selectedCategoriesKeys: string[] = [];

  @Output() selectedCategoriesChange = new EventEmitter<string[]>();

  disciplineSelect = true;
  anneeSelect = true;
  catSelect = true;
  niveauSelect = true;
  disciplines: Category[];
  annees: Category[];
  cats: Category[];
  niveaux: Category[];

  constructor(private readonly referenceDataService: ReferenceDataService) {}
  

  get disciplinesSelected():Category[]{
    return this._categories.filter(c=>c.group==='Discipline' && this.selectedCategoriesKeys.includes(c.key));
  }

  get anneesSelected():Category[]{
    return this._categories.filter(c=>c.group==='Année' && this.selectedCategoriesKeys.includes(c.key));
  }

  get catsSelected():Category[]{
    return this._categories.filter(c=>c.group==='Catégorie' && this.selectedCategoriesKeys.includes(c.key));
  }

  get niveauxSelected():Category[]{
    return this._categories.filter(c=>c.group==='Niveau' && this.selectedCategoriesKeys.includes(c.key));
  }
  


  ngOnInit():void{
    this._categories = this.referenceDataService.getCategories().filter(c=>this.selectableCategoriesKeys.includes(c.key));
    this.disciplines = this._categories.filter(c=>c.group==='Discipline');
    this.annees = this._categories.filter(c=>c.group==='Année');
    this.cats=this._categories.filter(c=>c.group==='Catégorie');
    this.niveaux=this._categories.filter(c=>c.group==='Niveau');

    this.disciplineSelect = this.disciplinesSelected.length===0;
    this.anneeSelect =this.anneesSelected.length===0;
    this.catSelect=this.catsSelected.length===0;
    this.niveauSelect=this.niveauxSelected.length===0;
  }

  reinit():void {
    this._categories = this.referenceDataService.getCategories().filter(c=>this.selectableCategoriesKeys.includes(c.key));
    this.disciplineSelect = true;
    this.anneeSelect = true;
    this.catSelect = true;
  }

  toggle(category: any):void {
    if(!this.selectedCategoriesKeys.includes(category.key)){
      this.selectedCategoriesKeys.push(category.key)
    }else{
      this.selectedCategoriesKeys.splice(this.selectedCategoriesKeys.indexOf(category.key), 1);
    }
    this.disciplineSelect = this.disciplinesSelected.length===0;
    this.anneeSelect =this.anneesSelected.length===0;
    this.catSelect=this.catsSelected.length===0;
    this.selectedCategoriesChange.emit(this.selectedCategoriesKeys);
  }
  
  categorySelected(c: Category):boolean {
    return this.selectedCategoriesKeys.includes(c.key);
  }
}
