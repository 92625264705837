import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivityService } from '@app/core/service';
import { Subscription, timer as observableTimer } from 'rxjs';
import { NgClass } from '@angular/common';

@Component({
    selector: 'nemo-timer',
    templateUrl: './timer.component.html',
    styleUrls: ['./timer.component.scss'],
    standalone: true,
    imports: [NgClass],
})
export class TimerComponent implements OnInit, OnDestroy {
  public ticks = 0;
  public seconds = 0;
  public visible = false;
  constructor(private readonly activityService: ActivityService) {
    this.activityService.timerStart$.subscribe(() => {
      this.ticks = 0;
      this.start();
    });
    this.activityService.timerStop$.subscribe(() => {
      this.stop();
    });
  }

  private sub: Subscription;

  ngOnInit() {
    this.start();
  }
  private start() {
    const timer = observableTimer(1, 1000);
    this.sub = timer.subscribe(t => {
      this.ticks = t;
      this.seconds = this.getSeconds(this.ticks);
    });
  }
  public stop() {
    this.sub.unsubscribe();
  }
  public getSeconds(ticks: number) {
    return ticks;
  }
  public toggleVisibility() {
    this.visible = !this.visible;
  }

  ngOnDestroy() {
    this.stop();
    this.sub.unsubscribe();
  }
}
