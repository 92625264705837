import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService, StudentService } from '@app/core/service';
import { Distribution, Group, Progression, Student } from '@app/model';
import { SuppressModalComponent } from '@app/shared/components/suppress-modal/suppress-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { CanComponentDeactivate } from '@app/core/guards/CanComponentDeactivate';
import { DialogService } from '@app/core/service/dialog.service';
import { DistributionService } from '@app/core/service/distribution.service';
import { Location, NgIf, NgFor } from '@angular/common';
import { DateRangeSelectorComponent } from '@app/shared/components/date-range-selector/date-range-selector.component';
import { DateRangeComponent } from '../../../shared/components/date-range/date-range.component';
import { UserDisplayComponent } from '../../../shared/components/user-display/user-display.component';
import { StudentSearchComponent } from '../../../shared/components/student-search/student-search.component';
import { StackedProgressBarComponent } from '../../../shared/components/stacked-progress-bar/stacked-progress-bar.component';
import { BasepageComponent } from '../../../shared/components/basepage/basepage.component';

export class DistributionGroup {
  key:string;
  label:string;
  distributions: Distribution[];
}
function groupBy<K, V>(array: V[], grouper: (item: V) => K) {
  return array.reduce((store, item) => {
    var key = grouper(item)
    if (!store.has(key)) {
      store.set(key, [item])
    } else {
      store.get(key).push(item)
    }
    return store
  }, new Map<K, V[]>())
}
@Component({
    selector: 'nemo-distribution',
    templateUrl: './distribution.component.html',
    styleUrls: ['./distribution.component.scss'],
    standalone: true,
    imports: [NgIf, BasepageComponent, FormsModule, ReactiveFormsModule, StackedProgressBarComponent, StudentSearchComponent, NgFor, UserDisplayComponent, DateRangeComponent]
})
export class DistributionComponent implements OnInit, CanComponentDeactivate {

  @HostListener('window:keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    if ((event.metaKey || event.ctrlKey) && event.key === 's') {
        this.save();
        event.preventDefault();
    }
  }

  //public distributions: Distribution[];
  public userForm: FormGroup;
  public groups: Group[];
  public distributionsGroupes:DistributionGroup[] =[];
  public distributions: Distribution[];


  
  
  courseId:number;
  searching = false;
  searchFailed = false;
  progression: Progression={};
  start:Date = null;
  end:Date = null;

  constructor(public router: Router,
    private readonly route: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    private readonly toastService: ToastService,
    private readonly studentService: StudentService,
    private readonly modalService: NgbModal,
    private readonly service: DistributionService,
    private readonly dialogService: DialogService,
    private location: Location) { }
  ngOnInit() {
    this.route.params.subscribe(params => {
      this.courseId=params['id'];
      this.service.getDistribution(params['id']).subscribe(distributions => {
        this.distributionsGroupes = [...groupBy([...distributions],x => x.classeGroupeKey)].map(g=>({key: g[0],label:g[1][0].classeName??'Individuels',distributions:g[1]})); 
        this.progression = this.computeProgression();
        this.studentService.getGroups().subscribe(g => {
          this.groups = g;
          this.initForm();
        });
      });
    });
  }
  
  public trackItem (index: number, item: Distribution) {
    return item.cloeeWorldId;
  }
  canDeactivate(): Observable<boolean> | boolean {
    // Allow synchronous navigation (`true`) if no crisis or the crisis is unchanged
    if (!this.userForm.dirty) {
      return true;
    }
    // Otherwise ask the user with the dialog service and return its
    // observable which resolves to true or false when the user decides
    return this.dialogService.confirm('Vous n\'avez pas sauvegardé les modifications, souhaitez-vous continuer sans sauvegarder ?');
  }
  
  initForm() {
    this.userForm = this.formBuilder.group({
      preserveFromDeletion: [false]
    });
  }
  dropGroup(group:DistributionGroup){
    this.distributionsGroupes.splice(this.distributionsGroupes.indexOf(group), 1);
    this.userForm.markAsDirty();
  }
  dropDistributions() {
    this.distributionsGroupes = new Array<DistributionGroup>();
    this.userForm.markAsDirty();
  }

  dropDistribution(distribution: Distribution) {
    this.distributionsGroupes.forEach(g=>{
      if(!g.distributions.every(d=>d.cloeeWorldId!=distribution.cloeeWorldId)) {
        g.distributions.splice(g.distributions.indexOf(distribution), 1);
        this.userForm.markAsDirty();
      }
    });
    
  }

  addStudents(group: Group) {
    if(this.distributionsGroupes.filter(g=>g.key===group.key).length === 0){
      this.distributionsGroupes.push({
      key:group.key,
      label:group.name,
      distributions: group.students.map(s=>({
          start:null,
          end:null,
          cloeeWorldId: s.cloeeWorldId,
          displayName: s.displayName,
          courseId: this.courseId,
          preserveFromDeletion: false,
          classeGroupeKey: group.key,
          classeName: group.name,
          schoolName: group.school,
          progression: 0
        }))
      });
      this.userForm.markAsDirty();
    }
  }
  addSingleStudent(eleve: Student){
    if(this.distributionsGroupes.every(g=>g.distributions.every(d=>d.cloeeWorldId!=eleve.cloeeWorldId))){
      if(this.distributionsGroupes.filter(c=>c.key===null).length === 0){
        this.distributionsGroupes.push({
          key:null,
          label:'Individuels',
          distributions:[]
        });
      }
      this.distributionsGroupes.filter(c=>c.key===null)[0].distributions.push({
        start:null,
        end:null,
        cloeeWorldId: eleve.cloeeWorldId,
        displayName: eleve.displayName,
        courseId: this.courseId,
        preserveFromDeletion: false,
        classeGroupeKey: null,
        classeName:null,
        schoolName:null,
        progression: 0
      });
      this.userForm.markAsDirty();
    }
  }
  
  sort() {
    this.distributionsGroupes.sort((a, b) => a.label.localeCompare(b.label));
  }
  delete() {
    const modalRef = this.modalService.open(SuppressModalComponent);
    modalRef.result.then((result) => {
      this.service.deleteDistribution(this.courseId).subscribe((res) => {
        this.router.navigate(['../'], { relativeTo: this.route });
      });
    }, () => {
    });
  }
  editRange() {
    const modalRef = this.modalService.open(DateRangeSelectorComponent, {size : 'lg'});
    modalRef.result.then((range) => {
      this.distributionsGroupes.forEach(g=>g.distributions.map(d=>d.start= range.start));
      this.distributionsGroupes.forEach(g=>g.distributions.map(d=>d.end= range.end));
      this.userForm.markAsDirty();
    }, () => {
    });
  }
  editRangeForGroup(group: DistributionGroup){
    const modalRef = this.modalService.open(DateRangeSelectorComponent, {size : 'lg'});
    modalRef.result.then((range) => {
      group.distributions.map(d=>d.start= range.start);
      group.distributions.map(d=>d.end= range.end);
      this.userForm.markAsDirty();
    }, () => {
    });
  }
  save() {
    this.distributionsGroupes.forEach(g=>g.distributions.map(d=>d.preserveFromDeletion= this.userForm.get('preserveFromDeletion').value));
    this.service.saveDistribution(this.courseId, this.distributionsGroupes.map(g=>g.distributions).flat(1)).subscribe((distributions) => {
      this.distributionsGroupes = [...groupBy([...distributions],x => x.classeGroupeKey)].map(g=>({key: g[0],label:g[1][0].classeName??'Individuels',distributions:g[1]})); ;
      this.progression = this.computeProgression();
      this.toastService.success('Distribution sauvegardée');
      this.userForm.markAsPristine();
    });
  }
  computeProgression (): Progression {
    let distributions= this.distributionsGroupes.map(g=>g.distributions).flat(1)
    return  {
      success: distributions.length > 0 ?
      Math.round(distributions.filter(s => s.progression === 100).length / distributions.length * 100) : 0,
      info: distributions.length > 0 ?
      Math.round(distributions.filter(s => s.progression > 0 && s.progression < 100).length /
      distributions.length * 100) : 0
    };
  }
 
  updateDates(event:any,d:Distribution) {
    console.log(event);
    d.start=event.start;
    d.end=event.end;
    this.userForm.markAsDirty();
  }

  back(): void {
    this.location.back();
  }
}
