<nav role="navigation">
  <div class="d-flex">
    <div>
      <ul class="nav nav-fill">
        <li class ="nav-item">
          <a class="nav-link" [routerLink]="['/']" aria-label="Accueil iClasse">
            <span class="edicons edicons-visualidentity-iclasse"></span>
            <span class="d-none d-lg-block ms-lg-0 ms-2">iClasse</span>
          </a>
        </li>
        <li class="nav-item" *ngIf="!isLoggedIn">
          <a class="nav-link" [routerLinkActive]="'active'" [routerLink]="['parcours/libre/']" aria-label="Parcours disponibles">
            <span class="bi bi-map-fill"></span>
            <span class="d-none d-lg-block">Parcours</span>
          </a>
        </li>
        <li class="nav-item" *ngIf="isLoggedIn && isTeacher()">
          <a class="nav-link" [routerLinkActive]="'active'" [routerLink]="['/folios/']" aria-label="Folios disponibles">
            <span class="edicons edicons-visualidentity-folio"></span>
            <span class="d-none d-lg-block">Folios</span>
          </a>
        </li>
        <li class="nav-item" *ngIf="isLoggedIn && selfEvaluationMenuEnabled && isStudent()">
          <a class="nav-link" [routerLinkActive]="'active'" [routerLink]="['/auto-evaluation/']" aria-label="Auto-évaluation">
            <span class="bi bi-check-circle-fill"></span>
            <span class="d-none d-lg-block">Auto-évaluation</span>
          </a>
        </li>
        <li class="nav-item" *ngIf="isLoggedIn">
          <a class="nav-link" [routerLinkActive]="'active'" [routerLink]="['/parcours/']" aria-label="Parcours disponibles">
            <span class="bi bi-map-fill"></span>
            <span class="d-none d-lg-block">Parcours</span>
          </a>
        </li>
        <li class="nav-item" *ngIf="isLoggedIn">
          <a class="nav-link" [routerLinkActive]="'active'" [routerLink]="['/listes']" aria-label="Listes disponibles">
            <span class="bi bi-card-list"></span>
            <span class="d-none d-lg-block">Listes</span>
          </a>
        </li>
        <li class="nav-item" *ngIf="isLoggedIn && notificationEnabled ">
          <a class="nav-link" [routerLinkActive]="'active'" [routerLink]="['notifications']" aria-label="Notifications">
            <span class="bi bi-bell-fill"></span>
            <span class="badge bg-danger" *ngIf="notificationCount>0">{{notificationCount}}</span><span class="d-none d-lg-block">Notifications</span>
          </a>
        </li>
        <li class="nav-item" *ngIf="isLoggedIn && isAdmin()">
          <a class="nav-link" [routerLinkActive]="'active'" [routerLink]="['/administration/']" aria-label="Administration">
            <span class="bi bi-gear-wide-connected"></span>
            <span class="d-none d-lg-block">Administration</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/aide/index.html" target="_blank" aria-label="Aide en ligne">
            <span class="bi bi-question-circle-fill"></span>
            <span class="d-none d-lg-block">Aide</span>
          </a>
        </li>
        <li class="nav-item" *ngIf="isLoggedIn">
          <a class="nav-link" [routerLinkActive]="'active'" [routerLink]="['/preferences']" aria-label="Préférences de l'utilisateur connecté">
            <span class="bi bi-person-circle"></span>
            <span class="d-none d-lg-block" *ngIf="userName">{{ userName.displayName }}</span>
          </a>
        </li>
        <li class="nav-item" *ngIf="isLoggedIn">
          <form method="post" action="api/v1/account/logout">
            <button class="" type="submit">
              <span class="bi bi-box-arrow-in-right"></span>
              <span  class="d-none d-lg-block">Se déconnecter</span>
            </button>
          </form>
        </li>
        
        <li class="nav-item" *ngIf="!isLoggedIn">
          <a class="nav-link" href="api/v1/account/login" aria-label="Connexion">
            <span class="bi bi-box-arrow-in-right"></span>
            <span class="d-none d-lg-block">Connexion</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
