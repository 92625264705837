<div class="modal-header">
  <h4 class="modal-title">Discussion</h4>
  <button type="button" class="btn-close" aria-label="btn-close" (click)="activeModal.dismiss()">

  </button>
</div>
<div class="modal-body" #scrollMe *ngIf="!comments || (comments && comments.length === 0)">
  Aucune discussion en cours sur cette observation.
</div>
<div class="modal-body" #scrollMe *ngIf="comments && comments.length > 0">

  <nemo-comment-display [comments]="comments"></nemo-comment-display>
</div>
<form (submit)="comment()" action="#">
  <div class="modal-footer">
    <div class="input-group mb-3">
      <input class="form-control" ngbAutofocus #cmnt type="text" id="commentaire" [(ngModel)]="currentComment"
        placeholder="Commentaire" aria-label="Commentaire" autocomplete="off" autocorrect="off" autocapitalize="off"
        spellcheck="false" maxlength="150" [ngModelOptions]="{standalone: true}" />
      <button type="submit" class="btn btn-outline-secondary" [disabled]="currentComment ? currentComment.length === 0 : true"><span class="bi bi-send-fill"></span> Envoyer</button>
      <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss()"><span class="bi bi-x-lg"></span> Fermer</button>
    </div>
  </div>
</form>
