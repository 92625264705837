import { Component, Input, OnInit } from '@angular/core';
import { CourseItem } from '@app/model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ParcoursItemEditionComponent } from '../parcours-item-edition/parcours-item-edition.component';

@Component({
    selector: 'nemo-parcours-item-edition-modal',
    templateUrl: './parcours-item-edition-modal.component.html',
    styleUrls: ['./parcours-item-edition-modal.component.scss'],
    standalone: true,
    imports: [ParcoursItemEditionComponent]
})
export class ParcoursItemEditionModalComponent {

  @Input() item: CourseItem;
  @Input() defaultColor = 'c-grey';
  @Input() defaultIcon = 'edicons-visualidentity-rpn';

  constructor(public activeModal: NgbActiveModal) { }



}
