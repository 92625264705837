import { Injectable } from '@angular/core';
import { ItemListSummary, SearchNatureEnum } from '@app/model';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { ListeService } from './liste.service';
import { Observable, of, EMPTY } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ListeRechercheResolverService  {

  constructor(private readonly listeService: ListeService,
  private readonly router: Router) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): ItemListSummary[] | Observable<ItemListSummary[]> | Promise<ItemListSummary[] > {
    const search = this.listeService.getSearchFilter();
      search.skip = 0;
      if (route.routeConfig.path === '') {
        search.nature = SearchNatureEnum.Private;
        this.listeService.setSearchFilter(search);
        return this.listeService.getLists(search).pipe(
          take(1),
          mergeMap(lists => {
            if (lists.length > 0) {
              this.router.navigate(['/listes/personnelles']);
              return EMPTY;
            }
            this.router.navigate(['/listes/lexique']);
            return EMPTY;
          }));
      }
      if (route.routeConfig.path === 'personnelles') {
        search.nature = SearchNatureEnum.Private;
      } else if (route.routeConfig.path === 'lexique') {
        search.nature = SearchNatureEnum.Lexique;
      } else {
        search.nature = SearchNatureEnum.Private;
      }
      this.listeService.setSearchFilter(search);
      return this.listeService.getLists(search).pipe(
        take(1),
        mergeMap(lists => {
          return of(lists);
        }));
    }
}
