import { Component, Input } from '@angular/core';
import { CourseItem } from '@app/model';
import { QuillViewHTMLComponent } from 'ngx-quill';
import { NgIf } from '@angular/common';

@Component({
    selector: 'nemo-text-item-display',
    templateUrl: './text-item-display.component.html',
    styleUrls: ['./text-item-display.component.scss'],
    standalone: true,
    imports: [NgIf, QuillViewHTMLComponent]
})
export class TextItemDisplayComponent {

  @Input() item: CourseItem;

}
