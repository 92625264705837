import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EvaluationLevel, EvaluationScale } from '@app/model';
import { BookletService, InputValidationService } from '@app/core/service';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgIf, NgClass, NgStyle, NgFor } from '@angular/common';

@Component({
    selector: 'nemo-evaluation',
    templateUrl: './evaluation.component.html',
    styleUrls: ['./evaluation.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass, NgStyle, NgFor, NgbTooltip]
})
export class EvaluationComponent implements OnInit {

  @Input() scale: EvaluationScale;
  @Input() displayLabel = false;
  @Input() evaluationCode?: string;
  @Input() edit = false;
  @Output() changed = new EventEmitter<string>();
  @Input() inline = false;

  evaluation: EvaluationLevel;


  constructor(public bookletService: BookletService) { }

  ngOnInit() {
    this.evaluation = this.scale.levels.find(s => s.code === this.evaluationCode);
  }
  updateEvaluation(level: EvaluationLevel) {
    this.evaluationCode = level.code === this.evaluationCode ? 'unset' : level.code;
    this.changed.emit(this.evaluationCode);
  }

}
