import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { MediaService, ParcoursService, ToastService } from '@app/core/service';
import { CourseItemSearchViewModel } from '@app/model/course/course-item-search-view-model';
import { MediaDisplayModalComponent } from '@app/shared/components/media-display-modal/media-display-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgEventBus } from 'ng-event-bus';
import { ParcoursItemStartComponent } from '../parcours-item-start/parcours-item-start.component';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'nemo-parcours-ressources-detail',
    templateUrl: './parcours-ressources-detail.component.html',
    styleUrls: ['./parcours-ressources-detail.component.scss'],
    standalone: true,
    imports: [RouterLink, ParcoursItemStartComponent,CommonModule]
})
export class ParcoursRessourcesDetailComponent {
  @Input() resource: CourseItemSearchViewModel;
  @Input() selectionMode = false;
  @Output() ressourceToAdd = new EventEmitter<CourseItemSearchViewModel>(true);

  constructor(private readonly router: Router,
    private readonly parcoursService: ParcoursService,
    private readonly messageBusService: NgEventBus,
    private readonly mediaService: MediaService,
    private readonly modalService: NgbModal,
    private readonly toastService: ToastService) {

  }
  displayCourse():void {
    this.router.navigate(['parcours','ressources',this.resource.courseId]);
  }

  addToCart():void {
    this.parcoursService.addItemToCart(this.resource);
    this.toastService.success('Ressource ajoutée à votre panier de ressources');
    this.messageBusService.cast('display-cart');
  }
  add():void {
    this.ressourceToAdd.emit(this.resource);
  }
  startItem() {
    if(!this.selectionMode) {
      this.parcoursService.setCurrentCourseUrl(this.router.url);
      const self = this.resource.navigationUrl && this.resource.navigationUrl.startsWith('/') && !this.resource.navigationUrl.startsWith("/api/v1/medias");;
      const mediaAction = this.mediaService.detectMediaActionToHandle(this.resource.navigationUrl);
      if (self) {
        this.router.navigate([this.resource.navigationUrl]);
      } else if (this.resource.navigationUrl) {
        if (mediaAction.image || mediaAction.video || mediaAction.sound) {
          const modalRef = this.modalService.open(MediaDisplayModalComponent, {size: 'xl'});
          modalRef.componentInstance.item = this.resource;
          modalRef.componentInstance.mediaAction = mediaAction;
          modalRef.result.then(() => {
          }, () => {
          });
        } else {
          window.open(this.resource.navigationUrl, '_blank');
        }
      }
    }
  }
}
