<div *ngIf="planEtudeRomand">
  <div>
    <span class="badge bg-secondary me-1" >{{planEtudeRomand.objectiveCode}}</span>
    <span class="badge category-tag me-1" *ngFor="let a of computedYears">{{a}}</span>
    <small class="fw-light">{{planEtudeRomand.discipline}}</small>
  </div>
  <div>
    <div class="fw-light" *ngFor="let t of planEtudeRomand.axesThematiques"><small>{{t | replace:'^\\d+ - ':''}}</small></div>
    <div class="fw-light" *ngIf="planEtudeRomand.progressionSectionName !== '...'"><small>{{planEtudeRomand.progressionSectionName}}</small></div>
  </div>
  <div class="per">
    <small [innerHTML]="planEtudeRomand.learningHtml"></small>
    <p class="text-end" *ngIf="displayUrl">
      <small>
        <a href="{{planEtudeRomand.url}}" target="_blank" rel="noopener noreferrer"><i class="edicons edicons-visualidentity-per"></i>consulter le PER</a>
      </small>
    </p>
  </div>
</div>