import { Item } from '../itemlist/item';

export class ActivityItem extends Item {
  constructor(item: Item) {
    super(item);
    this.attempt = 0;
    this.known = false;
  }
  attempt: number;
  known: boolean;
}
