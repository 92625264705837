<div class="card p-3" *ngIf="criteria">
  <div class="d-flex">
    <div class="align-self-center" *ngIf="editable">
      <span class="bi bi-three-dots-vertical"></span>
    </div>
    <div class="align-self-center flex-grow-1 p-2">
      {{criteria.label}}
    </div>
    <div class="align-self-center pe-2 d-none d-md-block">
      <div *ngIf="criteria.studentEvaluationEnabled"  class="text-center">
        <span class="badge rounded-pill bg-info">Auto-évaluation par l'élève</span>
      </div>
      <div class="text-center">
        <span class="badge category-tag" *ngFor="let discpline of disciplines">
          {{discpline.value}}
        </span>
      </div>
      <div class="text-center">
        <span class="badge category-tag" *ngFor="let tag of criteria.tags">
          {{tag}}
        </span>
      </div>
      <div class="text-center" *ngIf="criteria.perObjectiveCode">
        <span class="badge category-tag">
          {{criteria.perObjectiveCode}}
        </span>
      </div>

    </div>
    <div class="align-self-center toolbar" *ngIf="editable">
      <button type="button" class="btn btn-outline-secondary btn-sm" tooltip="Modifier le critère" tooltipPlacement="top"
        (click)="editCriteria()">
        <span class="bi bi-pencil-square"></span>
      </button>
      <button type="button" class="btn btn-outline-secondary btn-sm" tooltip="Supprimer le critère" tooltipPlacement="top"
        (click)="dropCriteria()">
        <span class="bi bi-trash-fill"></span>
      </button>
    </div>
  </div>
</div>
