<nemo-basepage>
  <div page-body>
    <h1>Conditions générales <small>du 11 juillet 2019</small></h1>

    <h2>Merci d'utiliser iClasse !</h2>
    <p>Les présentes conditions générales ont pour objet de définir les termes et conditions régissant l'utilisation de la
      plateforme iClasse.</p>
    <p>Au sens des présentes conditions générales, le terme <strong>utilisateur·trice</strong> désigne toute personne qui accède à la
      plateforme iClasse.</p>
    <p>Toute utilisation de la plateforme iClasse et des ressources du Réseau pédagogique neuchâtelois (RPN ci-après) est
      soumise à l'acceptation préalable par l'utilisateur·trice des présentes conditions générales.</p>
    <p>En choisissant d'utiliser la plateforme iClasse, l'utilisateur·trice déclare avoir pris connaissance et avoir accepté
      expressément et sans réserve les termes et conditions des présentes conditions générales.</p>
    <p>Ces conditions générales peuvent changer sans préavis. Il est par conséquent recommandé de consulter régulièrement la
      dernière version des conditions générales, disponible en permanence sur la plateforme.</p>
    <h2>1. Objet</h2>
    <p>iClasse est une plateforme du Réseau pédagogique neuchâtelois (RPN) proposant des ressources numériques organisées en
      parcours. </p>
    <p>Elle est développée par le Service informatique de l'entité neuchâteloise (SIEN), sur mandat de l'Office de
      l'informatique scolaire et de l'organisation (OISO) et en collaboration avec le conseil des Aides pédagogiques par
      l'informatique (API) du Centre régional d'apprentissages spécialisés du CERAS.</p>
    <p>iClasse est destiné : </p>
    <ul>
      <li>En mode connecté avec un compte RPN pour les enseignant∙e∙s et élèves neuchâtelois∙e∙s ;</li>
      <li>En mode tout public pour tout utilisateur∙trice d'Internet.</li>
    </ul>
    <p>Spécificité du mode connecté avec compte RPN (enseignant∙e, élève) :</p>
    <p>iClasse permet aux enseignant·e·s neuchâtelois·e·s de choisir librement des ressources numériques et de les
      distribuer aux élèves. Un suivi des compétences des élèves des années 1 à 11 de la scolarité obligatoire est intégré.
      Les utilisateurs·trices peuvent créer des parcours personnels, s'approprier des parcours proposés par le RPN, les
      modifier et les partager avec d'autres utilisateurs·trices.</p>
    <p>Les élèves utilisent les ressources mises à disposition par leur∙s enseignant∙e∙s ou par le RPN à l'école, à leur
      domicile ou dans tout autre lieu connecté.</p>
    <p>Spécificités du mode tout public :</p>
    <p>Une sélection de parcours sont disponibles pour toute personne intéressée. Les parcours peuvent être utilisés tels
      quels, sans possibilité de modification.</p>
    <h2>2. Utilisation commerciale de iClasse</h2>
    <p>Il n'est en aucun cas autorisé d'utiliser iClasse à des fins commerciales.</p>
    <h2>3. Obligation de l'utilisateur∙trice </h2>
    <p>iClasse permet aux détenteurs·trices d'un compte RPN de publier du contenu tel que des images, des photos, des liens,
      des textes et des documents au format PDF. Tout élément publié par un∙e utilisateur∙trice est appelé « contenu
      utilisateur∙trice ». </p>
    <p>La publication de contenu utilisateur∙trice sur la plateforme iClasse est faite sous la seule et entière
      responsabilité de l'utilisateur∙trice.</p>
    <p>L'OISO se réserve le droit de supprimer ou de modifier le contenu utilisateur∙trice, ou de changer la manière dont il
      est utilisé pour quelque raison que ce soit. </p>
    <p>En rédigeant du contenu sur la plateforme iClasse, l'utilisateur·trice s'engage à respecter toutes dispositions
      légales applicables et à ne pas porter atteinte aux droits de tiers.</p>
    <p>En particulier, l'utilisateur∙trice s'engage à ne pas communiquer de propos ou contenus :</p>
    <ul>
      <li>injurieux, diffamatoires, calomnieux ou de toute autre manière attentatoires à l'honneur ou à la réputation de
        tiers ;</li>
      <li>racistes, xénophobes, révisionnistes ou négationnistes ;</li>
      <li>obscènes, pornographiques, pédophiles, offensants ou de toute autre manière contraires aux bonnes mœurs ;</li>
      <li>portant atteinte d'une quelconque manière aux droits d'autrui, notamment le droit à la vie privée, le droit à
        l'image, le droit au nom, le droit à la dignité humaine, </li>et la protection des enfants et des adolescents ;
      <li>portant atteinte aux droits de propriété intellectuelle d'autrui, notamment le droit des marques, le droit
        d'auteur (images, sons, textes, photographies, logiciels, </li>etc.), ainsi que les droits des artistes interprètes,
      des producteurs de phonogrammes et de vidéogrammes et des organismes de diffusion ;
      <li>incitant à la discrimination, à la haine, à la violence, ou à commettre un crime ou un délit ou à consommer des
        substances illicites ;</li>
      <li>à caractère publicitaire, ou relevant de la propagande ou du prosélytisme ; </li>
      <li>ou d'une quelconque manière contraire aux lois et réglementations en vigueur.</li>
    </ul>
    <p>En outre, l'utilisateur∙trice s'engage à ne pas communiquer de message contenant des virus ou tout autre programme
      susceptible de causer des dommages au RPN ou à des tiers.</p>
    <p>Enfin, l'utilisateur∙trice s'engage à ne pas générer de surcharge du serveur hébergeant iClasse en publiant un trop
      grand nombre de fichiers, dans le but de nuire volontairement.</p>
    <h2>4. Liens, sites et services de tiers </h2>
    <p>iClasse contient des liens vers du contenu indépendant. Ce contenu externe est généralement intéressant pour
      l'enseignement, mais nous ne pouvons engager notre responsabilité s'il ne l'est pas.</p>
    <p>Les renvois et liens vers d'autres sites Internet ne sont pas de la responsabilité de la plateforme iClasse ou du
      RPN. L'accès à ces sites et leur utilisation se font aux risques des utilisateurs∙trices. Le RPN déclare expressément
      qu'il n'a aucune influence sur la forme, le contenu et les offres des sites auxquels il renvoie. Les informations et
      services offerts par ces sites dépendent entièrement de la responsabilité de leurs auteur∙e∙s.</p>
    <p>Par conséquent, le RPN ne peut être tenu responsable pour avoir proposé de tels liens hypertextes. En outre, le RPN
      n'est en aucun cas responsable du contenu ou de l'existence des sites concernés, ni d'un quelconque dommage, direct ou
      indirect, subi par l'utilisateur·trice du fait de la consultation et/ou de l'utilisation d'un site tiers accessible à
      partir de la plateforme iClasse.</p>
    <h2>5. Responsabilité de l'utilisateur∙trice</h2>
    <p>L'utilisateur∙trice est seul∙e et unique responsable, sur le plan civil, administratif et pénal, des propos et/ou
      contenus (textes, images, sons, etc.) qu'il∙elle rédige sur la plateforme iClasse.</p>
    <p>Par conséquent, l'utilisateur∙trice garantit le RPN contre tous recours, contestations, demandes en dommages et
      intérêts, ou autres actions ou prétentions que pourraient former des tiers à un titre quelconque à la suite de la
      communication par l'utilisateur·trice de propos et/ou contenus sur la plateforme iClasse. Dans le cas où la
      responsabilité du RPN serait recherchée, à raison d'un manquement par l'utilisateur∙trice aux obligations qui lui
      incombent aux termes des présentes conditions générales d'utilisation, le RPN pourra appeler l'utilisateur∙trice en
      garantie.</p>
    <p>La RPN se réserve le droit, sur demande d'une autorité judiciaire ou d'un tiers, de communiquer toute information en
      sa possession permettant ou facilitant l'identification de l'utilisateur∙trice, telle que l'adresse IP et l'heure de
      connexion, en particulier en cas de non-respect par l'utilisateur∙trice des obligations définies au point 3 des
      présentes conditions générales.</p>
    <h2>6. Politique relative au droit d'auteur </h2>
    <p>iClasse respecte les droits d'auteur et recommande à ses utilisateurs·trices d'en faire autant.</p>
    <p>Les textes, audios et vidéos ainsi que tous autres contenus communiqués par l'utilisateur·trice à la plateforme
      iClasse doivent être libres de tout droit et sont transmis à la plateforme iClasse sous la seule et entière
      responsabilité de l'utilisateur∙trice. </p>
    <p>Des contenus non libres de droits peuvent être publiés à des fins pédagogiques, mais doivent être accessibles
      uniquement via une connexion à iClasse avec un compte RPN.</p>
    <p>L'utilisateur.trice cède au RPN, sans restriction aucune et sans limite de temps ni de lieu, tous les droits afférant
      aux textes, audios et vidéos ainsi qu'à tous autres contenus personnels qu'il∙elle communique à la plateforme iClasse,
      notamment les droits d'auteur, les droits voisins ainsi que les droits à l'image. Par conséquent, l'utilisateur∙trice
      autorise gracieusement le RPN à utiliser librement les textes et autres contenus qu'il communique à la plateforme
      iClasse, seuls ou incorporés dans toute production audiovisuelle, par tous modes de transmission actuels ou futurs, de
      représentation, d'adaptation, de diffusion en webcasting ou en simulcasting ou de mise à disposition à la demande avec
      ou sans téléchargement, par tous systèmes et sur tous réseaux y compris Internet, en tout format, à titre commercial
      ou non commercial, dans le monde entier et pour une durée illimitée.</p>
    <h2>7. Commentaires </h2>
    <p>L'avis des utilisateurs∙trices est précieux, et l'OISO est toujours curieux de découvrir comment rendre la plateforme
      iClasse encore plus performante. Si l'utilisateur·trice décide de faire part de commentaires, de suggestions d'idées
      ou de réactions via l'adresse iclasse&#64;rnp.ch, il·elle accepte que l'OISO soit libre de les utiliser sans restriction
      ou sans rémunération. </p>
    <h2>8. Modération</h2>
    <p>Le contenu de la plateforme iClasse peut être modéré. Par conséquent, la RPN se réserve le droit, à sa seule et
      entière discrétion, de modifier ou non tout contenu texte, audio ou vidéo ou autre contenu communiqué par
      l'utilisateur·trice, de le conserver en ligne ou de le supprimer à tout moment sans avoir à en informer préalablement
      ou postérieurement l'utilisateur·trice et sans que cela puisse donner lieu à de quelconques prétentions de quelque
      nature que ce soit à l'égard du RPN.</p>
    <p>Le RPN se réserve le droit de limiter ou supprimer l'accès à ses services à tout·e utilisateur·trice qui ne se
      conformerait pas aux dispositions des présentes conditions générales, en particulier en cas de non-respect des
      obligations énoncées au point 3 des présentes conditions générales.</p>
    <h2>9. Sécurité </h2>
    <p>L'OISO fait son possible afin d'assurer la sécurité des contenus et des comptes. Toutefois, il ne peut garantir que
      des tiers non autorisés ne parviendront pas à passer outre les mesures de protection. Il est ainsi demandé de veiller
      à la confidentialité du mot de passe. L'OISO doit immédiatement être informé en cas de piratage ou d'utilisation non
      autorisée d'un compte.</p>
    <p>Malgré le plus grand soin apporté à la création et à la mise à jour des contenus, le RPN ne peut garantir
      l'exactitude des informations qui y figurent.</p>
    <p>Par conséquent, sous réserve de la responsabilité découlant des dispositions légales impératives de droit suisse, le
      RPN ne peut être tenu responsable de quelconques erreurs, inexactitudes ou omissions, de même que de tout dommage,
      direct ou indirect, de quelque nature que ce soit, résultant de la consultation et/ou de l'utilisation de la
      plateforme iClasse.</p>
    <p>Le RPN ne peut être tenu responsable si des propos ou images contenus sur la plateforme iClasse devaient heurter
      certaines personnes particulièrement sensibles.</p>
    <p>Les contenus publiés sur la plateforme iClasse, y compris ceux rédigés par des collaborateurs·trices du RPN,
      expriment l'opinion personnelle de leur auteur∙e et n'engagent que ces derniers∙ères. Ils ne représentent dès lors en
      aucun cas un quelconque avis officiel du RPN.</p>
    <h2>10. Responsabilité </h2>
    <p>Le service et tous les contenus sur iClasse sont fournis <strong>tels quels</strong>, sans garantie d'aucune sorte, formelle ou
      tacite.</p>
    <p>iClasse n'assume aucune responsabilité pour le contenu utilisateur·trice publié ou envoyé via notre service.
      L'utilisateur·trice comprend et accepte qu'il·elle risque de rencontrer du contenu utilisateur·trice peu adapté à
      l'objectif poursuivi. Si tel est le cas, l'utilisateur·trice en informera qui de droit via l'adresse iclasse&#64;rpn.ch.
    </p>
    <p>L'OISO élabore le meilleur service possible pour l'utilisateur·trice, mais ne peut promettre qu'il soit parfait.</p>
    <p>La plateforme iClasse est en principe accessible 24 heures sur 24. Cependant, le RPN ne peut être tenu responsable en
      cas d'impossibilité technique de connexion, quelle qu'en soit la cause (force majeure, opération de maintenance, mise
      à jour, interruption ou panne de réseau, coupure d'électricité, défaillance, mauvaise configuration ou mauvais usage
      de l'ordinateur par l'utilisateur·trice, du matériel de réception ou des lignes téléphoniques, ou toute autre cause
      dépendant ou non de la volonté du RPN).</p>
    <p>La RPN se réserve le droit, à tout moment et sans préavis, de modifier ou d'interrompre temporairement ou de façon
      permanente tout ou partie des services proposés par la plateforme iClasse, sans que cela puisse donner lieu à de
      quelconques recours, réclamations ou prétentions de quelque nature que ce soit à l'égard du RPN.</p>
    <p>L'utilisateur∙trice est responsable envers le RPN de tout dommage de quelque nature que ce soit qu'il ou elle cause
      lors de l'utilisation de la plateforme iClasse.</p>
    <p>Tout matériel téléchargé et/ou obtenu de quelque manière que ce soit lors de l'utilisation de la plateforme iClasse
      l'est aux risques et périls de l'utilisateur∙trice, le RPN ne pouvant en aucun cas être tenu responsable des dommages
      ou pertes de données subis par l'utilisateur∙trice de ce fait.</p>
    <p>Le RPN ne saurait être tenu responsable d'éventuels dégâts au matériel informatique de l'utilisateur∙trice en cas de
      contamination par un virus. Il appartient à l'utilisateur∙trice de prendre toutes les mesures nécessaires afin de
      protéger ses propres données, logiciels, ordinateur et autre matériel informatique de la contamination par de
      possibles virus circulant sur le réseau Internet.</p>
    <p>Par ailleurs, le RPN ne garantit en aucune manière le taux et le temps de transfert des données du serveur hébergeant
      la plateforme iClasse vers le réseau Internet et l'ordinateur de l'utilisateur·trice.</p>
    <h2>11. Conservation du contenu publié </h2>
    <p>Si l'utilisateur·trice décide de publier du contenu, il·elle autorise à l'utiliser pour améliorer iClasse. Des copies
      du contenu partagé avec d'autres peuvent subsister, même après avoir supprimé le contenu du compte en question.</p>
    <h2>12. Résiliation </h2>
    <p>iClasse est mis à disposition gratuitement. L'OISO se réserve le droit de refuser ou supprimer le service à
      quelqu'un∙e ; celui ou celle-ci en sera, dans la mesure du possible, informé de manière appropriée.</p>
    <h2>13. Arbitrage </h2>
    <p>En cas de litige entre iClasse et l'utilisateur·trice, ce dernier ou cette dernière accepte de contacter d'abord
      l'OISO pour tenter de résoudre le problème à l'amiable. </p>
    <h2>14. Contact</h2>
    <p>Si l'utilisateur·trice doit contacter l'OISO, il adressera sa communication à oiso&#64;rpn.ch. Si l'OISO doit contacter
      l'utilisateur·trice, il adressera sa communication à l'adresse électronique RPN usuelle.</p>
    <h2>15. Droit applicable et for</h2>
    <p>Les présentes conditions générales sont soumises au droit suisse.</p>
    <p>Le lieu d'exécution et le for exclusif pour tous genres de procédures est Neuchâtel.</p>
    <p>Version du 11 juillet 2019</p>
  </div>
</nemo-basepage>
