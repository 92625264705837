<div class="modal-header">
  <h4 class="modal-title">Auto-évaluation de l'élève</h4>
  <button type="button" class="btn-close" aria-label="btn-close" (click)="activeModal.dismiss()">

  </button>
</div>
<div class="modal-body">
  <nemo-evaluation *ngIf="selfState && scale" [evaluationCode]="selfState" [scale]="scale" [displayLabel]="true" [inline]="true"></nemo-evaluation>
  <blockquote class="blockquote mt-2" *ngIf="selfComment">
    <p>{{selfComment}}</p>
  </blockquote>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="activeModal.close()">Fermer</button>
</div>

