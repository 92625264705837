import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AuthenticationService } from '@app/core/service/authentication.service';
import { SharedModule } from '@app/shared/shared.module';

@Component({
  selector: 'nemo-unauthorized',
  standalone: true,
  imports: [CommonModule,SharedModule,RouterModule],
  templateUrl: './unauthorized.component.html',
  styleUrl: './unauthorized.component.scss'
})
export class UnauthorizedComponent {
  /**
   *
   */
  constructor(
    private readonly authService: AuthenticationService,
    private readonly router: Router
  ) {
    
    
  }
  public isLogged():boolean{
    return this.authService.getCurrentUserProfile().isAuthenticated;
  }
}
