
<div class="card text-center" [ngClass]="{'clickable':clickable}">
  <nemo-ribbon [text]="ribbon" *ngIf="ribbon"></nemo-ribbon>
  <div class="card-icon mt-1 ms-1 me-1">
    <nemo-logo [color]="color" [icon]="icon" [top]="progression">
      <small *ngIf="tags && isMoyenEnseignementRomand">Moyen d'enseignement officiel</small>
      <small *ngIf="tags && isMoyenEnseignementNeuchatelois">Moyen d'enseignement neuchâtelois</small>
    </nemo-logo>
  </div>  
  <div *ngIf="progression" class="progress mx-1 rounded-bottom">
    <div class="progress-bar" [ngClass]="{'bg-info':progression<100, 'bg-success': progression === 100}"
      role="progressbar" [ngStyle]="{'width': progression +'%'}" [attr.aria-valuenow]="progression" aria-valuemin="0"
      aria-valuemax="100"></div>
  </div>
  <div class="card-body">
    <h5 class="card-title" *ngIf="title && big">{{ title }}</h5>
    <h5 class="card-title" *ngIf="subtitle && big">{{ subtitle }}</h5>
    <h6 class="card-subtitle text-muted" *ngIf="description && big">{{ description }}</h6>
    <p class="card-text mb-0" *ngIf="title && !big"><span>{{ title }}</span></p>
    <p class="card-text mb-0" *ngIf="subtitle && !big"><span>{{ subtitle }}</span></p>
    <p class="card-text mb-0" *ngIf="text || author"><small class="text-muted " *ngIf="text">{{ text }}</small><small class="text-muted" *ngIf="author && displayAuthor"><em> par {{ author }}</em></small></p>
    <a *ngIf="clickable" [routerLink]="[]" class="stretched-link" (click)="cardClick()" [attr.aria-label]="title"></a>
    <ng-content></ng-content>
    <div *ngIf="displayCategories.length > 0" class="metas mt-1">
      <span *ngFor="let cat of displayCategories" class="badge category-tag">
        {{big ? cat.value : cat.short}}
      </span>
    </div>
    <div *ngIf="perCodes.length > 0 && isTeacher()" class="metas mt-1">
      <span *ngFor="let perCode of perCodes" class="badge category-tag">{{ perCode }}</span>
    </div>
  </div>
</div>
