<div class="d-flex">
  <div class="p-2 flex-grow-1" [ngClass]="size" >
    <span [ngClass]="{invisible: !displayText}" *ngIf="mask === 0">{{text}}</span>
    <div class="parent" *ngIf="mask !== 0" [ngClass]="{invisible: !displayText}">
      {{text}}
      <div [ngClass]="{'top': mask === 1,'mid': mask === 2 , 'low': mask === 3, 'left': mask === 4, 'right': mask === 5}"></div>
  </div>
  </div>
  <div class="p-2 align-self-center" *ngIf="enableSpeech"><nemo-speaker  [text]="text" [languageCode]="languageCode"></nemo-speaker></div>
</div>
