<div *ngIf="items.length <= 0">
  <div class="alert alert-secondary" role="alert">
    <h4 class="alert-heading">Dommage, c'est encore vide par ici !</h4>
    <p>
      Vous n'avez pas encore ajouté de ressources à votre panier.
    </p>
    <p>Consultez des <a [routerLink]="['../rpn']">parcours RPN</a> pour trouver des ressources à ajouter à votre panier.</p>
  </div>
</div>
<div *ngIf="items.length > 0">
  <p class="lead">Vous trouvez ici les ressources des parcours que vous avez ajouté à votre panier de ressources.</p>
  <div class="d-flex  align-content-center flex-nowrap align-items-stretch" *ngFor="let item of items; let i = index">
    <div class="flex-grow-1 align-self-center lead p-1">
      <nemo-parcours-item-start  [item]="item" (started)="startItem($event)" [displayProgression]="false"></nemo-parcours-item-start>
    </div>
    <div class="ms-2 align-self-center d-grid gap-2">
      <button type="button" class="btn btn-sm btn-outline-secondary btn-lg" (click)="removeItem(item)" aria-label="Supprimer cette ressource du panier">
        <em class="bi bi-trash-fill"></em>
      </button>
    </div>
  </div>

  <button type="button" form="userForm" class="btn btn-outline-secondary" tooltip=" Vider le panier"
    tooltipPlacement="top" (click)="empty()"><span
      class="bi bi-trash-fill"></span> Vider le panier</button>
  <button type="submit" form="userForm" class="btn btn-primary" tooltip="Sauvegarder" tooltipPlacement="top"
    (click)="createCourse()">
    <span class="bi bi-map-fill"></span> Créer un parcours</button>
</div>
