<div class=" card" *ngIf="!commentSent">
  <div class="card-body">
    <h5 class="card-title">Votre avis nous intéresse !</h5>
    <p class="card-text lead">Laissez-nous un commentaire et une évaluation sur votre expérience iClasse et nous nous ferons un plaisir de le lire et d'en tenir compte au mieux lors de notre prochaine mise à jour.</p>
    <ng-template #t let-fill="fill">
      <span class="star" [class.full]="fill === 100">
        <span class="half" [style.width.%]="fill">&hearts;</span>&hearts;
      </span>
    </ng-template>
    <ngb-rating [(rate)]="currentRate" [starTemplate]="t"></ngb-rating>
    <div class="mt-2">
      <label class="form-label" for="title" class="visually-hidden"></label>
      <input type="text" name="title" id="title" class="form-control form-control" placeholder="Commentaire"
        [(ngModel)]="comment" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
        maxlength="499" />
      <button type="button" class="btn btn-outline-secondary mt-2" tooltip="Envoyer" tooltipPlacement="top"
        (click)="rate()"><span class="bi bi-send-fill"></span> Envoyer votre avis</button>
    </div>
  </div>
</div>
