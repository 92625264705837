<div class="d-flex">
  <div class="mb-2 flex-grow-1 align-self-center" *ngIf="item.itemType !=='Text'" [ngClass]="{'card':item.navigationUrl,'border-success' : item.done && displayProgression, 'clickable': item.navigationUrl && !selectionMode}">
    <nemo-parcours-item-detail [item]="item"></nemo-parcours-item-detail>
    <a *ngIf="item.navigationUrl && !selectionMode" [routerLink]="[]" class="stretched-link" (click)="start()"></a>
  </div>
  <div class="mb-2 flex-grow-1 align-self-center" *ngIf="item.itemType ==='Text'" [ngClass]="{'card':item.progressionEnabled,'border-success' : item.done && displayProgression }">
    <nemo-parcours-item-detail [item]="item"></nemo-parcours-item-detail>
    <div class="p-2 d-flex border-top" *ngIf="item.progressionEnabled">
      <div class="flex-grow-1 align-self-center text-end me-2"></div>
      <div class="form-check form-switch">
        <label class="form-check-label" for="done">J'ai pris connaissance de ce contenu</label>
        <input class="form-check-input" type="checkbox" role="switch" id="done" [(ngModel)]="item.done" [ngModelOptions]="{standalone: true}" (change)="onChange()">
      </div>
    </div>
  </div>
  <div class="ms-2 align-self-center d-grid gap-2" *ngIf="enableAddToCart">
    <button type="button" class="btn btn-sm btn-outline-secondary btn-lg" (click)="addItemToCart()" aria-label="Ajouter cette ressource au panier de ressources">
      <em class="bi bi-arrow-down"></em><br>
      <em class="bi bi-bag-fill"></em>
    </button>
  </div>
</div>
