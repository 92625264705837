<div class="d-flex">
  <div class="flex-grow-1">
    <nemo-student-search-input (addStudent)="addStudent.emit($event)"></nemo-student-search-input>
  </div>
  <div class="ms-2"  *ngIf="groups && groups.length > 0" ngbDropdown>
    <button class="btn btn-outline-secondary" id="dropdownBasic1" ngbDropdownToggle>
      <span class="bi bi-person-fill-add"></span> Ajouter un groupe</button>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="scrollable-dropdownmenu">
      <button class="dropdown-item" ngbDropdownItem (click)="addGroupStudents(group)"
        *ngFor="let group of groups ">{{group.name}}</button>
      <li *ngIf="settings.feature_flags.groupes"><hr class="dropdown-divider"></li>
      <button *ngIf="settings.feature_flags.groupes" class="dropdown-item" ngbDropdownItem (click)="manageGroups()">Gérer mes groupes</button>
    </div>
  </div>
  <div class="ms-2">
    <button type="button" class="btn btn-outline-secondary" tooltip="Enlever tous les élèves"
      tooltipPlacement="top" (click)="dropAll()"><span class="bi bi-person-fill-x"></span> Enlever tous les
      élèves</button>
  </div>
</div>

