import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConjugaisonService, ListeService, ReferenceDataService } from '@app/core/service';
import { ConjugaisonSelectorResult, Language } from '@app/model';
import { ConjugationSelectorComponent } from '@app/shared/components/conjugation-selector/conjugation-selector.component';
import { ConjugationSelectorComponent as ConjugationSelectorComponent_1 } from '../../../../shared/components/conjugation-selector/conjugation-selector.component';
import { BasepageComponent } from '../../../../shared/components/basepage/basepage.component';

@Component({
    selector: 'nemo-outil-conjugaison',
    templateUrl: './outil-conjugaison.component.html',
    styleUrls: ['./outil-conjugaison.component.scss'],
    standalone: true,
    imports: [BasepageComponent, ConjugationSelectorComponent_1]
})
export class OutilConjugaisonComponent {

  languages: Language[];
  isVerbInFrench = true;
  @ViewChild(ConjugationSelectorComponent) conjugatorSelector: ConjugationSelectorComponent;

  constructor(
    private readonly conjugaisonService: ConjugaisonService,
    private readonly referenceDataService: ReferenceDataService,
    private readonly listeService: ListeService,
    private readonly router: Router
  ) { }




  generateList(result: ConjugaisonSelectorResult): void {
    const listTitle =
    `${result.selectedVerbs.join('-')} ${result.selectedTenses.length === 1 ? result.selectedTenses[0].toLocaleLowerCase() : '' }`;

    this.listeService.cloneList({
      id: 0,
      hintLanguage: result.useFrenchHints ? 'fr' : result.items[0].hintLanguageId,
      learnLanguage: result.items[0].learnLanguageId,
      description: `Une liste de mémorisation de conjugaison`,
      icon: 'bi bi-card-list',
      color: 'c-grey',
      title: listTitle,
      items: result.items.map(u => {
        return {
          id:-1,
          hintTxt:  result.useFrenchHints ? u.hintTxtInFrench : u.hintTxt,
          tags: u.tags,
          learnTxt: u.learnTxt
        }
      })
    }).subscribe(r =>{
      this.router.navigate(['/listes/personnelles', r.id, 'edition']);
    });
  }
}
