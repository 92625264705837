<div class="row">
  <div class="col-sm-6 col-md-4 col-lg-2 mb-2" *ngFor="let item of workinglist">
    <div class="card text-center" [ngClass]="{'text-white':item.state !=='notfound' ,'bg-success':item.state === 'found' ,'bg-primary':item.state === 'selected','bg-warning': item.state === 'fail'}"  (click)="selectCard(item)" [@itemState]="item.state">
      <img *ngIf="item.imgUrl" class="card-img-top" alt="Card image cap"  [src]="item.imgUrl" [alt]="item.txt" >
      <div class="card-body align-middle" *ngIf="item.txt && item.txt.length > 0" [ngClass]="{'adapt-heigth': !item.imgUrl}">
        <nemo-item-text [text]="item.txt" [enableSpeech]="false"></nemo-item-text>
      </div>
    </div>
  </div>
</div>
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Régler</h4>
    <button type="button" class="btn-close" aria-label="btn-close" (click)="modal.dismiss()"></button>
  </div>
  <form>
    <ul class="list-group list-group-flush">
      <li class="list-group-item">
        <div class="d-flex flex-wrap py-1">
          <span class="lead flex-grow-1 pe-2">Nombre d'éléments à associer</span>
          <div class="align-self-center">
            <div class="btn-group" role="group">

              <input type="radio" class="btn-check" name="hmw" [(ngModel)]="config.howmanywords" id="hmw0" [value]="0" [checked]="config.howmanywords === 0"> 
              <label class="btn btn-outline-secondary" for="hmw0">Tous</label>
              
              <input type="radio" class="btn-check" name="hmw" [(ngModel)]="config.howmanywords" id="hmw1" [value]="1" [checked]="config.howmanywords === 1">
              <label class="btn btn-outline-secondary" for="hmw1">Maximum 12</label>
              
              <input type="radio" class="btn-check" name="hmw" [(ngModel)]="config.howmanywords" id="hmw2" [value]="2" [checked]="config.howmanywords === 2">
              <label class="btn btn-outline-secondary" for="hmw2">Maximum 24</label>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </form>
  <div class="modal-footer">
    <small><a [routerLink]="[]" (click)="displayPreferences()">D'autres réglages sont disponibles dans les préférences.</a></small>
    <button type="button" class="btn btn-outline-primary" ngbAutofocus (click)="modal.close()">Valider</button>
  </div>
</ng-template>
