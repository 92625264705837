import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Progression } from '@app/model';
import { NgIf } from '@angular/common';
import { LogoComponent } from '../logo/logo.component';


@Component({
    selector: 'nemo-user-display',
    templateUrl: './user-display.component.html',
    styleUrls: ['./user-display.component.scss'],
    standalone: true,
    imports: [LogoComponent, NgIf]
})
export class UserDisplayComponent {

  @Input() icon = 'bi bi-person-fill';
  @Output() dropUser = new EventEmitter<any>();

  constructor() { }


  drop() {
    this.dropUser.emit();
  }

}
