<div class="modal-header">
  <h4 class="modal-title">Apparence</h4>
  <button type="button" class="btn-close" aria-label="btn-close" (click)="activeModal.dismiss()">

  </button>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="my-2">
          <div [ngClass]="color" class="displayLogo">
            <span [ngClass]="[icon]"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="d-flex flex-column">
          <a class="p-2 flex-fill my-2 text-center rounded lead color" [routerLink]="[]" *ngFor="let c of logos.colors" [ngClass]="[c.value]" (click)="selectColor(c)">
            {{c.description}}
          </a>
        </div>
      </div>
      <div class="col-6">
        <div class="d-flex flex-wrap">
          <a class="p-2 flex-fill text-center logo" [routerLink]="[]" *ngFor="let i of logos.icons" (click)="selectIcon(i)"><span [ngClass]="[i.value]"></span></a>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss()">Annuler</button>
  <button type="button" class="btn btn-outline-primary" (click)="activeModal.close(selectedLogo)" ngbAutofocus>Valider</button>
</div>



