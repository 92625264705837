import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { PreferencesService } from '@app/core/service';
import { NgIf } from '@angular/common';
import { BasepageComponent } from '../../../shared/components/basepage/basepage.component';

@Component({
    selector: 'nemo-validation-email',
    templateUrl: './validation-email.component.html',
    styleUrls: ['./validation-email.component.scss'],
    standalone: true,
    imports: [BasepageComponent, NgIf, RouterLink]
})
export class ValidationEmailComponent implements OnInit {
  
  public valid = false;
  public notValid = false;

  constructor(
    public router: Router,
    private readonly preferencesService: PreferencesService,
    private readonly route: ActivatedRoute,
  ) { }


  ngOnInit() {
    this.route.params.subscribe(params => {
      this.preferencesService.validateEmail(params['token']).subscribe(p=>{
        this.valid = p;
        this.notValid = !p;
      },()=>{
        this.valid = false;
        this.notValid = true;
      });
  });
  }

  

}
