import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { ToastType, Toast, UserNotification } from '@app/model';
import { Observable, Subject } from 'rxjs';
import { ReferenceDataService } from './reference-data.service';
import { PreferencesService } from './preferences.service';
import { NgEventBus } from 'ng-event-bus';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  private readonly subject = new Subject<Toast>();
  private keepAfterRouteChange = false;

  constructor(private readonly router: Router,
    public referenceDataService: ReferenceDataService,
    private readonly messageBusService: NgEventBus,
    public preferencesService: PreferencesService) {


    // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterRouteChange) {
          // only keep for a single route change
          this.keepAfterRouteChange = false;
        } else {
          // clear alert messages
          this.clear();
        }
      }
    });
  }

  getAlert(): Observable<any> {
    return this.subject.asObservable();
  }

  success(message: string, keepAfterRouteChange = false, options = {}) {
    this.notify({
      message: message,
      type: ToastType.Success
    });
  }

  error(message: string, keepAfterRouteChange = false, options = {}) {
    this.notify({
      message: message,
      type: ToastType.Error
    });
  }

  info(message: string, keepAfterRouteChange = false, options = {}) {
    this.notify({
      message: message,
      type: ToastType.Info
    });
  }

  warn(message: string, keepAfterRouteChange = false, options = {}) {
    this.notify({
      message: message,
      type: ToastType.Warning
    });
  }

  notifyUser(toast:Toast){
    toast.url='/notifications';
    toast.type = ToastType.Info;
    this.notify(toast);
    this.messageBusService.cast('notif', toast.message);
  }

  notify(toast:Toast, keepAfterRouteChange = false, options = {}) {
    if (this.preferencesService.getPreferences().toastEnabled) {
      this.keepAfterRouteChange = keepAfterRouteChange;
      this.subject.next({ type: toast.type, url:toast.url, message: toast.message, options } as Toast);
    }
  }
  notifyHealthCheck() {
    if (this.preferencesService.getPreferences().warnHealthCheck) {
      this.subject.next({
        type: ToastType.Info,
        url:'/parcours/pour-enseignant/75928',
        message: 'Une pause santé ?',
        options: { timeOut: 20000  }
      } as Toast);
    }
  }

  clear() {
    this.subject.next(void 0);
  }
}
