<nemo-basepage [title]="'Parcours'">
  <div page-body>
    <ul class="nav nav-tabs mb-3">
      <li class="nav-item">
        <a class="nav-link" [routerLinkActive]="'active'" [routerLink]="['./personnels']">Personnels</a>
      </li>
      <li class="nav-item" *ngIf="isTeacher()">
        <a class="nav-link" [routerLinkActive]="'active'" [routerLink]="['./distributions']">Distributions</a>
      </li>
      <li class="nav-item" *ngIf="isStudent()">
        <a class="nav-link" [routerLinkActive]="'active'" [routerLink]="['./enseignants']">À faire</a>
      </li>
      <li class="nav-item" *ngIf="isTeacher()">
        <a class="nav-link" [routerLinkActive]="'active'" [routerLink]="['./pour-enseignant']">Enseignant·e·s
         <span class="bi bi-circle-fill" *ngIf="newTeacher > 0"><span class="visually-hidden">De nouveaux parcours sont disponibles</span></span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLinkActive]="'active'" [routerLink]="['./rpn']">RPN
          <span class="bi bi-circle-fill" *ngIf="newrpn > 0"><span class="visually-hidden">De nouveaux parcours sont disponibles</span></span>
        </a>
      </li>
      <li class="nav-item" *ngIf="!isCartEmpty">
        <a class="nav-link" [routerLinkActive]="'active'" [routerLink]="['./panier']">Panier</a>
      </li>
      <li class="nav-item" *ngIf="isTeacher()">
        <a class="nav-link" [routerLinkActive]="'active'" [routerLink]="['./ressources']">Ressources</a>
      </li>
    </ul>
    <router-outlet></router-outlet>
  </div>
</nemo-basepage>

