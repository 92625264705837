<div class="d-flex flex-column flex-xl-row align-self-center" *ngIf="this.categories">
  <div class="flex-grow-1">
    <span *ngFor="let category of categories" class="badge rounded-pill category-tag me-1">{{category.value}} <em class="bi bi-x-lg" (click)="drop(category)"></em></span>
  </div>
  <div class="mt-2 mt-xl-0">
    <div class="d-grid gap-2">
      <button type="button" class="btn btn-sm btn-outline-secondary mt-auto" (click)="editCategories()"><span class="bi bi-plus-lg"></span> Catégories</button>
    </div>
  </div>
</div>
