import { Injectable } from '@angular/core';
import { Group, Student } from '@app/model';
import { Observable, map } from 'rxjs';
import { HttpBaseService } from './http-base.service';

@Injectable({
  providedIn: 'root',
})
export class StudentService {
  
  updateGroupName(key: string, name: string): Observable<any> {
    return this.http.patch(`${this.url}/groupe`,{
      groupId: key,
      name:name,
      displayName :name
    });
  }
  
  private readonly url = '/eleve';

  constructor(private readonly http: HttpBaseService) {}

  public search(term: string): Observable<Student[]> {
    return this.http
      .get<Student[]>(`${this.url}/${term}`);
  }

  public getGroups(): Observable<Group[]> {
    return this.http
      .get<Group[]>(`${this.url}/groupes`).pipe(map(data => data.sort((a,b)=>{
        if(a.readOnly===b.readOnly){
          return a.name.localeCompare(b.name);
        }else{
          return a.readOnly>b.readOnly?1:-1;
        }
      })));
  }

  public getGroupStudents(key: string): Observable<Student[]> {
    return this.http
      .get<Student[]>(`${this.url}/groups/eleves/${key}`);
  }
  public deleteGroupStudent(groupId: string,id:number): Observable<Student[]> {
    return this.http
      .delete<Student[]>(`${this.url}/groupe/${groupId}/eleve/${id}`);
  }
  public deleteGroup(groupId: string): Observable<Student[]> {
    return this.http
      .delete<Student[]>(`${this.url}/groupe/${groupId}`);
  }
  public addStudentInGroup(student: Student, group: Group): Observable<any> {
    return this.http
      .post<Student[]>(`${this.url}/groupe/${group.key}/eleve/${student.cloeeWorldId}`,{displayName:group.name,name:group.name,groupId:group.key});
  }
  addStudentsInGroup(csv: string|ArrayBuffer, group: Group):Observable<any> {
    return this.http
    .post<Group>(`${this.url}/groupe/import/${group.key}`,{csv: csv, groupId:group.key});
    
  }
  addStudents(csv: string|ArrayBuffer): Observable<Group> {
    return this.http
      .post<Group>(`${this.url}/groupe/import`,{csv: csv});
  }
}
