import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConjugaisonService, ReferenceDataService } from '@app/core/service';
import { ConjugaisonItem, ConjugaisonSelectorResult, Language } from '@app/model';
import { Observable, OperatorFunction } from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { NgIf, NgFor, NgClass, LowerCasePipe } from '@angular/common';

@Component({
    selector: 'nemo-conjugation-selector',
    templateUrl: './conjugation-selector.component.html',
    styleUrls: ['./conjugation-selector.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, RouterLink, NgClass, FormsModule, LowerCasePipe]
})
export class ConjugationSelectorComponent implements OnInit {

  @Input() learnLanguage = 'fr';
  @Input() changeLanguageEnabled = true;
  @Input() useFrenchHints = false;
  @Input() ValidateButtonTxt = 'Valider';
  @Output() selectedItems = new EventEmitter<ConjugaisonSelectorResult>();

  languages: Language[];
  selectedVerbs: string[] = [];
  selectedTenses: string[] = [];
  verbs: string[];
  tenses: string[];
  years = [5,6,7,8,9,10,11];
  year: number;


  public model: any;

  constructor(
    private readonly conjugaisonService: ConjugaisonService,
    private readonly referenceDataService: ReferenceDataService
  ) { }


  ngOnInit(): void {
    this.conjugaisonService.getAvailableLanguages().subscribe(l => {
      this.languages =this.referenceDataService.getLanguages().filter(u => l.indexOf(u.id) >= 0);
      this.changeLanguage(this.learnLanguage);
    });
  }

  changeLanguage(languageCode: string): void {
    this.learnLanguage = languageCode;
    this.model = '';
    this.useFrenchHints = languageCode === 'fr';
    this.selectedVerbs = [];
    this.selectedTenses = [];
    this.year = undefined;

  }

  selectYear(y: number) {
    this.year = y;
    this.conjugaisonService.getAvailableVerbs(this.learnLanguage, this.year).subscribe(v => {
      this.verbs = v;
    });
    this.conjugaisonService.getAvailableTenses(this.learnLanguage, this.year).subscribe(t => {
      this.tenses = t;
    });
  }

  toggleVerb(v: string) {
    if (this.isVerbSelected(v)) {
      const i = this.selectedVerbs.indexOf(v);
      this.selectedVerbs.splice(i, 1);
    } else {
      this.selectedVerbs.push(v);
    }
  }
  isVerbSelected(v: string): boolean {
    return this.selectedVerbs.indexOf(v) >= 0;
  }

  toggleTense(v: string) {
    if (this.isTenseSelected(v)) {
      const i = this.selectedTenses.indexOf(v);
      this.selectedTenses.splice(i, 1);
    } else {
      this.selectedTenses.push(v);
    }

  }
  isTenseSelected(v: string): boolean {
    return this.selectedTenses.indexOf(v) >= 0;
  }
  validate():any {
     this.conjugaisonService.getItems(this.selectedVerbs,  this.learnLanguage, this.selectedTenses).subscribe(i => {
       this.selectedItems.emit({
          items:i,
          selectedVerbs: this.selectedVerbs,
          selectedTenses: this.selectedTenses,
          learnLanguage: this.learnLanguage,
          useFrenchHints: this.useFrenchHints
       });
     });
  }
}
