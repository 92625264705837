import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReferenceDataService, ListeService, ParcoursService, BookletService } from '@app/core/service';
import { Search, SearchNatureEnum } from '@app/model';

@Component({
    selector: 'nemo-filtre',
    templateUrl: './filtre.component.html',
    styleUrls: ['./filtre.component.scss'],
    standalone: true,
})
export class FiltreComponent implements OnInit {

  constructor(
    public router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly referenceDataService: ReferenceDataService,
    private readonly listService: ListeService,
    private readonly courseService: ParcoursService,
    private readonly bookletService: BookletService

  ) { }

  ngOnInit() {
    this.referenceDataService.updateDataFromApi();
    this.activatedRoute.url.subscribe(u => {
      const type = u[0].toString();
      const nature = +u[1].toString();
      const title = u[2].toString();
      const cats = u[3].toString().split('-');
      const filter = new Search();

      filter.title = title === '_' ? '' : title;
      filter.nature = nature;
      filter.categories = cats[0] === '_' ? [] : cats;
      
      if (type === 'listes') {
        this.listService.setSearchFilter(filter);
      } else if (type === 'parcours') {
        this.courseService.setSearchFilter(filter);
      } else if(type === 'folios'){
        this.bookletService.setSearchFilter(filter);
      }

      if (nature === 5) {
        this.router.navigate([type, 'pour-enseignant']);
      } else {
        this.router.navigate([type, 'rpn']);
      }
    });

  }

}
