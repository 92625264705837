import { Component, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { MediaService, ParcoursService } from '@app/core/service';
import { CourseItem } from '@app/model';
import { MediaDisplayModalComponent } from '@app/shared/components/media-display-modal/media-display-modal.component';
import { TextItemDisplayModalComponent } from '@app/shared/components/text-item-display-modal/text-item-display-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ParcoursItemStartComponent } from '../../../shared/components/parcours-item-start/parcours-item-start.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'nemo-panier',
    templateUrl: './panier.component.html',
    styleUrls: ['./panier.component.scss'],
    standalone: true,
    imports: [NgIf, RouterLink, NgFor, ParcoursItemStartComponent]
})
export class PanierComponent implements OnInit {

  items: CourseItem[] = [];
  constructor(
    private readonly parcoursService: ParcoursService,
    public router: Router,
    private readonly mediaService: MediaService,
    private readonly modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.items = this.parcoursService.getCartItems();
  }

  startItem(item: CourseItem) {
    this.parcoursService.setCurrentCourseUrl(this.router.url);
    const self = item.navigationUrl && item.navigationUrl.startsWith('/') && !item.navigationUrl.startsWith("/api/v1/medias");;
    item.done = true;
    if (item.itemType && item.itemType === 'Text') {
       const modalRef = this.modalService.open(TextItemDisplayModalComponent, {size: 'lg'});
          modalRef.componentInstance.item = item;
          modalRef.result.then(() => {
          }, () => {
          });

    } else {
      const mediaAction = this.mediaService.detectMediaActionToHandle(item.navigationUrl);
      if (self) {
        this.router.navigate([item.navigationUrl]);
      } else if (item.navigationUrl) {
        if (mediaAction.image || mediaAction.video || mediaAction.sound) {
          const modalRef = this.modalService.open(MediaDisplayModalComponent, {size: 'xl'});
          modalRef.componentInstance.item = item;
          modalRef.componentInstance.mediaAction = mediaAction;
          modalRef.result.then(() => {
          }, () => {
          });
        } else {
          window.open(item.navigationUrl, '_blank');
        }
      }
    }
  }
  removeItem(item: CourseItem): void {
    this.items.splice(this.items.indexOf(item), 1);
  }
  empty(): void {
    this.items = [];
  }
  createCourse(): void {
    this.items.forEach((i,u) => i.id = -u);
    this.parcoursService.createNew({
      items: this.items,
      title: 'Créé depuis le panier',
      description: '',
      planEtudeRomandIds:[],
      icon: 'bi bi-map-fill',
      color: 'c-grey',
      distributed: false,
      mine: true,
      id: 0,
      libre: false,
      rpn: false,
      teacher: false,
      progression: 0
    }).subscribe(res => {
      this.router.navigate(['/', 'parcours', 'personnels', res.id, 'edition']);
    });
  }
}
