import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'nemo-file-input',
  standalone: true,
  imports: [FormsModule,CommonModule],
  templateUrl: './file-input.component.html',
  styleUrl: './file-input.component.scss'
})
export class FileInputComponent {
  fileName:File;
  fileType: string;
  @Output() selectedFile=new  EventEmitter<File>(true);
  fileChange(e: File[]){
    this.fileName = e[0];
    this.fileType = this.fileName.type;
    this.selectedFile.emit(this.fileName);
  }
}
