<div class="row">
  <div class="col-md-9">
    <div *ngIf="grid" class="m-2">
      <div class="d-flex border-top border-start" *ngFor="let r of grid.cases">
        <div class="p-2 flex-fill case border-end border-bottom" *ngFor="let c of r" (click)="highlight(c)"
          [ngClass]="{'bg-info': c.selected && !c.success, 'bg-success': c.success, 'bg-warning': c.startWord && config.highlightFirstLetter && !c.success && !c.selected}">{{c.letter}}</div>
      </div>
    </div>
  </div>
  <div class="col-md-3">
    <ul class="list-unstyled">
      <li *ngFor="let w of grid.words" [ngClass]="{'found': w.found}">
        <nemo-item-text [text]="w.label" [enableSpeech]="false"></nemo-item-text>
      </li>
    </ul>
  </div>
</div>
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Régler</h4>
    <button type="button" class="btn-close" aria-label="btn-close" (click)="modal.dismiss()"></button>
  </div>
  <form>
    <ul class="list-group list-group-flush">
      <li class="list-group-item">
        <div class="d-flex flex-wrap py-1">
          <div class="flex-grow-1 align-self-center pe-2">
            <label class="lead form-check-label" for="highlightFirstLetter">Première lettre en surbrillance ?</label>
          </div>
          <div class="align-self-center">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" role="switch" id="highlightFirstLetter"  [(ngModel)]="config.highlightFirstLetter" [ngModelOptions]="{standalone: true}">
            </div>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="d-flex flex-wrap py-1">
          <div class="flex-grow-1 align-self-center pe-2">
            <label class="lead form-check-label" for="allowBackward">Mots dans le sens inverse autorisés ?</label>
          </div>
          <div class="align-self-center">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" role="switch" id="allowBackward"  [(ngModel)]="config.allowBackward" [ngModelOptions]="{standalone: true}">
            </div>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="d-flex flex-wrap py-1">
          <div class="flex-grow-1 align-self-center pe-2">
            <label class="lead form-check-label" for="allowVertical">Mots verticaux autorisés ?</label>
          </div>
          <div class="align-self-center">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" role="switch" id="allowVertical"  [(ngModel)]="config.allowVertical" [ngModelOptions]="{standalone: true}">
            </div>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="d-flex flex-wrap py-1">
          <div class="flex-grow-1 align-self-center pe-2">
            <label class="lead form-check-label" for="allowDiagonal">Mots en diagonale autorisés ?</label>
          </div>
          <div class="align-self-center">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" role="switch" id="allowDiagonal"  [(ngModel)]="config.allowDiagonal" [ngModelOptions]="{standalone: true}">
            </div>
          </div>
        </div>
      </li>
    </ul>
  </form>
  <div class="modal-footer">
    <small><a [routerLink]="[]" (click)="displayPreferences()">D'autres réglages sont disponibles dans les préférences.</a></small><button type="button" class="btn btn-outline-primary"  ngbAutofocus (click)="modal.close()">Valider</button>
  </div>
</ng-template>
