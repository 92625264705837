<div class="modal-header">
  <h4 class="modal-title">Sélection d'une liste iClasse</h4>
  <button type="button" class="btn-close" aria-label="btn-close" (click)="activeModal.dismiss()">

  </button>
</div>
<div class="modal-body" *ngIf="lists">
  <div class="input-group mb-3" *ngIf="lists.length>10">
    <input type="text" class="form-control" placeholder="Rechercher" aria-label="Rechercher"
      aria-describedby="button-addon2" (keyup)="filterlists($event)" [(ngModel)]="filter" maxlength="255">
    <button class="btn btn-outline-secondary" type="button" id="button-addon2" (click)="clearFilterlists()"><span
        class="bi bi-x-lg"></span></button>
  </div>
  <ul class="list-group list-group-flush">
    <a [routerLink]="[]" *ngFor="let list of filteredLists" class="list-group-item list-group-item-action"
      [ngClass]="{active: selectedList === list}" (click)="selectList(list)">
      <span class="{{list.icon}}"></span> {{list.title}}
    </a>
  </ul>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss()" ngbAutofocus>Annuler</button>
  <button type="button" [disabled]="!selectedList" class="btn btn-outline-primary"
    (click)="emitChanges()">Valider</button>
</div>
