<nemo-basepage>
  <div page-body>
    <div class="p-3 mb-2 p-md-5 mb-md-4 bg-body-tertiary rounded-3" *ngIf="!isLoggedIn">
      <div class="container-fluid py-2">
        <h1 class="display-5">
          <img class="flip-vertical-bck" alt="logo iClasse" src="/assets/icons/logo.png" /> iClasse
        </h1>
        <p class="lead">
          La plateforme iClasse permet aux enseignant·e·s de choisir librement des
          ressources numériques et de les distribuer aux élèves.
        </p>
        <p class="lead d-none d-lg-block">
          Les élèves utilisent les ressources à l'école, à leur domicile ou dans
          tout autre lieu connecté.
        </p>
        <p class="lead d-none d-lg-block">
          iClasse est une plateforme du
          <a href="http://www.rpn.ch" target="_blank" rel="noopener noreferrer">Réseau pédagogique neuchâtelois</a>.
          Elle est développée par le <a href="http://www.ne.ch/SIEN" target="_blank" rel="noopener noreferrer">Service informatique de l'entité neuchâteloise</a> (SIEN), sur mandat de l'<a href="http://www.ne.ch/oiso" target="_blank" rel="noopener noreferrer">Office de l'informatique scolaire et de
          l'organisation</a> (OISO) et en collaboration avec le conseil des <a href="http://api.ceras.ch/" target="_blank" rel="noopener noreferrer">Aides pédagogiques par l'informatique</a> (API) du Centre régional d'apprentissages spécialisés du CERAS.
        </p>
        <hr class="my-4" />
        <div class="d-flex flex-column flex-md-row align-self-center">
          <div class="mt-2 mt-md-0 me-0 me-md-2">
            <div class="d-grid gap-2">
              <a class="btn btn-primary btn-lg" role="button" href="api/v1/account/login"><span class="bi bi-box-arrow-in-right"></span> Se connecter</a>
            </div>
          </div>
          <div class="mt-2 mt-md-0">
            <div class="d-grid gap-2">
              <a class="btn btn-outline-secondary btn-lg" role="button" href="javascript:void(0)"
              [routerLink]="['/parcours/libre']"><span class="bi bi-map-fill"></span> Parcours publics</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="isLoggedIn">
      <div class="col-md-12">
        <h1 class="text-md-left display-5 my-3">
          <img class="flip-vertical-bck" alt="logo iClasse" src="/assets/icons/logo.png" />
          iClasse
        </h1>
      </div>
      <div class="col-md-4" *ngIf="isTeacher()">
        <div class="card text-center mb-2">
          <img src="/assets/accueil/folio.jpg" class="card-img" alt="observer la progression d'élèves">
          <div class="card-img-overlay text-white">
            <h5 class="card-title display-6"><span class="edicons edicons-visualidentity-folio"></span> Folios</h5>
          </div>
          <div class="card-body">
            <p class="card-text">Les folios sont des outils de suivi de la progression des apprentissages des élèves.</p>
            <div class="d-grid gap-2">
              <a [routerLink]="['../folios']" class="btn btn-outline-secondary stretched-link">Consulter les Folios disponibles <span class="bi bi-arrow-right"></span></a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4" *ngIf="selfEvaluationMenuEnabled">
        <div class="card text-center mb-2">
          <img src="/assets/accueil/folio.jpg" class="card-img" alt="Donner son avis sur les observations">
          <div class="card-img-overlay text-white">
            <h5 class="card-title display-6"><span class="bi bi-check-circle-fill"></span> Auto-évaluation</h5>
          </div>
          <div class="card-body">
            <p class="card-text">Tu peux donner ton avis sur les différentes progressions observées par les enseignant∙e∙s.</p>
            <div class="d-grid gap-2">
              <a [routerLink]="['../auto-evaluation']" class="btn btn-outline-secondary stretched-link">Donner ton avis <span class="bi bi-arrow-right"></span></a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4" [ngClass]="{'offset-md-2':!isTeacher() && !selfEvaluationMenuEnabled}">
        <div class="card text-center mb-2">
          <img src="/assets/accueil/parcours.jpg" class="card-img" alt="Parcours iClasse">
          <div class="card-img-overlay text-white">
            <h5 class="card-title display-6"><span class="bi bi-map-fill"></span> Parcours</h5>
          </div>
          <div class="card-body">
            <p class="card-text">Les parcours sont des ensembles ordonnés de ressources numériques.</p>
            <div class="d-grid gap-2">
              <a [routerLink]="['../parcours']" class="btn btn-outline-secondary stretched-link">Consulter les parcours disponibles <span class="bi bi-arrow-right"></span></a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card text-center mb-2">
          <img src="/assets/accueil/liste.jpg" class="card-img" alt="Listes iClasse">
          <div class="card-img-overlay text-white">
            <h5 class="card-title display-6"><span class="bi bi-card-list"></span> Listes</h5>
          </div>
          <div class="card-body">
            <p class="card-text">Les listes sont des ensembles de mots permettant d'effectuer des activités de mémorisation.</p>
            <div class="d-grid gap-2">
              <a [routerLink]="['../listes']" class="btn btn-outline-secondary stretched-link">Consulter les listes disponibles <span class="bi bi-arrow-right"></span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nemo-basepage>
