export class CourseItem {
  id: number;
  title?: string;
  description?: string;
  navigationUrl?: string;
  done?: boolean;
  icon?: string;
  color?: string;
  itemType?: string;
  progressionEnabled?: boolean;
  contentType?: string;
  publishedOn?: Date;
  tags?:string[];
  categories?:string[];

  constructor(model?: CourseItem) {
    if (model) {
      this.id = model.id;
      this.title = model.title;
      this.description = model.description;
      this.navigationUrl = model.navigationUrl;
      this.done = model.done;
      this.icon = model.icon;
      this.color = model.color;
      this.itemType = model.itemType;
      this.progressionEnabled = model.progressionEnabled;
      this.contentType = model.contentType;
      this.publishedOn = model.publishedOn;
      this.tags = model.tags;
      this.categories = model.categories;
    }
  }
}


