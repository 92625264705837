<nemo-basepage [title]="'Auto-évaluation'">
  <div  page-body *ngIf="evaluationBooklets">
    <div class="row py-2" *ngIf="evaluationBooklets.length === 0">
      <div class="col-md-12">
        <div class="alert alert-secondary" role="alert">
          <h4 class="alert-heading">Dommage, c'est encore vide par ici !</h4>
          <p>
            Il n'y a pour l'instant rien à valider dans les folios. Repassez plus tard.
          </p>
          <p>
            Consultez les <a [routerLink]="['../parcours']">parcours disponibles</a> ou les <a [routerLink]="['../listes']">listes disponibles</a>  en attendant...
          </p>
        </div>
      </div>
    </div>
    <p *ngIf="evaluationBooklets.length > 0">Tu peux donner ton avis sur les différents critères. L'avis de ton enseignant·e est parfois déjà affiché. Si tu n'es pas d'accord, tu peux écrire un commentaire.</p>
    <div *ngFor="let booklet of evaluationBooklets">
      <h2 class="mt-2">{{booklet.title}}</h2>
      <div class="card mb-2" *ngFor="let criteria of booklet.evaluatedCriterias">
        <div class="card-body">
          <div>
            <h3>
              {{criteria.criteriaLabel}}
            </h3>
          </div>
          <div class="alert alert-warning" *ngIf="criteria.observationState && criteria.selfObservationState && criteria.observationState !== criteria.selfObservationState" role="alert">
              Ton avis est différent de celui de ton enseignant·e. Tu peux lui écrire un commentaire.
          </div>
          <div class="row mt-2 pt-2 border-top">
            <div class="col-md-6 px-2" >
              <p class="lead">Avis de ton enseignant·e.</p>
              <nemo-evaluation *ngIf="criteria && booklet.scale && criteria.observationState"
                [evaluationCode]="criteria.observationState" [scale]="booklet.scale" [displayLabel]="true" [inline]="true"></nemo-evaluation>
              <blockquote class="blockquote mt-2" *ngIf="criteria.observationState || criteria.observationComment">
                <p *ngIf="criteria.observationComment">{{criteria.observationComment}}</p>
              </blockquote>
            </div>
            <div  class="col-md-6 border-start px-2">
              <p class="lead">Mon avis.</p>
              <nemo-evaluation [evaluationCode]="criteria.selfObservationState" [scale]="booklet.autoEvaluationScale" [edit]="true" (changed)="selfEvaluationChanged($event, criteria, booklet.autoEvaluationScale)" [displayLabel]="true" ></nemo-evaluation>
              <div *ngIf="criteria.observationState && criteria.selfObservationState && criteria.observationState !== criteria.selfObservationState">
                <blockquote class="blockquote mt-2" *ngIf="criteria.selfObservationComment">
                  <p *ngIf="criteria.selfObservationComment">{{criteria.selfObservationComment}}</p>
                </blockquote>
                <button type="button" class="btn btn-outline-secondary mt-2" tooltip="Commenter cette observation" tooltipPlacement="top"
                  [disabled]="criteria.checked" (click)="comment(criteria)"><span class="bi bi-chat-dots-fill"></span> Commenter</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nemo-basepage>
