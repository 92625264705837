export class MonitoredStudent {
  id: number;
  displayName: string;
  cloeeWorldId: number;

  constructor(model?: MonitoredStudent) {
    if (model) {
      this.id = model.id;
      this.displayName = model.displayName;
      this.cloeeWorldId = model.cloeeWorldId;
    }
  }
}
