<div class="p-2">
  <div [ngClass]="{'mb-2':item.description}">
    <div class="d-flex align-items-center">
      <div *ngIf="item.itemType !=='Text' && item.itemType!=='Title'">
        <nemo-logo [icon]="item.icon" [fixed]="true" [color]="item.color"></nemo-logo>
      </div>
      <div class="ms-1 flex-grow-1">
        <span [ngClass]="{'lead': (item.itemType ==='Link' || item.itemType ==='Media' || item.itemType ==='Activity'),'display-6': (item.itemType === 'Title' || item.itemType === 'Text')}" *ngIf="item.title !== null">{{item.title}}</span>
        <span class="badge category-tag" *ngIf="item.publishedOn">{{item.publishedOn | amLocale:'fr' |  amDateFormat:'MMMM YYYY'}}</span>
      </div>
  </div>
  <div class="bg-body-tertiary p-3 description mt-2 ql-editor" [innerHtml]="item.description" *ngIf="item.description && item.description.length > 0"></div>
</div>


