import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { ActivityService, SpeechSynthesisService } from '@app/core/service';
import { ActivityItem, ItemList, Progression } from '@app/model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgEventBus } from 'ng-event-bus';
import { Subscription } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { ItemTextComponent } from '../common/item-text/item-text.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'nemo-carte',
    templateUrl: './carte.component.html',
    styleUrls: ['./carte.component.scss'],
    animations: [
        trigger('itemState', [
            state('guess', style({
                opacity: 0
            })),
            state('learn', style({
                opacity: 1
            })),
            transition('guess => learn', animate('500ms ease-in')),
            transition('learn => guess', animate('0ms'))
        ])
    ],
    standalone: true,
    imports: [NgIf, ItemTextComponent, FormsModule, RouterLink]
})
export class CarteComponent implements OnInit, OnDestroy {
  @Input() list: ItemList;

  @Output() activityProgress = new EventEmitter<Progression>(true);
  @Output() activityEnd = new EventEmitter(true);

  @ViewChild('content', {
    static: true
  })
    private readonly configureBtnTemplate: TemplateRef<any>;


  private progression: Progression;
  public config: CarteConfig;
  public allItems = new Array<ActivityItem>();
  public currentItem: ActivityItem;
  public displayLearn = 'guess';
  public state = 'out';
  public ended = false;
  subscription: Subscription;
  keyobs: Subscription;

  constructor(public readonly router: Router,
    private readonly activityService: ActivityService,
    private readonly speechSynthesisService: SpeechSynthesisService,
    public readonly modalService: NgbModal,
    public readonly messageBusService: NgEventBus
  ) {
    this.subscription = activityService.configureActivity$.subscribe(
      conf => {
        this.configure();
    });
    this.keyobs = this.messageBusService.on('activite').subscribe(e =>{
      if(e.data === 'down') {
        this.display();
      }
      if(e.data === 'right') {
        this.endItem(true);
      }
      if(e.data === 'left') {
        this.endItem(false);
      }
    });
  }

  ngOnInit() {
    this.displayLearn = 'guess';
    this.state = 'out';
    this.progression = {
      success: 0,
      danger: 0,
      info: 0,
      warning: 0
    };

    this.activityProgress.emit(this.progression);
    this.allItems = [];
    this.list.items.forEach((item) => {
      this.allItems.push(new ActivityItem(item));
    });
    this.config = this.activityService.getConfig<CarteConfig>('carte', {
      sonorize: true,
      invert: false
    });
    if (this.config.invert) {
      const tempLanguage = this.list.hintLanguage;
      this.list.hintLanguage = this.list.learnLanguage;
      this.list.learnLanguage = tempLanguage;
      this.allItems.forEach(i => {
        const temp = i.hintTxt;
        i.hintTxt = i.learnTxt;
        i.learnTxt = temp;
      });
    }
    this.startItem();

  }
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
    this.keyobs.unsubscribe();
  }

  configure() {
    this.modalService.open(this.configureBtnTemplate, { ariaLabelledBy: 'modal-basic-title', size: 'lg' })
    .result.then((result) => {
      this.activityService.setConfig<CarteConfig>('carte', this.config);
      this.ngOnInit();
    }, (reason) => {
      this.ngOnInit();
    });
  }
  displayPreferences() {
    this.modalService.dismissAll();
    this.router.navigate(['/preferences/']);
  }
  startItem() {
    this.displayLearn = 'guess';
    this.state = 'in';
    this.activityService.shuffle(this.allItems);
    if (this.allItems.filter(i => !i.known).length > 0) {
      this.currentItem = this.allItems.filter(i => !i.known)[0];
      this.currentItem.attempt++;
      if (this.config.sonorize) {
        this.speakHint();
      }
    } else {
      this.activityEnd.emit();
    }
  }
  display() {
    this.displayLearn = 'learn';
    if (this.config.sonorize) {
      this.speakLearn();
    }
  }
  speakHint() {
    if (this.currentItem.hintTxt) {
      this.speechSynthesisService.speak(this.currentItem.hintTxt, this.list.hintLanguage);
    }
  }
  speakLearn() {
    this.speechSynthesisService.speak(this.currentItem.learnTxt, this.list.learnLanguage);
  }
  endItem(acquired: boolean) {
    if(this.displayLearn === 'learn') {
      this.state = 'out';
      this.currentItem.known = acquired;
      this.progression.success =
        Math.round((this.allItems.filter(i => i.known && i.attempt === 1).length / this.allItems.length) * 100);
      this.progression.warning =
        Math.round((this.allItems.filter(i => (!i.known && i.attempt === 1) || i.attempt > 1).length / this.allItems.length) * 100);
      this.activityProgress.emit(this.progression);
      this.startItem();
    }

  }
  restart() {
    this.ngOnInit();
  }
}

export class CarteConfig {
  sonorize: boolean;
  invert: boolean;
}
