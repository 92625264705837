<div class="card mb-2" ngif="user">
  <div class="d-flex">
    <div class="flex-shrink-1 item-icon p-2 align-self-center">
      <nemo-logo [color]="'c-grey'" [icon]="icon" [fixed]="true"></nemo-logo>
    </div>
    <div class="p-2 flex-fill flex-grow-1 align-self-center">
      <ng-content></ng-content>
    </div>
    <div class="p-2 align-self-center flex-shrink-1" *ngIf="dropUser.observed">
      <button type="button" class="btn btn-outline-secondary btn-sm" tooltip="Supprimer"
        tooltipPlacement="top" (click)="drop()"><span class="bi bi-trash-fill"></span></button>
    </div>
  </div>
</div>
