import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { InputValidationService, ListeService } from '@app/core/service';
import { FormsModule } from '@angular/forms';
import { BasepageComponent } from '../../../../shared/components/basepage/basepage.component';

@Component({
    selector: 'nemo-outil-texte',
    templateUrl: './outil-texte.component.html',
    styleUrls: ['./outil-texte.component.scss'],
    standalone: true,
    imports: [BasepageComponent, FormsModule]
})
export class OutilTexteComponent {

  text = '';

  constructor(private readonly listeService: ListeService,
  private readonly inputValidationService: InputValidationService,
  private readonly router: Router) { }

  generateContent(): void {
    const list = this.listeService.extractListFromText(this.inputValidationService.cleanText(this.text));
    this.listeService.cloneList(list).subscribe(r => this.router.navigate(['./listes/personnelles/',r.id,'edition']));
  }

}
