import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
    selector: '[nemoFocus]',
    standalone: true,
})
export class FocusDirective {
  constructor(private readonly element: ElementRef, private readonly renderer: Renderer2) {}

  setFocus() {
    let top = document.documentElement && document.documentElement.scrollTop;
    let isIE = false;

    if (top) {
      isIE = true;
    } else {
      top = document.body.scrollTop;
    }
    this.element.nativeElement.focus();
    if (!isIE) {
      document.body.scrollTop = top;
    } else {
      document.documentElement.scrollTop = top;
    }
  }
}
