import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MediaUploadComponent } from '../media-upload/media-upload.component';

@Component({
    selector: 'nemo-media-upload-modal',
    templateUrl: './media-upload-modal.component.html',
    styleUrls: ['./media-upload-modal.component.scss'],
    standalone: true,
    imports: [MediaUploadComponent]
})
export class MediaUploadModalComponent {

  @Input() mediaUrl: string;
  @Input() downloadEnabled = true;
  @Input() onlyImages = true;
  mediaName: string;
  mediaIcon: string;
  mediaType: string;

  constructor(public activeModal: NgbActiveModal) { }

  update(value: any) {
    this.mediaUrl = value.url;
    this.mediaIcon = value.icon;
    this.mediaName = value.name;
    this.mediaType = value.type;
  }


}
